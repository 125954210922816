import { combineReducers } from "redux";
import eventTypesReducer from "./event-types/slice";
import eventsReducer from "./events/slice";
import { GenerateSlice } from "../../../utils";
import { default as sessionStorage } from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";

import { slice as publicSlice } from "./public/slice";

export const reducer = combineReducers({
  eventtypes: eventTypesReducer,
  events: eventsReducer,
  operators: GenerateSlice("operators").reducer,
  calendareventitems: GenerateSlice("calendareventitems").reducer,
  public: persistReducer(
    { key: "calendar.calendar_public", storage: sessionStorage },
    publicSlice.reducer
  ),
});