export function AddYears(d, years) {
  if (!d) {
    return d;
  }

  var date = d;
  if (typeof(date) === "string") {
    date = new Date(date);
  }

  date.setFullYear(date.getFullYear() + years);
  return date;
}
