export function SelectedEntities(entities, ids) {
  const _entities = [];
  ids.forEach((id) => {
    const entity = entities.find((el) => el.id === id);
    if (entity) {
      _entities.push(entity);
    }
  });
  return _entities;
}
