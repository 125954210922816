import React from "react";
import { useFormikContext, Field } from "formik";
import { Input } from "../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import { CountryLookupField } from "./CountryLookupField";
import { CountyLookupField } from "./CountyLookupField";

const getValueFromStringPath = (obj, path) => {
  return path.split(".").reduce(function (o, k) {
    return o && o[k];
  }, obj);
};

export function AddressPickerForm({
  address,
  country,
  county,
  municipality,
  zipcode,
  disabled = false,
  required = [],
}) {
  const intl = useIntl();
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext();

  return (
    <>
      <div className="form-group row">
        <div className="col-6 col-md-2">
          <CountryLookupField 
            country={country} 
            disabled={disabled} 
            required={required === "all" || required.includes(country) ? true : false}
          />
        </div>
        <div className="col-6 col-md-2">
          <CountyLookupField 
            county={county} 
            disabled={disabled} 
            required={required === "all" || required.includes(county) ? true : false}
          />
        </div>
        <div className="col-6 col-md-2">
          <Field
            type="text"
            name={zipcode}
            placeholder={intl.formatMessage({ id: "PARTY.ZIPCODE" })}
            label={intl.formatMessage({ id: "PARTY.ZIPCODE" })}
            component={Input}
            disabled={disabled}
            required={required === "all" || required.includes(zipcode) ? true : false}
          />
        </div>
        <div className="col-12 col-md-6">
          <Field
            type="text"
            name={municipality}
            placeholder={intl.formatMessage({ id: "PARTY.MUNICIPALITY" })}
            label={intl.formatMessage({ id: "PARTY.MUNICIPALITY" })}
            component={Input}
            disabled={disabled}
            required={required === "all" || required.includes(municipality) ? true : false}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-12">
          <Field
            type="text"
            name={address}
            placeholder={intl.formatMessage({ id: "PARTY.ADDRESS" })}
            label={intl.formatMessage({ id: "PARTY.ADDRESS" })}
            component={Input}
            disabled={disabled}
            required={required === "all" || required.includes(address) ? true : false}
          />
        </div>
      </div>
    </>
  );
}
