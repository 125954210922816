import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { API } from "aws-amplify";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { GetTenant } from "../functions";
import * as authActions from "../../modules/AuthCognito/_redux/authActions";

async function onboard(tenant) {
  let apiName = "PaymentApi";
  let path = tenant.stripeAccountId ? "/pay/refresh-onboard" : "/pay/onboard";
  let myInit = {
    body: {
      tenantId: tenant.id,
    },
    headers: {
      "Content-Type": "application/json",
    },
  };

  return API.post(apiName, path, myInit);
}

export function StripeConnect() {
  const intl = useIntl();

  const { tenant, user } = useSelector(
    (state) => ({
      tenant: GetTenant(),
      user: state.auth.user,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authActions.refreshCurrentUser(user.id));
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const onHide = () => {
    setShowError(false);
    setIsLoading(false);
    setIsModalOpen(false);
  };

  const choseOnboardAndRedirect = () => {
    setShowError(false);
    setIsLoading(true);

    onboard(tenant)
    .then((response) => {
      window.location.href = response.redirect;
    })
    .catch((e) => {
      setShowError(true);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <>
      <div className="container border rounded border-1 p-3 px-4">
        <h4>
          <span className="fas fas fa-dollar-sign text-success"></span>

          {" "}
          Accetta pagamenti online
        </h4>

        <div className="py-2 mb-2">
          La creazione di un account Stripe ti permetterà di
          ricevere pagamenti online da parte dei tuoi clienti.
        </div>

        <button 
          onClick={() => setIsModalOpen(true)}
          className="stripe-connect"
          disabled={tenant.stripeRegistrationCompleted}
        >
          <span>Registrati a</span>
        </button>
        {tenant.stripeRegistrationCompleted && (
          <div className="mt-2">
            <span className="text-success fas fa fa-check"></span>
            {" "}
            <span className="text-muted">Il tuo account Stripe è collegato!</span>
          </div>
        )}
      </div>

      <Modal show={isModalOpen} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="fas fas fa-dollar-sign text-success"></span>
            {" "}
            <span>Accetta pagamenti con Stripe</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-justify">
            <span>
              Prima di procedere alla registrazione del tuo account Stripe collegato
              a DTR-Service assicurati di avere a disposizione i dati richiesti:
            </span>
            <br/><br/>
            <ul>
              <li>Email e numero di cellulare.</li>
              <li>Informazioni generali sulla tua attività (partita iva, indirizzo, ecc).</li>
              <li>Codici del conto bancario.</li>
            </ul>
            <br/><br/>
            <div className="w-100 text-center">
              <button 
                onClick={choseOnboardAndRedirect}
                className="stripe-connect"
                disabled={isLoading || tenant.stripeRegistrationCompleted}
              >
                <span>Registrati a</span>
              </button>
            </div>
            {isLoading && (
              <div className="w-100 text-center mt-5">
                <span className="spinner spinner-dark"></span>
                <span className="ml-10 text-success">Verrai reindirizzato tra qualche secondo...</span>
              </div>
            )}
            {showError && (
              <div className="w-100 text-center mt-5 text-danger">
                <span className="fas fa fa-ban"></span>
                {" "}
                <span>Errore durante il reindirizzamento verso Stripe</span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              <FormattedMessage id="SERVICE.CANCEL" />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
