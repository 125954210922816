import React from "react";
import Can from "../../AWS/Can";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { getMenuItemActive } from "../../../utils";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Aside({ isPartner }) {
  const location = useLocation();

  return (
    <>
      {isPartner && (
        <Can I="view" a="service_manager_bi">
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              location,
              "/dtrbi"
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className={"menu-link menu-toggle "} to="/dtrbi">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                />
              </span>
              <span className={"menu-text "}>
                <FormattedMessage id="SERVICE.ASIDE.DTRBI" />
              </span>
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">
                      <FormattedMessage id="SERVICE.ASIDE.DTRBI" />
                    </span>
                  </span>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    location,
                    "/dtrbi/ecr_communication"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className={"menu-link "}
                    to="/dtrbi/ecr_communication"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className={"menu-text "}>
                      <FormattedMessage id="SERVICE.ASIDE.DTRBI.ECR_COMMUNICATION" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        </Can>
      )}
    </>
  );
}
