import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { GetValueFromStringPath } from "../../../../app/utils";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    //classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  required = false,
  autocomplete = "off",
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      {required && (
        <>
          {" "}
          <strong className="text-danger">*</strong>
        </>
      )}
      <input
        type={type}
        autoComplete={autocomplete}
        className={getFieldCSSClasses(GetValueFromStringPath(touched, field.name), GetValueFromStringPath(errors, field.name))}
        onWheel={(e) => e.target.blur()}
        {...field}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={GetValueFromStringPath(errors, field.name)}
          touched={GetValueFromStringPath(touched, field.name)}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
