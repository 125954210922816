import React from "react";
import { ToItalianTime } from "../../";

export const CreatedAtColumnFormatter = (
	cellContent,
	row,
	rowIndex
) => (
	<span>
		{row && row.createdAt ? ToItalianTime(new Date(row.createdAt)) : "-"}
	</span>
);