import { takeEvery, put, delay } from "redux-saga/effects";
import { SET_CURRENT_ECR, SET_CURRENT_ECR_ASYNC, REFRESH } from "./types";

export function* setCurrentCashRegisterSaga() {
  yield takeEvery(SET_CURRENT_ECR, changeCashRegister);
}

function* changeCashRegister(action) {
  yield put({ type: SET_CURRENT_ECR_ASYNC, payload: action.payload });
  yield delay(1000);
  yield put({ type: REFRESH });
}
