import { SET_CURRENT_ECR, SET_CURRENT_ECR_ASYNC } from "./types";

function setCurrentCashRegister(ecrId) {
  return function (dispatch) {
    dispatch({ type: SET_CURRENT_ECR, payload: ecrId });
  };
}

function setCurrentCashRegisterAsync(ecrId) {
  return function (dispatch) {
    dispatch({ type: SET_CURRENT_ECR_ASYNC, payload: ecrId });
  };
}

export const actions = {
	setCurrentCashRegister,
	setCurrentCashRegisterAsync,
}