import React from "react";
import { Aside } from "../../../AuthCognito/components/Aside";
import { DTRServiceLogo } from "../dashboard/components/DTRServiceLogo";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

export function TenantTermsOfService({
  showAside = true,
  showBackButton = true,
}) {
  const intl = useIntl();
  const history = useHistory();

  //Warning: This is the PRODUCTION S3 BUCKET
  const documentUrl =
    "https://coristechda3c2209cf6940b7976e4d383712a5ea142526-prod.s3.eu-central-1.amazonaws.com/public/tos/TENANT_TOS.pdf";

  const privacyPolicyUrl = "https://www.dtr-service.it/privacy-policy";

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
        id="kt_login"
      >
        {showAside && <Aside />}

        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
          {showBackButton && (
            <div className="container-fluid p-0 m-0">
              <button
                type="button"
                onClick={() => history.goBack()}
                className="btn btn-light"
              >
                <i className="fa fa-arrow-left"></i>
                <FormattedMessage id="SERVICE.BACK" />
              </button>
            </div>
          )}

          {showAside && <DTRServiceLogo dynamic={false} />}

          <div className="row">
            <div className="col-0 col-md-1"></div>
            <div className="col-12 col-md-10">
              <h5 className="text-center">
                Termini di Utilizzo dei Servizi attraverso Portale web
                DTR-Service per il Tenant
              </h5>
              <br />
              <p className="text-justify">
                <span className="font-weight-bold">1. Definizioni.</span>{" "}
                Qualora riportati nel Termini di utilizzo, i termini hanno il
                seguente significato:
                <br />
                <u>24/7/365</u>: acronimo utilizzato nei Termini di utilizzo per
                indicare la continuità di accesso al Portale 24 ore su 24, sette
                giorni alla settimana, 365 giorni all’anno;
                <br />
                <u>Credenziali di accesso</u>: login e password assegnate da DTR
                Italy al Tenant;
                <br />
                <u>Dealer</u>: il concessionario rivenditore di DTR Italy;
                <br />
                <u>DTR Italy</u>: DTR Italy s.r.l. con sede legale a Milano
                20131, via G. Luosi, 14, codice fiscale e Partita IVA n.
                10244350962, indirizzo PEC: dtritaly@pec.it, titolare e
                fornitore dei Servizi attraverso il Portale DTR-Service;
                <br />
                <u>Parti</u>: DTR Italy e il Tenant;
                <br />
                <u>Portale</u>: il sito web DTR-Service (
                <a href="https://www.dtr-service.it/" target="_blank">
                  https://www.dtr-service.it/
                </a>
                ) di DTR Italy;
                <br />
                <u>Servizi</u>: i servizi di DTR Italy forniti attraverso il
                Portale e attivati dal Dealer di riferimento;
                <br />
                <u>Tenant</u>: il cliente finale che acquista o noleggia un
                Registratore Telematico (RT) di DTR Italy o abbia acquistato un
                Servizio dal Dealer di riferimento;
                <br />
                <u>Termini di utilizzo</u>: il presente scritto.
                <br />
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">2. Oggetto.</span>I seguenti
                Termini di utilizzo disciplinano l'accesso e l'utilizzo di
                DTR-Service ovvero un Portale web dove il Tenant ha l’accesso
                unicamente in quanto preventivamente legato al proprio Dealer di
                riferimento o dal contratto di attivazione di un proprio RT o da
                un contratto di assistenza post-vendita o da un contratto di
                servizi (RT User o RT Utility-Tenant). I presenti Termini di
                utilizzo si applicano, unitamente alla informativa sulla
                privacy, consultabile al seguente link: (
                <a
                  href="https://www.dtr-service.it/privacy-policy/"
                  target="_blank"
                >
                  Informativa sulla Privacy
                </a>
                ), per la navigazione all’interno del Portale. Il Tenant deve
                quindi leggere attentamente i presenti Termini di utilizzo e
                l’Informativa sulla privacy e/o stamparne una copia per futura
                consultazione. Utilizzando il Portale, il Tenant dichiara di
                aver letto, compreso e accettato i presenti Termini di utilizzo
                e l'Informativa sulla privacy e di conformarsi a essi. Qualora
                il Tenant non accetti o non possa conformarsi ai presenti
                Termini di utilizzo o all'Informativa sulla privacy, deve
                cessare immediatamente l’utilizzo del Portale. DTR Italy si
                riserva di modificare i presenti Termini di utilizzo dandone
                evidenza sul Portale; le modifiche entreranno in vigore nel
                termine indicato nell’avviso sul Portale con il dovere del
                Tenant, qualora non le accetti, di cessare immediatamente
                l’utilizzo del Portale.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  3. Modifiche al Portale.
                </span>
                Il Portale e i suoi contenuti saranno aggiornati periodicamente
                a discrezione di DTR Italy. DTR Italy non garantisce che il
                Portale o qualsiasi contenuto in esso presente siano esenti da
                errori od omissioni. In caso di quesiti riguardanti il Portale,
                i Termini di utilizzo o qualora il Tenant desideri entrare in
                contatto con DTR Italy per qualsiasi motivo, lo invitiamo ad
                inviare una email all’indirizzo: marketing@dtr-italy.com.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">4. Accesso al Portale.</span>
                Per accedere al Portale e per l’erogazione dei Servizi attivati
                dal Dealer di riferimento, il Tenant deve registrarsi accettando
                espressamente tutte le clausole dei presenti Termini di utilizzo
                e fornendo tutti i dati richiesti. Il Tenant è a conoscenza ed
                accetta che, con la fruizione del Servizio RT User, l’RT del
                Tenant invierà automaticamente ad ogni chiusura Z al server di
                DTR Italy una serie di informazioni personali (matricola,
                versione e data del firmware installato; data e ora e numero
                dell'ultima chiusura gestionale Z; dettaglio della chiusura
                giornaliera Z in termini di documenti emessi, tipo di documenti,
                aliquote IVA movimentate, tipi di pagamento gestiti, vendite per
                reparto commerciale, report di vendita per fascia oraria). Per
                poter accedere al Portale ed utilizzare i Servizi anche se
                attivati dal Dealer di riferimento, il Tenant deve essere
                dotato, a proprio onere e carico, di un accesso al World Wide
                Web (internet), o direttamente oppure tramite strumenti che
                consentono l’accesso ai contenuti situati sul Web, nonché
                dell’attrezzatura necessaria per la connessione ad internet. DTR
                Italy non garantisce la disponibilità locale, temporale e
                qualitativa dell’infrastruttura internet necessaria per
                l’accesso al Portale o per il trasferimento dei dati e, in
                particolare, non garantisce che le operazioni relative ai
                Servizi attivati dal Dealer di riferimento possano essere
                eseguite entro un lasso temporale prestabilito. L’accesso e
                l’utilizzo del Portale è compatibile con i seguenti Browser:
                <br />
                • Chrome dalla versione 84.0.4147 e successive;
                <br />
                • Firefox dalla versione 78.0.0 e successive;
                <br />
                • Edge dalla versione 84.0.522 e successive;
                <br />
                • Safari dalla versione 13.1 e successive
                <br />
                Non è invece compatibile con Internet Explorer.
                <br />
                DTR Italy non ha server propri ma si avvale di server di terze
                parti. <br />
                DTR Italy potrà, in ogni momento, interrompere l’acceso al
                Portale o la prestazione dei Servizi attivati dal Dealer di
                riferimento qualora ricorrano motivate ragioni di sicurezza e/o
                garanzia di riservatezza, dandone in questo caso comunicazione
                al Tenant nel più breve tempo possibile. Il Tenant riconosce e
                accetta che DTR Italy possa interrompere l’accesso al Portale o
                ai Servizi, anche se stipulati con il Dealer di riferimento,
                senza preavviso qualora riceva una comunicazione e/o richiesta
                da qualsiasi Autorità competente amministrativa, arbitrale o
                giudiziaria. In caso di malfunzionamento dell’infrastruttura,
                del Portale o dei Servizi messi a disposizione del Tenant, DTR
                Italy adotterà ogni mezzo ragionevolmente necessario per porvi
                rimedio nel minor tempo possibile, salvo i casi in cui i guasti
                siano dovuti a fatti non direttamente imputabili a DTR Italy.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  5. Diritti ed Obblighi del Tenant.
                </span>
                Il Tenant ha diritto di accedere al Portale e i Servizi 24/7/365
                salvo i casi di sospensione o interruzione previsti nei presenti
                Termini di utilizzo. Il Tenant garantisce che i dati e le
                informazioni trasmesse a DTR Italy in fase di Registrazione al
                Portale sono veritiere, corrette e tali da consentire la sua
                identificazione, e si impegna a comunicare a DTR Italy ogni
                variazione dei medesimi. Qualora il Tenant, al momento della
                Registrazione abbia, anche mediante l’utilizzo di documenti
                personali e/o societari non veri, celato la propria reale
                identità o dichiarato falsamente di essere altro soggetto, o
                comunque agito in modo tale da compromettere il processo di
                identificazione, egli prende atto ed accetta che sarà ritenuto,
                anche penalmente, responsabile per le dichiarazioni mendaci e/o
                l’utilizzo di falsa documentazione e sarà altresì considerato
                esclusivamente responsabile di tutti i danni subiti e subendi da
                DTR Italy e/o da terzi, assumendo sin da ora l’obbligo di
                manlevare e mantenere indenne DTR Italy da ogni eventuale
                pretesa, azione e/o richiesta di indennizzo o risarcimento danni
                che dovesse essere avanzata da chiunque nei loro confronti. Le
                credenziali di autenticazione al Portale non possono, in alcun
                modo, essere cedute o utilizzate da terzi senza il permesso e la
                preventiva autorizzazione espressa di DTR Italy. Il Tenant si
                impegna ad utilizzare il Portale nel rispetto delle norme
                vigenti, perciò evitando tassativamente di: a) compiere attività
                illecite tramite il Portale o i Servizi anche se attivati dal
                Dealer di riferimento; b) compiere azioni che discreditino
                l’immagine di DTR Italy; c) compiere azioni che possano arrecare
                danni materiali o immateriali ad altri soggetti di qualsiasi
                natura; d) tentare di sondare, esaminare, penetrare o testare la
                vulnerabilità del Portale o delle relative procedure di
                autenticazione o dei Servizi anche se attivati dal Dealer di
                riferimento, sia con tecniche passive che invasive, senza
                l’espresso consenso scritto di DTR Italy; e) utilizzare il
                Portale o i Servizi anche se attivati dal Dealer di riferimento
                per violare, contravvenire o far contravvenire le leggi vigenti
                dello Stato italiano o in qualsiasi altro Stato. Il Tenant sarà
                il solo responsabile di qualsiasi utilizzo del Portale e dei
                Servizi anche se attivati dal Dealer di riferimento e
                dell’infrastruttura da parte dei propri collaboratori o da parte
                di qualsiasi persona alla quale il Tenant stesso abbia fornito
                una o più chiavi di accesso che permettano di accedere al
                Portale e ai Servizi o ad una parte dello stesso. Il Tenant sarà
                altresì esclusivamente responsabile di qualsiasi conseguenza
                connessa allo smarrimento della propria chiave di accesso.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  6. Obblighi e limiti di responsabilità di DTR Italy.{" "}
                </span>
                Gli obblighi e le responsabilità di DTR Italy verso il Tenant
                sono esclusivamente quelli definiti dai presenti Termini di
                utilizzo. Il Tenant prende atto ed accetta che DTR Italy non
                risponda in nessun modo di alcun danno, diretto o indiretto, di
                qualsiasi natura o specie subito dal Tenant stesso e/o da Terzi,
                in conseguenza dell’utilizzo del Portale o dei Servizi anche se
                attivati dal Dealer di riferimento. DTR Italy si impegna ad
                assicurare la migliore funzionalità del Portale e dei Servizi
                anche se attivati dal Dealer di riferimento, ma non assume
                alcuna responsabilità sia verso i Tenant sia verso Terzi per
                ritardi, cattivo funzionamento, sospensione e/o interruzione
                nell'erogazione dei Servizi anche se attivati dal Dealer di
                riferimento o al Portale determinati da cause ad essa non
                imputabili, quali a titolo esemplificativo e non esaustivo: a)
                caso fortuito, eventi catastrofici o di forza maggiore; b) fatto
                del terzo, anche fornitore di DTR Italy; c) malfunzionamento o
                non conformità degli apparecchi di connessione di cui il Tenant
                si è dotato o comunque di quelli dal medesimo utilizzati; d)
                manomissione o interventi sui Servizi anche se attivati dal
                Dealer di riferimento o sulle apparecchiature del Tenant o da
                parte di soggetti Terzi non autorizzati da DTR Italy; e) guasti
                e malfunzionamenti dell’hardware e dei software, siano essi di
                proprietà di DTR Italy e/o dei suoi fornitori.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  7. Diritti di proprietà intellettuale.
                </span>
                Il Tenant è tenuto ad utilizzare il Portale e i Servizi anche se
                attivati dal Dealer di riferimento nel rispetto dei diritti di
                proprietà intellettuale e/o industriale di DTR Italy. Il Portale
                e i Servizi anche se attivati dal Dealer di riferimento come
                qualsiasi altro diritto di autore o altro diritto di proprietà
                intellettuale ad essi inerenti sono di proprietà esclusiva di
                DTR Italy e/o dei suoi danti causa, pertanto il Tenant non
                acquista nessun diritto o titolo al riguardo ed è tenuto
                all’utilizzo degli stessi soltanto nel periodo di vigenza
                contrattuale.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  8. Sospensione accesso al Portale e dei Servizi.
                </span>
                Il Tenant accetta che DTR Italy, a sua discrezione e senza che
                l'esercizio di tale facoltà possa essergli contestata come
                inadempimento o violazione del Termini di utilizzo, si riservi
                la facoltà di sospendere o interrompere anche definitivamente
                l’acceso al Portale o ai Servizi anche se attivati dal Dealer di
                riferimento, anche senza alcun preavviso nel caso in cui: a) il
                Tenant si renda inadempiente o violi anche una soltanto delle
                disposizioni contenute nel Termini di utilizzo; b) il Tenant
                ometta di riscontrare, in tutto o in parte, le richieste
                legittime di DTR Italy e comunque il suo comportamento sia tale
                da ingenerare il fondato e ragionevole timore che egli si renda
                inadempiente ai Termini di utilizzo o responsabile di una o più
                violazioni alle sue disposizioni; c) vi siano fondate ragioni
                per ritenere che il Portale o i Servizi anche se attivati dal
                Dealer di riferimento siano utilizzati da Terzi non autorizzati;
                d) si verifichino casi di forza maggiore o circostanze che, ad
                insindacabile giudizio di DTR Italy, impongano di eseguire
                interventi di emergenza o relativi alla risoluzione di problemi
                di sicurezza, pericolo per l’intera rete e/o per persone o cose;
                in tal caso, i Servizi anche se attivati dal Dealer di
                riferimento saranno ripristinato quando DTR Italy, a sua
                discrezione, abbia valutato che siano state effettivamente
                rimosse o eliminate le cause che avevano determinato la sua
                sospensione/interruzione; e) il Tenant si trovi coinvolto, a
                qualsiasi titolo, in una qualsiasi controversia giudiziale o
                anche stragiudiziale di natura civile, penale o amministrativo e
                comunque nel caso in cui detta controversia abbia ad oggetto
                l’utilizzo del Portale o dei Servizi anche se attivati dal
                Dealer di riferimento; f) qualora ricorrano motivate ragioni di
                sicurezza e/o garanzia di riservatezza; g) qualora il Tenant
                utilizzi il Portale o i Servizi anche se attivati dal Dealer di
                riferimento a scopi illegali, inclusa la violazione di leggi
                sulla proprietà intellettuale ed industriale, sui diritti
                d’autore, sul copyright o di altri diritti di proprietà di terze
                parti, o in modo illegale o per la creazione o distribuzione di
                contenuti illegali oppure violando norme vigenti nello Stato
                italiano o in qualsiasi altro Stato. In qualsiasi caso di
                sospensione dell’acceso al Portale o dei Servizi anche se
                attivati dal Dealer di riferimento imputabile al Tenant resta
                impregiudicata l’eventuale azione di DTR Italy per il
                risarcimento del danno. Durante la detta sospensione, a
                qualsiasi causa dovuta, il Tenant non potrà avere accesso a dati
                e/o informazioni e/o contenuti dal medesimo immessi e/o trattati
                mediante il Portale o i Servizi anche se attivati dal Dealer di
                riferimento. Resta inteso che in tali casi, DTR Italy non
                risponderà di alcuna perdita, danno o lesione subiti e/o subendi
                dal Tenant e/o da Terzi, siano essi diretti o indiretti,
                prevedibili o imprevedibili, tra i quali a titolo
                esemplificativo e non esaustivo perdite economiche/finanziarie,
                di affari, di ricavi e di utili e/o di avviamento commerciale;
                il Tenant, pertanto, prende atto ed accetta che nulla avrà da
                pretendere da DTR Italy a titolo di risarcimento, indennizzo,
                rimborso o ad altro titolo.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">9. Diffida. </span>
                DTR Italy si riserva di contestare per iscritto al Tenant una o
                più violazione dei presenti Termini di utilizzo mediante
                raccomandata a.r. o posta elettronica certificata, recante
                l’espressa intimazione a cessare, entro quindici giorni dal
                ricevimento della comunicazione, la violazione contestata.
                Trascorso inutilmente detto termine, DTR Italy sarà autorizzata
                ad interrompere permanentemente l’accesso al Portale e ai
                Servizi anche se attivati dal Dealer di riferimento, fermo
                restando il diritto della Parte non inadempiente al risarcimento
                del danno.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  {" "}
                  10. Trattamento dei dati e Riservatezza.{" "}
                </span>
                DTR Italy dichiara al Tenant che i dati personali sono trattati
                nel rispetto della normativa vigente sulla protezione dei dati
                personali e che al link sopra indicato è disponibile
                l’Informativa in conformità alla normativa sul trattamento dei
                dati personali. Le Parti si obbligano reciprocamente a mantenere
                riservato qualsiasi tipo di informazione relativo alle
                reciproche attività e/o alle modalità di lavoro e/o alle
                soluzioni adottate di cui siano venute a conoscenza in
                dipendenza del presente Termini di utilizzo.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold"> 11. Clausole finali. </span>
                È espressamente vietato l'utilizzo del Portale per finalità
                diverse da quelle previste dai presenti Termini di utilizzo o
                contrarie alla normativa vigente. L’eventuale nullità di una o
                più clausole dei presenti Termini di utilizzo non incide sulla
                validità del presente accordo nel suo complesso e di conseguenza
                le parti concordano che il Giudice o qualsiasi terzo dovrà in
                ogni caso cercare di mantenere l'efficacia degli accordi
                intercorsi tra le Parti, come precisati nei presenti Termini di
                utilizzo e le altre disposizioni dei Termini di utilizzo
                rimarranno pienamente valide ed efficaci a tutti gli effetti. Il
                mancato esercizio o la mancata esecuzione da parte di DTR Italy
                di qualsiasi diritto o clausola riportata nei presenti Termini
                di utilizzo non costituisce, né può essere può essere
                interpretata quale rinuncia a tale diritto o clausola. I titoli
                degli articoli del presente Termini di utilizzo sono indicati
                solamente ai fini di prontezza di riferimento e non hanno alcun
                effetto (vincolante) giuridico, così come i termini con la
                lettera maiuscola utilizzati al singolare hanno lo stesso
                significato dei termini con la lettera maiuscola utilizzati al
                plurale e viceversa. Tutte le comunicazioni la cui esecuzione è
                contemplata nei presenti Termini di utilizzo, o che comunque
                devono essere effettuate in relazione ai presenti Termini di
                utilizzo, dovranno effettuarsi per raccomandata con avviso di
                ricevimento o posta elettronica certificata e si intenderanno
                effettuate il giorno dell'invio per il mittente ed il giorno del
                ricevimento per il destinatario.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  {" "}
                  12. Legge applicabile e Foro competente.{" "}
                </span>
                I presenti Termini di utilizzo sono regolati, in via residuale e
                per tutto quanto in esso non previsto, dalla normativa vigente
                in Italia e sono sottoposti alla giurisdizione italiana. Il Foro
                competente per qualsiasi controversia dovesse insorgere a causa
                ed in conseguenza di tali Termini di utilizzo è, in via
                esclusiva, quello di Milano.
              </p>

              <br />
              <p>Versione aggiornata a aprile 2021.</p>

              <br />
              <p>
                <a href={documentUrl} download target="_blank">
                  SCARICA IL DOCUMENTO
                </a>
              </p>
            </div>
            <div className="col-0 col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
