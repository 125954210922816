/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
//import axios from "axios";
//import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
//import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-phone-input-2/lib/style.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import Amplify, { Analytics } from "aws-amplify";
import awsExports from "./aws-exports";
import Geocode from "react-geocode";
import { MetronicSplashScreenProvider } from "./_metronic/layout";
import { MetronicI18nProvider } from "./_metronic/i18n";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsExports?.oauth?.redirectSignIn?.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsExports?.oauth?.redirectSignOut?.split(",");

const updatedAwsConfig = {
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

Analytics.disable();

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

const { PUBLIC_URL } = process.env;

ReactDOM.render(
  <MetronicSplashScreenProvider>
    <MetronicI18nProvider>
      <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    </MetronicI18nProvider>
  </MetronicSplashScreenProvider>,
  document.getElementById("root")
);

