// Actions related to authentication
export const AUTH_USER = "auth_user";
export const AUTH_MFA = "auth_mfa";
export const AUTH_NEW_PASSWORD_REQUIRED = "auth_new_password_required";
export const UNAUTH_USER = "unauth_user";
export const REGISTER_USER = "register_user";
export const REGISTER_USER_CONFIRM = "register_user_confirm";
export const REGISTER_MFA = "register_mfa";
export const REGISTER_USER_ERROR = "register_user_error";
export const FORGOT_PASSWORD = "forgot_password";
export const FORGOT_PASSWORD_CONFIRM = "forgot_password_confirm";
export const AUTH_ERROR = "auth_error";
export const AUTH_ERROR_CLEAR = "auth_error_clear";
// Actions associated with Your own API
export const REQRES_FETCH_ALL_SUCCESS = "reqres_fetch_all_success";
export const REQRES_CREATE_SUCCESS = "reqres_create_success";
export const REQRES_ERROR = "reqres_error";
export const HYDRATE_USER = "hydrate_user";

export const COMPLETE_REGISTRATION = "complete_registration";
export const UPDATE_USER_PARTY = "update_user_party";
export const UPDATE_TENANT_PARTY = "update_tenant_party";
export const UPDATE_TENANT = "update_tenant";
export const UPDATE_USER = "update_user";
export const REFRESH_CURRENT_USER = "refresh_current_user";
export const INCREMENT_ITEM_SORTING = "increment_item_sorting";
export const INCREMENT_ITEMGROUP_SORTING = "increment_itemgroup_sorting";
export const CHANGE_LOGIN_TYPE = "change_login_type";
export const CANCEL_FORGOT_PASSWORD = "cancel_forgot_password";
export const CANCEL_REGISTRATION = "cancel_registration";
export const FORCE_REFRESH_TOKEN = "force_refresh_token";
export const SET_USER_TYPE = "set_user_type";
export const PENDING_SOCIAL_SIGN_IN = "pending_social_sing_in";