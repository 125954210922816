import React from "react";
import { Route as RouterRoute } from "react-router-dom";
import { useHistory } from 'react-router-dom';

const fullPathByType = (basePath, idSlug, type) => {
  switch (type) {
    case "delete":
      return basePath + "/:id/delete";
    case "new":
      return basePath + "/new";
    case "edit":
      return basePath + "/:id/edit";
    case "moveSorting":
      return basePath + "/:id/moveSorting";
    case "copy":
      return basePath + "/:id/copy";
    case "enableAll":
      return basePath + "/enableAll";
    case "addQtyOnHand":
      return basePath + "/:id/addQtyOnHand";
    case "removeQtyOnHand":
      return basePath + "/:id/removeQtyOnHand";
    case "multipleDelete":
      return basePath + "/multipleDelete";
    case "massiveNew":
      return basePath + "/massiveNew";
    default:
      return idSlug ? (basePath + "/:id/" + type) : (basePath + "/" + type);
  }
};

export function Route({ UIContext, basePath, idSlug = false, type, Component, componentProps }) {
  const history = useHistory();

  return (
    <RouterRoute path={fullPathByType(basePath, idSlug, type)}>
      {({ history, match }) => (
        <Component
          UIContext={UIContext}
          show={match != null}
          history={history}
          id={match && match.params.id}
          copy={type === "copy"}
          onHide={() => {
            history.push(basePath);
          }}
          {...componentProps}
        />
      )}
    </RouterRoute>
  );
}
