import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import BootstrapTable from "react-bootstrap-table-next";
import * as columnFormatters from "./column-formatters";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { LoadingCard } from "../LoadingCard";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { GetTenant } from "../../functions/GetTenant";
import { LookupField } from "../../lookup/LookupField";
import { CSVExport } from "../CSVExport";
import { YYYYMMDD } from "../../functions/date/YYYYMMDD";
import * as restApi from "../../../modules/Admin/rest/api";
import { actions } from "../../../modules/Admin/_redux/retailstorelicenses/actions";

export function LicenseReport({ retailStoreLicenses, filename = null, isLoading = false, openEcrReportDialog = null }) {
  const intl = useIntl();

  const { partner } = useSelector(
    (state) => ({
      partner: GetTenant(),
    }),
    shallowEqual
  );

  const [data, setData] = useState([]);
  const [opened, setOpened] = useState(undefined);
  const [selectedTenantId, setSelectedTenantId] = useState("all");

  useEffect(() => {
    var byLicense = {};

    retailStoreLicenses.forEach(rsl => {
      if (selectedTenantId !== "all" && rsl.tenantId !== selectedTenantId) {
        return;
      }

      if (Object.keys(byLicense).includes(rsl.licenseId)) {
        byLicense[rsl.licenseId] = byLicense[rsl.licenseId].concat(rsl);
      }
      else {
        byLicense[rsl.licenseId] = [rsl];
      }
    });

    setData(byLicense);

    if (Object.keys(byLicense).length > 0 && !opened) {
      setOpened(byLicense[Object.keys(byLicense)[0]]);
    }
  }, [retailStoreLicenses, selectedTenantId]);

  const dispatch = useDispatch();

  const [autorenewIsLoadingId, setAutorenewIsLoadingId] = useState(undefined);

  const toggleAutorenew = (rslId) => {
    setAutorenewIsLoadingId(rslId);

    restApi.toggleAutorenew(rslId)
    .then(() => {
      dispatch(actions.refreshRecordOnEntities(rslId))
      .then(() => {
        setAutorenewIsLoadingId(undefined);
      });
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "formattedNumber",
      text: intl.formatMessage({ id: "SERVICE.CODE" }),
      formatter: columnFormatters.NumberColumnFormatter,
      formatExtraData: {
        openEcrReportDialog: openEcrReportDialog,
      },
    },
    {
      dataField: "applicant",
      text: intl.formatMessage({ id: "SERVICE.LICENSE_HOLDER" }),
      formatter: columnFormatters.ApplicantColumnFormatter,
    },
    {
      dataField: "duration",
      text: intl.formatMessage({ id: "SERVICE.DURATION" }),
      formatter: columnFormatters.DurationColumnFormatter,
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "SERVICE.STATUS" }),
      formatter: columnFormatters.ActivatedStatusColumnFormatter,
    },
    {
      dataField: "isFreeBadger",
      text: intl.formatMessage({ id: "SERVICE.TYPE" }),
      formatter: columnFormatters.IsFreeColumnFormatter,
    },
    {
      dataField: "autorenew",
      text: intl.formatMessage({ id: "SERVICE.AUTORENEW" }),
      formatter: columnFormatters.AutorenewColumnFormatter,
      formatExtraData: {
        toggleAutorenew: toggleAutorenew,
        autorenewIsLoadingId: autorenewIsLoadingId,
        partner: partner,
      },
    },
  ];

  const fwDownloadColumns = [
    {
      dataField: "firmware",
      text: intl.formatMessage({ id: "RT_USER.FIRMWARE" }),
    }
  ];

  const getColumnsByLicenseId = (licenseId) => {
    switch (licenseId) {
      case "9b362f09-b8c4-42f2-ab7c-527a0fe6ae5d": //Fw download
        return fwDownloadColumns.concat(columns.filter(el => !["duration", "status"].includes(el.dataField)));
      case "2dd7f09e-fd03-49cb-9c82-147de29394d9": //EJ extension
        return columns.filter(el => !["duration", "status"].includes(el.dataField));
      case "f2892650-5c25-4ef4-b250-f2df4b16bfde": //EInvoice   
      case "53546483-a7b1-4366-9c68-bf4c23ec6c88": //Atorder
      case "32357de7-594b-4c76-9d55-83b3c8aeed9c": //Atorder
      case "10d1611d-f86b-4eb7-87f5-7b2774842fa5": //calendar
      case "1cdf900d-748b-41cf-9061-bba90a67fbfa": //forvalue
        return columns.filter(el => !["formattedNumber"].includes(el.dataField));
      default:
        return columns;
    }
  };

  const getLicenseCommercialName = (records) => {
    return records && records.length > 0 && records[0].license && records[0].license.commercialName ? records[0].license.commercialName : "";
  };
  
  const onClick = (licenseId) => {
    if (opened === licenseId) {
      setOpened(undefined);
    }
    else {
      setOpened(licenseId);
    }
  };

  const getLogo = (licenseId) => {
    switch (licenseId) { 
      case "10d1611d-f86b-4eb7-87f5-7b2774842fa5":
        return "/media/coristech/logos/CALENDAR_3x.png";
        
      case "1cdf900d-748b-41cf-9061-bba90a67fbfa":
        return "/media/coristech/logos/for_value.jpg";

      case "53546483-a7b1-4366-9c68-bf4c23ec6c88":
      case "32357de7-594b-4c76-9d55-83b3c8aeed9c":
        return "/media/coristech/logos/atorder.png";

      case "f2892650-5c25-4ef4-b250-f2df4b16bfde":
        return "/media/coristech/logos/einvoice.png";

      case "9eae171e-93df-4b42-a2ee-41014e1a5d1c":
      case "8eb9062d-ebc1-4170-b400-8eda55eb2872":
        return "/media/coristech/logos/rt_user.png";

      case "f4e74abd-64ff-47d0-a887-b6058e240fb0":
      case "9b362f09-b8c4-42f2-ab7c-527a0fe6ae5d":
      case "2dd7f09e-fd03-49cb-9c82-147de29394d9":
        return "/media/coristech/logos/rt_utility.png";

      case "3eebc758-3b87-4423-8c14-59f89b67ce4f":
      case "bff77d8b-bfab-45b3-be66-4588bf7070d1":
      case "9d92dd27-6f88-4a6f-80c6-dcb53fa31aa0":
      case "25818ff7-dc7b-4723-9aac-fa0c6b7c15c9":
      case "559cb818-8ecb-4d80-b7d0-dafb45799b58":
      case "9ae29c06-76bb-4ada-9379-91425c3b616e":
      case "31dd24f6-0c72-4afd-a324-04c5e5e1661d":
      case "e10c5f96-dd90-47a1-9299-c67725d3a644":
      case "b02a485d-90fa-4485-9de9-f12b988a1b00":
      case "7a09ca36-7bed-42df-bae4-662591ae9aec":
      case "aa0abe1a-892d-4c54-8e5f-294a2e146710":
      case "2e49a6e6-d45c-488d-b8b1-81b547d5bd19":
        return "/media/coristech/logos/moitoiot.png";

      default:
        return "";
    }
  };

  const getCode = (rsl) => {
    if (rsl.number) {
      return rsl.number;
    }
    if (rsl.moitoIotCode) {
      return rsl.moitoIotCode;
    }
    return "";
  };
  

  const getTenantName = (rsl) => {
    if (rsl.ecrCompanyName) {
      return rsl.ecrCompanyName;
    }
    return rsl?.tenant?.companyName || "";
  }

  const getTenantCompanyVat = (rsl) => {
    if (rsl.ecrCompanyVat) {
      return rsl.ecrCompanyVat;
    }
    return rsl?.tenant?.companyVat || ""; 
  }

  return (
    <div className="w-100">
      <div className="row form-group mt-4"> 
        <div className="col-md-4 col-6">
          <LookupField
            onChange={(val) => setSelectedTenantId(val)}
            labelField="companyName"
            defaultValue={{ value: "all", label: "Tutti" }}
            topOption={{ value: "all", label: "Tutti" }}
            query={"searchTenants"}
            queryFilter={{ defaultPartnerCode: { eq: partner.shortCode } }}
          />

          <small className="form-text text-muted">
            <FormattedMessage id="SERVICE.FILTER_BY_CUSTOMER" />
          </small>
        </div>
        <div className="col-md-4 col-0">
        </div>
        <div className="col-md-4 col-6 text-right">
          <CSVExport
            items={retailStoreLicenses}
            fields={["license.commercialName", getCode, getTenantName, getTenantCompanyVat, "autorenew", "validFrom", "validTo", "isFree"]}
            headers={["licenza", "codice", "cliente", "piva_cliente", "rinnovo_automatico", "valida_da", "valida_a", "free"]}
            filename={filename ? filename : ("LICENZE_ATTIVATE_" + YYYYMMDD(new Date()) + ".csv")}
          />
        </div>
      </div>
      {!isLoading ? (
        <>
          <div className="w-100 my-3">
            {Object.keys(data).map((licenseId, idx) => (
              <div className={"card p-0 my-2 border rounded " + (opened === licenseId ? "border-primary" : "")} key={licenseId}>
                <div 
                  className={"card-body mt-1 p-3 cursor-pointer border-bottom " + (opened === licenseId ? "border-primary" : "")} 
                  onClick={() => onClick(licenseId)}
                >
                  <div className="row">
                    <div className="col-1 align-self-center">
                      <h3 className="d-inline-block">{data[licenseId].length}</h3>
                      <span className="float-right text-muted">{" x "}</span>
                    </div>
                    <div className="col-4 align-self-center">
                      <h6 className="d-inline-block">{getLicenseCommercialName(data[licenseId])}</h6>
                    </div>
                    <div className="col-6">
                      <img
                        className="img-fluid p-0 m-0"
                        src={toAbsoluteUrl(getLogo(licenseId))}
                        style={{maxHeight: 75}}
                      />
                    </div>
                    <div className="col-1 align-self-center">
                      <span className={"fas fa-2x fa-caret-" + (opened === licenseId ? "down" : "up")}></span>
                    </div>
                  </div>
                </div>
                {licenseId === opened && (
                  <div className="card-body p-2 text-center">
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center"
                      bootstrap4
                      keyField="id"
                      data={data[licenseId]}
                      columns={getColumnsByLicenseId(licenseId)}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="w-100 text-center">
          <LoadingCard />
        </div>
      )}
    </div>
  );
}
