import { getConfig } from "../../../_metronic/i18n/Metronici18n";

const langPriority = ["it", "en", "fr", "de", "es", "ru", "ar", "zh"];

function getByLangPriority(translationText, index) {
  if (index > langPriority.length) {
    return "";
  }

  const target = translationText.translations.items.find(
    (el) => el.languageCode === langPriority[index]
  );
  if (target) {
    return target.text;
  }
  return getByLangPriority(translationText, index + 1);
}

export function GetText(translationText, lang = null) {
  const currentLang = getConfig();
  const targetLanguageCode = !lang
    ? currentLang
      ? currentLang.selectedLang
      : "it"
    : lang;

  if (translationText) {
    const target = translationText.translations.items.find(
      (el) => el.languageCode === targetLanguageCode
    );
    if (target) {
      return target.text;
    } else {
      return getByLangPriority(translationText, 0);
    }
  }

  return "";
}
