//"29/05/2023"
export function FromDDMMYYYYtoDateObj(str_date, date_splitter = "/") {
	const splittedDate = str_date.split(date_splitter);

	const result = new Date(
		splittedDate[2].slice(0, 4),
		parseInt(splittedDate[1]) - 1,
		splittedDate[0]
	);
	
	return result;
}