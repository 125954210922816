import React from "react";
import { Aside as AdminAside } from "../../Admin/components/Aside";
import { Aside as AtorderAside } from "../../Atorder/components/Aside";
import { Aside as EInvoiceAside } from "../../EInvoice/components/Aside";
import { Aside as RTUserAside } from "../../RTUser/components/Aside";
import { Aside as RTUtilityAside } from "../../RTUtility/components/Aside";
import { Aside as DTRBIAside } from "../../DTRBI/components/Aside";
import { Aside as MoitoIotAside } from "../../MoitoIOT/components/Aside";
import { Aside as CalendarAside } from "../../Calendar/components/Aside";
import { Aside as ForValueAside } from "../../ForValue/components/Aside";
//import { Aside as EJManagementAside } from "../../EJManagement/components/Aside";
import { Aside as DTROpticalAside } from "../../DTROptical/components/Aside";

export function ServiceAside({ isPartner, loginType }) {
  return (
    <>
      {loginType === 1 ? (
        <AtorderAside isPartner={isPartner} />
      ) : loginType === 2 ? (
        <EInvoiceAside isPartner={isPartner} />
      ) : loginType === 3 ? (
        <RTUserAside isPartner={isPartner} />
      ) : (
        <>
          <AdminAside isPartner={isPartner} />
          <DTRBIAside isPartner={isPartner} />
          <RTUserAside isPartner={isPartner} />
          <RTUtilityAside isPartner={isPartner} />
          <EInvoiceAside isPartner={isPartner} />
          <AtorderAside isPartner={isPartner} />
          <MoitoIotAside isPartner={isPartner} />
          <CalendarAside isPartner={isPartner} />
          <ForValueAside isPartner={isPartner} />
          {/*<EJManagementAside isPartner={isPartner} />*/}
          <DTROpticalAside isPartner={isPartner} />
        </>
      )}
    </>
  );
}
