import React, { useState, useEffect } from "react";
import ImageUploader from "react-images-upload";
import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { differenceWith, isEqual } from "lodash";

export function ItemImagesUploader({
  setFieldValue,
  fieldName,
  defaultImages,
  setImageCount = null,
  tenantId,
}) {
  const [logos, setLogos] = useState([]);

  const putImages = (files) => {
    var puts = [];
    files.forEach((file) => {
      puts.push(
        new Promise((resolve, reject) => {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            var base64file = reader.result;
            if (!logos.map((el) => el.base64).includes(base64file)) {
              resolve(
                Storage.put(tenantId + "/items/" + uuidv4() + file.name, file).then(
                  (result) => {
                    setLogos((old) => [
                      ...old,
                      { key: result.key, base64: base64file },
                    ]);
                  }
                )
              );
            }
            resolve(true);
          };
        })
      );
    });
    return Promise.all(puts);
  };

  const onImageChange = (files, dataUrls) => {
    putImages(files).then(() => {
      const base64ToRemove = differenceWith(
        logos.map((el) => el.base64),
        dataUrls,
        isEqual
      );
      setLogos((old) => [
        ...old.filter((el) => !base64ToRemove.includes(el.base64)),
      ]);
      //setFieldValue(fieldName, [...logos.filter(el => !base64ToRemove.includes(el.base64)).map(el => el.key)]);
    });
  };

  useEffect(() => {
    let unmounted = false;

    if (defaultImages && defaultImages.length > 0) {
      defaultImages.forEach((el) => {
        Storage.get(el, { download: true }).then((logo) => {
          var reader = new FileReader();
          reader.readAsDataURL(logo.Body);
          reader.onloadend = function () {
            var base64data = reader.result;
            if (!unmounted) {
              setLogos((old) => [...old, { key: el, base64: base64data }]);
            }
          };
        });
      });
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFieldValue(fieldName, [...logos.map((el) => el.key)]);
    if (setImageCount) {
      setImageCount(logos.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logos]);

  return (
    <>
      {/*
			<br/>
			<label className="text-center"><FormattedMessage id="SERVICE.UPLOAD_CUSTOM_IMAGES" /></label>
			<br/>
			*/}
      <ImageUploader
        withLabel={false}
        buttonText="Upload"
        onChange={(files, dataUrls) => onImageChange(files, dataUrls)}
        imgExtension={[".jpg", ".png", ".jpeg"]}
        maxFileSize={5242880}
        withPreview={true}
        singleImage={false}
        defaultImages={logos.map((el) => el.base64)}
      />
    </>
  );
}
