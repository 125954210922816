import React from "react";
import Can from "../../AWS/Can";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { getMenuItemActive } from "../../../utils";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ability from "../../AWS/ability";

export function Aside() {
  const location = useLocation();

  return (
    <Can I="view" a="atorder">
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(location, [
          "/atorder",
          "/retail/rooms",
          "/retail/locations",
        ])}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <NavLink className={"menu-link menu-toggle "} to="/atorder">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
          </span>
          <span className={"menu-text "}>
            <FormattedMessage id="SERVICE.ASIDE.ATORDER" />
          </span>
        </NavLink>
        <div className="menu-submenu">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            <li className="menu-item menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text">
                  <FormattedMessage id="SERVICE.ASIDE.ATORDER" />
                </span>
              </span>
            </li>

            {ability.can("view", "room") && ability.can("access", "atorder_delivery") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/retail/rooms"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/retail/rooms">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="RETAIL.ROOM.ROOMS" />
                  </span>
                </NavLink>
              </li>
            )}

            {ability.can("view", "location") && ability.can("access", "atorder_delivery") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/retail/locations"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/retail/locations">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="RETAIL.LOCATION.LOCATIONS" />
                  </span>
                </NavLink>
              </li>
            )}

            <Can I="view" a="atorder_item_group">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/atorder/itemgroups"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/atorder/itemgroups">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.ATORDER.ITEMGROUPS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="atorder_item">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/atorder/items"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/atorder/items">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.ATORDER.ITEMS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="atorder_sales_order">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/atorder/salesorders"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/atorder/salesorders">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.ATORDER.ORDERS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="atorder_settings">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/atorder/settings"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/atorder/settings">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.ATORDER.SETTINGS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <li
              className={`menu-item ${getMenuItemActive(
                location,
                "/atorder/help"
              )}`}
              aria-haspopup="true"
            >
              <NavLink className={"menu-link "} to="/atorder/help">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className={"menu-text "}>
                  <FormattedMessage id="SERVICE.ASIDE.ATORDER.HELP" />
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
    </Can>
  );
}
