import Allergens from "../../common/allergens/Allergens";

export function GetAllergenObjectByIconCode(code) {
  var allergenObj = {};

  Allergens.forEach((allergen) => {
    const obj = allergen[Object.keys(allergen)[0]];

    obj.icons.forEach((el) => {
      if (el.code === code) {
        allergenObj = obj;
        return;
      }
    });

    if (allergenObj) {
      return;
    }
  });

  return allergenObj;
}
