export function appendToExtensions(
  obj,
  moduleName,
  key,
  value,
  stringify = true
) {
  var result = JSON.parse(JSON.stringify(obj));

  if (Object.keys(result).includes("extensions")) {
    if (!result.extensions) {
      result.extensions = {};
    }

    if (Object.keys(result.extensions).includes(moduleName)) {
      result.extensions = { ...result.extensions };
      result.extensions[moduleName] = { ...result.extensions[moduleName] };
      result.extensions[moduleName][key] = value;
    } else {
      result.extensions[moduleName] = { [key]: value };
    }
  } else {
    result["extensions"] = { [moduleName]: { [key]: value } };
  }

  if (stringify) {
    result.extensions = JSON.stringify(result.extensions);
  }

  return result;
}

export function getFromExtensions(obj, moduleName, key, otherwise = null) {
  if (
    obj &&
    obj.extensions &&
    Object.keys(obj.extensions).includes(moduleName) &&
    Object.keys(obj.extensions[moduleName]).includes(key)
  ) {
    return obj.extensions[moduleName][key];
  }
  return otherwise;
}
