import React from "react";
import { Card as MetronicCard, CardBody } from "../../../_metronic/_partials/controls";
import { FormattedMessage } from "react-intl";

export function LoadingCard() {

  return (
    <MetronicCard>
      <CardBody className="text-center w-100">
        <div className="d-flex align-items-center">
          <div className="spinner spinner-dark mr-10 text-center"></div>
          <FormattedMessage id="SERVICE.COMPONENT_LOADING_MESSAGE" />
        </div>
      </CardBody>
    </MetronicCard>
  );
}
