import * as Yup from "yup";
import { IsNumber } from "./IsNumber";
import * as restApi from "../../modules/Admin/rest/api";
import DTRModels from "../../common/cashregisters/dtr_models";
import { YupCacheValidation } from "./YupCacheValidation";

const checkExistanceOnDb = async (value, checkNotExistance) => {
  return new Promise((resolve, reject) => {
    if (value && value.length === 11) {
      restApi.checkEcrNumber(value)
      .then(result => {
        if (result?.data?.exists) {
          resolve(!checkNotExistance ? false : true);
        }
        else {
          resolve(!checkNotExistance ? true : false);
        }
      })
      .catch((error) => {
        resolve(!checkNotExistance ? false : true);
      });
    } else {
      resolve(!checkNotExistance ? false : true);
    }
  });
}

const checkRTUserExistanceOnDb = async (value) => {
  return new Promise((resolve, reject) => {
    if (value && value.length === 11) {
      restApi.checkLicenseExistance("9eae171e-93df-4b42-a2ee-41014e1a5d1c", value)
      .then(result => {
        if (result?.data?.exists) {
          resolve(false);
        }
        else {
          resolve(true);
        }
      })
      .catch((error) => {
        resolve(false);
      });
    } else {
      resolve(false);
    }
  });
}


export function ECRNumberValidator(
  dispatch,
  intl,
  required = true,
  checkExistance = true,
  checkRTUserExistance = false,
  checkNotExistance = false,
) {
  var result = Yup.string()
    .length(11, intl.formatMessage({ id: "RT_USER.NUMBER_11_CHARS" }))
    .test(
      "DTR-Italy ECR",
      intl.formatMessage({ id: "RT_USER.NOT_DTR_MACHINE" }),
      (val) => (val ? val.substring(0, 2) === "4C" : true)
    )
    .test(
      "Invalid number",
      intl.formatMessage({ id: "RT_USER.INVALID_NUMBER" }),
      (val) => (required ? !!val : true)
    )
    .test("Type", intl.formatMessage({ id: "RT_USER.INVALID_TYPE" }), (val) =>
      val ? val.substring(2, 3) === "I" || val.substring(2, 3) === "E" : true
    )
    .test(
      "Serial",
      intl.formatMessage({ id: "RT_USER.INVALID_SERIAL_NUMBER" }),
      (val) => (val ? IsNumber(val.substring(5, 11)) : true)
    )
    .test("Model", intl.formatMessage({ id: "RT_USER.INVALID_MODEL" }), (val) =>
      val
        ? DTRModels.map((el) => el.code).includes(val.substring(3, 5))
          ? true
          : false
        : true
    )
    .test(
      "Uppercase",
      intl.formatMessage({ id: "SERVICE.UPPERCASE_ONLY" }),
      (val) => (val ? val.toUpperCase() === val : true)
    );

  if (checkExistance || checkNotExistance) {
    const checkExistanceOnDbTest = YupCacheValidation((value) => checkExistanceOnDb(value, checkNotExistance));

    result = result.test(
      "checkNumberExistance",
      intl.formatMessage({ id: checkExistance ? "RT_USER.NUMBER_EXISTANCE_ERROR" : "RT_USER.NUMBER_NOT_EXISTANCE_ERROR" }),
      checkExistanceOnDbTest
    );
  }

  if (checkRTUserExistance) {
    const checkRTUserExistanceOnDbTest = YupCacheValidation((value) => checkRTUserExistanceOnDb(value));

    result = result.test(
      "checkRTUserExistance",
      intl.formatMessage({ id: "RT_USER.NUMBER_EXISTANCE_ERROR" }),
      checkRTUserExistanceOnDbTest
    );
  }

  if (required) {
    return result.required(
      intl.formatMessage({ id: "RT_USER.NUMBER_IS_MANDATORY" })
    );
  }
  return result;
}
