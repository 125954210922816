import { takeEvery, put, delay } from "redux-saga/effects";
import {
  SET_CURRENT_TENANT,
  SET_CURRENT_TENANT_ASYNC,
  REFRESH,
} from "./tenantTypes";

export function* setCurrentTenantSaga() {
  yield takeEvery(SET_CURRENT_TENANT, changeTenant);
}

function* changeTenant(action) {
  yield put({ type: SET_CURRENT_TENANT_ASYNC, payload: action.payload });
  yield delay(1000);
  yield put({ type: REFRESH });
}
