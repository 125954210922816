import React from "react";
import { FormattedMessage } from "react-intl";

export function IsFreeColumnFormatter(cellContent, row, rowIndex) {
  return (
    <span>
      {row.isFree ? (
        <span className="label label-lg label-light-warning label-inline">
          <FormattedMessage id="SERVICE.FREE" />
        </span>
      ) : (
        <span className="label label-lg label-light-info label-inline">
          <FormattedMessage id="SERVICE.BILLABLE" />
        </span>
      )}
    </span>
  );
}
