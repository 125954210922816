import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: {
    items: [],
    nextToken: undefined,
    prevTokens: [],
  },
  entity: undefined,
  lastError: null,
};
export const SliceCallTypes = {
  list: "list",
  action: "action",
};

export const GenerateSlice = (name) => {
  return createSlice({
    name: name,
    initialState: initialState,
    reducers: {
      catchError: (state, action) => {
        state.error = `${action.type}: ${JSON.stringify(action.payload.error)}`;
        if (action.payload && action.payload.callType === SliceCallTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      startCall: (state, action) => {
        state.error = null;
        if (action.payload && action.payload.callType === SliceCallTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },
      setEntity: (state, action) => {
        state.actionsLoading = false;
        state.entity = action.payload;
        if (state.entity) {
          state.entity.extensions = state.entity.extensions ? JSON.parse(state.entity.extensions) : {};
        }
        state.error = null;
      },
      objectFetched: (state, action) => {
        state.actionsLoading = false;
        state.entity = action.payload.entity;
        if (state.entity) {
          state.entity.extensions = state.entity.extensions ? JSON.parse(state.entity.extensions) : {};
        }
        state.error = null;
      },
      refreshRecordOnEntities: (state, action) => {
        state.actionsLoading = false;

        state.entity = action.payload.entity;
        if (state.entity) {
          state.entity.extensions = state.entity.extensions ? JSON.parse(state.entity.extensions) : {};
        }

        state.entities = {
          items: [
            ...state.entities.items.filter(el => el.id !== state.entity.id),
            state.entity,
          ],
          nextToken: state.entities.nextToken,
          prevTokens: state.entities.prevTokens,
        };

        state.error = null;
      },
      objectsFetched: (state, action) => {
        const { totalCount, items, nextToken, prevToken, operation } = action.payload;
        state.listLoading = false;
        state.error = null;

        switch (operation) {
          case "overwrite":
            //go ahead
            if (!state.entities.prevTokens.filter(el => !!el).includes(nextToken)) {
              state.entities = {
                items: items.map((el) => {
                  return { ...el, extensions: el.extensions ? JSON.parse(el.extensions) : {} };
                }),
                nextToken: nextToken,
                prevTokens: [...new Set([...state.entities.prevTokens, prevToken])],
              };
            }
            else {
              //go back
              state.entities = {
                items: items.map((el) => {
                  return { ...el, extensions: el.extensions ? JSON.parse(el.extensions) : {} };
                }),
                nextToken: nextToken,
                prevTokens: state.entities.prevTokens.filter(el => el !== nextToken),
              };
            }
            break;
          case "append":
            state.entities = {
              items: [
                ...state.entities.items,
                ...items.map((el) => {
                  return { ...el, extensions: el.extensions ? JSON.parse(el.extensions) : {} };
                }),
              ],
              nextToken: nextToken,
              prevTokens: [...state.entities.prevTokens, prevToken],
            };
            break;
          default:
            break;
        }

        state.totalCount = totalCount;
      },
      objectsCreated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;

        Object.keys(action.payload.objects).forEach(function (key) {
          state.entities.items.push({
            ...action.payload.objects[key],
            extensions: action.payload.objects[key].extensions ? JSON.parse(action.payload.objects[key].extensions) : {},
          });
        });
      },
      objectDeleted: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entities.items = state.entities.items.filter((el) => el.id !== action.payload.id);
      },
      objectsDeleted: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entities.items = state.entities.items.filter((el) => !action.payload.ids.includes(el.id));
      },
      objectUpdated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        state.entity = action.payload.object;
        if (state.entity) {
          state.entity.extensions = action.payload.object.extensions ? JSON.parse(action.payload.object.extensions) : {};
        }
        
        state.entities.items = state.entities.items
          .map((entity) => {
            if (entity.id === action.payload.object.id) {
              return {
                ...action.payload.object,
                extensions: action.payload.object.extensions && typeof(action.payload.object.extensions) === "string" ? JSON.parse(action.payload.object.extensions) : (typeof(action.payload.object.extensions) === "object" ? action.payload.object.extensions : {}),
              };
            }
            return entity;
          });

        state.entities.items = state.entities.items.filter(entity => !entity.deleted);
      },
      objectsUpdated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        const { objects } = action.payload;
        state.entities.items = state.entities.items
          .map((entity) => {
            const found = objects.find((el) => el.id === entity.id);
            if (found) {
              return {
                ...found,
                extensions: found.extensions ? JSON.parse(found.extensions) : {},
              };
            }
            return entity;
          });
        state.entities.items = state.entities.items.filter(entity => !entity.deleted);
      },
      removeFromEntities: (state, action) => {
        state.entities.items = state.entities.items.filter((el) => el.id !== action.payload.id);
      },
      clearData: (state, action) => {
        state.listLoading = false;
        state.actionsLoading = false;
        state.totalCount = 0;
        state.entities = {
          items: [],
          nextToken: undefined,
          prevTokens: [],
        };
        state.entity = undefined;
        state.lastError = null;
      },
      clearEntityData: (state, action) => {
        state.entity = undefined;
      },
      addOnEntities: (state, action) => {
        state.entities.items.push({
          ...action.payload.entity,
          extensions: action.payload.entity?.extensions ? JSON.parse(action.payload.entity.extensions) : {},
        });
      },
      setEntities: (state, action) => {
        state.entities.items = [
          ...action.payload.entities.map(el => {
            return {
              ...el,
              extensions: el?.extensions ? JSON.parse(el.extensions) : {},
            };
          }),
        ];
      },
    },
  });
}
