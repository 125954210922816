import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function GetAtorderFoodImages() {
  var images = [];

  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon100.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon101.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon102.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon103.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon104.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon105.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon106.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon107.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon108.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon109.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon110.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon111.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon112.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon113.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon114.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon115.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon116.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon117.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon118.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon119.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon120.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon121.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon122.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon123.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon124.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon125.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon126.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon127.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon128.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon129.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon130.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon131.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon132.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon133.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon134.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon135.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon136.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon137.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon138.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon139.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon140.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon141.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon142.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon143.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon144.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon145.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon146.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon147.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon148.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon149.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon150.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon151.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon152.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon153.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon154.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon155.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon156.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon157.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon158.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon159.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon160.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon161.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon162.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon163.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon200.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon201.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon202.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon203.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon204.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon205.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon206.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon207.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon208.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon209.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon210.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon211.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon212.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon213.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon214.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon215.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon216.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon217.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon218.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon219.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon220.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon221.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon222.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon223.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon224.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon225.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon226.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon227.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon228.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon229.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon230.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon231.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon232.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon233.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon234.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon235.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon236.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon237.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon238.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon239.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon240.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon241.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon242.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon243.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon244.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon245.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon246.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon247.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon248.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon249.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon250.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon251.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon252.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon253.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon254.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon255.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon256.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon257.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon258.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon259.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon260.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon261.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon262.png"));
  images.push(toAbsoluteUrl("/media/coristech/atorder/food/icon263.png"));

  return images;
}
