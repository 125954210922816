export function TimeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return "-";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return "-";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return "-";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    var tmp_interval = interval * 60;
    if (tmp_interval > 999) {
      return "-";
    } else {
      return interval * 60 + " '";
    }
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " '";
  }
  return "0 '";
}
