import * as request from "./crud";
import { GenerateSlice, GenerateActions, GenerateFetchObjectsBy } from "../../../../utils/components/generic/_redux";

const sliceActions = GenerateSlice("notifications").actions;

export const actions = {
  ...GenerateActions(sliceActions, request, "Notification"),
  fetchObjectsByUser: (qp, af, nextToken, op) => GenerateFetchObjectsBy(
    sliceActions,
    request.findObjectsByUser,
    "notificationsByUser",
    qp,
    af,
    nextToken,
    op
  )
};
