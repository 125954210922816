import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingDialog as MetronicLoadingDialog } from "../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import { GetValueFromStringPath } from "../../functions/GetValueFromStringPath";

export function LoadingDialog({ path }) {
  const intl = useIntl();
  const { isLoading } = useSelector(
    (state) => ({ 
      isLoading: GetValueFromStringPath(state, path),
    }),
    shallowEqual
  );

  return (
    <MetronicLoadingDialog
      isLoading={isLoading}
      text={intl.formatMessage({ id: "SERVICE.LOADING" })}
    />
  );
}
