import React, { useState, useMemo, useEffect } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHtmlClassService } from "../../../../_metronic/layout/_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { DropdownTopbarItemToggler } from "../../../../_metronic/_partials/dropdowns";
import { FormattedMessage, useIntl } from "react-intl";
import { useFetchAll, TimeAgo, GetTenant } from "../../../utils";
import { actions } from "../_redux/notifications/actions";
import { orderBy } from "lodash";
import * as restApi from "../rest/api";
import { useHistory } from "react-router-dom";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {
  const intl = useIntl();
  const history = useHistory();

  const [key, setKey] = useState("Messages");
  const [isOpen, setIsOpen] = useState(false);
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  const { entities, nextToken, tenant, user } = useSelector(
    (state) => ({
      entities: state.user.notifications.entities.items,
      nextToken: state.user.notifications.entities.nextToken,

      tenant: state.auth.user ? GetTenant() : null,
      user: state.auth.user,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const queryParams = () => {
    return {
      pageSize: 1000,
      filter: {
        tenantId: { eq: tenant.id },
      },
    };
  };

  useFetchAll((operation) => {
    if (user && tenant) {
      return dispatch(
        actions.fetchObjectsByUser(
          user.id,
          queryParams(),
          null,
          nextToken,
          "append"
        )
      );
    }
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }, nextToken);

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isActive && user && tenant) {
        dispatch(
          actions.fetchObjectsByUser(
            user.id,
            queryParams(),
            null,
            nextToken,
            "append"
          )
        );
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [isActive]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const onDropdownClick = () => {
    if (!isOpen && entities.filter((el) => !el.status).length > 0) {
      restApi.readAllNotifications(tenant.id, user.id).then(() => {
        dispatch(
          actions.fetchObjectsByUser(
            user.id,
            queryParams(),
            null,
            nextToken,
            "append"
          )
        );
      });
    }
    setIsOpen((old) => !old);
  };

  const onNotificationClick = (notification) => {
    if (notification) {
      switch (notification.context ? notification.context : 0) {
        case 0:
          //Should never happen
          break;
        /* All notifications should open a page
        case 2:
          //License activation
          //License approvement
          //License revoke
          break;
        case 1:
        //New license request
        case 3:  // eslint-disable-line no-fallthrough
          //New partnership request
          history.push("/admin/onboarding");
          break;
        case 4:
          //Partnership approved
          break;
        case 5:
          //News
          if (notification.news) {
            history.push("/news/" + notification.news.id);
          } else {
            history.push("/news");
          }
          break;
        case 6:
          //Errors from RT configuration (confirmUpdates endpoint)
          break;
        case 7:
          //License near due date
          break;
        case 8:
          //Tech intervention not registered from ECR's dealer vat number
          history.push("/rt_utility/dashboard");
          break;
        */
        default:
          break;
      }
    }
  };

  const style = {
    pointer: {
      //cursor: "pointer",
    },
  };

  const printIcon = (context) => {
    return (
      <>
        {context === 1 ? (
          <i className="flaticon2-line-chart text-success"></i>
        ) : context === 2 ? (
          <i className="flaticon2-user flaticon2-line- text-info"></i>
        ) : context === 3 ? (
          <i className="flaticon2-sms text-success"></i>
        ) : context === 4 ? (
          <i className="flaticon2-settings text-success"></i>
        ) : context === 5 ? (
          <i className="flaticon-attachment text-primary"></i>
        ) : context === 6 ? (
          <i className="flaticon-warning-sign text-danger"></i>
        ) : context === 7 ? (
          <i className="flaticon2-notification text-primary"></i>
        ) : context === 8 ? (
          <i className="flaticon2-pin text-danger"></i>
        ) : context === 9 ? (
          <i className="flaticon2-ui text-warning"></i>
        ) : context === 10 ? (
          <i className="fas fa fa-print text-info"></i>
        ) : (
          <i className="flaticon2-notification text-primary"></i>
        )}
      </>
    );
  };

  return (
    <>
      {user && (
        <>
          {layoutProps.offcanvas && (
            <div className="topbar-item">
              <div
                className={
                  "btn btn-icon btn-clean btn-lg mr-1 " +
                  (entities.filter((el) => !el.status).length > 0
                    ? "pulse pulse-primary"
                    : "")
                }
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                  />
                </span>
                <span className="pulse-ring"></span>
              </div>
            </div>
          )}
          {!layoutProps.offcanvas && (
            <Dropdown drop="down" alignRight onClick={onDropdownClick}>
              <Dropdown.Toggle
                as={DropdownTopbarItemToggler}
                id="kt_quick_notifications_toggle"
              >
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="user-notification-tooltip">
                      <FormattedMessage id="SERVICE.USER_NOTIFICATIONS" />
                    </Tooltip>
                  }
                >
                  <div
                    className={
                      "btn btn-icon btn-clean btn-lg mr-1 " +
                      (entities.filter((el) => !el.status).length > 0
                        ? "pulse pulse-primary"
                        : "")
                    }
                    id="kt_quick_notifications_toggle"
                  >
                    <span className="svg-icon svg-icon-xl svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Compiling.svg"
                        )}
                      />
                    </span>
                    <span className="pulse-ring"></span>
                    <span className="pulse-ring" />
                  </div>
                </OverlayTrigger>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                <form>
                  {/** Head */}
                  <div
                    className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                    style={{ backgroundImage: `url(${bgImage})` }}
                  >
                    <h4 className="d-flex flex-center rounded-top">
                      <span className="text-white">
                        <FormattedMessage id="SERVICE.USER_NOTIFICATIONS" />
                      </span>
                      <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                        {entities.filter((el) => !el.status).length}{" "}
                        <FormattedMessage id="SERVICE.NEW_NOTIFICATIONS" />
                      </span>
                    </h4>

                    <Tab.Container defaultActiveKey={key}>
                      <Nav
                        as="ul"
                        className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                        onSelect={(_key) => setKey(_key)}
                      >
                        <Nav.Item className="nav-item" as="li">
                          <Nav.Link
                            eventKey="Messages"
                            className={`nav-link show ${
                              key === "Messages" ? "active" : ""
                            }`}
                          >
                            <FormattedMessage id="SERVICE.MESSAGES" />
                            {entities.filter(
                              (el) => el.type === 0 && !el.status
                            ).length > 0 && (
                              <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                                {
                                  entities.filter(
                                    (el) => el.type === 0 && !el.status
                                  ).length
                                }
                              </span>
                            )}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey="Events"
                            className={`nav-link show ${
                              key === "Events" ? "active" : ""
                            }`}
                          >
                            <FormattedMessage id="SERVICE.EVENTS" />
                            {entities.filter(
                              (el) => el.type === 1 && !el.status
                            ).length > 0 && (
                              <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                                {
                                  entities.filter(
                                    (el) => el.type === 1 && !el.status
                                  ).length
                                }
                              </span>
                            )}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content className="tab-content">
                        <Tab.Pane eventKey="Messages">
                          <PerfectScrollbar
                            options={perfectScrollbarOptions}
                            className="navi navi-hover scroll mt-2 bg-light"
                            style={{ maxHeight: "300px", position: "relative" }}
                          >
                            {orderBy(
                              entities.filter((el) => el.type === 0),
                              ["createdAt"],
                              ["desc"]
                            ).map((notification) => (
                              <div
                                onClick={() =>
                                  onNotificationClick(notification)
                                }
                                className="navi-item"
                                key={notification.id}
                                style={style.pointer}
                              >
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    {printIcon(notification.context)}
                                  </div>
                                  <div className="navi-text">
                                    <div
                                      className={
                                        notification.status === 0
                                          ? "font-weight-bold"
                                          : ""
                                      }
                                    >
                                      {notification.text}
                                    </div>
                                    <div className="text-muted">
                                      {TimeAgo(
                                        new Date(notification.createdAt),
                                        intl
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </PerfectScrollbar>
                        </Tab.Pane>

                        <Tab.Pane eventKey="Events">
                          <PerfectScrollbar
                            options={perfectScrollbarOptions}
                            className="navi navi-hover scroll mt-2 bg-light"
                            style={{ maxHeight: "300px", position: "relative" }}
                          >
                            {orderBy(
                              entities.filter((el) => el.type === 1),
                              ["createdAt"],
                              ["desc"]
                            ).map((notification) => (
                              <div
                                onClick={() =>
                                  onNotificationClick(notification)
                                }
                                className="navi-item"
                                key={notification.id}
                                style={style.pointer}
                              >
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    {printIcon(notification.context)}
                                  </div>
                                  <div className="navi-text">
                                    <div
                                      className={
                                        notification.status === 0
                                          ? "font-weight-bold"
                                          : ""
                                      }
                                    >
                                      {notification.text}
                                    </div>
                                    <div className="text-muted">
                                      {TimeAgo(
                                        new Date(notification.createdAt),
                                        intl
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </PerfectScrollbar>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </form>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </>
      )}
    </>
  );
}
