import { checkIsActive } from "../../../_metronic/_helpers";

export const getMenuItemActive = (location, url) => {
  const urlType = typeof url;
  var isActive = false;

  if (urlType === "string") {
    isActive = checkIsActive(location, url);
  } else if (urlType === "object") {
    for (var el of url) {
      isActive = checkIsActive(location, el);
      if (!isActive) {
        break;
      }
    }
  }

  return isActive ? " menu-item-open menu-item-active " : " ";
};
