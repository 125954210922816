import * as Yup from "yup";
import * as restApi from "../../modules/Admin/rest/api";

export function ECRNumberExistance(
  dispatch,
  intl
) {
  return Yup.string().test(
    "checkNumberExistance",
    intl.formatMessage({ id: "RT_USER.NUMBER_EXISTANCE_ERROR" }),
    (value) => {
      return new Promise((resolve, reject) => {
        if (value && value.length === 11) {
          restApi.checkEcrNumber(value)
          .then(result => {
            if (result?.data?.exists) {
              resolve(false);
            }
            else {
              resolve(true);
            }
          })
          .catch((error) => {
            resolve(true);
          });
        } else {
          resolve(false);
        }
      });
    }
  );
}
