import React from "react";
import Can from "../../AWS/Can";
import { UserNotificationsDropdown } from "../../User/components/UserNotificationsDropdown";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../../../../_metronic/layout/components/extras/QuiclUserToggler";
import { NavLink, Link, useLocation } from "react-router-dom";
import { getMenuItemActive } from "../../../utils";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import { ModuleVisualizationDropdown } from "../../User/components/ModuleVisualizationDropdown";

export function Topbar() {
  const location = useLocation();

  return (
    <div className="topbar w-100">
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex align-items-stretch">
          <div
            className="header-menu-wrapper header-menu-wrapper-left"
            id="kt_header_menu_wrapper"
          >
            <div className="header-logo">
              <Link to="/dashboard">
                <img
                  alt="logo"
                  src={toAbsoluteUrl(
                    "/media/coristech/logos/dtrservice_white.jpg"
                  )}
                  style={{ width: 180, height: 60 }}
                />
              </Link>
            </div>
          </div>

          <div className="border-left pr-2 pl-2"> </div>

          <div className="" id="kt_header_menu_wrapper">
            <div
              id="kt_header_menu"
              className={`header-menu header-menu-mobile`}
            >
              <ul className={`menu-nav p-0 d-flex align-items-stretch`}>
                <Can I="view" a="tenant">
                  <li
                    className={`pl-2 pr-2 menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/admin/tenants"
                    )}`}
                  >
                    <NavLink
                      className="menu-link pl-2 pr-2"
                      to="/admin/tenants"
                    >
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.TENANT" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="room">
                  <li
                    className={`pl-2 pr-2 menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/retail/rooms"
                    )}`}
                  >
                    <NavLink className="menu-link pl-2 pr-2" to="/retail/rooms">
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.RETAIL.ROOMS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="location">
                  <li
                    className={`pl-2 pr-2 menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/retail/locations"
                    )}`}
                  >
                    <NavLink
                      className="menu-link pl-2 pr-2"
                      to="/retail/locations"
                    >
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.RETAIL.LOCATIONS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="atorder_item_group">
                  <li
                    className={`pl-2 pr-2 menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/atorder/itemgroups"
                    )}`}
                  >
                    <NavLink
                      className="menu-link pl-2 pr-2"
                      to="/atorder/itemgroups"
                    >
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.ATORDER.ITEMGROUPS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="atorder_item">
                  <li
                    className={`pl-2 pr-2 menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/atorder/items"
                    )}`}
                  >
                    <NavLink
                      className="menu-link pl-2 pr-2"
                      to="/atorder/items"
                    >
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.ATORDER.ITEMS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="atorder_sales_order">
                  <li
                    className={`pl-2 pr-2 menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/atorder/salesorders"
                    )}`}
                  >
                    <NavLink
                      className="menu-link pl-2 pr-2"
                      to="/atorder/salesorders"
                    >
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.ATORDER.ORDERS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="manage" a="atorder_booking">
                  <li
                    className={`pl-2 pr-2 menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/atorder/bookingorders"
                    )}`}
                  >
                    <NavLink
                      className="menu-link pl-2 pr-2"
                      to="/atorder/bookingorders"
                    >
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.ATORDER.BOOKING_ORDERS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="atorder_settings">
                  <li
                    className={`pl-2 pr-2 menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/atorder/settings"
                    )}`}
                  >
                    <NavLink
                      className="menu-link pl-2 pr-2"
                      to="/atorder/settings"
                    >
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.ATORDER.SETTINGS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>
              </ul>
            </div>
          </div>

          <div className="border-right pr-2 pl-2"> </div>
        </div>

        <div className="d-flex align-items-stretch">
          <ModuleVisualizationDropdown show={false} />

          <UserNotificationsDropdown />
          <LanguageSelectorDropdown />
          <QuickUserToggler />
        </div>
      </div>
    </div>
  );
}
