import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as actions from "../_redux/authActions";
import { UserTypeBoxes } from "../components/UserTypeBoxes";
import { RegistrationMethodBoxes } from "../components/RegistrationMethodBoxes";
import { RegistrationStepper } from "../components/RegistrationStepper";
import { ErrorMessage } from "../components/ErrorMessage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Input } from "../../../../_metronic/_partials/controls";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

export function Register({ match }) {
  const intl = useIntl();
  const history = useHistory();

  const [registrationMethod, setRegistrationMethod] = useState(0);
  const [currentStepper, setCurrentStepper] = useState(0);

  const [username, setUsername] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const { auth } = useSelector(
    (state) => ({
      auth: state.auth,
    }),
    shallowEqual
  );

  const insertDataInitialValues = {
    email: "",
    phone: "",
    password: "",
  };

  const validateCodeInitialValues = {
    code: "",
  };

  const insertDataValidationSchema = Yup.object().shape({
    email: Yup.string().email(
      intl.formatMessage({ id: "SERVICE.INVALID_EMAIL" })
    ),
    password: Yup.string()
      .required(intl.formatMessage({ id: "USER.PASSWORD_REQUIRED" }))
      .test(
        "len",
        intl.formatMessage({ id: "SERVICE.PASSWORD_MUST_BE_AT_LEAST_8" }),
        (val) => val && val.length >= 8
      ),
  });

  const validateCodeValidationSchema = Yup.object().shape({
    code: Yup.string().required(
      intl.formatMessage({ id: "SERVICE.CODE_IS_REQUIRED" })
    ),
  });

  const dispatch = useDispatch();

  const registerUser = (data) => {
    setIsLoading(true);

    const username = registrationMethod === 1 ? "+" + data.phone : data.email;
    setUsername(username);

    dispatch(actions.register(username, data.password, history))
      .then(() => {
        setCurrentStepper((old) => old + 1);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const socialSignIn = (providerName) => {
    setIsLoading(true);

    dispatch(actions.initializeSocialSignIn(providerName))
      .then(() => {
        console.log("socialSignIn then");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validateCode = (data) => {
    setIsLoading(true);

    dispatch(
      actions.confirmRegistration(
        auth.cognitoUser,
        data.code,
        auth?.userType || 1,
        registrationMethod,
        history
      )
    )
      .then(() => {
        setUsername("");
        setCurrentStepper(0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resendCode = () => {
    dispatch(actions.resendConfirmationCode(auth.cognitoUser));
  };

  const cancelRegistration = () => {
    setUsername("");
    setCurrentStepper(0);
    dispatch(actions.cancelRegistration());
    history.push("/auth/login");
  };

  const renderBackButton = () => {
    return (
      <button
        type="button"
        onClick={() => {
          if (currentStepper === 0) {
            history.push("/auth/login");
          } else {
            if (currentStepper >= 3) {
              setUsername("");
            }
            setCurrentStepper((old) => old - 1);
          }
        }}
        className="w-100 m-0 p-0 border-0 bg-transparent text-muted mt-4"
      >
        <FormattedMessage id="SERVICE.BACK" />
      </button>
    );
  };

  const renderNextButton = (disabled = false) => {
    return (
      <button
        type="button"
        onClick={() => setCurrentStepper((old) => old + 1)}
        className="btn btn-light-primary btn-elevate w-100 text-uppercase"
        disabled={disabled}
      >
        <FormattedMessage id="SERVICE.NEXT" />{" "}
        <i className="fa fa-arrow-right"></i>
      </button>
    );
  };

  return (
    <>
      <RegistrationStepper activeStep={currentStepper} />
      <ErrorMessage />
      {auth && !auth.mfa ? (
        <>
          {currentStepper === 0 ? (
            <>
              <div className="row">
                <div className="col-12">
                  <UserTypeBoxes
                    defaultValue={auth?.userType}
                    onChange={(v) => {
                      dispatch(actions.setUserType(v));
                      setCurrentStepper(old => old + 1);
                    }}
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-0 col-md-4"></div>
                <div className="col-12 col-md-4 text-center">{renderBackButton()}</div>
                <div className="col-0 col-md-4"></div>
              </div>
            </>
          ) : currentStepper === 1 ? (
            <>
              <div className="row">
                <div className="col-12">
                  <RegistrationMethodBoxes
                    defaultValue={registrationMethod}
                    onChange={(v) => {
                      if (v === 2) {
                        dispatch(actions.initializeSocialSignIn("Google"));
                      }
                      else {
                        setRegistrationMethod(v);
                        setCurrentStepper(old => old + 1);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-0 col-md-4"></div>
                <div className="col-12 col-md-4 text-center">{renderBackButton()}</div>
                <div className="col-0 col-md-4"></div>
              </div>
            </>
          ) : (
            <>
              <div className="w-100 my-4">
                <h3 className="text-center">
                  <FormattedMessage id="SERVICE.AUTH.REGISTATION_STEPPER_3" />
                </h3>
                <div className="text-center text-muted my-4">
                  <div className="row">
                    <div className="col-0 col-lg-4 col-md-3"></div>
                    <div className="col-12 col-lg-4 col-md-6">
                      {registrationMethod === 0 ? (
                        <FormattedMessage id="SERVICE.AUTH.REGISTATION_SUB_STEPPER_3_EMAIL" />
                      ) : (
                        <FormattedMessage id="SERVICE.AUTH.REGISTATION_SUB_STEPPER_3_PHONE" />
                      )}
                    </div>
                    <div className="col-0 col-lg-4 col-md-3"></div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-0 col-md-4"></div>
                <div className="col-12 col-md-4">
                  <Formik
                    enableReinitialize={true}
                    initialValues={insertDataInitialValues}
                    validationSchema={insertDataValidationSchema}
                    validateOnMount={true}
                    onSubmit={(values) => {
                      registerUser(values);
                    }}
                  >
                    {({ handleSubmit, isValid, setFieldValue, values }) => (
                      <>
                        <Form className="form form-label-right px-3">
                          <div className="row form-group pb-0 mb-0">
                            <div className="col-12">
                              {registrationMethod === 0 ? (
                                <Field
                                  type="email"
                                  name="email"
                                  component={Input}
                                  autocomplete={"on"}
                                  placeholder={intl.formatMessage({
                                    id: "SERVICE.EMAIL",
                                  })}
                                  label={intl.formatMessage({
                                    id: "SERVICE.EMAIL",
                                  })}
                                />
                              ) : (
                                <div className="w-100">
                                  <label>
                                    <FormattedMessage id="PARTY.PHONE" />
                                  </label>
                                  <PhoneInput
                                    country={"it"}
                                    value={values.phone}
                                    onChange={(value) =>
                                      setFieldValue("phone", value)
                                    }
                                    inputClass={"w-100"}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row form-group ">
                            <div className="col-12 password-wrapper">
                              <Field
                                type={passwordShown ? "text" : "password"}
                                name="password"
                                component={Input}
                                autocomplete={"on"}
                                placeholder={intl.formatMessage({
                                  id: "SERVICE.PASSWORD",
                                })}
                                label={intl.formatMessage({
                                  id: "SERVICE.PASSWORD",
                                })}
                              />
                              <i
                                className="password-eye-icon"
                                onClick={togglePasswordVisiblity}
                              >
                                <span className="fas fa-eye"></span>
                              </i>
                            </div>
                          </div>

                          <div className="row form-group mt-5">
                            <div className="col-12">
                              <button
                                type="submit"
                                onClick={handleSubmit}
                                className="btn btn-light-primary btn-elevate w-100"
                                disabled={!isValid || isLoading}
                              >
                                <div className="d-flex justify-content-center">
                                  {isLoading && (
                                    <div className="spinner spinner-white mr-10"></div>
                                  )}
                                  <div className="mr-2 text-uppercase font-weight-bold">
                                    <FormattedMessage id="SERVICE.CONFIRM" />
                                  </div>
                                </div>
                              </button>
                            </div>
                            <div className="col-12">
                              <div className="mt-5">
                                {renderBackButton()}
                              </div>
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
                <div className="col-0 col-md-4"></div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="w-100 my-4">
            <h3 className="text-center">
              <FormattedMessage id="SERVICE.AUTH.REGISTATION_STEPPER_4" />
            </h3>
            <div className="text-center text-muted my-4">
              <div className="row">
                <div className="col-0 col-lg-4 col-md-3"></div>
                <div className="col-12 col-lg-4 col-md-6">
                  {registrationMethod === 0 ? (
                    <FormattedMessage id="SERVICE.AUTH.REGISTATION_SUB_STEPPER_4_EMAIL" />
                  ) : (
                    <FormattedMessage id="SERVICE.AUTH.REGISTATION_SUB_STEPPER_4_PHONE" />
                  )}
                  {":"}
                  <br/>
                  <span className="text-primary">{username}</span>
                </div>
                <div className="col-0 col-lg-4 col-md-3"></div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-0 col-md-4"></div>
            <div className="col-12 col-md-4">
              <Formik
                enableReinitialize={true}
                initialValues={validateCodeInitialValues}
                validationSchema={validateCodeValidationSchema}
                validateOnMount={true}
                onSubmit={(values) => {
                  validateCode(values);
                }}
              >
                {({ handleSubmit, isValid, setFieldValue, values }) => (
                  <>
                    <Form className="form form-label-right px-3">
                      <div className="row form-group">
                        <div className="col-12">
                          <Field
                            type="text"
                            name="code"
                            component={Input}
                            autocomplete={"on"}
                            placeholder={intl.formatMessage({
                              id: "SERVICE.AUTH.VERIFICATION_CODE",
                            })}
                            label={intl.formatMessage({
                              id: "SERVICE.AUTH.VERIFICATION_CODE",
                            })}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-6">
                          <button
                            type="button"
                            onClick={() => cancelRegistration()}
                            className="btn btn-light-secondary btn-elevate text-dark w-100"
                          >
                            <div className="mr-2 text-uppercase font-weight-bold">
                              <FormattedMessage id="SERVICE.CANCEL" />
                            </div>
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            className="btn btn-light-primary btn-elevate w-100"
                            disabled={!isValid || isLoading}
                          >
                            <div className="d-flex justify-content-center">
                              {isLoading && (
                                <div className="spinner spinner-white mr-10"></div>
                              )}
                              <div className="mr-2 text-uppercase font-weight-bold">
                                <FormattedMessage id="SERVICE.CONFIRM" />
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>

                      {auth && auth.resendCode && (
                        <div className="row form-group">
                          <div className="col-12">
                            <button
                              type="button"
                              onClick={resendCode}
                              className="btn btn-light-primary btn-elevate w-100"
                            >
                              <div className="mr-2 text-uppercase font-weight-bold">
                                <FormattedMessage id="SERVICE.AUTH.RESEND_CODE" />
                              </div>
                            </button>
                          </div>
                        </div>
                      )}
                    </Form>
                  </>
                )}
              </Formik>
            </div>
            <div className="col-0 col-md-4"></div>
          </div>
        </>
      )}
    </>
  );
}
