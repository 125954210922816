import React from "react";
import { ISODateDDMMYYYY } from "../../../functions/date/ISODateDDMMYYYY";
import { ProgressBar } from "react-bootstrap";
import { DateDiffDays } from "../../../functions/date/DateDiffDays";

export function DurationColumnFormatter(cellContent, row, rowIndex) {
	const validFrom = new Date(row.validFrom);
	const validTo = new Date(row.validTo);
	const today = new Date();

	const durationDiff = DateDiffDays(validFrom, validTo);
	const todayDiff = DateDiffDays(validFrom, today);

	const timeSpentPerc = (todayDiff * 100) / durationDiff;

	return (
		<>
			<span>
				{ISODateDDMMYYYY(validFrom, "/") +
					" - " +
					ISODateDDMMYYYY(validTo, "/")}
			</span>
			<ProgressBar striped variant="info" now={timeSpentPerc} />
		</>
	);
}