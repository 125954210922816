const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export function IsNumber(string) {
  if (!string || typeof string !== "string") {
    return false;
  }

  var result = true;

  for (var i = 0; i < string.length; i++) {
    var char = string.charAt(i);

    if (!numbers.includes(char)) {
      result = false;
      break;
    }
  }

  return result;
}
