import React from "react";

export function ColorColumnFormatter(cellContent, row, rowIndex) {
  const defaultColor = "#D1D1D1";

  const style = {
    box: {
      background: row.color ? row.color : defaultColor,
    },
  };

  return (
    <>
      <div style={style.box} className="border rounded">
        &nbsp;
      </div>
    </>
  );
}
