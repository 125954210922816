import axios from "axios";
import * as restApi from "../../modules/Admin/rest/api";

export async function DownloadProtectedFile(tenantId, s3Key, sendToastNotification, intl, callback) {
  return restApi.generatePreSignedURL(tenantId, s3Key, "getObject")
    .then((result) => {
      axios.get(result.data, {
        decompress: false,
        responseType: "arraybuffer",
      })
      .then((axiosResponse) => {
        callback(result.data, axiosResponse.data);
      })
      .catch((e) => {
        if (sendToastNotification) {
          sendToastNotification("error", intl.formatMessage({ id: "SERVICE.ERROR_DOWNLOADING_FILE" }));
        }
      });
    })
    .catch((e) => {
      if (sendToastNotification) {
        sendToastNotification("error", intl.formatMessage({ id: "SERVICE.ERROR_DOWNLOADING_FILE" }));
      }
    });
}
