import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tenantId: undefined,
  retailStoreId: undefined,
  cashRegisterId: undefined,
  cashRegisterNumber: "",
  cashRegister: {},
};

export const slice = createSlice({
  name: "filters",
  initialState: initialState,
  reducers: {
    setTenant: (state, action) => {
      state.tenantId = action.payload.tenantId;
    },
    setRetailStore: (state, action) => {
      state.retailStoreId = action.payload.retailStoreId;
    },
    setCashRegisterId: (state, action) => {
      state.cashRegisterId = action.payload.cashRegisterId;
    },
    setCashRegisterNumber: (state, action) => {
      state.cashRegisterNumber = action.payload.cashRegisterNumber;
    },
    setCashRegister: (state, action) => {
      state.cashRegister = action.payload.cashRegister;
    },
  },
});
