/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { useIntl, FormattedMessage } from "react-intl";
import { GetValueFromStringPath } from "../../functions/GetValueFromStringPath";

export function MultipleDeleteDialog({ show, onHide, UIContext }) {
  const intl = useIntl();

  const UIProps = useMemo(() => {
    return {
      ids: UIContext.ids,
      setIds: UIContext.setIds,
      queryParams: UIContext.queryParams,
      sendToastNotification: UIContext.sendToastNotification,
      statePath: UIContext.statePath,
      actions: UIContext.actions,
    };
  }, [UIContext]);

  const dispatch = useDispatch();
  const { entities, isLoading } = useSelector(
    (state) => ({
      entities: GetValueFromStringPath(state, UIProps.statePath + ".entities.items"),
      isLoading: GetValueFromStringPath(state, UIProps.statePath + ".actionsLoading"),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!UIProps.ids || UIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.ids]);

  const deleteRecords = () => {
    const newRecords = UIProps.ids.map((selectedId) => ({ id: selectedId, deleted: true }));
    dispatch(UIProps.actions.updateObjects(newRecords))
      .then(() => {
        UIProps.sendToastNotification(
          "success",
          intl.formatMessage({ id: "SERVICE.RECORD_HAS_BEEN_DELETED" })
        );
        UIProps.setIds([]);
        onHide();
      })
      .catch(() => {
        UIProps.sendToastNotification(
          "error",
          intl.formatMessage({ id: "SERVICE.ERROR_DELETING_RECORD" })
        );
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="SERVICE.DELETE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <FormattedMessage id="SERVICE.SURE_TO_DELETE_RECORDS" />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="SERVICE.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteRecords}
            className="btn btn-danger btn-elevate"
          >
            <FormattedMessage id="SERVICE.DELETE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
