import React, { useState, useEffect } from "react";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";

export function MoitoIotImageCarousel({ row }) {
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);

  const style = {
    absolute: {
      position: "absolute",
    },
    img_size: {
      width: "100%",
      height: "100%",
    },
    img_cell: {
      width: 70,
      height: 70,
      cursor: "pointer",
    },
  };

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      setImages(row?.image ? [row.image] : []);
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {images.length > 0 && (
        <div className="text-center">
          <img
            src={images[0]}
            style={style.img_cell}
            onClick={() => setOpen(true)}
            className="rounded"
            alt=""
          />
        </div>
      )}
      <AutoRotatingCarousel
        open={open}
        onClose={() => setOpen(false)}
        onStart={() => setOpen(false)}
        style={style.absolute}
      >
        {images.map((image, idx) => (
          <Slide
            key={idx}
            media={<img src={image} style={style.img_size} alt="" />}
            title={row.name}
            subtitle={row.description}
          />
        ))}
      </AutoRotatingCarousel>
    </div>
  );
}
