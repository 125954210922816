import { combineReducers } from "redux";
import { GenerateSlice } from "../../../utils";

export const reducer = combineReducers({
  retailstores: GenerateSlice("retailstores").reducer,
  retailstorerooms: GenerateSlice("retailstorerooms").reducer,
  retailstorelocations: GenerateSlice("retailstorelocations").reducer,
  userretailstores: GenerateSlice("userretailstores").reducer,
  cashregisters: GenerateSlice("cashregisters").reducer,
});
