import * as request from "./crud";
import { GenerateSlice, GenerateActions, GenerateFetchObjectsBy, GenerateSlowFetchObjects } from "../../../../utils/components/generic/_redux";

const sliceActions = GenerateSlice("receipts").actions;

export const actions = {
  ...GenerateActions(sliceActions, request, "Receipt"),
  fetchObjects: (qp, af, nextToken, op, cc) => GenerateSlowFetchObjects(
    sliceActions,
    request.findObjects,
    "listReceipts",
    qp,
    af,
    nextToken,
    op,
    cc
  ),
  fetchObjectsByTenant: (tenantId, qp, af, nextToken, op) => GenerateFetchObjectsBy(
    sliceActions,
    request.findObjectsByTenant,
    "receiptsByTenant",
    tenantId,
    qp,
    af,
    nextToken,
    op
  ),
  fetchObjectsByRetailStore: (retailStoreId, qp, af, nextToken, op, cc) => GenerateFetchObjectsBy(
    sliceActions,
    request.findObjectsByRetailStore,
    "receiptsByRetailStore",
    retailStoreId,
    qp,
    af,
    nextToken,
    op,
    cc
  ),
  fetchObjectsByMoitoIot: (moitoIotId, qp, af, nextToken, op) => GenerateFetchObjectsBy(
    sliceActions,
    request.findObjectsByMoitoIot,
    "receiptsByMoitoIot",
    moitoIotId,
    qp,
    af,
    nextToken,
    op
  ),
  fetchObjectsByCustomer: (customerId, qp, af, nextToken, op, cc) => GenerateFetchObjectsBy(
    sliceActions,
    request.findObjectsByCustomer,
    "receiptsByCustomer",
    customerId,
    qp,
    af,
    nextToken,
    op,
    cc
  ),
};
