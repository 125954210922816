import React from "react";
import { Switch } from "@material-ui/core";

export function EnabledColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { fieldName, toggleEnabled }
) {
  return (
    <>
      <Switch 
        checked={row && row[fieldName] ? row[fieldName] : false} 
        onChange={() => toggleEnabled(row.id)} 
      />
    </>
  );
}
