import store from "../../../redux/store";

export function GetRetailStore(retailStoreId = undefined) {
  const state = store.getState();
  if (!state || !state.auth || !state.auth.user) {
    return undefined;
  }

  const storeId = retailStoreId ? retailStoreId : state.store.currentStoreId;
  const userRetailStore = state.auth.user.userRetailStore.items.find(
    (el) => el.retailStoreId === storeId
  );
  if (userRetailStore) {
    return userRetailStore.retailStore;
  }
  return undefined;
}
