/*global AppJS*/
/*eslint no-undef: "error"*/

export const DownloadContent = (filename, data, contentType) => {
    if (typeof AppJS !== 'undefined') {
        const file = new Blob([data], { type: contentType });
        file.text()
            .then(res => {
                AppJS.saveGenericFile(filename, res);
            })

    } else {
        const element = document.createElement("a");
        const file = new Blob([data], {
            type: contentType
        });
        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
};
