import { ToastNotify } from "../../functions/ToastNotify";

export const ConstructUIEvents = (history, basePath, UIActions) => {
  var result = {
    sendToastNotification: (type, text) => {
      ToastNotify(type, text);
    },
  };

  UIActions.forEach(a => {
    result = {
      ...result,
      [a.funcName]: function(id) {
        if (a.fullPath) {
          if (a.idSlug) {
            history.push(a.fullPath + "/" + id);
          }
          else {
            history.push(a.fullPath);
          }
        }
        else {
          if (a.idSlug) {
            history.push(basePath + "/" + id + "/" + a.type);
          }
          else {
            history.push(basePath + "/" + a.type);
          }
        }
      },
    };
  });

  return result;
}