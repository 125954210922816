import * as request from "./crud";
import { orderBy } from "lodash";
import { GenerateSlice, SliceCallTypes } from "../../../../utils/components/generic/_redux";

const sliceActions = GenerateSlice("userlicenses").actions;

const cleanState = () => (dispatch) => {
  dispatch(sliceActions.cleanState());
};

const fetchObjects =
  (queryParams, additionalFilters, nextToken, operation = "overwrite") =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjects(
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal
          );
          var data = result.data.listUserLicenses;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: sortedItems.length,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve({
          data: {
            listUserLicenses: {
              items: sortedItems,
              nextToken: nextTokenInternal,
            },
          },
        });
      } catch (error) {
        error.clientMessage = "Can't find user licenses";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };

const fetchObjectsByUser =
  (
    userId,
    queryParams,
    additionalFilters,
    nextToken,
    operation = "overwrite"
  ) =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjectsByUser(
            userId,
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal
          );
          var data = result.data.userLicensesByUser;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: sortedItems.length,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve({
          data: {
            userLicensesByUser: {
              items: sortedItems,
              nextToken: nextTokenInternal,
            },
          },
        });
      } catch (error) {
        error.clientMessage = "Can't find user licenses";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };


const fetchAllActiveDealerUserLicenses = (tenantId, userId, licenseIds) => async (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));

  var today = new Date().toISOString().substring(0, 10);

  const queryParams = {
    pageSize: 1000,
    userId: { eq: userId },
    filter: {
      validTo: { gt: today },
      validFrom: { lt: today },
      or: licenseIds.map(el => ({ licenseId: { eq: el } })),
    },
  };

  var nextToken = null;
  var entities = [];

  try {
    do {
      var result = await request.findObjectsByTenant(
        tenantId,
        {
          ...queryParams,
          pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
        },
        null,
        nextToken
      );
      var data = result.data.userLicensesByTenant;

      if (data && data.items && data.items.length > 0) {
        entities = entities.concat(data.items);
      }

      nextToken = data.nextToken;
    } while (nextToken !== null);

    const sortedItems = orderBy(
      entities,
      [queryParams.sortField],
      [queryParams.sortOrder]
    );
    dispatch(
      sliceActions.objectsFetched({
        totalCount: sortedItems.length,
        operation: "overwrite",
        items: sortedItems,
        nextToken: nextToken,
        prevToken: nextToken,
      })
    );
    return undefined;
  } catch (error) {
    error.clientMessage = "Can't get user licenses";
    dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    return error;
  }
};

const fetchObject = (id) => (dispatch) => {
  if (!id) {
    return dispatch(sliceActions.objectFetched({ entity: undefined }));
  }

  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .getObjectById(id)
    .then((response) => {
      const obj = response.data.getUserLicense;
      dispatch(sliceActions.objectFetched({ entity: obj }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find user license";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

const createObject = (obj) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .createObject(obj)
    .then((response) => {
      const object = response.data.createUserLicense;
      dispatch(sliceActions.objectsCreated({ objects: [object] }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create user license";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

const createObjects = (objs) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .createObjects(objs)
    .then((response) => {
      const objects = response.map((obj) => obj.data.createUserLicense);
      dispatch(sliceActions.objectsCreated({ objects: objects }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create user licenses";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

const deleteObject = (id) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .deleteObject(id)
    .then((response) => {
      dispatch(sliceActions.objectDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete user license";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

export const actions = {
  cleanState,
  fetchObjects,
  fetchObjectsByUser,
  fetchAllActiveDealerUserLicenses,
  fetchObject,
  createObject,
  createObjects,
  deleteObject,
}