import React from "react";
import { PaginationLinks } from "./PaginationLinks";

export function Pagination(props) {
  const {
    children,
    paginationProps,
    nextPage,
    prevPage,
    enableNextPage,
    enablePrevPage,
  } = props;
  return (
    <>
      {children}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <PaginationLinks
          paginationProps={paginationProps}
          nextPage={nextPage}
          prevPage={prevPage}
          enableNextPage={enableNextPage}
          enablePrevPage={enablePrevPage}
        />
      </div>
    </>
  );
}
