import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as actions from "../_redux/authActions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Input } from "../../../../_metronic/_partials/controls";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";

export function Forget({ match }) {
  const intl = useIntl();
  const history = useHistory();

  const [loginType, setLoginType] = useState(
    parseInt(
      match && match.params && match.params.type !== undefined
        ? match.params.type
        : 0
    )
  );

  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { auth } = useSelector(
    (state) => ({
      auth: state.auth,
    }),
    shallowEqual
  );

  const sendCodeInitialValues = {
    username: "",
  };

  const changePasswordInitialValues = {
    newPassword: "",
    newPasswordConfirmation: "",
    code: "",
  };

  const sendCodeValidationSchema = Yup.object().shape({
    email: Yup.string().email(
      intl.formatMessage({ id: "SERVICE.INVALID_EMAIL" })
    ),
  });

  const changePasswordValidationSchema = Yup.object().shape({
    code: Yup.string().required(
      intl.formatMessage({ id: "SERVICE.CODE_IS_REQUIRED" })
    ),
    newPassword: Yup.string()
      .required(intl.formatMessage({ id: "USER.PASSWORD_REQUIRED" }))
      .test(
        "len",
        intl.formatMessage({ id: "SERVICE.PASSWORD_MUST_BE_AT_LEAST_8" }),
        (val) => val && val.length > 8
      ),
    newPasswordConfirmation: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      intl.formatMessage({ id: "SERVICE.PASSWORDS_MUST_MATCH" })
    ),
  });

  const dispatch = useDispatch();

  const sendVerificationCode = (data) => {
    setIsLoading(true);

    const username = loginType === 1 ? "+" + data.username : data.username;
    setUsername(username);

    dispatch(actions.forgotPassword(username)).finally(() => {
      setIsLoading(false);
    });
  };

  const changePassword = (data) => {
    setIsLoading(true);

    dispatch(
      actions.confirmForgotPassword(username, data.code, data.newPassword)
    )
      .then(() => {
        history.push("/auth/login");
        setUsername("");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cancelForgotPassword = () => {
    setUsername("");
    dispatch(actions.cancelForgotPassword());
    history.push("/auth/login");
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4 col-xs-12"></div>
        <div className="col-md-4 col-xs-12">
          {auth && !auth.forgotPasswordCode ? (
            <Formik
              enableReinitialize={true}
              initialValues={sendCodeInitialValues}
              validationSchema={sendCodeValidationSchema}
              validateOnMount={true}
              onSubmit={(values) => {
                sendVerificationCode(values);
              }}
            >
              {({ handleSubmit, isValid, setFieldValue, values }) => (
                <>
                  <div className="w-100 my-4">
                    <h3 className="text-center">
                      <FormattedMessage id="SERVICE.AUTH.RESET_PASSWORD" />
                    </h3>
                    <div className="text-center text-muted my-4">
                      {loginType === 0 ? (
                        <FormattedMessage id="SERVICE.AUTH.RESET_PASSWORD_EMAIL" />
                      ) : (
                        <FormattedMessage id="SERVICE.AUTH.RESET_PASSWORD_PHONE" />
                      )}
                    </div>
                  </div>

                  <Form className="form form-label-right">
                    <div className="row form-group pb-0 mb-0">
                      <div className="col-12">
                        {loginType === 0 ? (
                          <Field
                            type="email"
                            name="username"
                            component={Input}
                            autocomplete={"on"}
                            placeholder={intl.formatMessage({
                              id: "SERVICE.EMAIL",
                            })}
                            label={intl.formatMessage({ id: "SERVICE.EMAIL" })}
                          />
                        ) : (
                          <div className="w-100">
                            <label>
                              <FormattedMessage id="PARTY.PHONE" />
                            </label>
                            <PhoneInput
                              country={"it"}
                              value={values.username}
                              inputClass={"w-100"}
                              onChange={(value) =>
                                setFieldValue("username", value)
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row form-group mt-5">
                      <div className="col-6">
                        <button
                          type="button"
                          onClick={() => cancelForgotPassword()}
                          className="btn btn-light-secondary btn-elevate w-100 text-dark"
                        >
                          <div className="mr-2 text-uppercase font-weight-bold">
                            <FormattedMessage id="SERVICE.CANCEL" />
                          </div>
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn btn-light-primary btn-elevate w-100"
                          disabled={!isValid || isLoading}
                        >
                          <div className="d-flex justify-content-center">
                            {isLoading && (
                              <div className="spinner spinner-white mr-10"></div>
                            )}
                            <div className="mr-2 text-uppercase font-weight-bold">
                              <FormattedMessage id="SERVICE.AUTH.SEND_VERIFICATION_CODE" />
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={changePasswordInitialValues}
              validationSchema={changePasswordValidationSchema}
              validateOnMount={true}
              onSubmit={(values) => {
                changePassword(values);
              }}
            >
              {({ handleSubmit, isValid, setFieldValue, values }) => (
                <>
                  <div className="w-100 my-4">
                    <h3 className="text-center">
                      <FormattedMessage id="SERVICE.AUTH.RESET_PASSWORD" />
                    </h3>
                    <div className="text-center text-muted my-4">
                      {loginType === 0 ? (
                        <FormattedMessage id="SERVICE.AUTH.RESET_PASSWORD_EMAIL_2" />
                      ) : (
                        <FormattedMessage id="SERVICE.AUTH.RESET_PASSWORD_PHONE_2" />
                      )}
                    </div>
                  </div>

                  <Form className="form form-label-right">
                    <div className="row form-group">
                      <div className="col-12">
                        <Field
                          type="text"
                          name="code"
                          component={Input}
                          autocomplete={"on"}
                          placeholder={intl.formatMessage({
                            id: "SERVICE.AUTH.VERIFICATION_CODE",
                          })}
                          label={intl.formatMessage({
                            id: "SERVICE.AUTH.VERIFICATION_CODE",
                          })}
                        />
                      </div>
                    </div>

                    <div className="row form-group pb-0 mb-0">
                      <div className="col-12">
                        <Field
                          type="password"
                          name="newPassword"
                          component={Input}
                          autocomplete={"on"}
                          placeholder={intl.formatMessage({
                            id: "SERVICE.AUTH.NEW_PASSWORD",
                          })}
                          label={intl.formatMessage({
                            id: "SERVICE.AUTH.NEW_PASSWORD",
                          })}
                        />
                      </div>
                    </div>

                    <div className="row form-group pb-0 mb-0">
                      <div className="col-12">
                        <Field
                          type="password"
                          name="newPasswordConfirmation"
                          component={Input}
                          autocomplete={"on"}
                          placeholder={intl.formatMessage({
                            id: "SERVICE.AUTH.REPEAT_NEW_PASSWORD",
                          })}
                          label={intl.formatMessage({
                            id: "SERVICE.AUTH.REPEAT_NEW_PASSWORD",
                          })}
                        />
                      </div>
                    </div>

                    <div className="row form-group mt-5">
                      <div className="col-6">
                        <button
                          type="button"
                          onClick={() => cancelForgotPassword()}
                          className="btn btn-light-secondary btn-elevate w-100 text-dark"
                        >
                          <div className="mr-2 text-uppercase font-weight-bold">
                            <FormattedMessage id="SERVICE.CANCEL" />
                          </div>
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn btn-light-primary btn-elevate w-100"
                          disabled={!isValid || isLoading}
                        >
                          <div className="d-flex justify-content-center">
                            {isLoading && (
                              <div className="spinner spinner-white mr-10"></div>
                            )}
                            <div className="mr-2 text-uppercase font-weight-bold">
                              <FormattedMessage id="SERVICE.CONFIRM" />
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          )}
        </div>
        <div className="col-md-4 col-xs-12"></div>
      </div>
    </>
  );
}
