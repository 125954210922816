import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { actions } from "../_redux/tenantActions";
import { actions as multistoreActions } from "../../Multistore/_redux/actions";
import { connect } from "react-redux";

class CompanySelectorDropdown extends Component {
  
  render() {
    return (
      <>
        
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenantDefault: state.auth.user ? state.auth.user.tenantDefault : "",
    currentTenantId: state.tenant.currentTenantId,
    userTenants: state.auth.user ? state.auth.user.tenantUser.items : [],
    userRetailStores: state.auth.user
      ? state.auth.user.userRetailStore.items
      : [],
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setCurrentTenant: actions.setCurrentTenant,
        setCurrentStore: multistoreActions.setCurrentStore,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySelectorDropdown);
