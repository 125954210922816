import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { GenerateSlice } from "../../../utils";

import { slice as configurationSlice } from "./configuration/slice";
import { slice as filtersSlice } from "./filters/slice";

export const reducer = combineReducers({
  ecrconfigurations: GenerateSlice("ecrconfigurations").reducer,
  configuration: configurationSlice.reducer,
  favorites: GenerateSlice("favorites").reducer,
  interventions: GenerateSlice("interventions").reducer,
  errors: GenerateSlice("errors").reducer,
  partnernotes: GenerateSlice("partnernotes").reducer,
  filters: persistReducer(
    { key: "rt_utility.filters", storage: storage },
    filtersSlice.reducer
  ),
  firmwares: GenerateSlice("firmwares").reducer,
  fwupgradeheaders: GenerateSlice("fwupgradeheaders").reducer,
  fwupgradelines: GenerateSlice("fwupgradelines").reducer,
  certificates: GenerateSlice("certificates").reducer,
  logs: GenerateSlice("logs").reducer,
  pendingxmls: GenerateSlice("pendingxmls").reducer,
  advmessages: GenerateSlice("advmessages").reducer,
});
