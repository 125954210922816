import { API, graphqlOperation } from "aws-amplify";
import { calendarEventByRetailStore } from "../../graphql/private/queries";
import { createCalendarEvent, updateCalendarEvent } from "../../graphql/private/mutations";
import { deleteCalendarEvent } from "../../../../../graphql/mutations";

export async function create(input){
    const {data} = await API.graphql(graphqlOperation(createCalendarEvent, { input: normalizeInput(input)}));
    return data.createCalendarEvent;
}

export async function read(params={}, fetchAll = false){
    if (fetchAll) {
        let results=[];
        let token=params?.nextToken || undefined;

        do {
            const {data} = await API.graphql(graphqlOperation(calendarEventByRetailStore,{ ...params, nextToken: nextToken }));
            const items = data.calendarEventByRetailStore.items;
            const nextToken = data.calendarEventByRetailStore.nextToken ?? undefined;

            results = results.concat(items);
            token = nextToken;
        } while (token !== undefined);

        return {
            items: results,
            nextToken: token || undefined,
        };
    }
    
    const {data} = await API.graphql(graphqlOperation(calendarEventByRetailStore,params));
    return {
        items:data.calendarEventByRetailStore.items,
        nextToken:data.calendarEventByRetailStore.nextToken ?? undefined
    };
}

export async function update(input){
    const {data} = await API.graphql(graphqlOperation(updateCalendarEvent, { input: normalizeInput(input)}));
    return data.updateCalendarEvent;
}

export async function softDelete(id){
    const {data} = await API.graphql(graphqlOperation(updateCalendarEvent, { input: { id: id, deleted: true }}));
    return data.updateCalendarEvent;
}


export async function hardDelete(id){
    return await API.graphql(graphqlOperation(deleteCalendarEvent, { input: {id:id} }))
}


function normalizeInput({
    id,
    title,
    tenantId,
    retailStoreId,
    userId,
    calendarEventTypeId,
    customerId,
    description,
    tags,
    isAllDayEvent,
    notificationEnabled,
    deleted,
    extensions,
    startDatetime,
    endDatetime,
    notificationDatetime,
    mobile,
    email,
    operatorId,
    isPending
}){
    return {
        id,
        title,
        tenantId,
        retailStoreId,
        userId,
        calendarEventTypeId,
        customerId,
        description,
        tags,
        isAllDayEvent,
        notificationEnabled,
        deleted,
        extensions,
        startDatetime,
        endDatetime,
        notificationDatetime,
        mobile,
        email,
        operatorId,
        isPending,
    };
}