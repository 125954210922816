export function ToItalianTime(d) {
	return d
		.toLocaleString("it-IT", {
			timeZone: "Europe/Rome",
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
		})
		.replace(",", "");
}