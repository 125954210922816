import { Auth, API } from "aws-amplify";

function call(endpoint, params) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/" + endpoint;
    let myInit = {
      body: { ...params },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function addNotification(type, text, tenantId, userId, context) {
  if (text && tenantId) {
    const params = {
      type: type ? type : 0,
      text: text,
      tenantId: tenantId,
      userId: userId,
      context: context,
    };
    return call("add_notification", params);
  }
  return new Promise((resolve, reject) => {
    return resolve(true);
  });
}

export function readAllNotifications(tenantId, userId) {
  const params = {
    tenantId: tenantId,
    userId: userId,
  };
  return call("read_notifications", params);
}

export function notifyPartnershipRequest(tenantId, userId, companyName) {
  const text = "Nuova richiesta di partnership da " + companyName;
  return addNotification(0, text, tenantId, userId, 3);
}

export function notifyPartnershipApprovement(tenantId, userId) {
  const text = "La tua richiesta di partnership è stata accettata";
  return addNotification(0, text, tenantId, userId, 4);
}

export function notifyLicenseRequest(partnerId, userId, commercialName) {
  const text = "Nuova richiesta di " + commercialName;
  return addNotification(0, text, partnerId, userId, 1);
}

export function notifyLicenseApprovement(tenantId, userId, commercialName) {
  const text = "La tua richiesta di  " + commercialName + " è stata accettata";
  return addNotification(0, text, tenantId, userId, 2);
}

export function notifyLicenseAssignment(tenantId, userId, commercialName) {
  const text = "Ti è stata assegnata una licenza di " + commercialName;
  return addNotification(0, text, tenantId, userId, 2);
}

export function notifyLicenseRevoking(tenantId, userId, commercialName) {
  const text = "Ti è stata revocata una licenza di " + commercialName;
  return addNotification(0, text, tenantId, userId, 2);
}
