import React from "react";

export function ScrollTop() {
  return (
      <div id="kt_scrolltop" className="scrolltop">
        <span className="fas fa fa-up text-primary"></span>
        {" "}
      </div>
  );
}
