import { API, graphqlOperation } from "aws-amplify";
import {
  listCustomers,
  customersByTenant,
  customersByMobile,
  customersByCompanyVat,
  customersByFiscalCode,
  getCustomer,
  searchCustomers,
} from "../../../../../graphql/queries";
import {
  createCustomer,
  deleteCustomer,
  updateCustomer,
} from "../../../../../graphql/mutations";

export function searchObjects(queryParams, additionalFilters, nextToken) {
  var params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  
  if (queryParams.sortField && queryParams.sortOrder) {
    params.sort = {
      field: queryParams.sortField,
      direction: queryParams.sortOrder,
    };
  }

  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
  }

  params.filter = {
    ...params.filter,
    deleted: { ne: true },
  };

  return API.graphql(graphqlOperation(searchCustomers, params));
}


export function findObjects(queryParams, additionalFilters, nextToken) {
  const params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
    if (Object.keys(params.filter).includes("or")) {
      params.filter.or = params.filter.or.concat([
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ]);
    } else {
      params.filter.or = [
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ];
    }
  }

  return API.graphql(graphqlOperation(listCustomers, params));
}

export function findObjectsByTenant(
  tenantId,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    tenantId: tenantId,
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
    if (Object.keys(params.filter).includes("or")) {
      params.filter.or = params.filter.or.concat([
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ]);
    } else {
      params.filter.or = [
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ];
    }
  }

  return API.graphql(graphqlOperation(customersByTenant, params));
}

export function findObjectsByCompanyVat(
  companyVat,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    limit: queryParams.pageSize,
    companyVat: companyVat,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(customersByCompanyVat, params));
}

export function findObjectsByFiscalCode(
  fiscalCode,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    limit: queryParams.pageSize,
    fiscalCode: fiscalCode,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(customersByFiscalCode, params));
}

export function findObjectsByMobile(
  mobile,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    limit: queryParams.pageSize,
    mobile: mobile,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(customersByMobile, params));
}

export function getObjectById(id) {
  return API.graphql(graphqlOperation(getCustomer, { id: id }));
}

export function deleteObject(id) {
  return API.graphql(graphqlOperation(deleteCustomer, { input: { id: id } }));
}

export function createObject(customer) {
  return API.graphql(graphqlOperation(createCustomer, { input: customer }));
}

export function updateObject(customer) {
  return API.graphql(graphqlOperation(updateCustomer, { input: customer }));
}

export function deleteObjects(ids) {
  var deletes = [];
  ids.forEach((id) => {
    deletes.push(
      new Promise((resolve, reject) => {
        return resolve(deleteObject(id));
      })
    );
  });
  return Promise.all(deletes);
}

export function updateObjects(objs) {
  var creates = [];
  objs.forEach((obj) => {
    creates.push(
      new Promise((resolve, reject) => {
        return resolve(updateObject(obj.entity));
      })
    );
  });
  return Promise.all(creates);
}
