import * as request from "./crud";
import { GenerateSlice, GenerateActions, GenerateSlowFetchObjects, GenerateFetchObjectsBy, SliceCallTypes } from "../../../../utils/components/generic/_redux";

const sliceActions = GenerateSlice("vendors").actions;

export const actions = {
  ...GenerateActions(sliceActions, request, "Vendor"),
  searchObjects: (qp, af, nextToken, op, cc) => GenerateSlowFetchObjects(
    sliceActions,
    request.searchObjects,
    "searchVendors",
    qp,
    af,
    nextToken,
    op,
    cc
  ),
  fetchObjectsByTenant: (tenantId, qp, af, nextToken, op) => GenerateFetchObjectsBy(
    sliceActions,
    request.findObjectsByTenant,
    "vendorsByTenant",
    tenantId,
    qp,
    af,
    nextToken,
    op
  ),
  fetchObjectsByCompanyVat: (companyVat, qp, af, nextToken, op) => GenerateFetchObjectsBy(
    sliceActions,
    request.findObjectsByCompanyVat,
    "vendorsByCompanyVat",
    companyVat,
    qp,
    af,
    nextToken,
    op
  ),
  getByVatNumber: (vatNum, tenantId, saveRedux = true) => async (dispatch) => {
    if (saveRedux) {
      dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
    }

    var queryParams = {
      pageSize: 1000,
      filter: {
        tenantId: { eq: tenantId },
        deleted: { ne: true },
      },
    };

    var nextToken = null;

    try {
      do {
        var result = await request.findObjectsByCompanyVat(
          vatNum,
          {
            ...queryParams,
            pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
          },
          null,
          nextToken
        );
        var data = result.data.vendorsByCompanyVat;

        if (data.items.length > 0) {
          if (saveRedux) {
            dispatch(sliceActions.objectFetched({ entity: data.items[0] }));
          }
          return data.items[0];
        }

        nextToken = data.nextToken;
      } while (nextToken !== null);

      if (saveRedux) {
        dispatch(sliceActions.objectFetched({ entity: undefined }));
      }
      return undefined;
    } catch (error) {
      error.clientMessage = "Can't get vendor by vat number";
      if (saveRedux) {
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
      }
      return error;
    }
  },
};
