import { Auth, API } from "aws-amplify";
import { RandomStringGenerator } from "../../../utils";

export function createCognitoUser(email, groups) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "AdminQueries";
    let path = "/createUser";
    let myInit = {
      body: {
        email: email,
        temporaryPassword: RandomStringGenerator(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit).then((cognitoUser) => {
      if (groups.length > 0) {
        const addUserToGroups = groups.map((el) => {
          return new Promise((resolve) => {
            return resolve(addUserToGroup(cognitoUser.Username, el));
          });
        });

        Promise.all(addUserToGroups);
      }

      return Promise.resolve(cognitoUser);
    });
  });
}

export function addUserToGroup(username, groupname) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "AdminQueries";
    let path = "/addUserToGroup";
    let myInit = {
      body: {
        username: username,
        groupname: groupname,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function getUser(username) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "AdminQueries";
    let path = "/getUser";
    let myInit = {
      body: {
        username: username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function getUserByEmailOrPhone(attribute) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "AdminQueries";
    let path = "/getUserByEmailOrPhone";
    let myInit = {
      body: {
        attribute: attribute,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}
