import React from "react";
import Can from "../../AWS/Can";
import { UserNotificationsDropdown } from "../../User/components/UserNotificationsDropdown";
import { ECRSelectorDropdown } from "../../../../app/modules/Multicashregister/components/ECRSelectorDropdown";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../../../../_metronic/layout/components/extras/QuiclUserToggler";
import { NavLink, useLocation, Link } from "react-router-dom";
import { getMenuItemActive } from "../../../utils";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import { ModuleVisualizationDropdown } from "../../User/components/ModuleVisualizationDropdown";

export function Topbar() {
  const location = useLocation();

  return (
    <div className="topbar w-100">
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex align-items-stretch">
          <div
            className="header-menu-wrapper header-menu-wrapper-left"
            id="kt_header_menu_wrapper"
          >
            <div className="header-logo">
              <Link to="/dashboard">
                <img
                  alt="logo"
                  src={toAbsoluteUrl(
                    "/media/coristech/logos/dtrservice_white.jpg"
                  )}
                  style={{ width: 180, height: 60 }}
                />
              </Link>
            </div>
          </div>

          <div className="border-left pr-2 pl-2"> </div>

          <ECRSelectorDropdown />

          <div className="border-left pr-2 pl-2"> </div>

          <div
            className="d-none d-sm-none d-md-none d-lg-block"
            id="kt_header_menu_wrapper"
          >
            <div
              id="kt_header_menu"
              className={`header-menu header-menu-mobile`}
            >
              <ul className={`menu-nav p-0 d-flex align-items-stretch`}>
                <Can I="view" a="ecr">
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/retail/cashregisters"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/retail/cashregisters">
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.RETAIL.RECORDERS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="rt_user_history">
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/rt_user/history"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/rt_user/history">
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.RT_USER.HISTORY" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="rt_user_aggregated">
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/rt_user/aggregated"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/rt_user/aggregated">
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.RT_USER.AGGREGATED" />
                      </span>
                    </NavLink>
                  </li>
                </Can>
              </ul>
            </div>
          </div>

          <div className="border-right pr-2 pl-2"> </div>
        </div>

        <div className="d-flex align-items-stretch">
          <ModuleVisualizationDropdown show={false} />

          <UserNotificationsDropdown />
          <LanguageSelectorDropdown />
          <QuickUserToggler />
        </div>
      </div>
    </div>
  );
}
