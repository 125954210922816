import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/AuthCognito/_redux/authReducer";
import {tenantReducer} from "../app/modules/Multitenancy/_redux/tenantReducer";
import * as multitenantSagas from "../app/modules/Multitenancy/_redux/tenantSagas";
import { reducer as adminReducer } from "../app/modules/Admin/_redux/reducer";
import { reducer as retailReducer } from "../app/modules/Retail/_redux/reducer";
import { reducer as productReducer } from "../app/modules/ProductManagement/_redux/reducer";
import { reducer as atorderReducer } from "../app/modules/Atorder/_redux/reducer";
import { reducer as salesReducer } from "../app/modules/Sales/_redux/reducer";
import { reducer as customerReducer } from "../app/modules/Customer/_redux/reducer";
import { reducer as accountingReducer } from "../app/modules/Accounting/_redux/reducer";
import { reducer as einvoiceReducer } from "../app/modules/EInvoice/_redux/reducer";
import { reducer as userReducer } from "../app/modules/User/_redux/reducer";
import { reducer as rtUserReducer } from "../app/modules/RTUser/_redux/reducer";
import { storeReducer } from "../app/modules/Multistore/_redux/reducer";
import * as multistoreSagas from "../app/modules/Multistore/_redux/sagas";
import { cashRegisterReducer } from "../app/modules/Multicashregister/_redux/reducer";
import * as multicashregisterSagas from "../app/modules/Multicashregister/_redux/sagas";
import { reducer as rtUtilityReducer } from "../app/modules/RTUtility/_redux/reducer";
import { reducer as dtrbiReducer } from "../app/modules/DTRBI/_redux/reducer";
import { reducer as moitoIotReducer } from "../app/modules/MoitoIOT/_redux/reducer";
import { reducer as forValueReducer } from "../app/modules/ForValue/_redux/reducer";
import { reducer as calendarReducer } from "../app/modules/Calendar/_redux/reducer";
import { reducer as ejManagementReducer } from "../app/modules/EJManagement/_redux/reducer";
import { reducer as dtrOpticalReducer } from "../app/modules/DTROptical/_redux/reducer";

const appReducer = combineReducers({
  auth: auth.reducer,
  tenant: tenantReducer,
  store: storeReducer,
  cashregister: combineReducers({
    cashregister: cashRegisterReducer,
  }),

  admin: adminReducer,
  retail: retailReducer,
  product: productReducer,
  atorder: atorderReducer,
  sales: salesReducer,
  customer: customerReducer,
  accounting: accountingReducer,
  einvoice: einvoiceReducer,
  user: userReducer,
  rt_user: rtUserReducer,
  rt_utility: rtUtilityReducer,
  dtrbi: dtrbiReducer,
  moitoiot: moitoIotReducer,
  forvalue: forValueReducer,
  calendar: calendarReducer,
  ej_management: ejManagementReducer,
  dtroptical: dtrOpticalReducer,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export function* rootSaga() {
  //yield all([auth.saga()]);
  yield all([
    multitenantSagas.setCurrentTenantSaga(),
    multistoreSagas.setCurrentStoreSaga(),
    multicashregisterSagas.setCurrentCashRegisterSaga(),
  ]);
}
