import React from "react";
import {
  Card as MetronicCard,
  CardBody,
} from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ReactHtmlParser from "react-html-parser";
import { FormattedMessage } from "react-intl";

export function WorkflowBox({
  id,
  text,
  description,
  logo,
  selected,
  onClick,
  className,
  height,
  showTopText = false,
  hrClassName,
  disabled = false
}) {
  const style = {
    card: {
      borderRadius: 4,
      padding: 2,
      cursor: disabled ? null : "pointer",
      border: selected === id ? "3px solid var(--primary)" : "1px solid var(--secondary)",
      height: height ? height : "100%",
    },
  };

  const customOnClick = (id) => {
    if (!disabled) {
      onClick(id);
    }
  };

  return (
    <div className={"p-2 " + className}>
      <MetronicCard onClick={() => customOnClick(id)} style={style.card} className={disabled ? "bg-secondary" : ""}>
        <CardBody className="text-center font-weight-bold w-100 p-2">
          {showTopText && (
            <span>
              {text}
              <br />
            </span>
          )}
          {logo ? (
            <img
              className="img-fluid p-0 m-0 rounded"
              style={{
                width: description ? "100%" : "80%",
                height: description ? "70%" : showTopText ? "80%" : "100%",
              }}
              alt={text}
              src={toAbsoluteUrl(logo)}
            />
          ) : (
            <div>
              <span>{text}</span>
              {hrClassName && (
                <hr className={hrClassName} style={{ width: "90%" }} />
              )}
            </div>
          )}
          {description && (
            <span className="text-muted">{ReactHtmlParser(description)}</span>
          )}
        </CardBody>
        {disabled && (
          <div className="text-center text-danger">
            <span><FormattedMessage id="SERVICE.TEMPORARILY_UNAVAILABLE" /></span>
          </div>
        )}
      </MetronicCard>
    </div>
  );
}
