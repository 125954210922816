const getValueFromStringPath = (obj, path) => {
  return path.split(".").reduce(function (o, k) {
    return o && o[k];
  }, obj);
};

export function GetDefaultValueById(
  dispatch,
  id,
  actions,
  valueField = "id",
  labelField = "name"
) {
  if (!id) {
    return new Promise((resolve, reject) => {
      dispatch(actions.fetchObject(undefined));
      return resolve({});
    });
  }

  return dispatch(actions.fetchObject(id, false)).then((result) => {
    return {
      value: getValueFromStringPath(result, valueField),
      label: getValueFromStringPath(result, labelField),
    };
  });
}
