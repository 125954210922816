import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const actionsPreset = (a, row) => {
  switch (a.id) {
    case "edit":
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              <FormattedMessage id="SERVICE.EDIT" />
            </Tooltip>
          }
        >
          <button
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => a.action(row.id)}
          >
            <span className="fas fa fa-pen text-primary"></span>
          </button>
        </OverlayTrigger>
      );
    case "delete":
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              <FormattedMessage id="SERVICE.DELETE" />
            </Tooltip>
          }
        >
          <button
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => a.action(row.id)}
          >
            <span className="fas fa fa-trash text-danger"></span>
          </button>
        </OverlayTrigger>
      );
    case "moveUp":
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              <FormattedMessage id="SERVICE.MOVE_UP" />
            </Tooltip>
          }
        >
          <button
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => a.action(row)}
          >
            <span className="fas fa fa-arrow-up text-primary"></span>
          </button>
        </OverlayTrigger>
      )
    case "moveDown":
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              <FormattedMessage id="SERVICE.MOVE_DOWN" />
            </Tooltip>
          }
        >
          <button
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => a.action(row)}
          >
            <span className="fas fa fa-arrow-down text-primary"></span>
          </button>
        </OverlayTrigger>
      )
    case "move":
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              <FormattedMessage id="SERVICE.MOVE" />
            </Tooltip>
          }
        >
          <button
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => a.action(row.id)}
          >
            <span className="fas fa fa-retweet text-primary"></span>
          </button>
        </OverlayTrigger>
      )
    case "copy":
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              <FormattedMessage id="SERVICE.COPY" />
            </Tooltip>
          }
        >
          <button
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => a.action(row.id)}
          >
            <span className="fas fa fa-clone text-primary"></span>
          </button>
        </OverlayTrigger>
      )
    case "view": 
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              <FormattedMessage id="SERVICE.VIEW" />
            </Tooltip>
          }
        >
          <button
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => a.action(row.id)}
          >
            <span className="fas fa fa-eye text-primary"></span>
          </button>
        </OverlayTrigger>
      )
  }
} 

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { actions }
) => (
  <>
    {actions.map((a, idx) => (
      <div key={"action_" + idx} className="m-0 p-0 d-inline-block">
        {a && a.id ? (
          <>
            {actionsPreset(a, row)}
          </>
        ) : (
          <OverlayTrigger
            overlay={
              <Tooltip>
                <FormattedMessage id={a.label} />
              </Tooltip>
            }
          >
            <button
              className={"btn btn-icon btn-light btn-hover-" + (a.color || "primary") + " btn-sm"}
              onClick={() => a.action(row.id)}
            >
              {a.iconClassNames ? (
                <span className={a.iconClassNames}/>
              ) : (
                <span className={"svg-icon svg-icon-md svg-icon-" + (a.color || "primary")}>
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/" + a.icon)}
                  />
                </span>
              )}
            </button>
          </OverlayTrigger>
        )}
      </div>
    ))}
  </>
);
