import { API, graphqlOperation } from "aws-amplify";
import {
  getVendor,
  listVendors,
  vendorsByTenant,
  vendorsByCompanyVat,
  searchVendors,
} from "../../../../../graphql/queries";
import {
  updateVendor,
  createVendor,
  deleteVendor,
} from "../../../../../graphql/mutations";

export function searchObjects(queryParams, additionalFilters, nextToken, customQuery) {
  var params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  
  if (queryParams.sortField && queryParams.sortOrder) {
    params.sort = {
      field: queryParams.sortField,
      direction: queryParams.sortOrder,
    };
  }

  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
  }

  params.filter = {
    ...params.filter,
    deleted: { ne: true },
  };

  return API.graphql(graphqlOperation(searchVendors, params));
}

export function findObjects(queryParams, additionalFilters, nextToken) {
  const params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
    if (Object.keys(params.filter).includes("or")) {
      params.filter.or = params.filter.or.concat([
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ]);
    } else {
      params.filter.or = [
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ];
    }
  }

  params.filter = {
    ...params.filter,
    deleted: { ne: true },
  };

  return API.graphql(graphqlOperation(listVendors, params));
}

export function findObjectsByTenant(
  tenantId,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    tenantId: tenantId,
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
    if (Object.keys(params.filter).includes("or")) {
      params.filter.or = params.filter.or.concat([
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ]);
    } else {
      params.filter.or = [
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ];
    }
  }

  params.filter = {
    ...params.filter,
    deleted: { ne: true },
  };

  return API.graphql(graphqlOperation(vendorsByTenant, params));
}


export function findObjectsByCompanyVat(
  companyVat,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    companyVat: companyVat,
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
    if (Object.keys(params.filter).includes("or")) {
      params.filter.or = params.filter.or.concat([
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ]);
    } else {
      params.filter.or = [
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ];
    }
  }

  params.filter = {
    ...params.filter,
    deleted: { ne: true },
  };

  return API.graphql(graphqlOperation(vendorsByCompanyVat, params));
}

export function getObjectById(id) {
  return API.graphql(graphqlOperation(getVendor, { id: id }));
}

export function deleteObject(id) {
  return API.graphql(graphqlOperation(deleteVendor, { input: { id: id } }));
}

export function createObject(obj) {
  return API.graphql(graphqlOperation(createVendor, { input: obj }));
}

export function updateObject(obj) {
  return API.graphql(graphqlOperation(updateVendor, { input: obj }));
}
