export function AddDays(d, days) {
  if (!d) {
    return d;
  }

  var date = d;
  if (typeof(date) === "string") {
    date = new Date(date);
  }

  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
