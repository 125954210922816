import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

const prepareFilter = (queryParams, values, dispatch, fields, actions) => {
  var { searchText } = values;
  const newQueryParams = { ...queryParams };
  var filter = {};

  if (searchText) {
    dispatch(actions.clearData());
  }

  //searchText = ("*" + searchText + "*").toLowerCase();

  if (searchText) {
    filter.or = fields.map(f => {
      return {
        and: searchText.split(" ").map(st => {
          return { [f]: { wildcard: ("*" + st + "*").toLowerCase() } };
        }),
      };
    });
  }

  /*
  searchText = ("*" + searchText + "*").toLowerCase();

  if (searchText) {
    filter.or = fields.map(f => {
      return { [f]: { wildcard: searchText } };
    });
  }
  */

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function OpenSearchFilter({ 
  UIContext, 
  fields,
  labelId = "SERVICE.SEARCH_ALL_FIELDS"
}) {
  const intl = useIntl();

  const [timeoutId, setTimeoutId] = useState(-1);

  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
      actions: UIContext.actions,

      setCurrentToken: UIContext.setCurrentToken,
    };
  }, [UIContext]);

  const dispatch = useDispatch();

  const applyFilter = (values) => {
    if (timeoutId >= 0) {
      clearTimeout(timeoutId);
      setTimeoutId(-1);
    }

    setTimeoutId(setTimeout(() => {
      const newQueryParams = prepareFilter(UIProps.queryParams, values, dispatch, fields, UIProps.actions);
      if (!isEqual(newQueryParams, UIProps.queryParams)) {
        if (UIProps.setCurrentToken) {
          UIProps.setCurrentToken(null);
        }

        newQueryParams.pageNumber = 1;
        UIProps.setQueryParams(newQueryParams);
      }
    }, 400));
  };

  return (
    <>
      <Formik
        initialValues={{
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder={intl.formatMessage({ id: "SERVICE.SEARCH" })}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <FormattedMessage id={labelId} />
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
