import React from "react";

export function IncrementalIdColumn(
  cellContent,
  row,
  rowIndex,
  { sizePerPage, pageNumber }
) {
  return <span>{rowIndex + 1 + sizePerPage * pageNumber - sizePerPage}</span>;
}
