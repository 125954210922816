import React from "react";
import Can from "../../AWS/Can";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { getMenuItemActive } from "../../../utils";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Aside() {
  const location = useLocation();

  return (
    <Can I="view" a="einvoice">
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(location, [
          "/einvoice",
          "/customers/customers",
          "/accounting/sectionals",
        ])}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <NavLink className={"menu-link menu-toggle "} to="/einvoice">
          <span className="svg-icon menu-icon">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Communication/Mail-opened.svg"
              )}
            />
          </span>
          <span className={"menu-text "}>
            <FormattedMessage id="SERVICE.ASIDE.EINVOICE" />
          </span>
        </NavLink>
        <div className="menu-submenu">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            <li className="menu-item menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text">
                  <FormattedMessage id="SERVICE.ASIDE.EINVOICE" />
                </span>
              </span>
            </li>

            <Can I="create" a="einvoice_invoice">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/einvoice/composer"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/einvoice/composer">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.EINVOICE.COMPOSER" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="upload" a="einvoice_invoice">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/einvoice/upload"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/einvoice/upload">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.EINVOICE.UPLOAD" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="einvoice_active_invoices">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/einvoice/active_invoices"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className={"menu-link "}
                  to="/einvoice/active_invoices"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.EINVOICE.ACTIVE_INVOICES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="einvoice_passive_invoices">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/einvoice/passive_invoices"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className={"menu-link "}
                  to="/einvoice/passive_invoices"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.EINVOICE.PASSIVE_INVOICES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="customer">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/customers/customers"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/customers/customers">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.CUSTOMER.CUSTOMERS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="einvoice_vendors">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/einvoice/vendors"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/einvoice/vendors">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.EINVOICE.VENDORS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="einvoice_items">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/einvoice/items"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/einvoice/items">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.PRODUCT.ITEMS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="sectional">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/accounting/sectionals"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/accounting/sectionals">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.ACCOUNTING.SECTIONALS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="einvoice_costcenters">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/einvoice/costcenters"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/einvoice/costcenters">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.EINVOICE.COST_CENTERS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="einvoice_stats">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/einvoice/stats"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/einvoice/stats">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.EINVOICE.STATS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="einvoice_settings">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/einvoice/settings"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/einvoice/settings">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.EINVOICE.SETTINGS" />
                  </span>
                </NavLink>
              </li>
            </Can>
          </ul>
        </div>
      </li>
    </Can>
  );
}
