/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect, useHistory } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import { Login } from "./Login";
import { Register } from "./Register";
import { Forget } from "./Forget";
import "../../../../_metronic/_assets/sass/pages/login/login-1.scss";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { FormattedMessage } from "react-intl";
import { Aside } from "../components/Aside";
import { shallowEqual, useSelector } from "react-redux";
import { EnvName } from "../../../utils";

export function AuthPage() {
  const history = useHistory();

  const today = new Date().getFullYear();

  const { auth } = useSelector(
    (state) => ({
      auth: state.auth,
    }),
    shallowEqual
  );

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          <Aside />

          <div className="w-100 h-100 d-flex flex-column">
            <div className="position-absolute top-0">
              <LanguageSelectorDropdown />  
            </div>

            <div className="w-100 d-flex justify-content-between p-4">
              <div className="text-center pl-5">
                {!history.location.pathname.includes("register") && (
                  <>
                    <div className="p-2">
                      <Link
                        to="/download/dtrservice"
                        className="font-weight-bold"
                      >
                        <FormattedMessage id="SERVICE.DOWNLOAD_APP" />
                      </Link>
                    </div>

                    <div className="p-2">
                      <Link
                        to="/download/moito"
                        className="font-weight-bold"
                      >
                        <FormattedMessage id="SERVICE.DOWNLOAD_MOITO_IOT" />
                      </Link>
                    </div>
                  </>
                )}
              </div>

              <EnvName />

              {!history.location.pathname.includes("register") && (
                <div className="text-center p-2">
                  <span className="font-weight-bold text-dark-50">
                    <FormattedMessage id="SERVICE.AUTH.DONT_HAVE_AN_ACCOUNT" />
                  </span>
                  <br/>
                  <Link
                    to="/auth/register" 
                    className="btn btn-light-primary font-weight-bold ml-2 text-uppercase"
                    id="kt_login_signup"
                  >
                    <FormattedMessage id="SERVICE.AUTH.SIGN_UP" />
                  </Link>
                </div>
              )}
            </div>

            {/* begin::Content body */}
            <div className="align-items-center my-auto">
              <div>
                <Switch>
                  <ContentRoute path="/auth/login/:feedback?" component={Login} />
                  <ContentRoute path="/auth/forget/:type?" component={Forget} />
                  <ContentRoute path="/auth/register" component={Register} />
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
              </div>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy;
                {" " + today.toString() + " DTR-Service"}
              </div>
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2 text-center">
                DTR Italy S.r.l. <br />
                Sede Legale: Via Giuseppe Luosi 14 - 20131 Milano <br />
                Sede Operativa: Via Lussemburgo 28 - 21013 Gallarate (VA) <br />
                P.IVA/C.F. 10244350962 <br />
                commerciale@dtr-italy.com
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link
                  to="/privacy-policy"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  <FormattedMessage id="SERVICE.PRIVACY_POLICY" />
                </Link>
                <Link
                  to="/cookie-policy"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  <FormattedMessage id="SERVICE.COOKIE_POLICY" />
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
