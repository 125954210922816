import React from "react";
import { ISODateDDMMYYYY } from "../../functions/date/ISODateDDMMYYYY";

export function DDMMYYYYColumnFormatter(
  cellContent,
  row,
  rowIndex
) {
  return (
    <span>{cellContent ? ISODateDDMMYYYY(new Date(cellContent)) : ""}</span>
  );
}
