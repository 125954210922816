import React, { useState } from "react";
import { WorkflowBox } from "./WorkflowBox";
import { FormattedMessage, useIntl } from "react-intl";

export function UserTypeBoxes({ defaultValue, onChange }) {
  const intl = useIntl();

  const [selected, setSelected] = useState(defaultValue);

  const onChangeSelected = (val) => {
    setSelected(val);
    onChange(val);
  };

  const style = {
    card: {
      borderRadius: 4,
      padding: 2,
      cursor: "pointer",
      border: "1px solid grey",
      height: "100%",
    },
  };

  return (
    <>
      <div className="w-100 my-4">
        <h3 className="text-center">
          <FormattedMessage id="SERVICE.AUTH.REGISTATION_STEPPER_1" />
        </h3>
        <div className="text-center text-muted my-4">
          <div className="row">
            <div className="col-0 col-lg-4 col-md-3"></div>
            <div className="col-12 col-lg-4 col-md-6">
              <FormattedMessage id="SERVICE.AUTH.REGISTATION_SUB_STEPPER_1" />
            </div>
            <div className="col-0 col-lg-4 col-md-3"></div>
          </div>
        </div>
      </div>
      <div className="row p-0 m-0">
        <div className="col-md-4 col-sm-0"></div>
        <WorkflowBox
          id={2}
          text={"REGISTRAZIONE CONCESSIONARIO"}
          description={
            "Registrazione riservata ai <span class='text-danger'>DEALER</span> DTR-Italy"
          }
          selected={selected}
          onClick={onChangeSelected}
          className="col-md-4 col-sm-12"
          hrClassName={"bg-danger"}
        />
        <div className="col-md-4 col-sm-0"></div>
      </div>
      <hr />
      <div className="row p-0 m-0">
        <div className="col-md-4 col-sm-0"></div>
        <WorkflowBox
          id={1}
          text={"REGISTRAZIONE CLIENTE"}
          description={
            "Registrazione riservata ai <span class='text-primary'>TENANT</span>"
          }
          selected={selected}
          onClick={onChangeSelected}
          className="col-md-4 col-sm-12"
          hrClassName={"bg-primary"}
        />
        <div className="col-md-4 col-sm-0"></div>
      </div>
      <br />
    </>
  );
}
