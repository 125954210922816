import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import AddressMap from "./AddressMap";
import Autosuggest from 'react-autosuggest';

const searchComponent = (addressComponents, type) => {
  for (const comp of addressComponents) {
    if (comp.types.includes(type)) {
      return comp.short_name;
    }
  }
  return "";
}

const composeAddressObject = (addressComponents, placeId) => {
  const municipality = searchComponent(addressComponents, "administrative_area_level_3");
  const zipCode = searchComponent(addressComponents, "postal_code");
  const country = searchComponent(addressComponents, "country");
  const county = searchComponent(addressComponents, "administrative_area_level_2");
  const route = searchComponent(addressComponents, "route");
  const streetNumber = searchComponent(addressComponents, "street_number");

  return {
    placeId,

    address: route + " " + streetNumber,
    municipality,
    zipCode,
    country,
    county,
  };
}


export function AddressAutocompleteInput({
  label,
  required = false,
  onChange,
  placeId,
  showMap = false,
}) {
  const intl = useIntl();

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    language:"it",
  });

  const [place, setPlace] = useState(undefined);
  const [searchString, setSearchString] = useState("");
  const [isLoadingDefaultAddress, setIsLoadingDefaultAddress] = useState(false);

  useEffect(() => {
    //executed on form open, when address has to be pre-popolated automatically

    if (placeId && !place) {
      if (!placesService) {
        setIsLoadingDefaultAddress(true);
      }

      placesService?.getDetails({ placeId: placeId }, (placeDetails) => {
        setPlace({
          place_id: placeId,
          details: placeDetails
        });

        setSearchString(placeDetails.formatted_address);

        setIsLoadingDefaultAddress(false);

        const addr = composeAddressObject(placeDetails.address_components, placeId);
        onChange(addr);
      });
    }
  }, [placeId, placesService]);

  useEffect(() => {
    if (place && !place.details) {
      placesService?.getDetails({ placeId: place.place_id }, (placeDetails) => {
        setPlace({
          ...place,
          details: placeDetails
        });

        const addr = composeAddressObject(placeDetails.address_components, place.place_id);
        onChange(addr);
      });
    }
  }, [place]);

  const onInputChange = (event, { newValue }) => {
    setSearchString(newValue);
  }

  const onSuggestionSelected = (event, { suggestion }) => {
    setPlace(suggestion);
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    getPlacePredictions({ input: value });
  }

  const onSuggestionsClearRequested = () => void 0;

  const renderSuggestion = (suggestion) => {
    return (
      <div>
        {suggestion.description}
      </div>
    );
  }

  return (
    <div>
      {label && (<label>{label}</label>)}
      {required && (
        <>
          {" "}
          <strong className="text-danger">*</strong>
        </>
      )}
      {isLoadingDefaultAddress && placeId && (
        <>
          {" "}
          <span className="spinner"></span>
        </>
      )}

      <div className="w-100">
        <Autosuggest
          suggestions={placePredictions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={(s) => s.description}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={{
            placeholder: intl.formatMessage({ id: "ATORDER.DELIVERY_ADDRESS" }),
            value: searchString,
            onChange: onInputChange
          }}
        />
      </div>

      <div className="my-4" style={{ height: 150 }}>
        {showMap && place?.details ? (
          <AddressMap
            latitude={place?.details?.geometry?.location?.lat()}
            longitude={place?.details?.geometry?.location?.lng()}
          />
        ) : (
          <div className="bg-secondary h-100 rounded text-center d-flex align-items-center w-100 justify-content-center">
            <div>
              <i className="fas fa fa-map-pin fa-2x"></i>
              <br/>
              <span className="text-muted">
                {isLoadingDefaultAddress ? (
                  <FormattedMessage id="SERVICE.LOADING" />
                ) : (
                  <FormattedMessage id="ATORDER.ORDINATIONS.INSERT_ADDRESS_TO_SEE_ON_MAP"/>
                )}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
