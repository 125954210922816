import entities from "../../../common/cashregisters/configuration_entities";

function splitVersion(v) {
  return v.split(".").map((el) => parseInt(el));
}

function composeVersion(array) {
  var result = "";
  array.forEach((el) => {
    result += el + ".";
  });
  return result.slice(0, -1);
}

export function increaseAllVersions(version) {
  var result = "";

  const splitted = splitVersion(version);
  splitted.forEach((single) => {
    result += single + 1 + ".";
  });

  return result.slice(0, -1);
}

export function defaultVersion() {
  var result = "";

  entities.forEach((el) => {
    result += "0.";
  });
  return result.slice(0, -1);
}

export function diff(v1, v2) {
  const splitted1 = splitVersion(v1);
  const splitted2 = splitVersion(v2);

  const baseArray =
    splitted1.length >= splitted2.length ? splitted1 : splitted2;
  const otherArray =
    splitted1.length < splitted2.length ? splitted1 : splitted2;

  var result = [];
  baseArray.forEach((el, idx) => {
    if (el !== otherArray[idx]) {
      result.push(idx);
    }
  });

  return result;
}

export function fromPositionsToEntities(positions) {
  var result = [];

  positions.forEach((el) => {
    if (entities[el]) {
      result.push(entities[el]);
    }
  });

  return result;
}

export function incrementVersionByCodes(baseVersion, codes) {
  var splitted = splitVersion(baseVersion);

  codes.forEach((code) => {
    const foundIdx = entities.findIndex((el) => el.code === code);
    if (foundIdx >= 0) {
      splitted[foundIdx] = splitted[foundIdx] + 1;
    }
  });

  return composeVersion(splitted);
}
