/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";
import { FormattedMessage } from "react-intl";
import * as actions from "../../../../app/modules/AuthCognito/_redux/authActions";
import { useHistory } from "react-router-dom";

export function QuickUserToggler() {
  const history = useHistory();

  const {user} = useSelector(state => state.auth);


  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  const getName = () => {
    return user.firstName || "";
  };

  const dispatch = useDispatch();

  const logoutClick = () => {
    dispatch(actions.logout()).then(() => {
      localStorage.clear();
      history.push("/");
    });
  };

  return (
      <>
        {user ? (
          <>
            {layoutProps.offcanvas && (<OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="quick-user-tooltip"><FormattedMessage id="SERVICE.VIEW_USER" /></Tooltip>}
            >
              <div className="topbar-item">
                <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                     id="kt_quick_user_toggle">
                  <>

                    <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"><FormattedMessage id="SERVICE.HI" />,</span>
                    <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                      {getName()}
                    </span>
                    <span className="symbol symbol-35 symbol-light-success">                
                        <span className="symbol-label font-size-h5 font-weight-bold">
                          {getName().length > 0 ? getName()[0] : ""}
                        </span>
                    </span>
                  </>
                </div>
              </div>
            </OverlayTrigger>)}

            {!layoutProps.offcanvas && (<UserProfileDropdown/>)}
          </>
        ) : (
          <button
            type="button"
            onClick={logoutClick}
            className="btn btn-light my-3 px-10"
          >
            <FormattedMessage id="USER.SIGN_OUT" />
          </button>
        )}
      </>
  );
}
