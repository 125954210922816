import { SET_CURRENT_STORE, SET_CURRENT_STORE_ASYNC, REFRESH } from "./types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  currentStoreId: "",
};

export const storeReducer = persistReducer(
  { storage, key: "store" },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_CURRENT_STORE: {
        const returnProps = {
          ...state,
          currentStoreId: action.payload,
        };
        return returnProps;
      }

      case SET_CURRENT_STORE_ASYNC: {
        const returnProps = {
          ...state,
          currentStoreId: action.payload,
        };
        return returnProps;
      }

      case REFRESH: {
        const returnProps = {
          ...state,
        };
        window.location.reload();
        return returnProps;
      }

      default:
        return state;
    }
  }
);
