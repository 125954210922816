/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from "react-intl";

export function PaginationLinks({
  paginationProps,
  nextPage,
  prevPage,
  enableNextPage,
  enablePrevPage,
}) {
  const handlePrevPage = ({ page, onPageChange }) => {
    prevPage();
    onPageChange(page - 1);
  };

  const handleNextPage = ({ page, onPageChange }) => {
    nextPage();
    onPageChange(page + 1);
  };

  return (
    <>
      <div className="w-100">
        <div className="d-flex flex-wrap py-2 mr-3">
          <button
            type="button"
            disabled={!enablePrevPage}
            onClick={() => handlePrevPage(paginationProps)}
            className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
          >
            <i className="ki ki-bold-arrow-back icon-xs" />
          </button>

          <button
            type="button"
            disabled={!enableNextPage}
            onClick={() => handleNextPage(paginationProps)}
            className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
          >
            <i className="ki ki-bold-arrow-next icon-xs"></i>
          </button>
        </div>
      </div>
      <br/>
      <div className="p-1">
        <span>
          <FormattedMessage id="SERVICE.PAGE" />
          {" "}
          <span className="font-weight-bold">{paginationProps?.page || 1}</span>
        </span>
      </div>
    </>
  );
}
