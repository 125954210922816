export const createCalendarEvent = /* GraphQL */ `
  mutation CreateCalendarEvent(
    $input: CreateCalendarEventInput!
    $condition: ModelCalendarEventConditionInput
  ) {
    createCalendarEvent(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      userId
      calendarEventTypeId
      customerId
      notificationEnabled
      notificationDatetime
      notificationSent
      title
      description
      startDatetime
      endDatetime
      tags
      isAllDayEvent
      extensions
      mobile
      email
      deleted
      operatorId
      closed
      isPending
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      calendarEventType {
        id
        tenantId
        retailStoreId
        name
        color
        notificationEnabled
        notificationAnticipationMinutes
        title
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          createdAt
          updatedAt
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        name
        deleted
        sorting
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          createdAt
          updatedAt
        }
      }
      calendarEventItems {
        items {
          id
          tenantId
          retailStoreId
          itemId
          item {
            id
            name
          }
          qty
          calendarEventId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const updateCalendarEvent = /* GraphQL */ `
  mutation UpdateCalendarEvent(
    $input: UpdateCalendarEventInput!
    $condition: ModelCalendarEventConditionInput
  ) {
    updateCalendarEvent(input: $input, condition: $condition) {
      id
      tenantId
      retailStoreId
      userId
      calendarEventTypeId
      customerId
      notificationEnabled
      notificationDatetime
      notificationSent
      title
      description
      startDatetime
      endDatetime
      tags
      isAllDayEvent
      extensions
      mobile
      email
      deleted
      operatorId
      closed
      isPending
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        currency {
          id
          name
          symbol
          code
          createdAt
          updatedAt
        }
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        isAccountant
        accountantId
        stripeAccountId
        stripeRegistrationCompleted
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      user {
        id
        partyId
        enabled
        tenantDefault
        retailStoreDefault
        showTutorial
        email
        mobile
        pushNotificationTokens {
          service
          deviceToken
        }
        deleted
        firstName
        lastName
        createdAt
        updatedAt
        tenantUser {
          nextToken
        }
        userPrivilege {
          nextToken
        }
        userLicense {
          nextToken
        }
        userRetailStore {
          nextToken
        }
      }
      customer {
        id
        tenantId
        description
        createdByModule
        deleted
        companyName
        companyVat
        firstName
        lastName
        mobile
        visible
        fiscalCode
        email
        zipCode
        county
        municipality
        address
        country
        recipientCode
        extensions
        latitude
        longitude
        addressPlaceId
        loyaltyPoints
        addressNotes
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        priceList {
          id
          tenantId
          retailStoreId
          name
          extensions
          createdByModule
          sorting
          deleted
          visible
          enabled
          createdAt
          updatedAt
        }
        vatRate {
          id
          tenantId
          code
          description
          sorting
          rate
          extensions
          isAdded
          createdByModule
          deleted
          createdAt
          updatedAt
        }
        autoDiscount {
          id
          tenantId
          description
          value
          percValue
          discountSurcharge
          itemSubTotal
          enabled
          sorting
          deleted
          createdAt
          updatedAt
        }
        prepaidCard {
          id
          tenantId
          retailStoreId
          customerId
          s3Key
          initialAmount
          remainingAmount
          email
          type
          percDiscount
          datetime
          dueDate
          revoked
          createdAt
          updatedAt
        }
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
        accountantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        retailStoreLicense {
          nextToken
        }
        party {
          id
          firstName
          lastName
          companyName
          companyVat
          fiscalCode
          email
          logo
          mobile
          zipCode
          county
          municipality
          address
          country
          role
          recipientCode
          latitude
          longitude
          createdAt
          updatedAt
        }
        user {
          nextToken
        }
        invoiceSectional {
          id
          tenantId
          partnerId
          code
          description
          deleted
          nextNumber
          createdAt
          updatedAt
        }
        cashRegisters {
          nextToken
        }
      }
      calendarEventType {
        id
        tenantId
        retailStoreId
        name
        color
        notificationEnabled
        notificationAnticipationMinutes
        title
        extensions
        deleted
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          createdAt
          updatedAt
        }
      }
      operator {
        id
        tenantId
        retailStoreId
        description
        name
        deleted
        sorting
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          createdAt
          updatedAt
        }
      }
      calendarEventItems {
        items {
          id
          tenantId
          retailStoreId
          itemId
          item {
            id
            name
          }
          qty
          calendarEventId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;