import React from "react";
import { useSelector, shallowEqual } from "react-redux";

export function ErrorMessage() {
  const { auth } = useSelector(
    (state) => ({
      auth: state.auth,
    }),
    shallowEqual
  );

  return (
    <>
      {auth && auth.error && (
        <div className="container bg-danger text-white rounded border-dark text-center px-2 py-5 mt-10 mb-10 font-weight-bold">
          <span className="fas fa-exclamation-circle"></span>{" "}
          {auth ? auth.error : ""}
        </div>
      )}
    </>
  );
}
