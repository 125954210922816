export default [
  "rt_utility",
  "rt_utility_logs",
  "rt_utility_fw_download",
  "rt_utility_ej_extension",
  "moitoiot_dealer",
  "moitoiot_automatic_update",
  "moitoiot_multiple_users",
  "moitoiot_online_backup",
  "moitoiot_catering",
  "moitoiot_variant_management",
  "moitoiot_tables_and_orders_management",
  "moitoiot_advanced_payments_management",
  "atorder_delivery",
  "rt_user_accountant",
  "ej_management",
  "ej_1_year",
  "ej_2_years",
  "ej_nolimit",
  "moitoiot_kiosk",
  "moitoiot_tobacco",
];
