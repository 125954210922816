import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { read,update} from "./crud"

const initialState = { entities: [], loading: false };

export const fetchEventTypes = createAsyncThunk('eventtypes/fetch',async (args,{getState}) => {
  const {tenant, store}=getState();
  return read({
    tenantId:tenant.currentTenantId,
    retailStoreId:{ eq: store.currentStoreId},
    filter:{ 
      deleted: { ne: true },
    }
  });
});

export const updateColor = createAsyncThunk('eventtypes/updateColor',async ({color,id},{getState}) => update({color,id}));

const eventTypesSlice = createSlice({
  name: 'eventtypes',
  initialState,
  reducers: {
    objectsFetched: (state, action) => {
      state.loading = false;
      state.entities = action.payload.items;
    }
  },
  extraReducers: {
    [fetchEventTypes.pending]: (state) => {
      state.loading = true;
    },
    [fetchEventTypes.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.entities = payload.items;
    },
    [fetchEventTypes.rejected]: (state , { payload }) => {
      state.loading = false;
    },
    [updateColor.pending]: (state) => {
      state.loading = true;
    },
    [updateColor.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateColor.rejected]: (state , { payload }) => {
      state.loading = false;
    }
  },
})

export const selectEntities = (state) => state.calendar.eventtypes.entities;

export const selectLoading = (state) => state.calendar.eventtypes.loading;

export const setEventTypes = (records) => (dispatch) => {
  dispatch(eventTypesSlice.actions.objectsFetched({ items: records }));
}

export default eventTypesSlice.reducer