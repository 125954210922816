import { Auth, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../../graphql/mutations";

import { actions as tenantActions } from "../../Multitenancy/_redux/tenantActions";
import { actions as storeActions } from "../../Multistore/_redux/actions";
import { actions as cashRegisterActions } from "../../Multicashregister/_redux/actions";

import { translateError } from "../utils/translateError";

import * as restApi from "../../Admin/rest/api";

import {
  AUTH_USER,
  AUTH_MFA,
  AUTH_NEW_PASSWORD_REQUIRED,
  UNAUTH_USER,
  REGISTER_USER,
  REGISTER_USER_CONFIRM,
  REGISTER_MFA,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CONFIRM,
  AUTH_ERROR,
  //COMPLETE_REGISTRATION,
  //UPDATE_USER_PARTY,
  UPDATE_TENANT_PARTY,
  UPDATE_TENANT,
  UPDATE_USER,
  REFRESH_CURRENT_USER,
  INCREMENT_ITEM_SORTING,
  INCREMENT_ITEMGROUP_SORTING,
  CHANGE_LOGIN_TYPE,
  CANCEL_FORGOT_PASSWORD,
  CANCEL_REGISTRATION,
  FORCE_REFRESH_TOKEN,
  SET_USER_TYPE,
  PENDING_SOCIAL_SIGN_IN,
} from "./types";

const BLOCKED_LOGIN_ERROR = {
  code: "Service_NotAuthorizedTenant",
  message: "ACCESSO NON AUTORIZZATO. Contattare amministrazione@dtr-italy.com per maggiori informazioni.",
};

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: translateError(error),
  };
}

const getAllUserData = async (id, throwError = true) => {
  var user = null;

  try {
    const getUserResponse = await restApi.getUser(id);
    user = getUserResponse.data;
  } catch(e) {
    /* tslint:disable:no-empty */
  }

  if (!user && throwError) {
    throw Error("User not found");
  }

  //Appsync-like result for compatibility
  return {
    data: {
      getUser: user,
    },
  };
};

export const setUserType = (userType) => (dispatch) => {
  dispatch({ type: SET_USER_TYPE, payload: { userType: userType } });
};

export const forceRefreshToken = () => (dispatch) => {
  console.log("on forceRefreshToken");

  return Auth.currentAuthenticatedUser()
    .then((cognitoUser) => {
      return Auth.currentSession()
      .then((currentSession) => {
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          dispatch({ type: FORCE_REFRESH_TOKEN, payload: cognitoUser });
        });

      })
      .catch((err) => {
        console.log("on forceRefreshToken, internal authError", err);

        dispatch(authError(err));
      });
    })
    .catch((err) => {
      console.log("on forceRefreshToken, authError", err);

      dispatch(authError(err));
    });
};

export const login =
  (username, password, loginType = 0) =>
  (dispatch) => {
    return Auth.signIn(username, password)
      .then((data) => {
        if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
          dispatch({ type: AUTH_NEW_PASSWORD_REQUIRED, payload: data });
        } else if (
          data.challengeName === "MFA_REQUIRED" ||
          data.challengeName === "SMS_MFA"
        ) {
          dispatch({ type: AUTH_MFA, payload: data });
        } else {
          return getAllUserData(data.username, false).then((u) => {
            let payl = {
              cognitoUser: data,
              user: u.data.getUser,
            };

            if (!payl.user) {
              dispatch({
                type: AUTH_USER,
                payload: { ...payl, loginType: loginType },
              });
            } else {
              const defaultTenantUserData = payl?.user?.tenantUser?.items?.find(el => el?.tenant?.id === payl?.user?.tenantDefault);
              if (defaultTenantUserData?.tenant?.blockLevel === 2) {
                dispatch(logout());
                dispatch(authError(BLOCKED_LOGIN_ERROR));
              }
              else {
                if (payl.user.retailStoreDefault) {
                  if (payl.user) {
                    dispatch(
                      storeActions.setCurrentStoreAsync(
                        payl.user.retailStoreDefault
                      )
                    );
                    dispatch(
                      tenantActions.setCurrentTenantAsync(payl.user.tenantDefault)
                    );
                    dispatch(cashRegisterActions.setCurrentCashRegisterAsync(""));
                  }

                  dispatch({
                    type: AUTH_USER,
                    payload: { ...payl, loginType: loginType },
                  });
                } else {
                  dispatch(
                    authError({
                      code: "Service_NotAuthorizedStore",
                      message: "You are not authorized to access any store.",
                    })
                  );
                }
              }
            }
          });
        }
      })
      .catch((err) => {
        console.log("on login, authError", err);

        dispatch(authError(err));
      });
  };

// Cognito - Auth.currentAuthenticatedUser()
// Cognito - Auth.userSession()
// This is a pass-through function to indicate that user has already authenticated
// and has a valid Amplify session.
export function validateUserSession() {
  console.log("on validateUserSession");

  return function (dispatch) {
    Auth.currentAuthenticatedUser()
      .then((currentAuthUser) => {
        // grab the user session
        Auth.userSession(currentAuthUser)
          .then((session) => {
            // finally invoke isValid() method on session to check if auth tokens are valid
            // if tokens have expired, lets call "logout"
            // otherwise, dispatch AUTH_USER success action and by-pass login screen
            if (session.isValid()) {
              // fire user is authenticated
              dispatch({ type: AUTH_USER, payload: session });
              //history.push('/');
            } else {
              console.log("on validateUserSession, session is not valid");

              // fire user is unauthenticated
              dispatch({ type: UNAUTH_USER, payload: { source: "validateUserSession_sessionNotValid" } });
              //history.push('/');
            }
          })
          .catch((err) => {
            console.log("on validateUserSession, internal UNAUTH_USER", err);

            // error occured during session validation, fire user is unauthenticated
            dispatch({ type: UNAUTH_USER, payload: { source: "validateUserSession_internalCatch" } });
            //history.push('/');
          });
      })
      .catch((err) => {
        console.log("on validateUserSession, UNAUTH_USER", err);

        // error occured while retrieving current auth user, fire user is unauthenticated
        dispatch({ type: UNAUTH_USER, payload: { source: "validateUserSession_externalCatch" } });
        //history.push('/');
      });
  };
}

export const setNewPassword = (cognitoUser, newPassword) => (dispatch) => {
  return Auth.completeNewPassword(cognitoUser, newPassword)
    .then((data) => {
      if (data.challengeName === "SMS_MFA") {
        dispatch({ type: AUTH_MFA, payload: data });
      } else {
        Auth.currentAuthenticatedUser().then((newCognitoUser) => {
          dispatch(login(newCognitoUser.username, newPassword));
        });
      }
    })
    .catch((err) => {
      dispatch(authError(err));
    });
};

export const logout = () => (dispatch) => {
  console.log("on logout");

  console.log("on logout UNAUTH_USER");

  dispatch({ type: UNAUTH_USER, payload: { source: "logout" } });
  return Auth.signOut()
    .then((data) => {
      console.log("signOut result", data);
    })
    .catch((err) => {
      console.log("on logout, authError", err);

      dispatch(authError(err));
    });
};

// Cognito - Auth.confirmSignIn()
export function confirmLogin({ cognitoUser, code }, history) {
  return function (dispatch) {
    // confirmSignIn (cognito)
    Auth.confirmSignIn(cognitoUser, code)
      .then((data) => {
        // dispatch AUTH_USER
        dispatch({ type: AUTH_USER });

        // we have authenticated, lets navigate to /main route
        history.push("/");
      })
      .catch((err) => {
        // error -- invoke authError which dispatches AUTH_ERROR
        dispatch(authError(err));
      });
  };
}

export const register = (email, password, history) => (dispatch) => {
  return Auth.signUp(email, password)
    .then((data) => {
      if (
        typeof data.userConfirmed != "undefined" &&
        data.userConfirmed === false
      ) {
        dispatch({ type: REGISTER_MFA, payload: data });
      } else {
        dispatch({ type: REGISTER_USER });
        history.push("/");
      }
    })
    .catch((err) => {
      dispatch(authError(err));
    });
};

export const initializeSocialSignIn = (providerName) => (dispatch) => {
  dispatch({ type: PENDING_SOCIAL_SIGN_IN, payload: { provider: providerName } });

  return Auth.federatedSignIn({ provider: providerName })
  .then((r) => {
    doSocialSignIn();
  })
  .catch((err) => {
    dispatch(authError(err));
  });
};

const timer = ms => new Promise(res => setTimeout(res, ms));

const waitForCurrentAuthenticatedUser = async () => {
  var userData;
  do {
    try {
      userData = await Auth.currentAuthenticatedUser();
    } catch(e) {
      //Nothing to do here
    }

    await timer(250);
  } while(!userData);

  return userData;
}

export const doSocialSignIn = () => async (dispatch) => {
  const userData = await waitForCurrentAuthenticatedUser();

  return getAllUserData(userData.username, false).then((u) => {
    let payl = {
      cognitoUser: userData,
      user: u.data.getUser,
      loginType: 0,
    };

    if (!payl.user) {
      dispatch({
        type: AUTH_USER,
        payload: { ...payl },
      });
    } else {
      if (payl.user.retailStoreDefault) {
        if (payl.user) {
          dispatch(
            storeActions.setCurrentStoreAsync(
              payl.user.retailStoreDefault
            )
          );
          dispatch(
            tenantActions.setCurrentTenantAsync(payl.user.tenantDefault)
          );
          dispatch(cashRegisterActions.setCurrentCashRegisterAsync(""));
        }

        dispatch({
          type: AUTH_USER,
          payload: { ...payl },
        });
      } else {
        dispatch(
          authError({
            code: "Service_NotAuthorizedStore",
            message: "You are not authorized to access any store.",
          })
        );
      }
    }
  });
};

export const cancelRegistration = () => (dispatch) => {
  dispatch({ type: CANCEL_REGISTRATION });
};

export const confirmRegistration =
  (cognitoUser, code, userType, registrationMethod, history) =>
  (dispatch) => {
    const { username } = cognitoUser.user;

    return Auth.confirmSignUp(username, code)
      .then((data) => {
        dispatch({
          type: REGISTER_USER_CONFIRM,
          payload: {
            registrationMethod: registrationMethod,
            userType: userType,
          },
        });
        history.push("/auth/login");
      })
      .catch((err) => {
        dispatch({
          type: REGISTER_USER_ERROR,
          payload: err.message,
          cognitoUser,
        });
        dispatch(authError(err));

        return Promise.reject(err);
      });
  };

export const resendConfirmationCode = (cognitoUser) => (dispatch) => {
  const { username } = cognitoUser.user;
  return Auth.resendSignUp(username)
    .then((data) => {
      dispatch({ type: REGISTER_MFA, payload: cognitoUser });
    })
    .catch((err) => {
      dispatch(authError(err));
    });
};

export const forgotPassword = (username) => (dispatch) => {
  return Auth.forgotPassword(username)
    .then((data) => {
      dispatch({ type: FORGOT_PASSWORD });
    })
    .catch((err) => {
      dispatch(authError(err));
    });
};

export const cancelForgotPassword = () => (dispatch) => {
  dispatch({ type: CANCEL_FORGOT_PASSWORD });
};

export const confirmForgotPassword =
  (username, code, newPassword) => (dispatch) => {
    return Auth.forgotPasswordSubmit(username, code, newPassword)
      .then((data) => {
        dispatch({ type: FORGOT_PASSWORD_CONFIRM });
      })
      .catch((err) => {
        dispatch(cancelForgotPassword());
        dispatch(authError(err));
      });
  };

export const updateLoggedUserParty = (user) => (dispatch) => {
  return API.graphql(
    graphqlOperation(mutations.updateUser, { input: user })
  ).then((result) => {
    dispatch(refreshCurrentUser(user.id));
  });
};

export const changePassword = (oldPassword, newPassword) => (dispatch) => {
  return Auth.currentAuthenticatedUser().then((cognitoUser) => {
    return Auth.changePassword(cognitoUser, oldPassword, newPassword);
  });
};

export const updateTenantParty = (tenant) => (dispatch) => {
  tenant.extensions = JSON.stringify(tenant.extensions);
  return API.graphql(
    graphqlOperation(mutations.updateTenant, { input: tenant })
  ).then((result) => {
    const newTenant = {
      ...result.data.updateTenant,
      extensions: JSON.parse(result.data.updateTenant.extensions),
    };

    dispatch({
      type: UPDATE_TENANT_PARTY,
      payload: { tenant: newTenant },
    });
  });
};

export const updateTenant = (tenantId, tenant) => (dispatch) => {
  if (tenant && tenant.extensions) {
    tenant.extensions = JSON.stringify(tenant.extensions);
  }
  return API.graphql(
    graphqlOperation(mutations.updateTenant, { input: tenant })
  ).then((result) => {
    const newTenant = result.data.updateTenant;
    dispatch({
      type: UPDATE_TENANT,
      payload: { tenantId: tenantId, tenant: newTenant },
    });
  });
};

export const updateUser = (user) => (dispatch) => {
  return API.graphql(
    graphqlOperation(mutations.updateUser, { input: user })
  ).then((result) => {
    const newUser = result.data.updateUser;
    dispatch({ type: UPDATE_USER, payload: { user: newUser } });
  });
};

export const refreshCurrentUser = (userId, storeId = undefined) => (dispatch) => {
  return getAllUserData(userId)
    .then((response) => {
      const user = response.data.getUser;

      const defaultTenantUserData = user?.tenantUser?.items?.find(el => el?.tenant?.id === user?.tenantDefault);
      if (defaultTenantUserData?.tenant?.blockLevel === 2) {
        dispatch(logout());
        dispatch(authError(BLOCKED_LOGIN_ERROR));
      }
      else {
        dispatch({ type: REFRESH_CURRENT_USER, payload: { user: user } });

        if (!storeId && user?.retailStoreDefault) {
          dispatch(
            storeActions.setCurrentStoreAsync(
              user?.retailStoreDefault
            )
          );
        }
      }

      return user;
    })
    .catch((e) => {
      /*
      return Auth.signOut()
        .then((data) => {
          dispatch({ type: UNAUTH_USER });
        })
        .catch((err) => {
          dispatch(authError(err));
        });
      */
    });
};

export const incrementItemSorting = (tenant) => (dispatch) => {
  const newTenant = {
    id: tenant.id,
    nextItemSorting: tenant.nextItemSorting ? tenant.nextItemSorting + 5 : 5,
  };
  return API.graphql(
    graphqlOperation(mutations.updateTenant, { input: newTenant })
  ).then((response) => {
    dispatch({
      type: INCREMENT_ITEM_SORTING,
      payload: {
        tenantId: tenant.id,
        nextItemSorting: newTenant.nextItemSorting,
      },
    });
  });
};

export const incrementItemGroupSorting = (tenant) => (dispatch) => {
  const newTenant = {
    id: tenant.id,
    nextItemGroupSorting: tenant.nextItemGroupSorting
      ? tenant.nextItemGroupSorting + 5
      : 5,
  };
  return API.graphql(
    graphqlOperation(mutations.updateTenant, { input: newTenant })
  ).then((response) => {
    dispatch({
      type: INCREMENT_ITEMGROUP_SORTING,
      payload: {
        tenantId: tenant.id,
        nextItemGroupSorting: newTenant.nextItemGroupSorting,
      },
    });
  });
};

export const changeLoginType = (newLoginType) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: CHANGE_LOGIN_TYPE, payload: { loginType: newLoginType } });
    return resolve(true);
  });
};
