import React from "react";
import { EnvironmentType } from "../functions/EnvironmentType";
import { useCheckMobileScreen } from "..";

export function EnvName() {
  const env = EnvironmentType();
  const isMobile = useCheckMobileScreen();

  return (
    <div>
      {(env !== "prod" && !isMobile) && (
        <h1 className="text-uppercase font-weight-bold text-danger pt-5 px-4">
          {env}
        </h1>
      )}
    </div>
  );
}
