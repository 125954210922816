import {
  SET_CURRENT_TENANT,
  SET_CURRENT_TENANT_ASYNC,
  REFRESH,
} from "./tenantTypes";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialCompanyState = {
  currentTenantId: "",
};

//todo get default company for user

export const tenantReducer = persistReducer(
  { storage, key: "tenant" },
  (state = initialCompanyState, action) => {
    switch (action.type) {
      case SET_CURRENT_TENANT: {
        const returnProps = {
          ...state,
        };
        return returnProps;
      }

      case SET_CURRENT_TENANT_ASYNC: {
        const returnProps = {
          ...state,
          currentTenantId: action.payload,
        };
        return returnProps;
      }

      case REFRESH: {
        const returnProps = {
          ...state,
        };
        //console.log("Refresh");
        window.location.reload();
        return returnProps;
      }

      default:
        return state;
    }
  }
);
