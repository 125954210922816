import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tenant: {},
  stores: [],
  lastError: null,
};

export const slice = createSlice({
  name: "moitoiot_registration",
  initialState: initialState,
  reducers: {
    setTenantData: (state, action) => {
      state.tenant = action.payload.data ? action.payload.data : {};
    },
    setStoreData: (state, action) => {
      state.stores = action.payload.data ? action.payload.data : [];
    },
    addStoreData: (state, action) => {
      state.stores = [...state.stores, action.payload.data];
    },
    updateStoreData: (state, action) => {
      state.stores = state.stores.map((el) =>
        el.id === action.payload.data.id ? action.payload.data : el
      );
    },
    clearData: (state, action) => {
      state.tenant = {};
      state.stores = [];
      state.lastError = null;
    },
  },
});
