import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import Picker from 'react-scrollable-picker';

const fullRotate = (values) => {
  const repeatTimes = 10;

  var results = [];
  [...Array(repeatTimes)].forEach((_, i) => {
    for (const el of values) {
      results.push({
        value: i + "_" + el.value,
        label: el.label,
      })
    }
  });

  return results;
};

const allHours = Array(24).fill(null).map((_, i) => ({ value: i, label: i }));
const allMinutes = Array(60).fill(null).map((_, i) => ({ value: i, label: i })).filter(el => el.value % 5 === 0);

export function TimeScrollPicker({
  defaultValue,
  onChange,
  enabledHours = [],
  enabledMinutes = []
}) {
  const intl = useIntl();

  const [valueGroups, setValueGroups] = useState({ 
    hour: "5_" + (defaultValue?.hour || 0), 
    minute: "5_" + (defaultValue?.minute || 0) 
  });

  const optionGroups = { 
    hour: fullRotate(enabledHours?.length > 0 ? enabledHours : allHours), 
    minute: fullRotate(enabledHours?.length > 0 ? enabledMinutes : allMinutes), 
  };

  const onTimeChange = (name, value) => {
    setValueGroups(old => ({
      ...old,
      [name]: value,
    }));

    const realValue = value.split("_")[1];
    if (name === "hour") {
      const realMinute = valueGroups.minute.split("_")[1];
      onChange(realValue, realMinute);
    }
    else {
      const realHour = valueGroups.hour.split("_")[1];
      onChange(realHour, realValue); 
    }
  }

  return (
    <div className="form-group row">
      <div className="col-12">
        <label>
          <FormattedMessage id="SERVICE.TIME" />
        </label>
        {" "}
        <strong className="text-danger">*</strong>
        <br />

        <Picker
          optionGroups={optionGroups}
          valueGroups={valueGroups}
          onChange={onTimeChange} 
        />
      </div>
    </div>
  );
}
