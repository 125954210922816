import { API, graphqlOperation } from "aws-amplify";
import {
  listECRDailySaless,
  ecrDailySalesByElectronicCashRegisterId,
  getECRDailySales,
} from "../../../../../graphql/queries";
import * as customQueries from "../../graphql/private/queries";

export function findObjects(queryParams, additionalFilters, nextToken) {
  const params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
  }

  return API.graphql(graphqlOperation(listECRDailySaless, params));
}

export function findObjectsByElectronicCashRegisterId(
  ecrId,
  queryParams,
  additionalFilters,
  nextToken,
  customQuery
) {
  const params = {
    limit: queryParams.pageSize,
    electronicCashRegisterId: ecrId,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
  }

  const query = !customQuery || customQuery === "ecrDailySalesByElectronicCashRegisterId" ? ecrDailySalesByElectronicCashRegisterId : customQueries[customQuery];
  return API.graphql(graphqlOperation(query, params));
}

export function getObjectById(id) {
  return API.graphql(graphqlOperation(getECRDailySales, { id: id }));
}
