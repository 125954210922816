import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Card as MetronicCard,
  CardBody,
} from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function PartnerCategoryPicker({
  defaultValue,
  onChange,
  isSubmitting = false,
  currentPlan = null,
}) {
  const intl = useIntl();
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  var blocked =
    currentPlan !== null
      ? currentPlan === 0
        ? [0]
        : currentPlan === 1
        ? [0, 1]
        : currentPlan === 2
        ? [0, 1, 2]
        : currentPlan === 3
        ? [0, 1, 2, 3]
        : [0, 1, 2, 3]
      : [];

  const style = {
    silver: {
      backgroundColor: "#f6f6f6",
      borderRadius: 4,
      padding: 2,
      cursor: blocked.includes(0) ? "default" : "pointer",
      boxShadow: value === 0 ? "0 0 0 2pt blue" : "",
    },
    gold: {
      backgroundColor: "#f6f6f6",
      borderRadius: 4,
      padding: 2,
      cursor: blocked.includes(1) ? "default" : "pointer",
      boxShadow: value === 1 ? "0 0 0 2pt blue" : "",
    },
    platinum: {
      backgroundColor: "#f6f6f6",
      borderRadius: 4,
      padding: 2,
      cursor: blocked.includes(2) ? "default" : "pointer",
      boxShadow: value === 2 ? "0 0 0 2pt blue" : "",
    },
    diamond: {
      backgroundColor: "#f6f6f6",
      borderRadius: 4,
      padding: 2,
      cursor: blocked.includes(2) ? "default" : "pointer",
      boxShadow: value === 3 ? "0 0 0 2pt blue" : "",
    },
  };

  const updateValue = (newVal) => {
    if (!isSubmitting && !blocked.includes(newVal)) {
      setValue(newVal);
      onChange(newVal);
    }
  };

  return (
    <div className="row p-0 m-0">
      <div className="col-6 col-md-3" style={blocked.includes(0) ? { opacity: 0.5 } : {}}>
        <MetronicCard onClick={() => updateValue(0)} style={style.silver}>
          <CardBody className="text-center font-weight-bold">
            <span className="font-weight-bold">
              SILVER
              {currentPlan !== null && currentPlan >= 0 && (
                <span>
                  {" "}
                  <span className="text-success fas fa-check"></span>
                </span>
              )}
            </span>
            <br />
            <img
              className="img-fluid rounded"
              style={{ height: 200 }}
              alt={"Silver"}
              src={toAbsoluteUrl("/media/coristech/others/silver.png")}
            />
            <hr />

            <div className="pb-1 pl-5 pr-5 pt-1">
              <span className="text-success fa-2x fas fa-check pr-5"></span>
              <span className="font-weight-bold">1 Operatore</span>
            </div>

            <div className="pb-1 pl-5 pr-5 pt-1">
              <span className="pr-5"></span>
              <span className="font-weight-bold"></span>
            </div>
          </CardBody>
        </MetronicCard>
      </div>

      <div className="col-6 col-md-3" style={blocked.includes(1) ? { opacity: 0.5 } : {}}>
        <MetronicCard onClick={() => updateValue(1)} style={style.gold}>
          <CardBody className="text-center font-weight-bold">
            <span className="font-weight-bold">
              GOLD
              {currentPlan !== null && currentPlan >= 1 && (
                <span>
                  {" "}
                  <span className="text-success fas fa-check"></span>
                </span>
              )}
            </span>
            <br />
            <img
              className="img-fluid rounded"
              style={{ height: 200 }}
              alt={"Gold"}
              src={toAbsoluteUrl("/media/coristech/others/gold.png")}
            />
            <hr />

            <div className="pb-1 pl-5 pr-5 pt-1">
              <span className="text-success fa-2x fas fa-check pr-5"></span>
              <span className="font-weight-bold">1 Titolare</span>
            </div>

            <div className="pb-1 pl-5 pr-5 pt-1">
              <span className="text-success fa-2x fas fa-check pr-5"></span>
              <span className="font-weight-bold">2 Operatori</span>
            </div>

            <div className="pb-1 pl-5 pr-5 pt-1">
              <span className="pr-5"></span>
              <span className="font-weight-bold"></span>
            </div>
          </CardBody>
        </MetronicCard>
      </div>

      <div className="col-6 col-md-3" style={blocked.includes(2) ? { opacity: 0.5 } : {}}>
        <MetronicCard onClick={() => updateValue(2)} style={style.platinum}>
          <CardBody className="text-center font-weight-bold">
            <span className="font-weight-bold">
              PLATINUM
              {currentPlan !== null && currentPlan >= 2 && (
                <span>
                  {" "}
                  <span className="text-success fas fa-check"></span>
                </span>
              )}
            </span>
            <br />
            <img
              className="img-fluid rounded"
              style={{ height: 200 }}
              alt={"Platinum"}
              src={toAbsoluteUrl("/media/coristech/others/platinum.png")}
            />
            <hr />

            <div className="pb-1 pl-5 pr-5 pt-1">
              <span className="text-success fa-2x fas fa-check pr-5"></span>
              <span className="font-weight-bold">1 Titolare</span>
            </div>

            <div className="pb-1 pl-5 pr-5 pt-1">
              <span className="text-success fa-2x fas fa-check pr-5"></span>
              <span className="font-weight-bold">5 Operatori</span>
            </div>

            <div className="pb-1 pl-5 pr-5 pt-1">
              <span className="text-success fa-2x fas fa-check pr-5"></span>
              <span className="font-weight-bold">Log operatori</span>
            </div>
          </CardBody>
        </MetronicCard>
      </div>

      <div className="col-6 col-md-3" style={blocked.includes(3) ? { opacity: 0.5 } : {}}>
        <MetronicCard onClick={() => updateValue(3)} style={style.diamond}>
          <CardBody className="text-center font-weight-bold">
            <span className="font-weight-bold">
              DIAMOND
              {currentPlan !== null && currentPlan >= 3 && (
                <span>
                  {" "}
                  <span className="text-success fas fa-check"></span>
                </span>
              )}
            </span>
            <br />
            <img
              className="img-fluid rounded"
              style={{ height: 200 }}
              alt={"Platinum"}
              src={toAbsoluteUrl("/media/coristech/others/diamond.png")}
            />
            <hr />

            <div className="pb-1 pl-5 pr-5 pt-1">
              <span className="text-success fa-2x fas fa-check pr-5"></span>
              <span className="font-weight-bold">1 Titolare</span>
            </div>

            <div className="pb-1 pl-5 pr-5 pt-1">
              <span className="text-success fa-2x fas fa-check pr-5"></span>
              <span className="font-weight-bold">10 Operatori</span>
            </div>

            <div className="pb-1 pl-5 pr-5 pt-1">
              <span className="text-success fa-2x fas fa-check pr-5"></span>
              <span className="font-weight-bold">Log operatori</span>
            </div>
          </CardBody>
        </MetronicCard>
      </div>

    </div>
  );
}
