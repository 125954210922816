import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";

const getValueFromStringPath = (obj, path) => {
  return path.split(".").reduce(function (o, k) {
    return o && o[k];
  }, obj);
};

export function DBQueryCSVExport({
  queryName,
  queryStr = null,
  params,
  fields,
  headers,
  filename,
  disabled = false,
}) {

  const [isGenerating, setIsGenerating] = useState(false);

  const onClick = async () => {
    setIsGenerating(true);

    var items = [];
    var nextToken = undefined;

    try {
      do {
        const queryParams = {
          ...params,
          limit: params.limit > 100 ? 100 : params.limit,
          nextToken: nextToken,
        };

        const result = await API.graphql(graphqlOperation(queryName ? queries[queryName] : queryStr, queryParams));
        const data = result && result.data && Object.keys(result.data).length > 0 && result.data[Object.keys(result.data)[0]] ? result.data[Object.keys(result.data)[0]] : {};

        items = items.concat(data && data.items ? data.items : []);
        nextToken = data.nextToken;
      } while (nextToken !== null);
    } catch(e) {
      items = [];
      nextToken = undefined;
    }

    const separator = ";";
    const replacer = (key, value) => value === null ? '' : (value + "");
    const csv = [
      headers.join(separator),
      ...items.map(row => {
        return fields.map(fieldName => {
          if (typeof(fieldName) === "function") {
            return JSON.stringify(fieldName(row), replacer);
          }
          return JSON.stringify(getValueFromStringPath(row, fieldName), replacer);
        }).join(separator);
      })
    ].join('\r\n')

    const file = new Blob([csv], { type: "text/csv" });
  
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = filename.replace(/^.*[\\\/]/, "");
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

    setIsGenerating(false);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary mr-2 text-center"
        onClick={async () => await onClick()}
        disabled={disabled || isGenerating}
      >
        <div className="d-flex align-items-center">
          {isGenerating && (
            <div className="spinner spinner-white mr-10"></div>
          )}
          <div className="mr-2">
            <span className="fas fa-file-download"></span>
            {" "}
            <FormattedMessage id="SERVICE.EXPORT_CSV" />
          </div>
        </div>
      </button>
    </>
  );
}
