import * as Yup from "yup";
import CodiceFiscale from "codice-fiscale-js";

export function YupItalianFiscalCodeValidator(intl, required = true) {
  var result = Yup.string()
    .test(
      "Uppercase",
      intl.formatMessage({ id: "SERVICE.UPPERCASE_ONLY" }),
      (val) => (val ? val.toUpperCase() === val : true)
    )
    .test(
      "checkFiscalCodeValidation",
      intl.formatMessage({ id: "SERVICE.INVALID_FISCAL_CODE" }),
      (value) => {
        if (value) {
          return CodiceFiscale.check(value);
        } else {
          if (required) {
            return false;
          }
          return true;
        }
      }
    );

  if (required) {
    return result.required(
      intl.formatMessage({ id: "SERVICE.FISCAL_CODE_IS_MANDATORY" })
    );
  }

  return result;
}
