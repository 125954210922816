//https://stackoverflow.com/questions/12793045/adding-months-to-a-date-in-javascript
export function AddMonths(d, months) {
  if (!d) {
    return d;
  }

  var date = d;
  if (typeof(date) === "string") {
    date = new Date(date);
  }

  var newD = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != newD) {
    date.setDate(0);
  }
  return date;
}