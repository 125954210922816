export default [
  {
    value: "TO",
    label: "TO",
    name: "Torino"
  },
  {
    value: "VC",
    label: "VC",
    name: "Vercelli"
  },
  {
    value: "NO",
    label: "NO",
    name: "Novara"
  },
  {
    value: "CN",
    label: "CN",
    name: "Cuneo"
  },
  {
    value: "AT",
    label: "AT",
    name: "Asti"
  },
  {
    value: "AL",
    label: "AL",
    name: "Alessandria"
  },
  {
    value: "AO",
    label: "AO",
    name: "Aosta"
  },
  {
    value: "IM",
    label: "IM",
    name: "Imperia"
  },
  {
    value: "SV",
    label: "SV",
    name: "Savona"
  },
  {
    value: "GE",
    label: "GE",
    name: "Genova"
  },
  {
    value: "SP",
    label: "SP",
    name: "Spezia"
  },
  {
    value: "VA",
    label: "VA",
    name: "Varese"
  },
  {
    value: "CO",
    label: "CO",
    name: "Como"
  },
  {
    value: "SO",
    label: "SO",
    name: "Sondrio"
  },
  {
    value: "MI",
    label: "MI",
    name: "Milano"
  },
  {
    value: "BG",
    label: "BG",
    name: "Bergamo"
  },
  {
    value: "BS",
    label: "BS",
    name: "Brescia"
  },
  {
    value: "PV",
    label: "PV",
    name: "Pavia"
  },
  {
    value: "CR",
    label: "CR",
    name: "Cremona"
  },
  {
    value: "MN",
    label: "MN",
    name: "Mantova"
  },
  {
    value: "BZ",
    label: "BZ",
    name: "Bolzano"
  },
  {
    value: "TN",
    label: "TN",
    name: "Trento"
  },
  {
    value: "VR",
    label: "VR",
    name: "Verona"
  },
  {
    value: "VI",
    label: "VI",
    name: "Vicenza"
  },
  {
    value: "BL",
    label: "BL",
    name: "Belluno"
  },
  {
    value: "TV",
    label: "TV",
    name: "Treviso"
  },
  {
    value: "VE",
    label: "VE",
    name: "Venezia"
  },
  {
    value: "PD",
    label: "PD",
    name: "Padova"
  },
  {
    value: "RO",
    label: "RO",
    name: "Rovigo"
  },
  {
    value: "UD",
    label: "UD",
    name: "Udine"
  },
  {
    value: "GO",
    label: "GO",
    name: "Gorizia"
  },
  {
    value: "TS",
    label: "TS",
    name: "Trieste"
  },
  {
    value: "PC",
    label: "PC",
    name: "Piacenza"
  },
  {
    value: "PR",
    label: "PR",
    name: "Parma"
  },
  {
    value: "RE",
    label: "RE",
    name: "Reggio emilia"
  },
  {
    value: "MO",
    label: "MO",
    name: "Modena"
  },
  {
    value: "BO",
    label: "BO",
    name: "Bologna"
  },
  {
    value: "FE",
    label: "FE",
    name: "Ferrara"
  },
  {
    value: "RA",
    label: "RA",
    name: "Ravenna"
  },
  {
    value: "FC",
    label: "FC",
    name: "Forlì-Cesena"
  },
  {
    value: "PU",
    label: "PU",
    name: "Pesaro e Urbino"
  },
  {
    value: "AN",
    label: "AN",
    name: "Ancona"
  },
  {
    value: "MC",
    label: "MC",
    name: "Macerata"
  },
  {
    value: "AP",
    label: "AP",
    name: "Ascoli Piceno"
  },
  {
    value: "MS",
    label: "MS",
    name: "Massa-Carrara"
  },
  {
    value: "LU",
    label: "LU",
    name: "Lucca"
  },
  {
    value: "RM",
    label: "RM",
    name: "Roma"
  },
  {
    value: "AG",
    label: "AG",
    name: "Agrigento"
  },
  {
    value: "AR",
    label: "AR",
    name: "Arezzo"
  },
  {
    value: "AV",
    label: "AV",
    name: "Avellino"
  },
  {
    value: "BA",
    label: "BA",
    name: "Bari"
  },
  {
    value: "BT",
    label: "BT",
    name: "Barletta Adria Trani"
  },
  {
    value: "BN",
    label: "BN",
    name: "Benevento"
  },
  {
    value: "BI",
    label: "BI",
    name: "Biella"
  },
  {
    value: "BR",
    label: "BR",
    name: "Brindisi"
  },
  {
    value: "CA",
    label: "CA",
    name: "Cagliari"
  },
  {
    value: "CL",
    label: "CL",
    name: "Cantanisseta"
  },
  {
    value: "CB",
    label: "CB",
    name: "Campobasso"
  },
  {
    value: "CE",
    label: "CE",
    name: "Caserta"
  },
  {
    value: "CT",
    label: "CT",
    name: "Catania"
  },
  {
    value: "CZ",
    label: "CZ",
    name: "Catanzaro"
  },
  {
    value: "CH",
    label: "CH",
    name: "Chieti"
  },
  {
    value: "CS",
    label: "CS",
    name: "Cosenza"
  },
  {
    value: "KR",
    label: "KR",
    name: "Crotone"
  },
  {
    value: "EN",
    label: "EN",
    name: "Enna"
  },
  {
    value: "FM",
    label: "FM",
    name: "Fermo"
  },
  {
    value: "FI",
    label: "FI",
    name: "Firenze"
  },
  {
    value: "FG",
    label: "FG",
    name: "Foggia"
  },
  {
    value: "FR",
    label: "FR",
    name: "Frosinone"
  },
  {
    value: "GR",
    label: "GR",
    name: "Grosseto"
  },
  {
    value: "IS",
    label: "IS",
    name: "Isernia"
  },
  {
    value: "AQ",
    label: "AQ",
    name: "Acquila"
  },
  {
    value: "LT",
    label: "LT",
    name: "Latina"
  },
  {
    value: "LE",
    label: "LE",
    name: "Lecce"
  },
  {
    value: "LC",
    label: "LC",
    name: "Lecco"
  },
  {
    value: "LI",
    label: "LI",
    name: "Livorno"
  },
  {
    value: "LO",
    label: "LO",
    name: "Lodi"
  },
  {
    value: "MT",
    label: "MT",
    name: "Matera"
  },
  {
    value: "ME",
    label: "ME",
    name: "Messina"
  },
  {
    value: "MB",
    label: "MB",
    name: "Monza e Brianza"
  },
  {
    value: "NA",
    label: "NA",
    name: "Napoli"
  },
  {
    value: "NU",
    label: "NU",
    name: "Nuoro"
  },
  {
    value: "OR",
    label: "OR",
    name: "Oristano"
  },
  {
    value: "PA",
    label: "PA",
    name: "Palermo"
  },
  {
    value: "PG",
    label: "PG",
    name: "Perugia"
  },
  {
    value: "PE",
    label: "PE",
    name: "Pescara"
  },
  {
    value: "PI",
    label: "PI",
    name: "Pisa"
  },
  {
    value: "PT",
    label: "PT",
    name: "Pistoia"
  },
  {
    value: "PN",
    label: "PN",
    name: "Pordenone"
  },
  {
    value: "PZ",
    label: "PZ",
    name: "Potenza"
  },
  {
    value: "PO",
    label: "PO",
    name: "Prato"
  },
  {
    value: "RC",
    label: "RC",
    name: "Reggio Calabria"
  },
  {
    value: "RG",
    label: "RG",
    name: "Ragusa"
  },
  {
    value: "RI",
    label: "RI",
    name: "Rieti"
  },
  {
    value: "RN",
    label: "RN",
    name: "Rimini"
  },
  {
    value: "SA",
    label: "SA",
    name: "Salerno"
  },
  {
    value: "SS",
    label: "SS",
    name: "Sassari"
  },
  {
    value: "SI",
    label: "SI",
    name: "Siena"
  },
  {
    value: "SR",
    label: "SR",
    name: "Siracusa"
  },
  {
    value: "SU",
    label: "SU",
    name: "Sud Sardegna"
  },
  {
    value: "TA",
    label: "TA",
    name: "Taranto"
  },
  {
    value: "TE",
    label: "TE",
    name: "Teramo"
  },
  {
    value: "TR",
    label: "TR",
    name: "Terni"
  },
  {
    value: "TP",
    label: "TP",
    name: "Trapani"
  },
  {
    value: "VB",
    label: "VB",
    name: "Verbano Cusion Ossola"
  },
  {
    value: "VV",
    label: "VV",
    name: "Vibo Valentia"
  },
  {
    value: "VT",
    label: "VT",
    name: "Viterbo"
  },
];
