import React from "react";
import { useIntl } from "react-intl";
import { Stepper } from "@material-ui/core";
import { Step } from "@material-ui/core";
import { StepLabel } from "@material-ui/core";

export function RegistrationStepper({ activeStep }) {
  const intl = useIntl();

  const steps = [
    intl.formatMessage({ id: "SERVICE.AUTH.REGISTATION_STEPPER_1" }),
    intl.formatMessage({ id: "SERVICE.AUTH.REGISTATION_STEPPER_2" }),
    intl.formatMessage({ id: "SERVICE.AUTH.REGISTATION_STEPPER_3" }),
    intl.formatMessage({ id: "SERVICE.AUTH.REGISTATION_STEPPER_4" }),
  ];

  return (
    <div className="d-none d-sm-block">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label} >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
