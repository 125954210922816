export function TextColorFromHexBackground(hexColor) {
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  const avg = (r + g + b) / 3;

  if (avg > 128) {
    return "000000";
  }
  return "FFFFFF";
}
