import Allergens from "../../common/allergens/Allergens";

export function GetAllergenNameByCode(code) {
  var nameLabelId = "";

  Allergens.forEach((allergen) => {
    const obj = allergen[Object.keys(allergen)[0]];

    obj.icons.forEach((el) => {
      if (el.code === code) {
        nameLabelId = obj.name;
        return;
      }
    });

    if (nameLabelId) {
      return;
    }
  });

  return nameLabelId;
}
