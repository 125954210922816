import { combineReducers } from "redux";
import { GenerateSlice } from "../../../utils";

export const reducer = combineReducers({
  sectionals: GenerateSlice("sectionals").reducer,
  salesinvoices: GenerateSlice("salesinvoices").reducer,
  salesinvoicelines: GenerateSlice("salesinvoicelines").reducer,
  purchinvoices: GenerateSlice("purchinvoices").reducer,
  vatrates: GenerateSlice("vatrates").reducer,
  departments: GenerateSlice("departments").reducer,
});
