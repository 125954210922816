import { useEffect, useRef } from "react";

export function useFetchAll(fetchPromise, nextToken) {
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current && !nextToken) {
      fetchPromise("overwrite").then(() => {
        didMount.current = true;
      });
    } else {
      if (!didMount.current || nextToken) {
        fetchPromise("append").then(() => {
          didMount.current = true;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken]);
}
