import * as request from "./crud";
import { orderBy } from "lodash";
import { GenerateSlice, SliceCallTypes } from "../../../../utils/components/generic/_redux";

const sliceActions = GenerateSlice("retailstorelicenses").actions;

const clearData = () => (dispatch) => {
  dispatch(sliceActions.clearData());
};

const searchObjects =
  (queryParams, additionalFilters, nextToken, operation = "overwrite", customQuery = null) =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.searchObjects(
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal,
            customQuery
          );
          var data = result.data.searchRetailStoreLicenses;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: data.total,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve({ items: sortedItems, nextToken: nextTokenInternal, total: data.total, });
      } catch (error) {
        error.clientMessage = "Can't find retail store licenses";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };

const fetchObjects =
  (queryParams, additionalFilters, nextToken, operation = "overwrite", customQuery = null) =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjects(
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal,
            customQuery
          );
          var data = result.data.listRetailStoreLicenses;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: sortedItems.length,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve({ items: sortedItems, nextToken: nextTokenInternal });
      } catch (error) {
        error.clientMessage = "Can't find retail store licenses";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };

const fetchObjectsByRetailStore =
  (
    retailStoreId,
    queryParams,
    additionalFilters,
    nextToken,
    operation = "overwrite"
  ) =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjectsByRetailStore(
            retailStoreId,
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal
          );
          var data = result.data.retailStoreLicensesByRetailStore;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: sortedItems.length,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve({ items: sortedItems, nextToken: nextTokenInternal });
      } catch (error) {
        error.clientMessage = "Can't find retail store licenses";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };

const fetchObjectsByTenant =
  (
    tenantId,
    queryParams,
    additionalFilters,
    nextToken,
    operation = "overwrite"
  ) =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjectsByTenant(
            tenantId,
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal
          );
          var data = result.data.retailStoreLicensesByTenant;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: sortedItems.length,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve({ items: sortedItems, nextToken: nextTokenInternal });
      } catch (error) {
        error.clientMessage = "Can't find retail store licenses";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };

const fetchObjectsByPartner =
  (
    partnerId,
    queryParams,
    additionalFilters,
    nextToken,
    operation = "overwrite"
  ) =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjectsByTenant(
            partnerId,
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal
          );
          var data = result.data.retailStoreLicensesByPartner;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: sortedItems.length,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve({ items: sortedItems, nextToken: nextTokenInternal });
      } catch (error) {
        error.clientMessage = "Can't find retail store licenses";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };


const fetchObjectsByLicenseRetailStore =
  (
    licenseId,
    retailStoreId,
    queryParams,
    additionalFilters,
    nextToken,
    operation = "overwrite"
  ) =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjectsByLicenseRetailStore(
            licenseId,
            retailStoreId,
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal
          );
          var data = result.data.retailStoreLicensesByLicenseRetailStore;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: sortedItems.length,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve({ items: sortedItems, nextToken: nextTokenInternal });
      } catch (error) {
        error.clientMessage = "Can't find retail store licenses";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };

const fetchAllActiveDealerUserLicenses = (retailStoreId, licenseIds) => async (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));

  var today = new Date().toISOString().substring(0, 10);

  const queryParams = {
    pageSize: 1000,
    filter: {
      validTo: { gt: today },
      validFrom: { lt: today },
      and: [
        { or: licenseIds.map(el => ({ licenseId: { eq: el } })) },
  
        { or: [
          { electronicCashRegisterId: { eq: "" } },
          { electronicCashRegisterId: { attributeExists: false } },
        ] },
      ]
    },
  };

  var nextToken = null;
  var entities = [];

  try {
    do {
      var result = await request.findObjectsByRetailStore(
        retailStoreId,
        {
          ...queryParams,
          pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
        },
        null,
        nextToken
      );
      var data = result.data.retailStoreLicensesByRetailStore;

      if (data && data.items && data.items.length > 0) {
        entities = entities.concat(data.items);
      }

      nextToken = data.nextToken;
    } while (nextToken !== null);

    const sortedItems = orderBy(
      entities,
      [queryParams.sortField],
      [queryParams.sortOrder]
    );
    dispatch(
      sliceActions.objectsFetched({
        totalCount: sortedItems.length,
        operation: "overwrite",
        items: sortedItems,
        nextToken: nextToken,
        prevToken: nextToken,
      })
    );
    return undefined;
  } catch (error) {
    error.clientMessage = "Can't get retail store licenses";
    dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    return error;
  }
};

const fetchObject = (id) => (dispatch) => {
  if (!id) {
    return dispatch(sliceActions.objectFetched({ entity: undefined }));
  }

  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .getObjectById(id)
    .then((response) => {
      const obj = response.data.getRetailStoreLicense;
      dispatch(sliceActions.objectFetched({ entity: obj }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find retail store license";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

const createObject = (obj) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .createObject(obj)
    .then((response) => {
      const object = response.data.createRetailStoreLicense;
      dispatch(sliceActions.objectsCreated({ objects: [object] }));
      return object;
    })
    .catch((error) => {
      error.clientMessage = "Can't create retail store license";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
      return error;
    });
};

const createObjects = (objs) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .createObjects(objs)
    .then((response) => {
      const objects = response.map((obj) => obj.data.createRetailStoreLicense);
      dispatch(sliceActions.objectsCreated({ objects: objects }));
      return objects;
    })
    .catch((error) => {
      error.clientMessage = "Can't create retail store licenses";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
      return error;
    });
};

const deleteObject = (id) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .deleteObject(id)
    .then((response) => {
      dispatch(sliceActions.objectDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete retail store license";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

const updateObject = (object) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .updateObject(object)
    .then((response) => {
      dispatch(
        sliceActions.objectsUpdated({
          objects: [response.data.updateRetailStoreLicense],
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't update retail store license";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
      return error;
    });
};

const updateObjects = (objects) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .updateObjects(objects)
    .then((response) => {
      const objects = response.map((obj) => obj.data.updateRetailStoreLicense);
      dispatch(sliceActions.objectsUpdated({ objects: objects }));
      return objects;
    })
    .catch((error) => {
      error.clientMessage = "Can't update retail store licenses";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
      return error;
    });
};

const fetchActiveLicensesByECRId = async (ecrId, licenseId = null) => {
  const today = new Date().toISOString().substring(0, 10);
  const queryParams = {
    pageSize: 1000,
    filter: {
      electronicCashRegisterId: { eq: ecrId },
      validTo: { gt: today },
      validFrom: { lt: today },
      licenseId: licenseId ? { eq: licenseId } : undefined,
    },
  };

  var nextToken = null;
  var entities = [];

  try {
    do {
      var result = await request.searchObjects(
        queryParams,
        null, 
        nextToken,
        "searchRetailStoreLicenses"
      );
      var data = result.data.searchRetailStoreLicenses;

      entities = entities.concat(data.items);
      nextToken = data.nextToken;
    } while (nextToken !== null);
  } catch (error) {
    //eslint-disable-line no-empty
  }

  return entities;
};


const fetchActiveRtUtilityLicensesByECRId = async (ecrId) => {
  return fetchActiveLicensesByECRId(ecrId, "f4e74abd-64ff-47d0-a887-b6058e240fb0");
}

const fetchActiveLicensesByMoitoIotId = async (moitoIotId) => {
  const today = new Date().toISOString().substring(0, 10);
  const queryParams = {
    pageSize: 1000,
    filter: {
      moitoIotId: { eq: moitoIotId },
      validTo: { gt: today },
      validFrom: { lt: today },
    },
  };

  var nextToken = null;
  var entities = [];

  try {
    do {
      var result = await request.searchObjects(
        queryParams,
        null, 
        nextToken,
        "searchRetailStoreLicenses"
      );
      var data = result.data.searchRetailStoreLicenses;

      entities = entities.concat(data.items);
      nextToken = data.nextToken;
    } while (nextToken !== null);
  } catch (error) {
    //eslint-disable-line no-empty
  }

  return entities;
};

const fetchActiveLicensesByRetailStoreAndMoitoIot = (retailStoreId, moitoIotId) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));

  return new Promise(async (resolve, reject) => {
    const today = new Date().toISOString().substring(0, 10);
    const queryParams = {
      pageSize: 1000,
      retailStoreId: retailStoreId,
      filter: {
        moitoIotId: { eq: moitoIotId },
        validTo: { gt: today },
        validFrom: { lt: today },
      },
    };

    var nextToken = null;
    var entities = [];

    try {
      do {
        var result = await request.findObjectsByRetailStore(
          retailStoreId, 
          queryParams,
          null, 
          nextToken
        );
        var data = result.data.retailStoreLicensesByRetailStore;

        entities = entities.concat(data.items);
        nextToken = data.nextToken;
      } while (nextToken !== null);
    } catch (error) {
      //eslint-disable-line no-empty
    }

    dispatch(
      sliceActions.objectsFetched({
        totalCount: entities.length,
        operation: "overwrite",
        items: entities,
        nextToken: null,
        prevToken: null,
      })
    );

    return resolve(entities);
  });
};

const fetchAllExpiringLicenses = (partnerId) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));

  return new Promise(async (resolve, reject) => {
    const today = new Date().toISOString().substring(0, 10);
    var todayPlus30 = new Date();
    todayPlus30.setDate(todayPlus30.getDate() + 30);
    todayPlus30 = todayPlus30.toISOString().substring(0, 10);

    const queryParams = {
      pageSize: 1000,
      filter: {
        and: [
          { validFrom: { lt: today } },
          { validTo: { gt: today } },
          { validTo: { lt: todayPlus30 } },
          {
            or: [
              { tenantId: { eq: partnerId } },
              { partnerId: { eq: partnerId } },
            ],
          },
        ],
      },
    };

    var nextToken = null;
    var entities = [];

    try {
      do {
        var result = await request.searchObjects(
          {
            ...queryParams,
            pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
          },
          null, 
          nextToken,
          "searchRetailStoreLicenses"
        );
        var data = result.data.searchRetailStoreLicenses;

        entities = entities.concat(data.items);
        nextToken = data.nextToken;
      } while (nextToken !== null);
    } catch (error) {
      //eslint-disable-line no-empty
    }

    dispatch(sliceActions.clearData());
    dispatch(sliceActions.objectsFetched({
      totalCount: entities.length,
      operation: "overwrite",
      items: entities,
      nextToken: null,
      prevToken: null,
    }));
    return entities;
  });
};

const fetchAllExpiringLicensesByRange = (startDate, endDate, partnerId) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));

  return new Promise(async (resolve, reject) => {
    const queryParams = {
      pageSize: 1000,
      filter: {
        and: [
          { validTo: { range: [ startDate, endDate ] } },
          {
            or: [
              { tenantId: { eq: partnerId } },
              { partnerId: { eq: partnerId } },
            ],
          },
        ],
      },
    };

    var nextToken = null;
    var entities = [];

    try {
      do {
        var result = await request.searchObjects(
          {
            ...queryParams,
            pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
          },
          null, 
          nextToken, 
          "lightSearchRetailStoreLicenses"
        );
        var data = result.data.searchRetailStoreLicenses;

        entities = entities.concat(data.items);
        nextToken = data.nextToken;
      } while (nextToken !== null);
    } catch (error) {
      //eslint-disable-line no-empty
    }

    dispatch(sliceActions.clearData());
    dispatch(sliceActions.objectsFetched({
      totalCount: entities.length,
      operation: "overwrite",
      items: entities,
      nextToken: null,
      prevToken: null,
    }));
    return entities;
  });
};

const checkActiveRetailStoreLicensesExistance = async (
  retailStoreId,
  licenseId,
  ecrId
) => {
  const today = new Date().toISOString().substring(0, 10);
  var queryParams = {
    pageSize: 1000,
    filter: {
      licenseId: { eq: licenseId },
      validTo: { gt: today },
      validFrom: { lt: today },
    },
  };

  if (ecrId) {
    queryParams.filter = {
      ...queryParams.filter,
      electronicCashRegisterId: { eq: ecrId },
    };
  }

  var nextToken = null;

  try {
    do {
      var result = await request.findObjectsByRetailStore(
        retailStoreId,
        {
          ...queryParams,
          pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
        },
        null,
        nextToken
      );
      var data = result.data.retailStoreLicensesByRetailStore;

      if (data.items && data.items.length > 0) {
        return true;
      }

      nextToken = data.nextToken;
    } while (nextToken !== null);
  } catch (error) {
    return true;
  }

  return false;
};

const fetchActiveLicensesByRetailStore = async (
  retailStoreId,
) => {
  const today = new Date().toISOString().substring(0, 10);
  var queryParams = {
    pageSize: 1000,
    filter: {
      validTo: { gt: today },
      validFrom: { lt: today },
    },
  };

  var nextToken = null;
  var entities = [];

  try {
    do {
      var result = await request.findObjectsByRetailStore(
        retailStoreId,
        {
          ...queryParams,
          pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
        },
        null,
        nextToken
      );
      var data = result.data.retailStoreLicensesByRetailStore;

      if (data.items && data.items.length > 0) {
        entities = entities.concat(data.items);
      }

      nextToken = data.nextToken;
    } while (nextToken !== null);
  } catch (error) {
    //eslint-disable-line no-empty
  }

  return entities;
};

const fetchObjectsByTenantNoRedux =
  (
    tenantId,
    queryParams,
    additionalFilters,
    nextToken,
    queryName = null
  ) => {
    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjectsByTenant(
            tenantId,
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal,
            queryName
          );
          var data = result.data.retailStoreLicensesByTenant;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );

        return resolve({ items: sortedItems, nextToken: nextTokenInternal });
      } catch (error) {
        error.clientMessage = "Can't find retail store licenses";
        //return reject(error);
      }
    });
  };


const fetchActiveMoitoIotLicensesByStoreIds = (storeIds, customQuery = null) => async (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

  const today = new Date().toISOString().substring(0, 10);
  const queryParams = {
    pageSize: 1000,
    filter: {
      and: [
        { validTo: { gt: today } },
        { validFrom: { lt: today } },
        { moitoIotCode: { ne: "" } },
        { autorenew: { eq: true } },
        {
          or: storeIds
            .map((el) => {
              return { retailStoreId: { eq: el } };
            }),
        },
      ],
    },
  };

  var nextToken = null;
  var entities = [];

  try {
    do {
      var result = await request.searchObjects(
        queryParams,
        null, 
        nextToken,
        customQuery
      );
      var data = result.data.searchRetailStoreLicenses;

      entities = entities.concat(data.items);
      nextToken = data.nextToken;
    } while (nextToken !== null);
  } catch (error) {
    //eslint-disable-line no-empty
  }

  dispatch(
    sliceActions.objectsFetched({
      totalCount: entities.length,
      operation: "overwrite",
      items: entities,
      nextToken: null,
      prevToken: null,
    })
  );

  return entities;
};

const refreshRecordOnEntities = (id) => (dispatch) => {
  if (!id) {
    return dispatch(sliceActions.objectFetched({ entity: undefined }));
  }

  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .getObjectById(id)
    .then((response) => {
      const obj = response.data.getRetailStoreLicense;
      dispatch(sliceActions.refreshRecordOnEntities({ entity: obj }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find retail store license";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

export const actions = {
  clearData,
  searchObjects, 
  fetchObjects,
  fetchObjectsByRetailStore,
  fetchObjectsByPartner,
  fetchObjectsByTenant,
  fetchAllActiveDealerUserLicenses,
  fetchObject,
  createObject,
  createObjects,
  deleteObject,
  updateObjects,
  updateObject,
  fetchActiveLicensesByECRId,
  fetchActiveRtUtilityLicensesByECRId,
  fetchActiveLicensesByMoitoIotId,
  fetchActiveLicensesByRetailStoreAndMoitoIot,
  fetchAllExpiringLicenses,
  checkActiveRetailStoreLicensesExistance,
  fetchActiveLicensesByRetailStore,
  fetchObjectsByTenantNoRedux,
  fetchAllExpiringLicensesByRange,
  fetchObjectsByLicenseRetailStore,
  fetchActiveMoitoIotLicensesByStoreIds,
  refreshRecordOnEntities,
}