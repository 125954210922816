import * as Yup from "yup";
import CodiceFiscale from "codice-fiscale-js";

export function YupCustomerVatNumberFiscalCodeValidator(
  intl,
  countryCodeField,
  required = true
) {
  var result = Yup.string().when(countryCodeField, {
    is: (val) => val && val.toLowerCase() === "it",
    then: () => {
      const res = Yup.string()
        .test(
          "Uppercase",
          intl.formatMessage({ id: "SERVICE.UPPERCASE_ONLY" }),
          (val) => (val ? val.toUpperCase() === val : false)
        )
        .test("check", intl.formatMessage({ id: "SERVICE.INVALID_VALUE" }), (value) => {
          if (!value && required) {
            return false;
          }

          if (value && value.length === 16) {
            return CodiceFiscale.check(value);
          }

          if (value && value.length === 11) {
            var s = 0;
            for (var i = 0; i < 11; i++) {
              var n = value.charCodeAt(i) - "0".charCodeAt(0);
              if ((i & 1) === 1) {
                n *= 2;
                if (n > 9) n -= 9;
              }
              s += n;
            }
            if (s % 10 !== 0) return false;
            return true;
          } else {
            if (required) {
              return false;
            }
            return true;
          }
        })

      if (required) {
        return res.required(
          intl.formatMessage({ id: "SERVICE.FIELD_IS_REQUIRED" })
        );
      }
    },
    otherwise: Yup.string(),
  });

  return result;
}
