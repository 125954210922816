export const accountantEcrDailySalesByElectronicCashRegisterId = /* GraphQL */ `
  query EcrDailySalesByElectronicCashRegisterId(
    $electronicCashRegisterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelECRDailySalesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ecrDailySalesByElectronicCashRegisterId(
      electronicCashRegisterId: $electronicCashRegisterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        electronicCashRegisterId
        number
        datetime
        content
        notified
        accountantId
        createdAt
        updatedAt
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          tenant {
            id
            companyName
            companyVat
          }
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          accountantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;