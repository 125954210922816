import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { Storage } from "aws-amplify";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { actions } from "../../../../Admin/_redux/tenants/actions";
import ReactHtmlParser from "react-html-parser";

export function DTRServiceLogo({ partner, dynamic = true }) {
  const [partnerLogo, setPartnerLogo] = useState("");

  const style = {
    made_by: {
      position: "absolute",
      right: 0,
      bottom: 0,
    },
  };

  const { currentPartner } = useSelector(
    (state) => ({
      currentPartner: state.admin.tenants.entity,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (partner) {
      dispatch(actions.fetchObject(partner.id, true, "lightGetTenant"));
    }
  }, [partner]);

  useEffect(() => {
    if (currentPartner && currentPartner.logo) {
      Storage.get(currentPartner.logo).then((logo) => {
        setPartnerLogo(logo);
      });
    } else {
      setPartnerLogo("");
    }
  }, [currentPartner]);

  return (
    <div className="w-100 text-center position-relative pb-10 dashboard-service-logo">
      <div className="row">
        {!dynamic ||
        !currentPartner ||
        !currentPartner.logo ? (
          <div className="col-12">
            <img
              className="img-fluid rounded"
              alt="DTR-Service"
              src={toAbsoluteUrl("/media/coristech/logos/dtrservice_white.jpg")}
              style={{ height: 100 }}
            />
          </div>
        ) : (
          <div className="col-12">
            <img
              className="img-fluid rounded"
              alt={currentPartner.companyName}
              src={partnerLogo}
              style={{ height: 140 }}
            />
            <br />
            {currentPartner.partnerText && (
              <span className="font-weight-bold">
                {ReactHtmlParser(currentPartner.partnerText)}
              </span>
            )}
          </div>
        )}
      </div>
      <div className="text-muted" style={style.made_by}>
        Made by
        <span> </span>
        <a
          href="https://www.dtr-italy.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          DTR-Italy
        </a>
      </div>
    </div>
  );
}
