export function translateError(error) {
  if (error && error.code) {
    var message = error.message;

    switch (error.code) {
      case "UserNotFoundException":
        message = "L'utente non esiste";
        break;
      case "InternalErrorException":
        message = "Errore interno. Riprova più tardi";
        break;
      case "InvalidParameterException":
        message = "Parametri non validi";
        break;
      case "NotAuthorizedException":
        message = "Email/Telefono e password non validi";
        break;
      case "PasswordResetRequiredException":
        message = "E' obbligatorio resettare la password";
        break;
      case "TooManyRequestsException":
        message = "Troppe richieste. Riprova più tardi";
        break;
      case "InvalidPasswordException":
        message = "Password non valida";
        break;
      case "LimitExceededException":
        message =
          "Il limite di richieste alla risorsa è stato raggiunto. Riprova più tardi";
        break;
      case "CodeMismatchException":
        message = "Codice non valido";
        break;
      case "ExpiredCodeException":
        message = "Il codice è scaduto";
        break;
      case "TooManyFailedAttemptsException":
        message =
          "Troppe richieste hanno avuto esito negativo. Riprova più tardi";
        break;
      case "UsernameExistsException":
        message = "Un account con questa email o questo numero esiste già";
        break;

      case "Service_NotAuthorizedStore":
        message = "L'utente non è autorizzato ad accedere a nessun negozio.";
        break;
      default:
        break;
    }

    return message;
  }

  return error.message;
}
