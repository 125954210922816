import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";

export function Grouping({ UIContext }) {
  
  const UIProps = useMemo(() => {
    return {
      ids: UIContext.ids,
      openMultipleDeleteDialog: UIContext.openMultipleDeleteDialog,
    };
  }, [UIContext]);

  return (
    <>
      {UIProps.ids && UIProps.ids.length > 0 && (
        <div className="form">
          <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
            <div className="col-xl-12">
              <div className="form-group form-group-inline">
                <div>
                  {UIProps.openMultipleDeleteDialog && (
                    <button
                      type="button"
                      className="btn btn-danger font-weight-bolder font-size-sm"
                      onClick={UIProps.openMultipleDeleteDialog}
                    >
                      <i className="fa fa-trash"></i>{" "}
                      <FormattedMessage id="SERVICE.DELETE_ALL" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
