import { API, graphqlOperation } from "aws-amplify";
import {
  listRetailStoreLicenses,
  //getRetailStoreLicense,
  retailStoreLicensesByTenant,
  retailStoreLicensesByPartner,
  //retailStoreLicensesByRetailStore,
  searchRetailStoreLicenses,
} from "../../../../../graphql/queries";
import * as customQueries from "../../graphql/private/queries";
import {
  updateRetailStoreLicense,
  createRetailStoreLicense,
  deleteRetailStoreLicense,
} from "../../../../../graphql/mutations";

export function searchObjects(queryParams, additionalFilters, nextToken, customQuery = null) {
  var params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  
  if (queryParams.sortField && queryParams.sortOrder) {
    params.sort = {
      field: queryParams.sortField,
      direction: queryParams.sortOrder,
    };
  }

  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
  }

  params.filter = {
    ...params.filter,
  };

  const query = customQuery ? customQueries[customQuery] : searchRetailStoreLicenses;
  return API.graphql(graphqlOperation(query, params));
}

export function findObjects(queryParams, additionalFilters, nextToken) {
  const params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(listRetailStoreLicenses, params));
}

export function findObjectsByRetailStore(
  retailStoreId,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    retailStoreId: retailStoreId,
    sortDirection: "ASC",
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(
    graphqlOperation(customQueries["retailStoreLicensesByRetailStore"], params)
  );
}

export function findObjectsByTenant(
  tenantId,
  queryParams,
  additionalFilters,
  nextToken,
  queryName = null,
) {
  const params = {
    tenantId: tenantId,
    sortDirection: "ASC",
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(queryName ? customQueries[queryName] : retailStoreLicensesByTenant, params));
}

export function findObjectsByPartner(
  partnerId,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    partnerId: partnerId,
    sortDirection: "ASC",
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(retailStoreLicensesByPartner, params));
}


export function findObjectsByLicenseRetailStore(
  licenseId,
  retailStoreId,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    licenseId: licenseId,
    retailStoreId: { eq: retailStoreId },
    sortDirection: "ASC",
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(
    graphqlOperation(customQueries["lightRetailStoreLicensesByLicenseRetailStore"], params)
  );
}


export function getObjectById(id) {
  return API.graphql(graphqlOperation(customQueries["getRetailStoreLicense"], { id: id }));
}

export function createObject(obj) {
  return API.graphql(
    graphqlOperation(createRetailStoreLicense, { input: obj })
  );
}

export function deleteObject(id) {
  return API.graphql(
    graphqlOperation(deleteRetailStoreLicense, { input: { id: id } })
  );
}

export function createObjects(objs) {
  var creates = [];
  objs.forEach((obj) => {
    creates.push(
      new Promise((resolve, reject) => {
        return resolve(createObject(obj));
      })
    );
  });
  return Promise.all(creates);
}

export function updateObject(obj) {
  return API.graphql(
    graphqlOperation(updateRetailStoreLicense, { input: obj })
  );
}

export function updateObjects(objs) {
  var updates = [];
  objs.forEach((obj) => {
    updates.push(
      new Promise((resolve, reject) => {
        return resolve(updateObject(obj));
      })
    );
  });
  return Promise.all(updates);
}
