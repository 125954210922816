/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DropdownTopbarItemToggler } from "../../../../_metronic/_partials/dropdowns";
import { FormattedMessage, useIntl } from "react-intl";
import { useFetchAll, GetTenant } from "../../../utils";
import * as authCognitoActions from "../../AuthCognito/_redux/authActions";
import { actions as userLicenseActions } from "../../Admin/_redux/userlicenses/actions";
import loginTypes from "../../../common/service/login_types";
import ExcludedUserLicenses from "../../../common/licenses/excluded_user_licenses";

export function ModuleVisualizationDropdown({ show }) {
  const intl = useIntl();

  const { loginType, userLicenses, userLicensesNextToken, user, tenant } =
    useSelector(
      (state) => ({
        loginType: state.auth.loginType,

        userLicenses: state.admin.userlicenses.entities.items,
        userLicensesNextToken: state.admin.userlicenses.entities.nextToken,

        user: state.auth.user,
        tenant: GetTenant(),
      }),
      shallowEqual
    );

  const getQueryParams = () => {
    var today = new Date();
    today.setDate(today.getDate() + 1);
    today = today.toISOString().substring(0, 10);

    return {
      pageSize: 1000,
      filter: {
        tenantId: { eq: tenant.id },
        validTo: { gt: today },
        validFrom: { lt: today },
      },
    };
  };

  const dispatch = useDispatch();
  useFetchAll((operation) => {
    if (user && tenant) {
      const queryParams = getQueryParams();
      return dispatch(
        userLicenseActions.fetchObjectsByUser(
          user.id,
          queryParams,
          null,
          userLicensesNextToken,
          operation
        )
      );
    }

    return new Promise((resolve, reject) => {
      return resolve(true);
    });
  }, userLicensesNextToken);

  useEffect(() => {
    var licensesTypes = [0];

    if (userLicensesNextToken === null && user) {
      userLicenses
        .filter((el) => el.userId === user.id)
        .map((el) => {
          licensesTypes.push(getLoginTypeByLicenseName(el.license.name));
        });

      if (!licensesTypes.includes(loginType)) {
        const newLoginType = licensesTypes.find((el) => el !== 0);
        if (newLoginType) {
          changeLoginType(newLoginType, true);
        } else {
          changeLoginType(0, false);
        }
      }
    }
  }, [userLicenses, userLicensesNextToken]);

  const getCommercialNameByLoginType = (loginType) => {
    const result = loginTypes.find((el) => el.value === loginType);
    if (result) {
      return result.commercialName;
    }
    return "";
  };

  const getLoginTypeByLicenseName = (licenseName) => {
    const result = loginTypes.find((el) => el.name === licenseName);
    if (result) {
      return result.value;
    }
    return 0;
  };

  const changeLoginType = (newLoginType, reload = true) => {
    dispatch(authCognitoActions.changeLoginType(newLoginType)).then(() => {
      if (reload) {
        window.location.reload(true);
      }
    });
  };

  const filteredUserLicenses = userLicenses.filter((el) => {
    if (el.license.name && ExcludedUserLicenses.includes(el.license.name)) {
      return false;
    }
    return user && el.userId === user.id;
  });

  return (
    <>
      {user && show && (
        <Dropdown drop="down" className="pr-2">
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="dropdown-toggle-my-cart"
            className="w-100"
          >
            <OverlayTrigger
              placement="bottom"
              className="w-100"
              overlay={
                <Tooltip>
                  <FormattedMessage id="SERVICE.SELECT_VISUALIZATION_TYPE" />
                </Tooltip>
              }
            >
              <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 p-2 w-100">
                <div className="bg-secondary rounded p-2 text-dark w-100 text-center">
                  {loginType !== 0 ? (
                    <span>{getCommercialNameByLoginType(loginType)}</span>
                  ) : (
                    <span>{getCommercialNameByLoginType(0)}</span>
                  )}
                </div>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
            <ul className="navi navi-hover py-4">
              <li className="navi-item">
                <a
                  href="/#"
                  onClick={() =>
                    changeLoginType(getLoginTypeByLicenseName("dtr_service"))
                  }
                  className="navi-link"
                >
                  <span className="navi-text">{"DTR-Service"}</span>
                </a>
              </li>

              {filteredUserLicenses.map((userLicense) => (
                <li key={userLicense.id} className="navi-item">
                  <a
                    href="/#"
                    onClick={() =>
                      changeLoginType(
                        getLoginTypeByLicenseName(userLicense.license.name)
                      )
                    }
                    className="navi-link"
                  >
                    <span className="navi-text">
                      {userLicense.license.commercialName}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
