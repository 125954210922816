export default [
  {
    value: 0,
    name: "dtr_service",
    commercialName: "DTR-Service",
  },
  {
    value: 1,
    name: "atorder",
    commercialName: "@Order",
  },
  {
    value: 2,
    name: "einvoice",
    commercialName: "&Invoice",
  },
  {
    value: 3,
    name: "rt_user",
    commercialName: "RT-User",
  },
];
