/* Pagination Helprs */
import React from "react";

export const sortCaret = (order, column) => {
  if (!order) return (
    <span className="fas fa fa-sort text-primary"></span>
  );
  else if (order === "asc")
    return (
      <span className="fas fa fa-arrow-down text-primary"></span>
    );
  else if (order === "desc")
    return (
      <span className="fas fa fa-arrow-up text-primary"></span>
    );
  return null;
};

export const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
  (sortOrder === 'asc' || sortOrder === "desc") ? 'sortable-active' : ''
);