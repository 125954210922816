import React, { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { GetTenant, GetRetailStore } from "../functions";
import { actions } from "../../modules/MoitoIOT/_redux/receipts/actions";
import { DDMMYYYY_HHMM } from "../functions";

export function CustomerPurchaseHistory({ 
  customerId,
  count = 10,
}) {
  const intl = useIntl();

  const { tenant, store, receipts } = useSelector(
    (state) => ({
      tenant: GetTenant(),
      store: GetRetailStore(),

      receipts: state.moitoiot.receipts.entities.items,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const queryParams = {
      pageSize: count,
      filter: {
        deleted: { ne: true },
      },
    };

    dispatch(actions.fetchObjectsByCustomer(customerId, queryParams, null, null, "overwrite", "receiptsByCustomer"));
  }, [customerId]);

  return (
    <div className="py-4">
      <div className="px-4">
        <h2 className="d-inline-block">
          <span className="fas fa fa-cart-plus text-warning"></span>
          {" "}
          <FormattedMessage id="FOR_VALUE.PURCHASE_HISTORY" />
        </h2>
        <hr/>
      </div>

      {receipts.length <= 0 ? (
        <div className="text-center">
          <FormattedMessage id="FOR_VALUE.NO_PURCHASE_HISTORY" />
        </div>
      ) : (
        <div className="mx-2">
          {receipts.map(receipt => (
            <div key={receipt.id} className="row h-100 my-2 py-2 border-left border-5 border-warning bg-light-warning">
              <div className="col-12 col-md-8">
                <span className="text-muted">{DDMMYYYY_HHMM(new Date(receipt.moitoIotDatetime))}</span>
                
                <div className="mt-2">
                  {receipt.lines.items.map(line => (
                    <div key={line.id}>
                      {line.comment ? (
                        <>
                          {line?.description && (
                            <>
                              <span className="font-weight-bold">{line.quantity}</span>
                              {" x " + line.description}
                              {" "}
                            </>
                          )}

                          <span className="fa fas fa-pen"></span>
                          {" "}
                          <span>{line.comment || ""}</span>
                        </>
                      ) : (
                        <>
                          <span className="font-weight-bold">{line.quantity}</span>
                          {" x " + line.description}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-12 col-md-4 h-100 my-auto">
                <div className="text-center font-weight-bold text-success">
                  <h5 className="d-inline-block">{Number.parseFloat(receipt.amount || 0).toFixed(2) + " €"}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
