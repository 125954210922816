import orderBy from "lodash/orderBy";
import { SliceCallTypes as callTypes } from "./slice";

export const GenerateSlowFetchObjects =
(
  actions,
  requestFunction,
  queryName,
  queryParams,
  additionalFilters,
  nextToken,
  operation = "overwrite",
  cc = null,
  postFilters = null,
) =>
(dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  var nextTokenInternal = nextToken;
  var items = [];

  return new Promise(async (resolve, reject) => {
    try {
      const lastIncrementalId = (operation === "overwrite" || !queryParams?.pageNumber || queryParams?.pageNumber <= 1) ? 0 : 0;

      do {
        var result = await requestFunction(
          {
            ...queryParams,
            pageSize: queryParams.pageSize === 999 ? queryParams.pageSize : (queryParams.pageSize > 100 ? 100 : queryParams.pageSize),
          },
          additionalFilters,
          nextTokenInternal,
          cc
        );
        var data = result.data[queryName];

        items = items.concat(postFilters ? data.items.filter(el => postFilters(el)) : data.items);
        nextTokenInternal = data.nextToken;
        if (items.length >= queryParams.pageSize) {
          break;
        }
      } while (nextTokenInternal !== null);

      const sortedItems = orderBy(
        items,
        [queryParams.sortField],
        [queryParams.sortOrder]
      );
      dispatch(
        actions.objectsFetched({
          totalCount: sortedItems.length,
          operation: operation,
          items: sortedItems,
          nextToken: nextTokenInternal,
          prevToken: nextToken,
        })
      );

      return resolve({
        data: {
          [queryName]: {
            items: sortedItems,
            nextToken: nextTokenInternal,
          },
        },
      });
    } catch (error) {
      error.clientMessage = "Can't find records";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      //return reject(error);
    }
  });
};

export const GenerateFetchObjectsBy =
(
  actions,
  requestFunction,
  queryName,
  parameter,
  queryParams,
  additionalFilters,
  nextToken,
  operation = "overwrite",
  cc = null,
  postFilters = null
) =>
(dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  var nextTokenInternal = nextToken;
  var items = [];

  return new Promise(async (resolve, reject) => {
    try {
      do {
        var result = await requestFunction(
          parameter,
          {
            ...queryParams,
            pageSize: queryParams.pageSize === 999 ? queryParams.pageSize : (queryParams.pageSize > 100 ? 100 : queryParams.pageSize),
          },
          additionalFilters,
          nextTokenInternal,
          cc,
        );
        var data = result.data[queryName];

        items = items.concat(postFilters ? data.items.filter(el => postFilters(el)) : data.items);
        nextTokenInternal = data.nextToken;
        if (items.length >= queryParams.pageSize) {
          break;
        }
      } while (nextTokenInternal !== null);

      const sortedItems = orderBy(
        items,
        [queryParams.sortField],
        [queryParams.sortOrder]
      );
      
      dispatch(
        actions.objectsFetched({
          totalCount: sortedItems.length,
          operation: operation,
          items: sortedItems,
          nextToken: nextTokenInternal,
          prevToken: nextToken,
        })
      );

      return resolve({
        data: {
          [queryName]: {
            items: sortedItems,
            nextToken: nextTokenInternal,
          },
        },
      });
    } catch (error) {
      error.clientMessage = "Can't find records";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      //return reject(error);
    }
  });
};

export const GenerateActions = (actions, request, entity) => {
  return {

    clearData: () => (dispatch) => {
      dispatch(actions.clearData());
    },

    refreshRecordOnEntities: (id) => (dispatch) => {
      if (!id) {
        return dispatch(actions.objectFetched({ entity: undefined }));
      }

      dispatch(actions.startCall({ callType: callTypes.action }));
      return request
        .getObjectById(id)
        .then((response) => {
          const obj = response.data["get" + entity];
          dispatch(actions.refreshRecordOnEntities({ entity: obj }));
          return obj;
        })
        .catch((error) => {
          error.clientMessage = "Can't find record";
          dispatch(actions.catchError({ error, callType: callTypes.action }));
          return error;
        });
    },

    fetchObject: (id) => (dispatch) => {
      if (!id) {
        return dispatch(actions.objectFetched({ entity: undefined }));
      }

      dispatch(actions.startCall({ callType: callTypes.action }));
      return request
        .getObjectById(id)
        .then((response) => {
          const obj = response.data["get" + entity];
          dispatch(actions.objectFetched({ entity: obj }));
          return obj;
        })
        .catch((error) => {
          error.clientMessage = "Can't find record";
          dispatch(actions.catchError({ error, callType: callTypes.action }));
          return error;
        });
    },

    deleteObject: (id) => (dispatch) => {
      dispatch(actions.startCall({ callType: callTypes.action }));
      return request
        .deleteObject(id)
        .then((response) => {
          dispatch(actions.objectDeleted({ id }));
          return response;
        })
        .catch((error) => {
          error.clientMessage = "Can't delete record";
          dispatch(actions.catchError({ error, callType: callTypes.action }));
          return error;
        });
    },
    deleteObjects: (ids) => (dispatch) => {
      dispatch(actions.startCall({ callType: callTypes.action }));
      return request
        .deleteObjects(ids)
        .then((response) => {
          dispatch(actions.objectsDeleted({ ids }));
          return response;
        })
        .catch((error) => {
          error.clientMessage = "Can't delete records";
          dispatch(actions.catchError({ error, callType: callTypes.action }));
          return error;
        });
    },

    createObject: (obj) => (dispatch) => {
      dispatch(actions.startCall({ callType: callTypes.action }));
      obj.extensions = obj.extensions ? JSON.stringify(obj.extensions) : undefined;
      return request
        .createObject(obj)
        .then((response) => {
          const object = response.data["create" + entity];
          dispatch(actions.objectsCreated({ objects: [object] }));
          return object;
        })
        .catch((error) => {
          error.clientMessage = "Can't create record";
          dispatch(actions.catchError({ error, callType: callTypes.action }));
          return error;
        });
    },

    createObjects: (objs) => (dispatch) => {
      dispatch(actions.startCall({ callType: callTypes.action }));
      objs = objs.map(obj => {
        return {
          ...obj,
          extensions: obj.extensions ? JSON.stringify(obj.extensions) : undefined,
        };
      });
      return request
        .createObjects(objs)
        .then((response) => {
          const objects = response.map((obj) => obj.data["create" + entity]);
          dispatch(actions.objectsCreated({ objects: objects }));
          return objects;
        })
        .catch((error) => {
          error.clientMessage = "Can't create records";
          dispatch(actions.catchError({ error, callType: callTypes.action }));
          return error;
        });
    },

    createObjectsNoRedux: (objs) => (dispatch) => {
      objs = objs.map(obj => {
        return {
          ...obj,
          extensions: obj.extensions ? JSON.stringify(obj.extensions) : undefined,
        };
      });
      return request
        .createObjects(objs)
        .then((response) => {
          const objects = response.map((obj) => obj.data["create" + entity]);
          return objects;
        })
        .catch((error) => {
          return error;
        });
    },

    updateObject: (object) => (dispatch) => {
      dispatch(actions.startCall({ callType: callTypes.action }));
      object.extensions = object.extensions ? JSON.stringify(object.extensions) : undefined;
      return request
        .updateObject(object)
        .then((response) => {
          dispatch(
            actions.objectsUpdated({ objects: [response.data["update" + entity]] })
          );
          return response.data["update" + entity];
        })
        .catch((error) => {
          error.clientMessage = "Can't update record";
          dispatch(actions.catchError({ error, callType: callTypes.action }));
          return error;
        });
    },
    updateObjects: (objects) => (dispatch) => {
      dispatch(actions.startCall({ callType: callTypes.action }));
      objects = objects.map(obj => {
        return {
          ...obj,
          extensions: obj.extensions ? JSON.stringify(obj.extensions) : undefined,
        };
      });
      return request
        .updateObjects(objects)
        .then((response) => {
          const objects = response.map((obj) => obj.data["update" + entity]);
          dispatch(actions.objectsUpdated({ objects: objects }));
          return objects;
        })
        .catch((error) => {
          error.clientMessage = "Can't update records";
          dispatch(actions.catchError({ error, callType: callTypes.action }));
          return error;
        });
    },

    //only for compatibility somewhere, use clearData instead
    cleanState: () => (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(actions.clearData());
        return resolve(true);
      });
    },

    removeFromEntities: (id) => (dispatch) => {
      dispatch(actions.removeFromEntities({ id }));
    },

    addOnEntities: (entity) => (dispatch) => {
      dispatch(actions.addOnEntities({ entity: entity }));
    },

    setEntities: (entities) => (dispatch) => {
      dispatch(actions.setEntities({ entities: entities }));
    },
  }
}