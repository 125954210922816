export default [
  {
    veg: {
      name: "SERVICE.ALLERGENS.1",
      description: "SERVICE.ALLERGENS.1_DESC",
      icons: [
        {
          code: "veg",
          path: "veg.png",
        },
      ],
    },
  },
  {
    bio: {
      name: "SERVICE.ALLERGENS.2",
      description: "SERVICE.ALLERGENS.2_DESC",
      icons: [
        {
          code: "bio",
          path: "bio.png",
        },
      ],
    },
  },
  {
    dyn: {
      name: "SERVICE.ALLERGENS.3",
      description: "SERVICE.ALLERGENS.3_DESC",
      icons: [
        {
          code: "dyn",
          path: "dyn.png",
        },
      ],
    },
  },
  {
    ice: {
      name: "SERVICE.ALLERGENS.4",
      description: "SERVICE.ALLERGENS.4_DESC",
      icons: [
        {
          code: "ice",
          path: "ice.png",
        },
      ],
    },
  },
  {
    low: {
      name: "SERVICE.ALLERGENS.5",
      description: "SERVICE.ALLERGENS.5_DESC",
      icons: [
        {
          code: "low",
          path: "low.png",
        },
      ],
    },
  },
  {
    15: {
      name: "SERVICE.ALLERGENS.6",
      description: "SERVICE.ALLERGENS.6_DESC",
      icons: [
        {
          code: "15a",
          path: "15a.png",
        },
        {
          code: "15b",
          path: "15b.png",
        },
        {
          code: "15c",
          path: "15c.png",
        },
        {
          code: "15d",
          path: "15d.png",
        },
      ],
    },
  },
  {
    alc: {
      name: "SERVICE.ALLERGENS.7",
      description: "SERVICE.ALLERGENS.7_DESC",
      icons: [
        {
          code: "alc",
          path: "alc.png",
        },
      ],
    },
  },
  {
    "01": {
      name: "SERVICE.ALLERGENS.8",
      description: "SERVICE.ALLERGENS.8_DESC",
      icons: [
        {
          code: "01a",
          path: "01a.png",
        },
        {
          code: "01b",
          path: "01b.png",
        },
        {
          code: "01c",
          path: "01c.png",
        },
        {
          code: "01d",
          path: "01d.png",
        },
      ],
    },
  },
  {
    "02": {
      name: "SERVICE.ALLERGENS.9",
      description: "SERVICE.ALLERGENS.9_DESC",
      icons: [
        {
          code: "02a",
          path: "02a.png",
        },
        {
          code: "02b",
          path: "02b.png",
        },
        {
          code: "02c",
          path: "02c.png",
        },
        {
          code: "02d",
          path: "02d.png",
        },
      ],
    },
  },
  {
    "03": {
      name: "SERVICE.ALLERGENS.10",
      description: "SERVICE.ALLERGENS.10_DESC",
      icons: [
        {
          code: "03a",
          path: "03a.png",
        },
        {
          code: "03b",
          path: "03b.png",
        },
        {
          code: "03c",
          path: "03c.png",
        },
        {
          code: "03d",
          path: "03d.png",
        },
      ],
    },
  },
  {
    "04": {
      name: "SERVICE.ALLERGENS.11",
      description: "SERVICE.ALLERGENS.11_DESC",
      icons: [
        {
          code: "04a",
          path: "04a.png",
        },
        {
          code: "04b",
          path: "04b.png",
        },
        {
          code: "04c",
          path: "04c.png",
        },
        {
          code: "04d",
          path: "04d.png",
        },
      ],
    },
  },
  {
    "05": {
      name: "SERVICE.ALLERGENS.12",
      description: "SERVICE.ALLERGENS.12_DESC",
      icons: [
        {
          code: "05a",
          path: "05a.png",
        },
        {
          code: "05b",
          path: "05b.png",
        },
        {
          code: "05c",
          path: "05c.png",
        },
        {
          code: "05d",
          path: "05d.png",
        },
      ],
    },
  },
  {
    "06": {
      name: "SERVICE.ALLERGENS.13",
      description: "SERVICE.ALLERGENS.13_DESC",
      icons: [
        {
          code: "06a",
          path: "06a.png",
        },
        {
          code: "06b",
          path: "06b.png",
        },
        {
          code: "06c",
          path: "06c.png",
        },
        {
          code: "06d",
          path: "06d.png",
        },
      ],
    },
  },
  {
    "07": {
      name: "SERVICE.ALLERGENS.14",
      description: "SERVICE.ALLERGENS.14_DESC",
      icons: [
        {
          code: "07a",
          path: "07a.png",
        },
        {
          code: "07b",
          path: "07b.png",
        },
        {
          code: "07c",
          path: "07c.png",
        },
        {
          code: "07d",
          path: "07d.png",
        },
      ],
    },
  },
  {
    "08": {
      name: "SERVICE.ALLERGENS.15",
      description: "SERVICE.ALLERGENS.15_DESC",
      icons: [
        {
          code: "08a",
          path: "08a.png",
        },
        {
          code: "08b",
          path: "08b.png",
        },
        {
          code: "08c",
          path: "08c.png",
        },
        {
          code: "08d",
          path: "08d.png",
        },
      ],
    },
  },
  {
    "09": {
      name: "SERVICE.ALLERGENS.16",
      description: "SERVICE.ALLERGENS.16_DESC",
      icons: [
        {
          code: "09a",
          path: "09a.png",
        },
        {
          code: "09b",
          path: "09b.png",
        },
        {
          code: "09c",
          path: "09c.png",
        },
        {
          code: "09d",
          path: "09d.png",
        },
      ],
    },
  },
  {
    10: {
      name: "SERVICE.ALLERGENS.17",
      description: "SERVICE.ALLERGENS.17_DESC",
      icons: [
        {
          code: "10a",
          path: "10a.png",
        },
        {
          code: "10b",
          path: "10b.png",
        },
        {
          code: "10c",
          path: "10c.png",
        },
        {
          code: "10d",
          path: "10d.png",
        },
      ],
    },
  },
  {
    11: {
      name: "SERVICE.ALLERGENS.18",
      description: "SERVICE.ALLERGENS.18_DESC",
      icons: [
        {
          code: "11a",
          path: "11a.png",
        },
        {
          code: "11b",
          path: "11b.png",
        },
        {
          code: "11c",
          path: "11c.png",
        },
        {
          code: "11d",
          path: "11d.png",
        },
      ],
    },
  },
  {
    12: {
      name: "SERVICE.ALLERGENS.19",
      description: "SERVICE.ALLERGENS.19_DESC",
      icons: [
        {
          code: "12a",
          path: "12a.png",
        },
        {
          code: "12b",
          path: "12b.png",
        },
        {
          code: "12c",
          path: "12c.png",
        },
        {
          code: "12d",
          path: "12d.png",
        },
      ],
    },
  },
  {
    13: {
      name: "SERVICE.ALLERGENS.20",
      description: "SERVICE.ALLERGENS.20_DESC",
      icons: [
        {
          code: "13a",
          path: "13a.png",
        },
        {
          code: "13b",
          path: "13b.png",
        },
        {
          code: "13c",
          path: "13c.png",
        },
        {
          code: "13d",
          path: "13d.png",
        },
      ],
    },
  },
  {
    14: {
      name: "SERVICE.ALLERGENS.21",
      description: "SERVICE.ALLERGENS.21_DESC",
      icons: [
        {
          code: "14a",
          path: "14a.png",
        },
        {
          code: "14b",
          path: "14b.png",
        },
        {
          code: "14c",
          path: "14c.png",
        },
        {
          code: "14d",
          path: "14d.png",
        },
      ],
    },
  },
];
