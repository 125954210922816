import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const UPDATE_REFRESH_MS = 250;

export function TimeProgressBar({ seconds }) {
  const classes = useStyles();
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCompleted(oldCompleted => {
        if (oldCompleted >= 100) { //100%
          return 0;
        }
        return oldCompleted + (UPDATE_REFRESH_MS * 100 / (seconds * 1000));
      });
    }, UPDATE_REFRESH_MS);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" variant="determinate" value={completed} />
    </div>
  );
}
