import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as actions from "../_redux/authActions";
import { LoginDataTypeWorkflowBoxes } from "../components/LoginDataTypeWorkflowBoxes";
import { ErrorMessage } from "../components/ErrorMessage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Input, Card as MetronicCard } from "../../../../_metronic/_partials/controls";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useHistory } from "react-router-dom";
//import { GoogleSignInButton } from "../../../utils";

export function Login({ match }) {
  const intl = useIntl();
  const history = useHistory();

  const [loginButtonEnabled, setLoginButtonEnabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const { auth } = useSelector(
    (state) => ({
      auth: state.auth,
    }),
    shallowEqual
  );

  const initialValues = {
    email: "",
    phone: "",
    password: "",
    loginType: 0,
  };

  const changePasswordInitialValues = {
    newPassword: "",
    newPasswordConfirmation: "",
  };

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email(
      intl.formatMessage({ id: "SERVICE.INVALID_EMAIL" })
    ),
    password: Yup.string().required(
      intl.formatMessage({ id: "USER.PASSWORD_REQUIRED" })
    ),
  });

  const changePasswordValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(intl.formatMessage({ id: "USER.PASSWORD_REQUIRED" }))
      .test(
        "len",
        intl.formatMessage({ id: "SERVICE.PASSWORD_MUST_BE_AT_LEAST_8" }),
        (val) => val && val.length > 8
      ),
    newPasswordConfirmation: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      intl.formatMessage({ id: "SERVICE.PASSWORDS_MUST_MATCH" })
    ),
  });

  const dispatch = useDispatch();

  const [isSocialLoginLoading, setIsSocialLoginLoading] = useState(false);

  useEffect(() => {
    if (match?.params?.feedback === "social-login") {
      setIsSocialLoginLoading(true);

      dispatch(actions.doSocialSignIn())
      .finally(() => {
        setIsSocialLoginLoading(false);
      });
    }
  }, [match]);

  const doLogin = (data) => {
    setIsLoading(true);

    dispatch(
      actions.login(
        data.loginType === 1 ? "+" + data.phone : data.email,
        data.password,
        0
      )
    ).finally(() => {
      setIsLoading(false);
    });
  };

  const doChangePassword = (data) => {
    setIsLoading(true);

    dispatch(
      actions.setNewPassword(auth.cognitoUser, data.newPassword)
    ).finally(() => {
      setIsLoading(false);
      history.push("/");
    });
  };

  return (
    <div className="row">
      <div className="col-12">
        <ErrorMessage />
      </div>

      <div className="col-12 col-xl-4 col-lg-2"></div>
      <div className="col-12 col-xl-4 col-lg-8">
        {auth && !auth.changePassword ? (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            validateOnMount={true}
            onSubmit={(values) => {
              doLogin(values);
            }}
          >
            {({ handleSubmit, isValid, setFieldValue, values }) => (
              <>
                {isSocialLoginLoading && (
                  <div className="container p-4 mb-4 text-center ">
                    <div className="d-flex justify-content-center mt-2">
                      <div className="spinner spinner-dark mr-10"></div>  
                    </div>
                    <br/>
                    <h4><FormattedMessage id="SERVICE.AUTH.LOADING_LOGIN" /></h4>
                    <br/>
                    <div>
                      <span className="text-muted"><FormattedMessage id="SERVICE.AUTH.LOADING_LOGIN_MESSAGE" /></span>
                    </div>
                  </div>
                )}

                <MetronicCard className="p-0 border border-1 border-light-primary mt-4">
                  <Form>
                    <div className="w-100 p-0 m-0">
                      <LoginDataTypeWorkflowBoxes
                        defaultValue={initialValues.loginType}
                        onChange={(v) => setFieldValue("loginType", v)}
                      />
                    </div>
                    <div className="px-5">
                      <div className="row form-group pb-0 mb-0">
                        <div className="col-12">
                          {values.loginType === 0 ? (
                            <Field
                              type="email"
                              name="email"
                              component={Input}
                              autocomplete={"on"}
                              placeholder={intl.formatMessage({
                                id: "SERVICE.EMAIL",
                              })}
                              disabled={isSocialLoginLoading}
                              label={intl.formatMessage({ id: "SERVICE.EMAIL" })}
                            />
                          ) : (
                            <div className="w-100">
                              <label>
                                <FormattedMessage id="PARTY.PHONE" />
                              </label>
                              <PhoneInput
                                country={"it"}
                                value={values.phone}
                                onChange={(value) => setFieldValue("phone", value)}
                                inputClass={"w-100"}
                                disabled={isSocialLoginLoading}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-12 password-wrapper">
                          <Field
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            autocomplete={"on"}
                            component={Input}
                            placeholder={intl.formatMessage({
                              id: "SERVICE.PASSWORD",
                            })}
                            label={intl.formatMessage({ id: "SERVICE.PASSWORD" })}
                            disabled={isSocialLoginLoading}
                          />
                          <i
                            className="password-eye-icon"
                            onClick={togglePasswordVisiblity}
                          >
                            <span className="fas fa-eye"></span>
                          </i>
                        </div>
                      </div>

                      <div className="form-group w-100 text-left">
                        <Link to={"/auth/forget/" + values.loginType} disabled={isSocialLoginLoading}>
                          <FormattedMessage id="SERVICE.AUTH.FORGOT_PASSWORD" />
                        </Link>
                      </div>

                      <div className="form-group w-100">
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn btn-primary btn-elevate w-100 py-4"
                          disabled={!isValid || isLoading || isSocialLoginLoading}
                        >
                          <div className="d-flex justify-content-center">
                            {isLoading && (
                              <div className="spinner spinner-white mr-10"></div>
                            )}
                            <div className="mr-2 text-uppercase font-weight-bold">
                              <FormattedMessage id="SERVICE.AUTH.SIGN_IN" />
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </Form>
                </MetronicCard>

                {/*
                <div className="or my-2 text-lowercase"><FormattedMessage id="SERVICE.OR" /></div>

                <MetronicCard className="p-0 border border-1 border-light-primary mt-4">
                  <div className="px-5 my-4">
                    <GoogleSignInButton
                      type="login"
                      isLoading={isSocialLoginLoading && auth?.socialProvider?.toLowerCase() === "google"}
                      disabled={isSocialLoginLoading}
                      onClick={() => dispatch(actions.initializeSocialSignIn("Google"))}
                    />
                  </div>
                </MetronicCard>
                */}
              </>
            )}
          </Formik>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={changePasswordInitialValues}
            validationSchema={changePasswordValidationSchema}
            validateOnMount={true}
            onSubmit={(values) => {
              doChangePassword(values);
            }}
          >
            {({ handleSubmit, isValid, setFieldValue, values }) => (
              <>
                <Form className="form form-label-right mt-4">
                  <div className="row form-group pb-0 mb-0">
                    <div className="col-12 text-center">
                      <h4><FormattedMessage id="SERVICE.AUTH.CHANGE_PASSWORD" /></h4>
                      <hr/>
                    </div>
                    <div className="col-12">
                      <Field
                        type="password"
                        name="newPassword"
                        component={Input}
                        autocomplete={"on"}
                        placeholder={intl.formatMessage({
                          id: "SERVICE.AUTH.NEW_PASSWORD",
                        })}
                        label={intl.formatMessage({
                          id: "SERVICE.AUTH.NEW_PASSWORD",
                        })}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-12">
                      <Field
                        type="password"
                        name="newPasswordConfirmation"
                        component={Input}
                        autocomplete={"on"}
                        placeholder={intl.formatMessage({
                          id: "SERVICE.AUTH.REPEAT_NEW_PASSWORD",
                        })}
                        label={intl.formatMessage({
                          id: "SERVICE.AUTH.REPEAT_NEW_PASSWORD",
                        })}
                      />
                    </div>
                  </div>

                  <div className="row form-group">
                    <div className="col-3"></div>
                    <div className="col-6">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary btn-elevate w-100"
                        disabled={!isValid || isLoading}
                      >
                        <div className="d-flex justify-content-center">
                          {isLoading && (
                            <div className="spinner spinner-white mr-10"></div>
                          )}
                          <div className="mr-2 text-uppercase font-weight-bold">
                            <FormattedMessage id="SERVICE.AUTH.CHANGE_PASSWORD" />
                          </div>
                        </div>
                      </button>
                    </div>
                    <div className="col-3"></div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        )}
      </div>
      <div className="col-12 col-xl-4 col-lg-2"></div>
    </div>
  );
}
