import React from "react";
import { Topbar as AtorderTopbar } from "../../Atorder/components/Topbar";
import { Topbar as EInvoiceTopbar } from "../../EInvoice/components/Topbar";
import { Topbar as RTUserTopbar } from "../../RTUser/components/Topbar";
import { shallowEqual, useSelector } from "react-redux";
import { Topbar } from "../../../../_metronic/layout/components/header/Topbar";

export function ServiceTopbar() {
  const { loginType } = useSelector(
    (state) => ({
      loginType: state.auth.loginType,
    }),
    shallowEqual
  );

  return (
    <>
      {loginType === 1 ? (
        <AtorderTopbar />
      ) : loginType === 2 ? (
        <EInvoiceTopbar />
      ) : loginType === 3 ? (
        <RTUserTopbar />
      ) : (
        <Topbar />
      )}
    </>
  );
}
