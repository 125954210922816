import * as Yup from "yup";
import { actions } from "../../modules/EInvoice/_redux/vendors/actions";

export function YupVendorVatNumberValidator(
  intl,
  tenantId,
  required = true,
  dispatch = false,
  saveRedux = false
) {
  var result = Yup.string()
    .length(11, intl.formatMessage({ id: "SERVICE.INVALID_VAT_NUM_LENGTH" }))
    .test(
      "checkVatNumValidation",
      intl.formatMessage({ id: "SERVICE.INVALID_VAT_NUM" }),
      (value) => {
        if (value) {
          var s = 0;
          for (var i = 0; i < 11; i++) {
            var n = value.charCodeAt(i) - "0".charCodeAt(0);
            if ((i & 1) === 1) {
              n *= 2;
              if (n > 9) n -= 9;
            }
            s += n;
          }
          if (s % 10 !== 0) return false;
          return true;
        } else {
          if (required) {
            return false;
          }
          return true;
        }
      }
    );

  if (dispatch) {
    result = result.test(
      "checkExistance",
      intl.formatMessage({ id: "SERVICE.VAT_NUM_ALREADY_PRESENT" }),
      (value) => {
        return new Promise((resolve, reject) => {
          if (value && value.length === 11) {
            dispatch(actions.getByVatNumber(value, tenantId, saveRedux))
              .then((result) => {
                if (result) {
                  resolve(false);
                } else {
                  resolve(true);
                }
              })
              .catch((error) => {
                resolve(true);
              });
          } else {
            resolve(true);
          }
        });
      }
    );
  }

  if (required) {
    return result.required(
      intl.formatMessage({ id: "SERVICE.VAT_NUM_IS_MANDATORY" })
    );
  }
  return result;
}
