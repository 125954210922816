import React, { useState, useEffect } from "react";
import { StringToHexColor, TextColorFromHexBackground } from "../functions";

export function TagsManager({
  stringValues,
  setStringValues,
  editable = true,
  customStyle = {}
}) {
  const [values, setValues] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (stringValues) {
      const vals = stringValues.split("#");
      if (vals && vals.length > 1) {
        setValues(vals.slice(1));
      } else {
        setValues([]);
      }
    } else {
      setValues([]);
    }
  }, [stringValues]);

  const parseInput = (v) => {
    setInputValue(v);

    var newValue = "";
    if (v && v.slice(-1) === " ") {
      newValue = v.slice(0, v.length - 1);
    }

    if (newValue) {
      setInputValue("");
      setStringValues(
        (stringValues ? stringValues : "") + "#" + newValue.toUpperCase()
      );
    }
  };

  const removeTag = (value) => {
    if (stringValues && stringValues.includes(value)) {
      const idx = stringValues.indexOf(value);

      const before = stringValues.slice(0, idx - 1);
      const after = stringValues.slice(idx + value.length, stringValues.length);

      setStringValues(before + after);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      parseInput(e.target.value + " "); //Simulate the space char to add tag
    }
  };

  const style = {
    remove_icon: {
      cursor: "pointer",
    },
  };

  return (
    <>
      {values.map((t, idx) => (
        <span
          key={idx}
          className={"label label-lg label-inline m-1"}
          style={{
            backgroundColor: "#" + StringToHexColor(t),
            color: "#" + TextColorFromHexBackground(StringToHexColor(t)),
          }}
        >
          {t}{" "}
          {editable && (
            <span
              className="far fa-2x fa-times-circle p-0 m-0 ml-2"
              onClick={() => removeTag(t)}
              style={style.remove_icon}
            ></span>
          )}
        </span>
      ))}

      {editable && (
        <input
          type="text"
          className="form-control w-100 text-center"
          value={inputValue}
          onChange={(e) => parseInput(e.target.value)}
          style={customStyle}
          placeholder={"Tags"}
          onKeyDown={handleKeyDown}
        />
      )}
    </>
  );
}
