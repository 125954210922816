import { API, graphqlOperation } from "aws-amplify";
import {
  listReceipts,
  receiptsByTenant,
  receiptsByRetailStore,
  receiptsByCustomer,
  receiptsByMoitoIot,
  getReceipt,
} from "../../../../../graphql/queries";
import * as moitoIotQueries from "../../graphql/private/queries";

export function findObjects(
  queryParams,
  additionalFilters,
  nextToken,
  customQuery = null
) {
  const params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
    if (Object.keys(params.filter).includes("or")) {
      params.filter.or = params.filter.or.concat([
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ]);
    } else {
      params.filter.or = [
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ];
    }
  }

  params.filter = {
    ...params.filter,
    deleted: { ne: true },
  };

  return API.graphql(
    graphqlOperation(
      customQuery ? moitoIotQueries[customQuery] : listReceipts,
      params
    )
  );
}

export function findObjectsByTenant(
  tenantId,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    tenantId: tenantId,
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
    if (Object.keys(params.filter).includes("or")) {
      params.filter.or = params.filter.or.concat([
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ]);
    } else {
      params.filter.or = [
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ];
    }
  }

  params.filter = {
    ...params.filter,
    deleted: { ne: true },
  };

  return API.graphql(graphqlOperation(receiptsByTenant, params));
}

export function findObjectsByRetailStore(
  retailStoreId,
  queryParams,
  additionalFilters,
  nextToken,
  customQuery
) {
  const params = {
    retailStoreId: retailStoreId,
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
    if (Object.keys(params.filter).includes("or")) {
      params.filter.or = params.filter.or.concat([
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ]);
    } else {
      params.filter.or = [
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ];
    }
  }

  params.filter = {
    ...params.filter,
    deleted: { ne: true },
  };

  return API.graphql(graphqlOperation(
    customQuery ? moitoIotQueries[customQuery] : receiptsByRetailStore,
    params
  ));
}

export function findObjectsByMoitoIot(
  moitoIotId,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    moitoIotId: moitoIotId,
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
    if (Object.keys(params.filter).includes("or")) {
      params.filter.or = params.filter.or.concat([
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ]);
    } else {
      params.filter.or = [
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ];
    }
  }

  params.filter = {
    ...params.filter,
    deleted: { ne: true },
  };
  
  return API.graphql(graphqlOperation(receiptsByMoitoIot, params));
}

export function findObjectsByCustomer(
  customerId,
  queryParams,
  additionalFilters,
  nextToken,
  customQuery
) {
  const params = {
    customerId: customerId,
    sortDirection: "DESC",
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
    if (Object.keys(params.filter).includes("or")) {
      params.filter.or = params.filter.or.concat([
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ]);
    } else {
      params.filter.or = [
        { deleted: { eq: false } },
        { deleted: { attributeExists: false } },
      ];
    }
  }

  params.filter = {
    ...params.filter,
    deleted: { ne: true },
  };

  return API.graphql(graphqlOperation(
    customQuery ? moitoIotQueries[customQuery] : receiptsByCustomer,
    params
  ));
}

export function getObjectById(id) {
  return API.graphql(graphqlOperation(getReceipt, { id: id }));
}
