import { API, graphqlOperation } from "aws-amplify";
import {
    calendarEventTypeByTenant
} from "../../../../../graphql/queries";
import {
    createCalendarEventType,
    updateCalendarEventType,
} from "../../../../../graphql/mutations";


export async function create(input){
    const {data} = await API.graphql(graphqlOperation(createCalendarEventType, { input: input }));
    return data.createCalendarEventType;
}

export async function read(params={}){
    const {data} = await API.graphql(graphqlOperation(calendarEventTypeByTenant,params));
    return {
        items:data.calendarEventTypeByTenant.items,
        nextToken:data.calendarEventTypeByTenant.nextToken ?? undefined
    };
}

export async function update(input){
    const {data} = await API.graphql(graphqlOperation(updateCalendarEventType, { input: input }));
    return data.updateCalendarEventType;
}

export async function softDelete(id){
    return await API.graphql(graphqlOperation(updateCalendarEventType, { input: {id:id,deleted:true} }))
}