import { API, graphqlOperation } from "aws-amplify";
import {
  listUserLicenses,
  userLicensesByUser,
  getUserLicense,
  userLicensesByTenant,
} from "../../../../../graphql/queries";
import {
  createUserLicense,
  deleteUserLicense,
} from "../../../../../graphql/mutations";

export function findObjects(queryParams, additionalFilters, nextToken) {
  const params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(listUserLicenses, params));
}

export function findObjectsByUser(
  userId,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    userId: userId,
    sortDirection: "ASC",
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(userLicensesByUser, params));
}

export function findObjectsByTenant(
  tenantId,
  queryParams,
  additionalFilters,
  nextToken
) {
  var params = {
    tenantId: tenantId,
    sortDirection: "ASC",
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.userId) {
    params.userId = queryParams.userId;
  }

  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(userLicensesByTenant, params));
}

export function getObjectById(id) {
  return API.graphql(graphqlOperation(getUserLicense, { id: id }));
}

export function createObject(obj) {
  return API.graphql(graphqlOperation(createUserLicense, { input: obj }));
}

export function deleteObject(id) {
  return API.graphql(
    graphqlOperation(deleteUserLicense, { input: { id: id } })
  );
}

export function createObjects(objs) {
  var creates = [];
  objs.forEach((obj) => {
    creates.push(
      new Promise((resolve, reject) => {
        return resolve(createObject(obj));
      })
    );
  });
  return Promise.all(creates);
}
