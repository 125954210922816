import { createSlice } from "@reduxjs/toolkit";
import * as versions from "../../utils/versions";
import DefaultConfiguration from "../../../../common/cashregisters/default_configuration";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  content: DefaultConfiguration,
  plu: [],
  lastContent: DefaultConfiguration,
  cashRegister: null,
  version: versions.defaultVersion(),
  entities: [],
  checked: [],
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "configuration",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${JSON.stringify(action.payload.error)}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    setLastConfiguration: (state, action) => {
      if (!action.payload.content) {
        state.lastContent = DefaultConfiguration;
      } else {
        state.lastContent = action.payload.content;
      }
    },
    setCurrentConfiguration: (state, action) => {
      if (!action.payload.content) {
        state.content = DefaultConfiguration;
      } else {
        state.content = action.payload.content;
      }
    },
    setPlu: (state, action) => {
      if (!action.payload.content) {
        state.plu = [];
      } else {
        state.plu = action.payload.content;
      }
    },
    setVersion: (state, action) => {
      state.version = action.payload.version;
    },
    setChecked: (state, action) => {
      state.checked = action.payload.checked;
    },
    toggleEntity: (state, action) => {
      const { entity } = action.payload;
      if (state.entities.includes(entity)) {
        state.entities = state.entities.filter((el) => el !== entity);
      } else {
        state.entities = [...state.entities, entity];
      }
    },
    setCurrentCashRegister: (state, action) => {
      if (!action.payload.cashRegister) {
        state.cashRegister = null;
      } else {
        state.cashRegister = {
          ...action.payload.cashRegister,
          extensions: JSON.parse(action.payload.cashRegister.extensions),
        };
      }
    },
    updatePlu: (state, action) => {
      if (action.payload.idx >= 0 && action.payload.idx < state.plu.length) {
        state.plu[action.payload.idx] = action.payload.newValues;
      }

      if (action.payload.idx >= state.plu.length) {
        if (state.plu.length <= 0) {
          state.plu = [action.payload.newValues];
        } else {
          state.plu = [...state.plu, [action.payload.newValues]];
        }
      }
    },
    updateConfiguration: (state, action) => {
      const { entity, fieldName, value } = action.payload;

      if (fieldName) {
        if (state.content && state.content[entity]) {
          state.content = {
            ...state.content,
            [entity]: {
              ...state.content[entity],
              [fieldName]: value,
            },
          };
        } else {
          state.content = {
            ...state.content,
            [entity]: {
              [fieldName]: value,
            },
          };
        }
      } else {
        if (typeof value === "object") {
          if (value instanceof Array) {
            state.content = {
              ...state.content,
              [entity]: [...value],
            };
          } else {
            state.content = {
              ...state.content,
              [entity]: {
                ...value,
              },
            };
          }
        } else {
          state.content = {
            ...state.content,
            [entity]: value,
          };
        }
      }
    },
  },
});
