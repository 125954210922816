export default [
  {
    name: "PLU",
    code: "plu",
  },
  {
    name: "Reparti",
    code: "departments",
  },
  {
    name: "Gruppi",
    code: "groups",
  },
  {
    name: "Flag di sistema",
    code: "systemFlag",
  },
  {
    name: "Sconti/Magg.",
    code: "discountinfo",
  },
  {
    name: "Intestazione",
    code: "HeadrInfo",
  },
  {
    name: "Aliquote IVA",
    code: "TAX",
  },
  {
    name: "Fine scontrino",
    code: "tail",
  },
  {
    name: "Densità stampa",
    code: "PrnDensity",
  },
  {
    name: "Pubblicità",
    code: "advertMsg",
  },
  {
    name: "Operatori",
    code: "clerk",
  },
  {
    name: "Macro",
    code: "macro",
  },
  {
    name: "Valute",
    code: "currency",
  },
  {
    name: "Ateco",
    code: "Ateco",
  },
  {
    name: "Omaggio",
    code: "omaggio",
  },
  {
    name: "Tasti pagam.",
    code: "PayKey",
  },
  {
    name: "Varie",
    code: "varie",
  },
  {
    name: "Tastiera",
    code: "programkey",
  },
  {
    name: "Servizi Web",
    code: "serverServices",
  },
  {
    name: "Logo",
    code: "logo",
  },
  {
    name: "URL",
    code: "urlData",
  },
  {
    name: "Footer logo",
    code: "footerlogo",
  },
];
