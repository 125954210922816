import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sessionId: undefined,
  customerName: "",
  ordinationsPassword: "",
  initialData: {},
};
export const slice = createSlice({
  name: "ordinations",
  initialState: initialState,
  reducers: {
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    setCustomerName: (state, action) => {
      state.customerName = action.payload;
    },
    setOrdinationsPassword: (state, action) => {
      state.ordinationsPassword = action.payload;
    },
    initialDataFetched: (state, action) => {
      state.initialData = action.payload;
    },
  },
});
