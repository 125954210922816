import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { read } from "./crud"

const initialState = { entities: [], prevTokens : [], currentToken:null, nextToken:null, loading: false};

async function readWithFilter({nextToken,limit,retailStoreId,filter}){

  let results=[];
  let token=nextToken;

  do {
    const {items,nextToken} = await read({nextToken:token,limit,retailStoreId,filter,sortDirection: "DESC"});
    results = results.concat(items);
    token = nextToken;
    if (results.length >= limit) break;
  }while (token !== undefined);
  
  return {
    items:results,
    nextToken:token
  };
}

export const fetchOnEffectEvents = createAsyncThunk('events/fetchOnEffect',async ({nextToken,limit,retailStoreId,filter}, thunkAPI) => {
  return filter ? readWithFilter({nextToken,limit,retailStoreId,filter}) : read({nextToken,limit,retailStoreId,sortDirection: "DESC"});
});

export const fetchPrevEvents = createAsyncThunk('events/fetchPrev',async ({nextToken,limit,retailStoreId,filter}, thunkAPI) => {
  return filter ? readWithFilter({nextToken,limit,retailStoreId,filter}) : read({nextToken,limit,retailStoreId,sortDirection: "DESC"});
});

export const fetchCurrentEvents = createAsyncThunk('events/fetchCurrent',async ({nextToken,limit,retailStoreId,filter}, thunkAPI) => {
  return filter ? readWithFilter({nextToken,limit,retailStoreId,filter}) : read({nextToken,limit,retailStoreId,sortDirection: "DESC"});
});

export const fetchNextEvents = createAsyncThunk('events/fetchNext',async ({nextToken,limit,retailStoreId,filter}, thunkAPI) => {
  return filter ? readWithFilter({nextToken,limit,retailStoreId,filter}) : read({nextToken,limit,retailStoreId,sortDirection: "DESC"});
});

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {/** to do if needed **/},
  extraReducers: {
    [fetchNextEvents.pending]: (state) => {
      state.loading = true;
    },
    [fetchNextEvents.fulfilled]: (state, { payload }) => {
      state.prevTokens.push(state.currentToken);
      state.currentToken = state.nextToken;
      state.nextToken = payload.nextToken;
      state.entities = payload.items;
      state.loading = false;
    },
    [fetchNextEvents.rejected]: (state , { payload }) => {
      state.loading = false;
    },
    [fetchCurrentEvents.pending]: (state) => {
      state.loading = true;
    },
    [fetchCurrentEvents.fulfilled]: (state, { payload }) => {
      state.nextToken = payload.nextToken;
      state.entities = payload.items;
      state.loading = false;
    },
    [fetchCurrentEvents.rejected]: (state , { payload }) => {
      state.loading = false;
    },
    [fetchPrevEvents.pending]: (state) => {
      state.loading = true;
    },
    [fetchPrevEvents.fulfilled]: (state, { payload }) => {
      state.nextToken = state.currentToken;
      state.currentToken=state.prevTokens.pop();
      state.entities = payload.items;
      state.loading = false;
    },
    [fetchPrevEvents.rejected]: (state , { payload }) => {
      state.loading = false;
    },
    [fetchOnEffectEvents.pending]: (state) => {
      state.loading = true;
    },
    [fetchOnEffectEvents.fulfilled]: (state, { payload }) => {
      state.currentToken=null;
      state.prevTokens=[];
      state.nextToken = payload.nextToken;
      state.entities = payload.items;
      state.loading = false;
    },
    [
      fetchOnEffectEvents.rejected]: (state , { payload }) => {
      state.loading = false;
    },
  },
})

export const selectEntities = (state) => state.calendar.events.entities;

export const selectLoading = (state) => state.calendar.events.loading;

export const selectNextToken = (state) => state.calendar.events.nextToken;

export const selectCurrentToken= (state) => state.calendar.events.currentToken;

export const  selectPrevToken = (state) =>state.calendar.events.prevTokens[state.calendar.events.prevTokens.length-1];

export default eventsSlice.reducer