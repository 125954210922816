import { SET_CURRENT_TENANT, SET_CURRENT_TENANT_ASYNC } from "./tenantTypes";

function setCurrentTenant(tenantId) {
  return function (dispatch) {
    dispatch({ type: SET_CURRENT_TENANT, payload: tenantId });
  };
}

function setCurrentTenantAsync(tenantId) {
  return function (dispatch) {
    dispatch({ type: SET_CURRENT_TENANT_ASYNC, payload: tenantId });
  };
}

export const actions = {
	setCurrentTenant,
	setCurrentTenantAsync,
}