import * as request from "./crud";
import { GenerateSlice, SliceCallTypes } from "../../../../utils/components/generic/_redux";
import { orderBy } from "lodash";

const sliceActions = GenerateSlice("customers").actions;

const clearData = () => (dispatch) => {
  dispatch(sliceActions.clearData());
};

const searchObjects =
  (queryParams, additionalFilters, nextToken, operation = "overwrite") =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.searchObjects(
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal,
          );
          var data = result.data.searchCustomers;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: data.total,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve({
          data: {
            searchCustomer: {
              items: sortedItems,
              nextToken: nextTokenInternal,
              total: data.total,
            },
          },
        });
      } catch (error) {
        error.clientMessage = "Can't find customers";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };
  

const fetchObjects =
  (queryParams, additionalFilters, nextToken, operation = "overwrite") =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjects(
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal
          );
          var data = result.data.listCustomers;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: sortedItems.length,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve(sortedItems);
      } catch (error) {
        error.clientMessage = "Can't find customers";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };

const fetchObjectsByTenant =
  (
    tenantId,
    queryParams,
    additionalFilters,
    nextToken,
    operation = "overwrite"
  ) =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjectsByTenant(
            tenantId,
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal
          );
          var data = result.data.customersByTenant;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: sortedItems.length,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve(sortedItems);
      } catch (error) {
        error.clientMessage = "Can't find customers";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };

const getByVatNumber = (vatNum, tenantId, saveRedux = true) => async (dispatch) => {
  if (saveRedux) {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  }

  var queryParams = {
    pageSize: 1000,
    filter: {
      tenantId: { eq: tenantId },
      deleted: { ne: true },
    },
  };

  var nextToken = null;

  try {
    do {
      var result = await request.findObjectsByCompanyVat(
        vatNum,
        {
          ...queryParams,
          pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
        },
        null,
        nextToken
      );
      var data = result.data.customersByCompanyVat;

      if (data.items.length > 0) {
        if (saveRedux) {
          dispatch(sliceActions.objectFetched({ entity: data.items[0] }));
        }
        return data.items[0];
      }

      nextToken = data.nextToken;
    } while (nextToken !== null);

    if (saveRedux) {
      dispatch(sliceActions.objectFetched({ entity: undefined }));
    }
    return undefined;
  } catch (error) {
    error.clientMessage = "Can't get tenant by vat number";
    if (saveRedux) {
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    }
    return error;
  }
};

const getByFiscalCode = (fiscalCode, tenantId, saveRedux = true) => async (dispatch) => {
  if (saveRedux) {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  }

  var queryParams = {
    pageSize: 1000,
    filter: {
      tenantId: { eq: tenantId },
      deleted: { ne: true },
    },
  };

  var nextToken = null;

  try {
    do {
      var result = await request.findObjectsByFiscalCode(
        fiscalCode,
        {
          ...queryParams,
          pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
        },
        null,
        nextToken
      );
      var data = result.data.customersByFiscalCode;

      if (data.items.length > 0) {
        if (saveRedux) {
          dispatch(sliceActions.objectFetched({ entity: data.items[0] }));
        }
        return data.items[0];
      }

      nextToken = data.nextToken;
    } while (nextToken !== null);

    if (saveRedux) {
      dispatch(sliceActions.objectFetched({ entity: undefined }));
    }
    return undefined;
  } catch (error) {
    error.clientMessage = "Can't get tenant by fiscal code";
    if (saveRedux) {
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    }
    return error;
  }
};

const getByMobile = (mobile, tenantId) => async (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));

  var queryParams = {
    pageSize: 1000,
    filter: {
      tenantId: { eq: tenantId },
      deleted: { ne: true },
    },
  };

  var nextToken = null;

  try {
    do {
      var result = await request.findObjectsByMobile(
        mobile,
        {
          ...queryParams,
          pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
        },
        null,
        nextToken
      );
      var data = result.data.customersByMobile;

      if (data.items.length > 0) {
        dispatch(sliceActions.objectFetched({ entity: data.items[0] }));
        return data.items[0];
      }

      nextToken = data.nextToken;
    } while (nextToken !== null);

    dispatch(sliceActions.objectFetched({ entity: undefined }));
    return undefined;
  } catch (error) {
    error.clientMessage = "Can't get tenant by mobile";
    dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    return error;
  }
};

const fetchObject = (id) => (dispatch) => {
  if (!id) {
    return dispatch(sliceActions.objectFetched({ entity: undefined }));
  }

  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .getObjectById(id)
    .then((response) => {
      const obj = response.data.getCustomer;
      dispatch(sliceActions.objectFetched({ entity: obj }));
      return obj;
    })
    .catch((error) => {
      error.clientMessage = "Can't find customer";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
      return error;
    });
};

const deleteObject = (id) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .deleteObject(id)
    .then((response) => {
      dispatch(sliceActions.objectDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete customer";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

const createObject = (customer) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .createObject(customer)
    .then((response) => {
      const object = response.data.createCustomer;
      dispatch(sliceActions.objectsCreated({ objects: [object] }));
      return object;
    })
    .catch((error) => {
      error.clientMessage = "Can't create customer";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
      return error;
    });
};

const deleteObjects = (ids) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .deleteObjects(ids)
    .then(() => {
      dispatch(sliceActions.objectsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete customer";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

const updateObjects = (objects) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .updateObjects(objects)
    .then((response) => {
      const objects = response.map((obj) => obj.data.updateCustomer);
      dispatch(sliceActions.objectsUpdated({ objects: objects }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update customers";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

const updateObject = (customer) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .updateObject(customer)
    .then((response) => {
      dispatch(
        sliceActions.objectsUpdated({ objects: [response.data.updateCustomer] })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't update customer";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

export const actions = {
  clearData,
  searchObjects,
  fetchObjects,
  fetchObjectsByTenant,
  getByVatNumber,
  getByFiscalCode,
  getByMobile,
  fetchObject,
  deleteObject,
  createObject,
  deleteObjects,
  updateObject,
  updateObjects,
}