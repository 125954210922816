import React from "react";
import Can from "../../AWS/Can";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { getMenuItemActive } from "../../../utils";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Aside() {
  const location = useLocation();

  return (
    <Can I="view" a="rt_utility">
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(
          location,
          "/rt_utility"
        )}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <NavLink className={"menu-link menu-toggle "} to="/rt_utility">
          <span className="svg-icon menu-icon">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")}
            />
          </span>
          <span className={"menu-text "}>
            <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY" />
          </span>
        </NavLink>
        <div className="menu-submenu">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            <li className="menu-item menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text">
                  <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY" />
                </span>
              </span>
            </li>

            <Can I="view" a="rt_utility_ecr">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/cashregisters"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className={"menu-link "}
                  to="/rt_utility/cashregisters"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RETAIL.RECORDERS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="edit" a="rt_utility_configuration">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/configuration"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className={"menu-link "}
                  to="/rt_utility/configuration"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.EDIT_CONFIGURATION" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="rt_utility_backups">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/backups"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/rt_utility/backups">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.BACKUPS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="rt_utility_favorites">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/favorites"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/rt_utility/favorites">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.FAVORITES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="rt_utility_dashboard">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/dashboard"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/rt_utility/dashboard">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.DASHBOARD" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="rt_utility_partnernotes">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/notes"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/rt_utility/notes">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.NOTES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="rt_utility_ejreport">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/ejreport"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/rt_utility/ejreport">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.EJREPORT" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="rt_utility_fwupload">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/fwupload"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/rt_utility/fwupload">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.FWUPLOAD" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="rt_utility_fwupgrade">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/fwupgrade"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/rt_utility/fwupgrade">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.FWUPGRADE" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="download" a="rt_utility_firmware">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/fwdownload"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/rt_utility/fwdownload">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.FWDOWNLOAD" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manager" a="rt_utility_adv_messages">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/advmessages"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/rt_utility/advmessages">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.ADV_MESSAGES" />
                  </span>
                </NavLink>
              </li>
            </Can>
            
            <Can I="view" a="rt_utility_logs">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/rt_utility/logs"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/rt_utility/logs">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.LOGS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <li
              className={`menu-item ${getMenuItemActive(
                location,
                "/rt_utility/help"
              )}`}
              aria-haspopup="true"
            >
              <NavLink className={"menu-link "} to="/rt_utility/help">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className={"menu-text "}>
                  <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.ONLINE_HELP" />
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
    </Can>
  );
}
