import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerId: undefined,
  password: undefined,
};

export const slice = createSlice({
  name: "calendar_public",
  initialState: initialState,
  reducers: {
    setCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
  },
});
