import { takeEvery, put, delay } from "redux-saga/effects";
import { SET_CURRENT_STORE, SET_CURRENT_STORE_ASYNC, REFRESH } from "./types";

export function* setCurrentStoreSaga() {
  yield takeEvery(SET_CURRENT_STORE, changeStore);
}

function* changeStore(action) {
  yield put({ type: SET_CURRENT_STORE_ASYNC, payload: action.payload });
  yield delay(1000);
  yield put({ type: REFRESH });
}
