import Allergens from "../../common/allergens/Allergens";

export function GetAllergenPathByIconCode(code) {
  var path = "";

  Allergens.forEach((allergen) => {
    const obj = allergen[Object.keys(allergen)[0]];

    obj.icons.forEach((el) => {
      if (el.code === code) {
        path = el.path;
        return;
      }
    });

    if (path) {
      return;
    }
  });

  return path;
}
