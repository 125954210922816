import { combineReducers } from "redux";
import { GenerateSlice } from "../../../utils";

import { slice as ecrRegistrationSlice } from "./ecr_registration/slice";
import { slice as moitoRegistrationSlice } from "./moitoiot_registration/slice";

export const reducer = combineReducers({
  users: GenerateSlice("users").reducer,
  licenses: GenerateSlice("licenses").reducer,
  licenserequests: GenerateSlice("licenserequests").reducer,
  retailstorelicenses: GenerateSlice("retailstorelicenses").reducer,
  tenantusers: GenerateSlice("tenantusers").reducer,
  userprivileges: GenerateSlice("userprivileges").reducer,
  userlicenses: GenerateSlice("userlicenses").reducer,
  privileges: GenerateSlice("privileges").reducer,
  partnershiprequests: GenerateSlice("partnershiprequests").reducer,
  privilegegroups: GenerateSlice("privilegegroups").reducer,
  appsettings: GenerateSlice("appsettings").reducer,
  tenants: GenerateSlice("tenants").reducer,
  ecr_registration: ecrRegistrationSlice.reducer,
  moitoiot_registration: moitoRegistrationSlice.reducer,
  privilegeprivilegegroups: GenerateSlice("privilegeprivilegegroups").reducer,
  news: GenerateSlice("news").reducer,
});
