export function EnvironmentType() {
  const host = window.location.host;

  const patterns = {
    dev: ["localhost", "dev"],
    test: ["test"],
    build: ["build", "staging", "preprod"],
    prod: ["prod", "master"],
  };

  var env = "prod";
  if (host) {
    const keys = Object.keys(patterns);
    for (const key of keys) {
      var found = false;
      const values = patterns[key];
      for (const value of values) {
        if (host.includes(value)) {
          found = true;
          env = key;
          break;
        }
      }
      if (found) {
        break;
      }
    }
  }
  return env;
}
