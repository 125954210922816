import React from "react";
import Can from "../../AWS/Can";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { getMenuItemActive } from "../../../utils";
import { NavLink } from "react-router-dom";

export function Menu() {
  const location = useLocation();

  return (
    <Can I="view" a="admin">
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(
          location,
          "/admin"
        )}`}
        data-menu-toggle="hover"
        aria-haspopup="true"
      >
        <NavLink className="menu-link menu-toggle" to="/admin">
          <span className="fas fa fa-key text-primary mr-2"></span>
          {" "}
          <span className="menu-text">
            <FormattedMessage id="SERVICE.ASIDE.ADMIN" />
          </span>
          <i className="menu-arrow" />
        </NavLink>
        <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
          <ul className="menu-subnav">
            <Can I="view" a="tenant">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/admin/tenants"
                )}`}
              >
                <NavLink className="menu-link" to="/admin/tenants">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="SERVICE.ASIDE.ADMIN.TENANT" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="license">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/admin/licenses"
                )}`}
              >
                <NavLink className="menu-link" to="/admin/licenses">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="SERVICE.ASIDE.ADMIN.LICENSES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="user">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/admin/users"
                )}`}
              >
                <NavLink className="menu-link" to="/admin/users">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="SERVICE.ASIDE.ADMIN.USERS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="onboarding">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/admin/onboarding"
                )}`}
              >
                <NavLink className="menu-link" to="/admin/onboarding">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="SERVICE.ASIDE.ADMIN.ONBOARDING" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="license_report">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/admin/license_report"
                )}`}
              >
                <NavLink className="menu-link" to="/admin/license_report">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="SERVICE.ASIDE.ADMIN.LICENSE_REPORT" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="registration">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/admin/ecr_registration"
                )}`}
              >
                <NavLink className="menu-link" to="/admin/ecr_registration">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="SERVICE.ASIDE.ADMIN.ECR_REGISTRATION" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="moitoiot_registration">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/admin/moitoiot_registration"
                )}`}
              >
                <NavLink
                  className="menu-link"
                  to="/admin/moitoiot_registration"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="SERVICE.ASIDE.ADMIN.MOITOIOT_REGISTRATION" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="license_setting">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/admin/license_setting"
                )}`}
              >
                <NavLink className="menu-link" to="/admin/license_setting">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="SERVICE.ASIDE.ADMIN.LICENSE_SETTING" />
                  </span>
                </NavLink>
              </li>
            </Can>
          </ul>
        </div>
      </li>
    </Can>
  );
}
