import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getSelectRow, getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage } from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { Pagination, GetTenant, GetRetailStore } from "../../";
import { GetValueFromStringPath } from "../../functions/GetValueFromStringPath";
import orderBy from "lodash/orderBy";

export function Table({ 
  UIContext, 
  columns, 
  onUseEffect, 
  sorting = { field: "createdAt", direction: "desc" },
  selectableRow = true,
  disablePaginator = false,
}) {
  const intl = useIntl();

  const UIProps = useMemo(() => {
    return {
      ids: UIContext.ids,
      setIds: UIContext.setIds,
      queryParams: UIContext.queryParams,
      setQueryParams: UIContext.setQueryParams,
      currentToken: UIContext.currentToken,
      setCurrentToken: UIContext.setCurrentToken,
      statePath: UIContext.statePath,
    };
  }, [UIContext]);

  const { entities, nextToken, prevTokens, listLoading, tenant, store } = useSelector(
    (state) => ({
      entities: GetValueFromStringPath(state, UIProps.statePath + ".entities.items"),
      nextToken: GetValueFromStringPath(state, UIProps.statePath + ".entities.nextToken"),
      prevTokens: GetValueFromStringPath(state, UIProps.statePath + ".entities.prevTokens"),
      listLoading: GetValueFromStringPath(state, UIProps.statePath + ".listLoading"),

      tenant: GetTenant(),
      store: GetRetailStore(),
    }),
    shallowEqual
  );

  const nextPage = () => {
    UIProps.setCurrentToken(nextToken);
  };
  const prevPage = () => {
    if (prevTokens.length >= 2) {
      UIProps.setCurrentToken(prevTokens[prevTokens.length - 2]);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (UIProps.setIds) {
      UIProps.setIds([]);
    }
    
    if (onUseEffect) {
      onUseEffect(dispatch, tenant.id, store.id, UIProps.queryParams, UIProps.currentToken);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.queryParams, tenant.id, dispatch]);

  const sortedEntities = orderBy(entities, [sorting.field], [sorting.direction]);

  const paginationOptions = {
    custom: true,
    sizePerPageList: [],
    sizePerPage: UIProps.queryParams.pageSize,
    page: UIProps.queryParams.pageNumber,
  };
  return (
    <>
      {disablePaginator ? (
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center"
          bootstrap4
          remote
          keyField="id"
          data={sortedEntities === null ? [] : sortedEntities}
          columns={columns}
          defaultSorted={[{ dataField: "id", order: "asc" }]}
          onTableChange={getHandlerTableChange(UIProps.setQueryParams)}
          selectRow={selectableRow ? getSelectRow({
            entities: sortedEntities,
            ids: UIProps.ids,
            setIds: UIProps.setIds,
          }) : undefined}
        >
          <PleaseWaitMessage entities={sortedEntities} />
          <NoRecordsFoundMessage entities={sortedEntities} />
        </BootstrapTable>
      ) : (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={listLoading}
                paginationProps={paginationProps}
                nextPage={nextPage}
                enableNextPage={!!nextToken}
                prevPage={prevPage}
                enablePrevPage={prevTokens.filter(el => !!el).length > 0}
              >
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center"
                  bootstrap4
                  remote
                  keyField="id"
                  data={sortedEntities === null ? [] : sortedEntities}
                  columns={columns}
                  defaultSorted={[{ dataField: "id", order: "asc" }]}
                  onTableChange={getHandlerTableChange(UIProps.setQueryParams)}
                  selectRow={selectableRow ? getSelectRow({
                    entities: sortedEntities,
                    ids: UIProps.ids,
                    setIds: UIProps.setIds,
                  }) : undefined}
                  {...paginationTableProps}
                >
                  <PleaseWaitMessage entities={sortedEntities} />
                  <NoRecordsFoundMessage entities={sortedEntities} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      )}
    </>
  );
}
