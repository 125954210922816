import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";
import Provinces from "../../common/localization/provinces";
import { CreatableLookupField } from "../lookup/CreatableLookupField";
import { orderBy } from "lodash";
import { GetValueFromStringPath } from "../functions/GetValueFromStringPath";

export function CountyLookupField({ county, disabled = false, required = false }) {
  const intl = useIntl();
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext();

  const [defaultCounty, setDefaultCounty] = useState(null);

  useEffect(() => {
    const value = GetValueFromStringPath(values, county);
    if (value) {
      setDefaultCounty({ value: value, label: value });
      setFieldTouched(county);
    } else {
      setDefaultCounty({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[county]]);

  return (
    <CreatableLookupField
      defaultValue={defaultCounty}
      label={intl.formatMessage({ id: "PARTY.COUNTY" })}
      data={orderBy(Provinces, ["label"], ["asc"])}
      labelField="label"
      valueField="value"
      onChange={(val) => setFieldValue(county, val.value)}
      disabled={disabled}
      required={required}
    />
  );
}
