import React from "react";
import { Aside } from "../../../AuthCognito/components/Aside";
import { DTRServiceLogo } from "../dashboard/components/DTRServiceLogo";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

export function PrivacyPolicy() {
  const intl = useIntl();
  const history = useHistory();

  const url = "https://www.dtr-service.it";

  //Warning: This is the PRODUCTION S3 BUCKET
  const documentUrl =
    "https://coristechda3c2209cf6940b7976e4d383712a5ea142526-prod.s3.eu-central-1.amazonaws.com/public/tos/PRIVACY_POLICY.pdf";

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
        id="kt_login"
      >
        <Aside />

        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
          <div className="container-fluid p-0 m-0">
            <button
              type="button"
              onClick={() => history.goBack()}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              <FormattedMessage id="SERVICE.BACK" />
            </button>
          </div>

          <DTRServiceLogo dynamic={false} />

          <div className="row">
            <div className="col-0 col-md-1"></div>
            <div className="col-12 col-md-10">
              <h5 className="text-center">
                Informativa sul trattamento dei dati personali sito web
                DTR-Service
              </h5>
              <br />
              <p className="text-justify">
                La presente informativa è resa in conformità al Regolamento (UE)
                n. 2016/679 (“GDPR”) e alle norme attualmente vigenti del d.lgs.
                196/2003 (c.d. Codice Privacy) al fine di rendere edotto
                l’Utente-Interessato del sito{" "}
                <a href="https://www.dtr-service.it" target="_blank">
                  {url}
                </a>{" "}
                (in seguito il “Sito”) dei trattamenti di dati personali che
                vengono effettuati nel corso della navigazione stessa. Questo
                documento potrà subire delle modifiche per eventuali variazioni
                delle finalità di trattamento oppure per modifiche della
                normativa sulla protezione dei datie per evoluzioni tecnologiche
                eventualmente sopravvenute. In tal caso, alle versioni
                aggiornate verrà data pubblicità su questo Sito.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Titolare del Trattamento dei Dati.
                </span>{" "}
                Il Titolare del trattamento dei dati è DTR Italy s.r.l.con sede
                legale a Milano 20131, via G. Luosi, 14(di seguito identificata
                come "DTR" o “Titolare”), ed è contattabile all’indirizzo
                e-mail: gdpr@dtr-italy.com.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Tipologia di Dati Raccolti.{" "}
                </span>{" "}
                I sistemi informatici e le procedure software preposte al
                funzionamento del Sito possono, nel corso del loro normale
                esercizio, acquisire alcuni dati personali la cui trasmissione è
                implicita nell'uso dei protocolli di comunicazione di Internet.
                Si tratta di informazioni che non sono raccolte per essere
                associate a interessati identificati, ma che per loro stessa
                natura potrebbero, attraverso elaborazioni ed associazioni con
                dati detenuti da terzi, permettere di identificare gli utenti.In
                questa categoria di dati rientrano informazioni come l’indirizzo
                IP, il tipo di dispositivo, i numeri univoci di identificazione
                del dispositivo, il tipo di browser, la posizione geografica
                generale (ad es. luogo a livello di Paese o città) e altre
                informazioni tecniche. Potremmo altresì raccogliere informazioni
                sulle modalità di interazione del dispositivo dell’utente con il
                nostro sito Web, comprese le pagine visitate e i link cliccati,
                data ed orario della richiesta, tipo e dimensione della risorsa
                richiesta ed altri dati relativi al sistema operativo.DTRnon ha
                attivato alcuna funzionalità di acquisizione automatica
                (mediante log) delle informazioni sopra indicate che normalmente
                l’Utente implicitamente invia mediante la navigazione Internet.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Finalità del trattamento dei dati.{" "}
                </span>{" "}
                Non eseguiamo trattamenti occulti né registriamo dati personali
                o di preferenze relative alla fruizione dei contenuti del sito
                istituzionale; non identifichiamo né tracciamo l'identità
                personale di chi accede al Sito. Il trattamento dei dati
                personali di navigazione viene utilizzato al fine di ricavare
                informazioni statistiche anonime sull’uso del Sito, per
                controllarne il corretto funzionamento, per migliorare la
                qualità dei servizi offerti ed ottimizzare la funzionalità del
                Sito. Tali dati sono trattati con strumenti informatici (ivi
                compresi dispositivi portatili) in misura strettamente
                necessaria e proporzionata per garantire la sicurezza delle reti
                e delle informazioni che vi transitano e nel rispetto degli
                obblighi di riservatezza a cui è tenuto il Titolare, nonché
                adottando misure di sicurezza tecnico-organizzative adeguate
                alla tipologia di dati trattati.
                <u>
                  Il conferimento di tali dati non ha natura obbligatoria, nel
                  momento in cui l’Utente decida di non conferire tali dati non
                  deve navigare sul Sito e non potrà accedere alle funzionalità
                  messe a disposizione dallo stesso.
                </u>
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Base giuridica del trattamento.{" "}
                </span>{" "}
                La base giuridica è il legittimo interesse del Titolare del
                trattamento al mantenimento in sicurezza del Sito e che lo
                stesso non sia utilizzato secondo modalità lesive di diritti
                altrui o quale canale per la commissione di illeciti o eventuali
                frodi (cfr. Art. 6 (1) (f) GDPR e considerando 47 del GDPR).
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Conservazione dei dati.{" "}
                </span>
                DTR conserva i dati personali sopra indicati soltanto per un
                periodo massimo di 2 settimane, salvo l’esercizio di diritti di
                difesa del Titolare. Alla cessazione del trattamento, i dati
                personali raccolti sono cancellati o resi inintelligibili, in
                modo tecnicamente irreversibile.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Comunicazione e Diffusione dei dati.{" "}
                </span>
                I dati sopra indicati non sono comunicati a terzi, non vi è
                alcuna diffusione né tanto meno alcuna profilazione.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Trasferimento dei dati personali all’estero.{" "}
                </span>
                Il Sito è ospitato su un server collocato in Germania. Non vi è
                quindi alcun trasferimento di dati personali all’estero.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Diritti dell’interessato.{" "}
                </span>
                L’Utente, quale Interessato, che naviga sul Sito ha diritto, nei
                limiti previsti dalla normativa sulla protezione dei dati
                personali, a:
                <br />
                <ul className="list-group">
                  <li className="">
                    Accedere ai propri dati personali in possesso del Titolare;{" "}
                  </li>
                  <li className="">
                    Chiederne la rettifica e/o la cancellazione (oblio);
                  </li>
                  <li className="">
                    Chiederne la limitazione o opporsi al trattamento;{" "}
                  </li>
                  <li className="">Richiedere la portabilità dei dati;</li>
                  <li className="">
                    Proporre reclamo all'Autorità di Controllo competente
                    (Autorità Garante per la protezione dei dati personali –{" "}
                    <a href="https://www.garanteprivacy.it/" target="_blank">
                      www.garanteprivacy.it
                    </a>
                    ).
                  </li>
                </ul>
                <br />
                Le richieste per l’esercizio dei diritti sopra indicati devono
                essere rivolte all’e-mail: gdpr@dtr-italy.com.
              </p>

              <br />
              <p>Questa informativa è aggiornata a marzo 2021.</p>

              <br />
              <p>
                <a href={documentUrl} download target="_blank">
                  SCARICA IL DOCUMENTO
                </a>
              </p>
            </div>
            <div className="col-0 col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
