import { ActionsColumnFormatter } from "./ActionsColumnFormatter";

export const ActionsColumn = (intl, actions) => {
  return {
    dataField: "actions",
    text: intl.formatMessage({ id: "SERVICE.ACTIONS" }),
    formatter: ActionsColumnFormatter,
    formatExtraData: {
      actions: actions,
    },
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "110px",
    },
  }
}