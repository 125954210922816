import React, { useState, useEffect } from "react";
import Creatable from "react-select/creatable";
import { withAsyncPaginate, AsyncPaginate } from "react-select-async-paginate";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import { isEqual } from "lodash";
import { GetText } from "../functions/GetText";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

const getValueFromStringPath = (obj, path, getText = false) => {
  return path.split(".").reduce(function (o, k) {
    if (getText) {
      return GetText(o && o[k]);
    }
    return o && o[k];
  }, obj);
};

export function CreatableLookupField({
  label,
  getText = false,
  filterDeleted = false,
  onChange,
  data = [],
  valueField = "id",
  labelField = "name",
  defaultValue = undefined,
  queryFilter = null,
  query,
  topOption,
  isMulti = false,
  closeMenuOnSelect = true,
  disabled = false,
  error = "",
  additionalParams = {},
  forceChangeValue = false,
  required = false,
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (
      isEqual(value, defaultValue) ||
      (defaultValue && (!value || Object.keys(value).length <= 0))
    ) {
      setValue(defaultValue);
    }

    if (forceChangeValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, value]);

  const loadOptions = async (search, prevOptions, { nextToken }) => {
    await sleep(500);

    const params = {
      limit: 20,
      nextToken: nextToken,
      filter: {
        [labelField]: { contains: search },
      },
      ...additionalParams,
    };
    if (queryFilter) {
      params.filter = { ...params.filter, ...queryFilter };

      if (filterDeleted) {
        if (Object.keys(params.filter).includes("or")) {
          params.filter.or = params.filter.or.concat([
            { deleted: { eq: false } },
            { deleted: { attributeExists: false } },
          ]);
        } else {
          params.filter.or = [
            { deleted: { eq: false } },
            { deleted: { attributeExists: false } },
          ];
        }
      }
    } else {
      if (filterDeleted) {
        params.filter = {
          or: [
            { deleted: { eq: false } },
            { deleted: { attributeExists: false } },
          ],
        };
      }
    }

    var additionalOptions = [];
    if (topOption && !prevOptions.length) {
      additionalOptions[0] = topOption;
    }
    if (data.length > 0) {
      const hasMore =
        prevOptions.length === 0 && data.length > 20
          ? true
          : data.length > prevOptions.length + 20;
      var filteredData = data.filter(
        (el) =>
          el[labelField].toLowerCase().includes(search) ||
          el[labelField].toUpperCase().includes(search)
      );
      const slicedData = filteredData.slice(
        prevOptions.length,
        prevOptions.length + 20
      );

      return {
        options: slicedData.map((el) => {
          return {
            value: getValueFromStringPath(el, valueField),
            label: getValueFromStringPath(el, labelField, getText),
          };
        }),
        hasMore: hasMore,
      };
    } else {
      return API.graphql(graphqlOperation(queries[query], params))
        .then((result) => {
          return {
            options: additionalOptions.concat(
              result.data[query].items.map((el) => {
                return {
                  value: getValueFromStringPath(el, valueField),
                  label: getValueFromStringPath(el, labelField, getText),
                };
              })
            ),
            hasMore: !!result.data[query].nextToken,
            additional: {
              nextToken: result.data[query].nextToken,
            },
          };
        })
        .catch((e) => {
          return {
            options: additionalOptions.concat([{}]),
            hasMore: true,
            additional: {
              nextToken: null,
            },
          };
        });
    }
  };

  const onSelectChange = (newValue) => {
    onChange(newValue ? newValue : null);
    setValue(newValue);
  };

  return (
    <>
      {label && <label>{label}</label>}
      {required && (
        <>
          {" "}
          <strong className="text-danger">*</strong>
        </>
      )}
      {!disabled ? (
        <>
          <div
            className={
              error && !isEqual(value, {}) ? "border border-danger rounded" : ""
            }
          >
            <CreatableAsyncPaginate
              value={value}
              loadOptions={loadOptions}
              onChange={onSelectChange}
              additional={{
                nextToken: null,
              }}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
            />
          </div>
          {error && !isEqual(value, {}) && (
            <label className="text-danger">{error}</label>
          )}
        </>
      ) : (
        <>
          <AsyncPaginate value={value} />
        </>
      )}
    </>
  );
}
