/* eslint-disable no-underscore-dangle */
import { Ability, AbilityBuilder } from "@casl/ability";
import store from "../../../redux/store";
import { isEqual } from "lodash";
import { GetTenant } from "../../utils";

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });

var user = store.getState().auth.user;
var currentTenantId = store.getState().tenant.currentTenantId;
var currentRetailStoreId = store.getState().store.currentStoreId;

store.subscribe(() => {
  const prevUser = user;
  user = store.getState().auth.user;

  const prevCurrentTenantId = currentTenantId;
  currentTenantId = store.getState().tenant.currentTenantId;

  const prevCurrentRetailStoreId = currentRetailStoreId;
  currentRetailStoreId = store.getState().store.currentStoreId;

  if (
    !isEqual(prevUser, user) ||
    prevCurrentTenantId !== currentTenantId ||
    prevCurrentRetailStoreId !== currentRetailStoreId
  ) {
    ability.update(defineRulesFor(user, currentTenantId, currentRetailStoreId));
  }
});

const defineRulesFor = (user, currentTenantId, currentRetailStoreId) => {
  const { can, rules, cannot } = new AbilityBuilder();

  if (user) {
    const tenant = GetTenant(currentTenantId);

    const today = new Date();

    const userLicenses = user.userLicense.items
      .filter((el) => {
        if (
          tenant &&
          (el.license.name.includes("rt_utility") || el.license.name.includes("ej_management")) &&
          !tenant.isPartner
        ) {
          return false;
        }

        if (
          tenant &&
          el.license.name.includes("moitoiot_dealer") &&
          !tenant.isPartner
        ) {
          return false;
        }

        if (
          el.retailStoreLicense.retailStoreId !== currentRetailStoreId
          && el.license.name !== "rt_user_collector"
          && el.license.name !== "moitoiot_collector"
        ) {
          return false;
        }

        const validFrom = new Date(el.validFrom);
        const validTo = new Date(el.validTo);

        return validFrom <= today && validTo >= today;
      })
      .map((el) => el.licenseId);

    user.userLicense.items.forEach(ul => {
      can("access", ul.license.name);
    });

    var userPrivileges = user.userPrivilege.items;

    userPrivileges.forEach((el) => {
      if (
        el.tenantId === currentTenantId &&
        (!el.privilege.license ||
          userLicenses.includes(el.privilege.license.id))
      ) {
        can(el.privilege.ability, el.privilege.entity);
      }
    });

    cannot("view", "metronic");
  }
  return rules;
};

export default ability;
