import React from "react";

export function NumberColumnFormatter(
	cellContent,
	row,
	rowIndex,
	{ openEcrReportDialog }
) {
	return (
		<span>
			{row.electronicCashRegisterId && (
				<span>
					{openEcrReportDialog && (
						<>
							<span
								onClick={() =>
									openEcrReportDialog(row?.electronicCashRegisterId)
								}
								className="fas fa-info-circle fa-2x text-primary cursor-pointer"
							></span>
							{" "}
						</>
					)}

					{row.number.includes("DELETED")
						? row.number.slice(0, 11)
						: row.number}
				</span>
			)}
			{row.moitoIotCode && (
				<span>
					{row.moitoIotCode.includes("DELETED")
						? row.moitoIotCode.slice(0, 16)
						: row.moitoIotCode}
				</span>
			)}
		</span>
	);
}