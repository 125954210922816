export function FormatNumber(number) {
  if (!number) {
    number = "0";
  }
  if (typeof number !== "string") {
    number = number + "";
  }

  try {
    const r = Number.parseFloat(number.replace(",", ".")).toFixed(2);
    if (r === "NaN") {
      return Number.parseFloat(0).toFixed(2);
    }
    return r;
  } catch (e) {
    return Number.parseFloat(0).toFixed(2);
  }
}
