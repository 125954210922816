import { EnabledColumnFormatter } from "./EnabledColumnFormatter";

export const EnableColumn = (intl, keyField, toggleEnabled) => {
  return {
    dataField: "enabled",
    text: intl.formatMessage({ id: "SERVICE.ENABLED" }),
    formatter: EnabledColumnFormatter,
    formatExtraData: {
      fieldName: keyField,
      toggleEnabled: toggleEnabled,
    },
  }
}