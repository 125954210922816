import store from "../../../redux/store";

export function GetTenant(tenantId = undefined) {
  const state = store.getState();
  if (!state || !state.auth || !state.auth.user) {
    return undefined;
  }

  const tenantIdFind = tenantId ? tenantId : state.tenant.currentTenantId;
  const tenantUser = state.auth.user.tenantUser.items.find(
    (el) => el.tenantId === tenantIdFind
  );
  if (tenantUser) {
    return tenantUser.tenant;
  }
  return undefined;
}
