import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export function LoginDataTypeWorkflowBoxes({ defaultValue, onChange }) {
  const intl = useIntl();

  const [selected, setSelected] = useState(defaultValue);

  const onChangeSelected = (val) => {
    setSelected(val);
    onChange(val);
  };

  const style = {
    card: {
      cursor: "pointer",
      borderBottom: "1px solid var(--secondary)",
      height: 60,
    },
  };

  return (
    <div>
      <div className="row p-0 m-0">
        <div className="col-6 p-0 m-0">
          <div
            onClick={() => onChangeSelected(0)}
            style={{
              ...style.card,
              borderTop: selected === 0 ? "6px solid var(--primary)" : "",
            }}
            className={selected !== 0 ? "bg-light-secondary" : ""}
          >
            <div className="w-100 text-center pt-2 d-flex flex-column">
              <div className={selected === 0 ? "text-primary" : "text-dark"}><span className="fas fa fa-envelope"></span></div>
              <div><span className="text-muted"><FormattedMessage id="PARTY.EMAIL" /></span></div>
            </div>
          </div>
        </div>

        <div className="col-6 p-0 m-0">
          <div
            onClick={() => onChangeSelected(1)}
            style={{
              ...style.card,
              borderTop: selected === 1 ? "6px solid var(--primary)" : "",
            }}
            className={selected !== 1 ? "bg-light-secondary" : ""}
          >
            <div className="w-100 text-center pt-2 d-flex flex-column">
              <div className={selected === 1 ? "text-primary" : "text-dark"}><span className="fas fa fa-phone"></span></div>
              <div><span className="text-muted"><FormattedMessage id="PARTY.PHONE" /></span></div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 mb-3 w-100 text-center">
        <h4>
          <FormattedMessage id="AUTH.LOGIN.BUTTON" />
        </h4>
      </div>
    </div>
  );
}
