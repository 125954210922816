import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { UserNotificationsDropdown as ServiceUserNotificationsDropdown } from "../../../../app/modules/User/components/UserNotificationsDropdown";
import Can from "../../../../app/modules/AWS/Can";
import CompanySelectorDropdown from "../../../../app/modules/Multitenancy/components/CompanySelectorDropdown";
import StoreSelectorDropdown from "../../../../app/modules/Multistore/components/StoreSelectorDropdown";
import { ECRSelectorDropdown } from "../../../../app/modules/Multicashregister/components/ECRSelectorDropdown";
import {HeaderMenuWrapper} from "./header-menu/HeaderMenuWrapper";
//import { TenantSelectorDropdown as PartnerTenantSelectorDropdown } from "../../../../app/modules/RTUtility/components/TenantSelectorDropdown";
//import { ECRSelectorDropdown as PartnerECRSelectorDropdown } from "../../../../app/modules/RTUtility/components/ECRSelectorDropdown";
import { shallowEqual, useSelector } from "react-redux";
import { GetTenant, EnvName } from "../../../../app/utils";

export function Topbar() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  const { user, tenant } = useSelector(
    (state) => ({
      user: state.auth.user,
      tenant: GetTenant(),
    }),
    shallowEqual
  );

  return (
    <div className="topbar w-100">
      <Can I="view" a="metronic">
        {layoutProps.viewSearchDisplay && <SearchDropdown />}
      </Can>

      <Can I="view" a="metronic">
        {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}

        {layoutProps.viewCartDisplay && <MyCartDropdown />}

      </Can>

      <Can I="view" a="metronic">
        {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}
      </Can>

      <div className="topbar w-100 bg-white" style={{boxShadow: "0 0 0 0"}}>
        <div className="d-flex justify-content-between w-100">

          <div className="d-flex align-items-stretch">
            <EnvName />

            <div className="d-none d-sm-block">
              <CompanySelectorDropdown />
            </div>

            <div className="d-none d-sm-block mt-4">
              <StoreSelectorDropdown />
            </div>

            <div className="d-none d-sm-block mt-4">
              <ECRSelectorDropdown />
            </div>

            {tenant && !tenant.isAccountant && (
              <HeaderMenuWrapper />
            )}

            {/*
            <PartnerTenantSelectorDropdown />
            <PartnerECRSelectorDropdown />
            */}
          </div>

          {!user && (
            <div className="text-center">
              <img
                className="img-fluid rounded "
                alt="DTR-Service"
                src={"/media/coristech/logos/dtrservice_white.jpg"}
                style={{ height: "100%" }}
              />
            </div>
          )}

          <div className="d-flex align-items-stretch">
            {/*
            <div className="d-none d-sm-block mt-4">
              <ModuleVisualizationDropdown show={true} />
            </div>
            */}

            {layoutProps.viewNotificationsDisplay && <ServiceUserNotificationsDropdown />}

            {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

            {layoutProps.viewUserDisplay && <QuickUserToggler />}
          </div>

        </div>
      </div>
      
      <div className="d-block d-sm-none topbar w-100 bg-white second-mobile-topbar" style={{zIndex: 0, boxShadow: "0 0 0 0"}}>
        <div className="d-flex justify-content-between w-100">
          <CompanySelectorDropdown />
          <StoreSelectorDropdown />
          <ECRSelectorDropdown />

          {/*
          <ModuleVisualizationDropdown show={true} />
          */}
        </div>
      </div>

    </div>
  );
}
