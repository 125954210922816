import React from "react";
import { Route } from "./Route";
import Can from "../../../modules/AWS/Can";

export function UIRoutes({ UIContext, UIActions, basePath }) {
  return (
    <>
      {UIActions.filter(el => !el.fullPath).map((a, idx) => (
        <div key={"route_" + idx}>
          {a.can && a.can.length == 2 ? (
            <Can I={a.can[0]} a={a.can[1]}>
              <Route 
                UIContext={UIContext}
                basePath={basePath} 
                type={a.type} 
                idSlug={a.idSlug || false}
                Component={a.Component} 
                componentProps={a.componentProps || {}}
              />
            </Can>
          ) : (
            <Route 
              UIContext={UIContext}
              basePath={basePath} 
              type={a.type} 
              idSlug={a.idSlug || false}
              Component={a.Component} 
              componentProps={a.componentProps || {}}
            />
          )}
        </div>
      ))}
    </>
  );
}
