import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

const getValueFromStringPath = (obj, path) => {
  return path.split(".").reduce(function (o, k) {
    return o && o[k];
  }, obj);
};

export function CSVExport({
  items,
  fields,
  headers,
  filename,
  disabled = false,
}) {

  const [isGenerating, setIsGenerating] = useState(false);

  const onClick = async () => {
    setIsGenerating(true);

    const separator = ";";
    const replacer = (key, value) => value === null ? '' : (value + "");
    const csv = [
      headers.join(separator),
      ...items.map(row => {
        return fields.map(fieldName => {
          if (typeof(fieldName) === "function") {
            return JSON.stringify(fieldName(row), replacer);
          }
          return JSON.stringify(getValueFromStringPath(row, fieldName), replacer);
        }).join(separator);
      })
    ].join('\r\n');

    const file = new Blob([csv], { type: "text/csv" });
  
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = filename.replace(/^.*[\\\/]/, "");
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

    setIsGenerating(false);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary mr-2 text-center"
        onClick={() => onClick()}
        disabled={disabled || isGenerating || items.length <= 0}
      >
        <div className="d-flex align-items-center">
          {isGenerating && (
            <div className="spinner spinner-white mr-10"></div>
          )}
          <div className="mr-2">
            <span className="fas fa-file-download"></span>
            {" "}
            <FormattedMessage id="SERVICE.EXPORT_CSV" />
          </div>
        </div>
      </button>
    </>
  );
}
