export default [
  {
    value: "IT",
    label: "IT",
  },
  {
    value: "FR",
    label: "FR",
  },
  {
    value: "ES",
    label: "ES",
  },
  {
    value: "BE",
    label: "BE",
  },
  {
    value: "BG",
    label: "BG",
  },
  {
    value: "CZ",
    label: "CZ",
  },
  {
    value: "DK",
    label: "DK",
  },
  {
    value: "DE",
    label: "DE",
  },
  {
    value: "EE",
    label: "EE",
  },
  {
    value: "IE",
    label: "IE",
  },
  {
    value: "EL",
    label: "EL",
  },
  {
    value: "HR",
    label: "HR",
  },
  {
    value: "CY",
    label: "CY",
  },
  {
    value: "LV",
    label: "LV",
  },
  {
    value: "LT",
    label: "LT",
  },
  {
    value: "LU",
    label: "LU",
  },
  {
    value: "HU",
    label: "HU",
  },
  {
    value: "MT",
    label: "MT",
  },
  {
    value: "NL",
    label: "NL",
  },
  {
    value: "AT",
    label: "AT",
  },
  {
    value: "PL",
    label: "PL",
  },
  {
    value: "PT",
    label: "PT",
  },
  {
    value: "RO",
    label: "RO",
  },
  {
    value: "SI",
    label: "SI",
  },
  {
    value: "SK",
    label: "SK",
  },
  {
    value: "FI",
    label: "FI",
  },
  {
    value: "SE",
    label: "SE",
  },
];
