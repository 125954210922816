export const calendarEventByRetailStore = /* GraphQL */ `
  query CalendarEventByRetailStore(
    $retailStoreId: ID
    $startDatetimeEndDatetime: ModelCalendarEventByRetailStoreCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calendarEventByRetailStore(
      retailStoreId: $retailStoreId
      startDatetimeEndDatetime: $startDatetimeEndDatetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        retailStoreId
        userId
        calendarEventTypeId
        customerId
        notificationEnabled
        notificationDatetime
        notificationSent
        title
        description
        startDatetime
        endDatetime
        tags
        isAllDayEvent
        extensions
        mobile
        email
        deleted
        isPending
        operatorId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          isAccountant
          accountantId
          stripeAccountId
          stripeRegistrationCompleted
          createdAt
          updatedAt
        }
        user {
          id
          partyId
          enabled
          tenantDefault
          retailStoreDefault
          showTutorial
          email
          mobile
          deleted
          firstName
          lastName
          createdAt
          updatedAt
        }
        customer {
          id
          tenantId
          description
          createdByModule
          deleted
          companyName
          companyVat
          firstName
          lastName
          mobile
          visible
          fiscalCode
          email
          zipCode
          county
          municipality
          address
          country
          recipientCode
          extensions
          latitude
          longitude
          addressPlaceId
          loyaltyPoints
          addressNotes
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          accountantId
          createdAt
          updatedAt
        }
        calendarEventType {
          id
          tenantId
          retailStoreId
          name
          color
          notificationEnabled
          notificationAnticipationMinutes
          title
          extensions
          deleted
          createdAt
          updatedAt
        }
        operator {
          id
          tenantId
          retailStoreId
          description
          name
          deleted
          sorting
          createdAt
          updatedAt
        }
        calendarEventItems {
          items {
            id
            tenantId
            retailStoreId
            itemId
            qty
            item {
              id
              name
              description
            }
            calendarEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

