import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

export class AddressMap extends Component {
  initialCenter = () => {
    if (this.props.latitude && this.props.longitude) {
      return {
        lat: this.props.latitude,
        lng: this.props.longitude,
      };
    }
    return {};
  };

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={15}
        disableDefaultUI={true}
        zoomControl={false}
        style={{
          width: "100%",
          height: "60%",
          padding: 0,
          margin: 0,
        }}
        initialCenter={this.initialCenter()}
        scaleControl={false}
        keyboardShortcuts={false}
      >
        <Marker
          position={{
            lat: this.props.latitude,
            lng: this.props.longitude,
          }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(AddressMap);
