export default [
  {
    code: "DW",
    name: "DTouch10",
    maxPlu: 3 * 1000,
    start: "4CIDW",
  },
  {
    code: "DU",
    name: "DFront",
    maxPlu: 3 * 1000,
    start: "4CIDU",
  },
  {
    code: "DL",
    name: "DPalm",
    maxPlu: 3 * 1000,
    start: "4CEDL",
  },
  {
    code: "DH",
    name: "DCash",
    maxPlu: 3 * 1000,
    start: "4CIDH",
  },
  {
    code: "DT",
    name: "Blanca",
    maxPlu: 3 * 1000,
    start: "4CEDT",
  },
  {
    code: "DM",
    name: "DCash+",
    maxPlu: 3 * 1000,
    start: "4CEDM",
  },
  {
    code: "DR",
    name: "DFlex",
    maxPlu: 30 * 1000,
    start: "4CIDR",
  },
];
