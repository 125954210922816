import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function ToastNotify(type, text, position = "bottom-center", style = undefined) {
  const options = {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    style: style || undefined,
  };

  switch (type) {
    case "success":
      toast.success(text, options);
      break;
    case "warning":
      toast.warning(text, options);
      break;
    case "error":
      toast.error(text, options);
      break;
    case "dark":
      toast.dark(text, options);
      break;
    case "info":
    default:
      toast.info(text, options);
      break;
  }
}
