export function FromTimeToDate(time) {
  var currentValue = new Date();
  const splittedTime = time.split(":");

  currentValue.setHours(splittedTime[0]);
  currentValue.setMinutes(splittedTime[1]);
  currentValue.setSeconds(0);

  return currentValue;
}
