import { SET_CURRENT_ECR, SET_CURRENT_ECR_ASYNC, REFRESH } from "./types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  currentCashRegisterId: "",
};

export const cashRegisterReducer = persistReducer(
  { storage, key: "cashregister" },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_CURRENT_ECR: {
        const returnProps = {
          ...state,
        };
        return returnProps;
      }

      case SET_CURRENT_ECR_ASYNC: {
        const returnProps = {
          ...state,
          currentCashRegisterId: action.payload,
        };
        return returnProps;
      }

      case REFRESH: {
        const returnProps = {
          ...state,
        };
        window.location.reload();
        return returnProps;
      }

      default:
        return state;
    }
  }
);
