import React from "react";

const ActivatedStatusCssClasses = ["success", "danger", ""];
const ActivatedStatusTitles = ["Valida", "Scaduta", ""];

export function ActivatedStatusColumnFormatter(cellContent, row) {
  const getStatus = () => {
    const validFrom = new Date(row.validFrom);
    const validTo = new Date(row.validTo);
    const today = new Date();

    if (today > validFrom && today < validTo) {
      return 0;
    }
    return 1;
  };

  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      ActivatedStatusCssClasses[getStatus()]
    } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {ActivatedStatusTitles[getStatus()]}
    </span>
  );
}
