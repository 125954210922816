export function TimeAgo(date, intl) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " " + intl.formatMessage({ id: "SERVICE.YEARS_AGO" });
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " " + intl.formatMessage({ id: "SERVICE.MONTHS_AGO" });
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " " + intl.formatMessage({ id: "SERVICE.DAYS_AGO" });
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " " + intl.formatMessage({ id: "SERVICE.HOURS_AGO" });
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " " + intl.formatMessage({ id: "SERVICE.MINUTES_AGO" });
  }
  return 0 + " " + intl.formatMessage({ id: "SERVICE.MINUTES_AGO" });
}
