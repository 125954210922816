import React, { useContext, useState, useEffect } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

function Arrow({
  children,
  disabled,
  onClick
}) {
  return (
    <>
      {!disabled ? (
        <button
          disabled={disabled}
          onClick={onClick}
          className="btn btn-light"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            right: "1%",
            marginTop: 65,
            opacity: disabled ? "0" : "1",
            userSelect: "none"
          }}
        >
          {children}
        </button>
      ) : (
        <></>
      )}
    </>
  );
}

export function LeftScrollerArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <i className="fa fa-arrow-left"></i>
    </Arrow>
  );
}

export function RightScrollerArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = useContext(
    VisibilityContext
  );

  const [disabled, setDisabled] = useState(
    !visibleElements.length && isLastItemVisible
  );
  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <i className="fa fa-arrow-right"></i>
    </Arrow>
  );
}
