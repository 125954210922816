import { toAbsoluteUrl } from "../_helpers";

export const languages = [
  {
    lang: "it",
    name: "Italiano",
    flag: toAbsoluteUrl("/media/svg/flags/013-italy.svg"),
  },
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/svg/flags/012-uk.svg"),
  },
  {
    lang: "fr",
    name: "French",
    flag: toAbsoluteUrl("/media/svg/flags/195-france.svg"),
  },
  {
    lang: "de",
    name: "German",
    flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg"),
  },
  {
    lang: "es",
    name: "Spanish",
    flag: toAbsoluteUrl("/media/svg/flags/128-spain.svg"),
  },
  {
    lang: "zh",
    name: "Mandarin",
    flag: toAbsoluteUrl("/media/svg/flags/015-china.svg"),
  },
  {
    lang: "ar",
    name: "Arabic",
    flag: toAbsoluteUrl("/media/svg/flags/008-saudi-arabia.svg"),
  },
  {
    lang: "ru",
    name: "Russian",
    flag: toAbsoluteUrl("/media/svg/flags/013-russia.svg"),
  },
];