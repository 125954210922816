import React from "react";
import { Aside } from "../../../AuthCognito/components/Aside";
import { DTRServiceLogo } from "../dashboard/components/DTRServiceLogo";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

export function CookiePolicy() {
  const intl = useIntl();
  const history = useHistory();

  const url = "https://www.dtr-service.it";

  //Warning: This is the PRODUCTION S3 BUCKET
  const documentUrl =
    "https://coristechda3c2209cf6940b7976e4d383712a5ea142526-prod.s3.eu-central-1.amazonaws.com/public/tos/COOKIE_POLICY.pdf";

  const chromeUrl =
    "https://support.google.com/chrome/bin/answer.py?hl=it&answer=95647&p=cpn_cookies";
  const firefoxUrl =
    "http://support.mozilla.org/it-IT/kb/Enabling%20and%20disabling%20cookies";
  const safariUrl =
    "http://support.apple.com/kb/PH17191?viewlocale=it_IT&locale=it_IT";

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
        id="kt_login"
      >
        <Aside />

        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
          <div className="container-fluid p-0 m-0">
            <button
              type="button"
              onClick={() => history.goBack()}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              <FormattedMessage id="SERVICE.BACK" />
            </button>
          </div>

          <DTRServiceLogo dynamic={false} />

          <div className="row">
            <div className="col-0 col-md-1"></div>
            <div className="col-12 col-md-10">
              <h5 className="text-center">
                Informativa cookie del sito web DTR-Service
              </h5>
              <br />
              <p className="text-justify">
                La presente informativa è resa in conformità al Regolamento (UE)
                n. 2016/679 (“GDPR”) e alle norme attualmente vigenti del d.lgs.
                196/2003 (c.d. Codice Privacy) al fine di rendere edotto
                l’Utente-Interessato del sito{" "}
                <a href={url} target="_blank">
                  {url}
                </a>{" "}
                (in seguito il “Sito”) sull’impiego di cookie durante la
                navigazione sul Sito. Questo documento potrà subire delle
                modifiche per eventuali variazioni delle finalità di trattamento
                oppure per modifiche della normativa sulla protezione dei dati e
                per evoluzioni tecnologiche eventualmente sopravvenute. In tal
                caso, alle versioni aggiornate verrà data pubblicità su questo
                Sito.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Titolare del Trattamento dei Dati.
                </span>
                <br />
                Il Titolare del trattamento dei dati è DTR Italy s.r.l. con sede
                legale a Milano 20131, via G. Luosi, 14 (di seguito identificata
                come "DTR" o “Titolare”), ed è contattabile all’indirizzo
                e-mail: gdpr@dtr-italy.com.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Cosa sono e a cosa servono i “cookie”?
                </span>
                <br />I cookie sono informazioni (piccole stringhe di testo)
                immesse nel browser (ad es. Firefox, Chrome, Safari, ecc)
                dell’Utente quando viene visitato il Sito. Svolgono diverse e
                importanti funzioni nell’ambito della rete (esecuzione di
                autenticazioni informatiche, monitoraggio di sessioni,
                memorizzazione di informazioni su specifiche configurazioni
                riguardanti gli utenti che accedono al server, memorizzazione
                delle preferenze, ecc.). Nel corso della navigazione l’Utente
                potrebbe ricevere sul suo terminale anche cookie di siti diversi
                (c.d. cookies di “terze parti”), impostati direttamente da
                gestori di detti siti web e utilizzati per le finalità e secondo
                le modalità da questi definiti.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Tipologia ed elenco di cookie utilizzati dal presente Sito
                </span>
                <br />
                Esistono diversi tipi di cookie che contengono informazioni
                differenti e svolgono funzioni diverse (es. esecuzione di
                autenticazioni informatiche, monitoraggio di sessioni,
                memorizzazione di preferenze per l’invio di messaggi
                pubblicitari mirati, etc). Ogni cookie, a sua volta, contiene
                diversi dati (es. il nome del server da cui proviene, un
                identificatore numerico, etc.). Tali dati possono rimanere nel
                dispositivo dell’Utente per diversi periodi di tempo – una
                sessione del browser, qualche ora, diversi giorni o per periodi
                più lunghi.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Questo sito utilizza solo cookie tecnici necessari alla
                  navigazione
                </span>
                <br />
                questi cookie servono a effettuare la navigazione o a fornire un
                servizio richiesto dall’Utente. Non vengono utilizzati per scopi
                ulteriori. I cookie tecnici sono necessari per il corretto
                funzionamento del Sito. Senza il ricorso a tali cookie, alcune
                operazioni non potrebbero essere compiute o sarebbero più
                complesse e/o meno sicure. Pertanto, i cookie tecnici risultano
                indispensabili.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Modalità del trattamento
                </span>
                <br />
                Il trattamento è effettuato con strumenti automatizzati dal
                Titolare. Non viene effettuata alcuna diffusione o
                comunicazione.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Conferimento dei dati e del consenso
                </span>
                <br />I cookie tecnici non richiedono il consenso dell’Utente,
                in quanto sono necessari per il corretto funzionamento del Sito.
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Disabilitazione dei cookie
                </span>
                <br />
                Fermo restando quanto sopra indicato in ordine ai cookie
                strettamente necessari alla navigazione, l’utente può eliminare
                in generale i cookie direttamente tramite il proprio browser.
                Ciascun browser presenta procedure diverse per la gestione delle
                impostazioni. Di seguito riportiamo i link ai quali è possibile
                trovare le istruzioni sulle modalità di disabilitazione dei
                cookies per I browser più diffusi:
                <br />• Google Chrome:{" "}
                <a href={chromeUrl} target="_blank">
                  {chromeUrl}
                </a>
                <br />• Mozilla Firefox:{" "}
                <a href={firefoxUrl} target="_blank">
                  {firefoxUrl}
                </a>
                <br />• Apple Safari:{" "}
                <a href={safariUrl} target="_blank">
                  {safariUrl}
                </a>
                <br />
              </p>

              <br />
              <p className="text-justify">
                <span className="font-weight-bold">
                  Informativa sul trattamento dei dati personali
                </span>
                <br />
                Per le ulteriori informazioni sui trattamenti di dati personali
                raccolti nel presente sito effettuati dal Titolare o per
                consultare la sezione relativa ai diritti dell’interessato, è
                possibile consultare la Privacy Policy del sito web disponibile{" "}
                <a
                  href="https://www.dtr-service.it/privacy-policy/"
                  target="_blank"
                >
                  qui
                </a>
                .
              </p>

              <br />
              <p>Questa informativa è aggiornata a aprile 2021.</p>

              <br />
              <p>
                <a href={documentUrl} download target="_blank">
                  SCARICA IL DOCUMENTO
                </a>
              </p>
            </div>
            <div className="col-0 col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
