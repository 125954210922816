import React from "react";
import { ToItalianTime } from "../";

export const ToItalianTimeColumnFormatter = (
	cellContent,
	row,
	rowIndex,
	{ fieldName }
) => (
	<span>
		{row && row?.[fieldName] ? ToItalianTime(new Date(row?.[fieldName])) : "-"}
	</span>
);
