import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storeData: {},
  deskData: {},
  sessionId: undefined,
  customerName: "",
  ordinationsPassword: "",
  cart: null, //(id, lines[id, itemId, qty, unitPrice, notes])
};

export const slice = createSlice({
  name: "atorder_public",
  initialState: initialState,
  reducers: {
    storeDataFetched: (state, action) => {
      state.storeData = action.payload;
    },
    deskDataFetched: (state, action) => {
      state.deskData = action.payload;
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    setCustomerName: (state, action) => {
      state.customerName = action.payload;
    },
    setOrdinationsPassword: (state, action) => {
      state.ordinationsPassword = action.payload;
    },
    addOnCart: (state, action) => {
      state.cart = {
        id: state.cart?.id || null,  
        lines: [
          ...(state.cart?.lines?.filter(el => el?.itemId !== action.payload.itemId) || []),
          {
            id: null,
            qty: action.payload.qty,
            notes: action.payload.notes,
            itemId: action.payload.itemId,
            unitPrice: action.payload.unitPrice,
          }
        ]
      };
    },
    removeItemFromCart: (state, action) => {
      state.cart = {
        ...state.cart,
        lines: state.cart.lines?.filter(el => el.itemId !== action.payload.itemId) || [],
      };
    },
    cleanCart: (state, action) => {
      state.cart = null;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
    },

    setCartOrderType: (state, action) => {
      state.cart = {
        ...(state.cart || {}),
        orderType: action.payload,
      };
    },
    setCartTime: (state, action) => {
      state.cart = {
        ...(state.cart || {}),
        time: action.payload,
      };
    },
    setPaymentType: (state, action) => {
      state.cart = {
        ...(state.cart || {}),
        paymentType: action.payload,
      };
    },
    setPhoneNumber: (state, action) => {
      state.cart = {
        ...(state.cart || {}),
        phoneNumber: action.payload,
      };
    },
    setCartCustomerName: (state, action) => {
      state.cart = {
        ...(state.cart || {}),
        customerName: action.payload,
      };
    },
    setCustomerSurname: (state, action) => {
      state.cart = {
        ...(state.cart || {}),
        customerSurname: action.payload,
      };
    },
    setCustomerAddress: (state, action) => {
      state.cart = {
        ...(state.cart || {}),
        customerAddress: action.payload?.address,
        customerCountry: action.payload?.county,
        customerCounty: action.payload?.country,
        customerMunicipality: action.payload?.municipality,
        customerZipCode: action.payload?.zipCode,
        customerAddressPlaceId: action.payload?.placeId,
      };
    },
    setAddressNote: (state, action) => {
      state.cart = {
        ...(state.cart || {}),
        customerAddressNotes: action.payload,
      };
    },
    
  },
});
