import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
//import { GoogleSignInButton } from "../../../utils";

export function RegistrationMethodBoxes({ defaultValue, onChange }) {
  const intl = useIntl();

  const [selected, setSelected] = useState(defaultValue);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const onChangeSelected = (val) => {
    setSelected(val);
    onChange(val);
  };

  const onGoogleSelect = () => {
    setIsGoogleLoading(true);
    onChangeSelected(2);
  };

  return (
    <>
      <div className="w-100 my-4">
        <h3 className="text-center">
          <FormattedMessage id="SERVICE.AUTH.REGISTATION_STEPPER_2" />
        </h3>
        <div className="text-center text-muted my-4">
          <div className="row">
            <div className="col-0 col-lg-4 col-md-3"></div>
            <div className="col-12 col-lg-4 col-md-6">
              <FormattedMessage id="SERVICE.AUTH.REGISTATION_SUB_STEPPER_2" />
            </div>
            <div className="col-0 col-lg-4 col-md-3"></div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4 col-0"></div>
        <div className="col-md-4 col-12">
          <div className="row text-center">
            <div className="col-1"></div>
            <div className="col-10">
              <button
                type="button"
                onClick={() => onChangeSelected(0)}
                className="btn bg-white w-100 text-dark m-2 border border-1 border-light-primary rounded"
              >
                <div className="d-flex justify-content-between">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Sending mail.svg")} />
                  <div className="flex-grow-1"><span><FormattedMessage id="PARTY.EMAIL" /></span></div>
                </div>
              </button>

              <button
                type="button"
                onClick={() => onChangeSelected(1)}
                className="btn bg-white w-100 text-dark m-2 border border-1 border-light-primary rounded"
              >
                <div className="d-flex justify-content-between">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Phone.svg")} />
                  <div className="flex-grow-1"><span><FormattedMessage id="PARTY.PHONE" /></span></div>
                </div>
              </button>

              {/*
              <GoogleSignInButton
                type="register"
                className="m-2 bg-white text-dark border border-1 border-light-primary rounded"
                iconPosition="left"
                isLoading={isGoogleLoading}
                onClick={() => onGoogleSelect()}
              />
              */}
            </div>
            <div className="col-1"></div>
          </div>
        </div>
        <div className="col-md-4 col-0"></div>
      </div>
    </>
  );
}
