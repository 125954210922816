import * as Yup from "yup";

export function YupTimeValidator(intl, required = true) {
  var result = Yup.string().test(
    "checkTime",
    intl.formatMessage({ id: "SERVICE.INVALID_TIME" }),
    (value) => {
      if (!value || typeof value !== "string" || !value.includes(":")) {
        return false;
      }

      var splitted = value.split(":");
      if (!splitted || splitted.length !== 2) {
        return false;
      }

      try {
        const h = parseInt(splitted[0]);
        const m = parseInt(splitted[1]);

        if (h < 0 || h > 24 || m < 0 || m > 60) {
          return false;
        }
      } catch (e) {
        return false;
      }

      return true;
    }
  );

  if (required) {
    result = result.required(
      intl.formatMessage({ id: "SERVICE.TIME_IS_MANDATORY" })
    );
  }

  return result;
}
