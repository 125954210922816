import React, { useEffect } from "react";
import { default as ReactBarcode } from "react-barcode";

export function Barcode({ value, height = "100%" }) {
  useEffect(() => {
    document.querySelectorAll('.barcode-wrapper').forEach(function(element) {
      if (element.childNodes.length > 0) {
        element.childNodes[0].setAttribute('width', '100%');
        element.childNodes[0].removeAttribute('height', "100%");
      }
    });
  }, [value]);

  return (
    <div className="w-100 barcode-wrapper" >
      <ReactBarcode 
        value={value} 
        height={height}
      /> 
    </div>
  );
}
