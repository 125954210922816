import { createSlice } from "@reduxjs/toolkit";
import { original } from "immer";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: {
    items: [],
    nextToken: undefined,
    prevTokens: [],
  },
  entity: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "dailysales",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${JSON.stringify(action.payload.error)}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    objectFetched: (state, action) => {
      state.actionsLoading = false;
      state.entity = action.payload.entity;
      if (action.payload.entity) {
        state.entity = {
          ...action.payload.entity,
          content: JSON.parse(action.payload.entity.content),
        };
      }
      state.error = null;
    },
    objectsFetched: (state, action) => {
      const { totalCount, items, nextToken, prevToken, operation } =
        action.payload;
      state.listLoading = false;
      state.error = null;

      switch (operation) {
        case "overwrite":
          const lastPrevElement =
            state.entities.prevTokens[state.entities.prevTokens.length - 1];
          if (
            state.entities.prevTokens.includes(prevToken) &&
            !lastPrevElement
          ) {
            state.entities = {
              items: items.map((el) => {
                return {
                  ...el,
                  content: JSON.parse(el.content),
                };
              }),
              nextToken: lastPrevElement,
              prevTokens: state.entities.prevTokens.filter(
                (el) => el !== lastPrevElement
              ),
            };
          } else {
            state.entities = {
              items: items.map((el) => {
                return {
                  ...el,
                  content: JSON.parse(el.content),
                };
              }),
              nextToken: nextToken,
              prevTokens: [...state.entities.prevTokens, prevToken],
            };
          }
          break;
        case "append":
          const itemsToAppend = items.map((el) => {
            return {
              ...el,
              content: JSON.parse(el.content),
            };
          });
          state.entities = {
            items: [...original(state.entities.items), ...itemsToAppend],
            nextToken: nextToken,
            prevTokens: [...state.entities.prevTokens, prevToken],
          };
          break;
        default:
          break;
      }

      state.totalCount = totalCount;
    },
    clearData: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.totalCount = 0;
      state.entities = {
      items: [],
      nextToken: undefined,
      prevTokens: [],
      };
      state.entity = undefined;
      state.lastError = null;
    },
  },
});
