import React from "react";
import Can from "../../AWS/Can";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { getMenuItemActive } from "../../../utils";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Aside() {
  const location = useLocation();

  return (
    <Can I="view" a="forvalue">
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(location, ["/forvalue"])}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <NavLink className={"menu-link menu-toggle "} to="/forvalue">
          <span className="svg-icon menu-icon">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Communication/Group.svg"
              )}
            />
          </span>
          <span className={"menu-text "}>
            <FormattedMessage id="SERVICE.ASIDE.FOR_VALUE" />
          </span>
        </NavLink>
        <div className="menu-submenu">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            <li className="menu-item menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text">
                  <FormattedMessage id="SERVICE.ASIDE.FOR_VALUE" />
                </span>
              </span>
            </li>

            <Can I="manage" a="forvalue_customers">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/forvalue/customers"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/forvalue/customers">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.FOR_VALUE.CUSTOMERS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="forvalue_cards">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/forvalue/cards"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/forvalue/cards">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.FOR_VALUE.CARDS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="forvalue_settings">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/forvalue/settings"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/forvalue/settings">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.FOR_VALUE.SETTINGS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="view" a="forvalue_stats">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/forvalue/stats"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/forvalue/stats">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.FOR_VALUE.STATS" />
                  </span>
                </NavLink>
              </li>
            </Can>

          </ul>
        </div>
      </li>
    </Can>
  );
}
