/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ServiceHeaderMenu } from "../../../../../app/modules/AWS/components/ServiceHeaderMenu";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses} p-0`}>
            {/*begin::1 Level*/}
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel`}>
                <NavLink className="menu-link menu-toggle" to="#">
                    <span className="menu-text"><FormattedMessage id="SERVICE.CONFIGURATIONS" /></span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">

                        <ServiceHeaderMenu />

                    </ul>
                </div>
            </li>
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
