import React from "react";
import Can from "../../AWS/Can";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { getMenuItemActive } from "../../../utils";
import { NavLink } from "react-router-dom";

export function Menu() {
  const location = useLocation();

  return (
    <Can I="view" a="retail">
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(
          location,
          "/retail"
        )}`}
        data-menu-toggle="hover"
        aria-haspopup="true"
      >
        <NavLink className="menu-link menu-toggle" to="/retail">
          <span className="fas fa fa-building text-primary mr-2"></span>
          {" "}
          <span className="menu-text">
            <FormattedMessage id="SERVICE.ASIDE.RETAIL" />
          </span>
          <i className="menu-arrow" />
        </NavLink>
        <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
          <ul className="menu-subnav">
            <Can I="view" a="store">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/retail/stores"
                )}`}
              >
                <NavLink className="menu-link" to="/retail/stores">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="SERVICE.ASIDE.RETAIL.STORES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            {/*
                        <Can I="view" a="room">
                            <li className={`menu-item ${getMenuItemActive(location, '/retail/rooms')}`}>
                                <NavLink className="menu-link" to="/retail/rooms">
                                    <i className="menu-bullet menu-bullet-dot"><span /></i>
                                    <span className="menu-text">
                                        <FormattedMessage id="SERVICE.ASIDE.RETAIL.ROOMS" />
                                    </span>
                                </NavLink>
                            </li>
                        </Can>

                        <Can I="view" a="location">
                            <li className={`menu-item ${getMenuItemActive(location, '/retail/locations')}`}>
                                <NavLink className="menu-link" to="/retail/locations">
                                    <i className="menu-bullet menu-bullet-dot"><span /></i>
                                    <span className="menu-text">
                                        <FormattedMessage id="SERVICE.ASIDE.RETAIL.LOCATIONS" />
                                    </span>
                                </NavLink>
                            </li>
                        </Can>
                        */}

            <Can I="view" a="ecr">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/retail/cashregisters"
                )}`}
              >
                <NavLink className="menu-link" to="/retail/cashregisters">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="SERVICE.ASIDE.RETAIL.RECORDERS" />
                  </span>
                </NavLink>
              </li>
            </Can>
          </ul>
        </div>
      </li>
    </Can>
  );
}
