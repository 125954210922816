import React from "react";
import { ToastContainer as ReactToastContainer } from "react-toastify";

export function ToastContainer() {
  return (
    <ReactToastContainer
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover={false}
    />
  );
}
