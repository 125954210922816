import React from "react";
import Can from "../../AWS/Can";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { getMenuItemActive } from "../../../utils";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Aside() {
  const location = useLocation();

  return (
    <Can I="view" a="dtroptical">
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(location, [
          "/dtroptical",
        ])}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <NavLink className={"menu-link menu-toggle "} to="/dtroptical">
          <span className="svg-icon menu-icon">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Clothes/Sun-glasses.svg"
              )}
            />
          </span>
          <span className={"menu-text "}>
            <FormattedMessage id="SERVICE.ASIDE.DTROPTICAL" />
          </span>
        </NavLink>
        <div className="menu-submenu">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            <li className="menu-item menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text">
                  <FormattedMessage id="SERVICE.ASIDE.DTROPTICAL" />
                </span>
              </span>
            </li>

            <Can I="manage" a="dtroptical_composer">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/dtroptical/composer"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/dtroptical/composer">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.DTROPTICAL.COMPOSER" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="dtroptical_files">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/dtroptical/files"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/dtroptical/files">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.DTROPTICAL.FILES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="dtroptical_upload">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/dtroptical/upload"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/dtroptical/upload">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.DTROPTICAL.UPLOAD" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="dtroptical_vat">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/dtroptical/vatrates"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/dtroptical/vatrates">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.ACCOUNTING.VAT_RATES" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="dtroptical_departments">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/dtroptical/departments"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/dtroptical/departments">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.ACCOUNTING.DEPARTMENTS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="dtroptical_items">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/dtroptical/items"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/dtroptical/items">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.ITEMS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="dtroptical_customers">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/dtroptical/customers"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/dtroptical/customers">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.MOITO_IOT.CUSTOMERS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <Can I="manage" a="dtroptical_settings">
              <li
                className={`menu-item ${getMenuItemActive(
                  location,
                  "/dtroptical/settings"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className={"menu-link "} to="/dtroptical/settings">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className={"menu-text "}>
                    <FormattedMessage id="SERVICE.ASIDE.DTROPTICAL.SETTINGS" />
                  </span>
                </NavLink>
              </li>
            </Can>

            <li
              className={`menu-item ${getMenuItemActive(
                location,
                "/dtroptical/help"
              )}`}
              aria-haspopup="true"
            >
              <NavLink className={"menu-link "} to="/dtroptical/help">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className={"menu-text "}>
                  <FormattedMessage id="SERVICE.ASIDE.RT_UTILITY.ONLINE_HELP" />
                </span>
              </NavLink>
            </li>
            
          </ul>
        </div>
      </li>
    </Can>
  );
}
