import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Aside() {
  const today = new Date().getFullYear();

  return (
    <div
      className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
      style={{
        backgroundColor: "#1a1a27",
      }}
    >
      <div className="d-flex flex-row-fluid flex-column justify-content-between ">
        <Link to="/" className="flex-column-auto mt-5">
          <img
            alt="Logo"
            className="max-h-100px"
            src={toAbsoluteUrl("/media/coristech/logos/dtrservice_dark.jpg")}
          />
        </Link>

        <div className="flex-column-fluid d-flex flex-column justify-content-center">
          <h3 className="font-size-h1 mb-5 text-white">DTR-Service</h3>
          <p className="font-weight-lighter text-white opacity-80">
            Un nuovo mondo di servizi
          </p>
        </div>

        <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
          <div className="text-white">
            DTR Italy S.r.l. <br />
            Sede Legale: Via Giuseppe Luosi 14 - 20131 Milano <br />
            Sede Operativa: Via Lussemburgo 28 - 21013 Gallarate (VA) <br />
            P.IVA/C.F. 10244350962 <br />
            commerciale@dtr-italy.com
          </div>
          <div className="d-flex flex-column">
            <Link to="/privacy-policy" className="text-white p-1">
              <FormattedMessage id="SERVICE.PRIVACY_POLICY" />
            </Link>
            <br />
            <Link to="/cookie-policy" className="text-white p-1">
              <FormattedMessage id="SERVICE.COOKIE_POLICY" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
