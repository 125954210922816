import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	customerId: undefined,
	initialData: {},
};
export const slice = createSlice({
	name: "booking",
	initialState: initialState,
	reducers: {
		setCustomerId: (state, action) => {
			state.customerId = action.payload;
		},
		initialDataFetched: (state, action) => {
			state.initialData = action.payload;
		},
	},
});