import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actions } from "../_redux/actions";
import { actions as retailStoreLicenseActions } from "../../Admin/_redux/retailstorelicenses/actions";
import { actions as dailySalesActions } from "../../RTUser/_redux/dailysales/actions";
import { DropdownTopbarItemToggler } from "../../../../_metronic/_partials/dropdowns";
import { FormattedMessage } from "react-intl";
import { GetTenant, GetRetailStore } from "../../../utils";
import { useHistory } from "react-router-dom";

export function ECRSelectorDropdown() {
  const history = useHistory();

  const { entities, tenant, store, ecrId } = useSelector(
    (state) => ({
      entities: state.admin.retailstorelicenses.entities.items,

      tenant: GetTenant(),
      store: GetRetailStore(),
      ecrId: state.cashregister.cashregister.currentCashRegisterId,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (store && tenant && history.location.pathname.includes("rt_user")) {
      var today = new Date();
      today = today.toISOString().substring(0, 10);

      const queryParams = {
        pageSize: 1000,
        filter: {
          validTo: { gt: today },
        },
      };

      dispatch(retailStoreLicenseActions.fetchObjectsByLicenseRetailStore(
        "9eae171e-93df-4b42-a2ee-41014e1a5d1c",
        store.id,
        queryParams, 
        null, 
        null, 
        "overwrite"
      ));
    }
  }, [history.location]);

  const getCashRegistersFromRetailStoreLicenses = () => {
    return entities
      .filter(el => !el.number.includes("_DELETED"))
      .filter(el => el.retailStoreId === store.id)
      .map(el => {
        return {
          id: el.electronicCashRegisterId,
          number: el.number,
        };
      })
      .filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i); //remove duplicates
  };

  useEffect(() => {
    const selectableCashRegisters = getCashRegistersFromRetailStoreLicenses();

    var doAutoselect = false;
    if (!ecrId) {
      doAutoselect = true;
    }
    else {
      const found = selectableCashRegisters.find(el => el.id === ecrId);
      if (!found) {
        doAutoselect = true;        
      }
    }
    
    if (
      entities.length > 0 &&
      doAutoselect &&
      history.location.pathname.includes("rt_user")
    ) {
      if (selectableCashRegisters.length > 0) {
        dispatch(actions.setCurrentCashRegisterAsync(selectableCashRegisters[0].id));
      }
    }
  }, [entities, history.location]);

  const getNumberById = (id) => {
    const entity = entities.find((el) => el.electronicCashRegisterId === id);
    if (entity) {
      return entity.number;
    }
    return "";
  };

  return (
    <>
      {ecrId && store && history.location.pathname.includes("rt_user") && (
        <Dropdown drop="down">
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="dropdown-toggle-my-cart"
            className="w-100"
          >
            <OverlayTrigger
              placement="bottom"
              className="w-100"
              overlay={
                <Tooltip id="language-panel-tooltip">
                  <FormattedMessage id="SERVICE.SELECT_ECR_NUMBER" />
                </Tooltip>
              }
            >
              <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 w-100 text-center">
                <div className="bg-secondary rounded p-2 w-100 text-center text-dark">
                  {getNumberById(ecrId)}
                </div>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
            <ul className="navi navi-hover py-4">
              {getCashRegistersFromRetailStoreLicenses()
                .map((ecr) => (
                  <li key={ecr.id} className="navi-item">
                    <a
                      href="/#"
                      onClick={() => {
                        dispatch(dailySalesActions.clearData());
                        dispatch(actions.setCurrentCashRegister(ecr.id));
                      }}
                      className="navi-link"
                    >
                      <span className="navi-text">{ecr.number}</span>
                    </a>
                  </li>
                ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
