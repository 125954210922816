import * as Yup from "yup";

export function YupAddressPickerValidation(
  address,
  country,
  county,
  zipCode,
  municipality,
  intl,
  mandatory = true
) {
  var addressVal = Yup.string();
  if (mandatory) {
    addressVal = addressVal.required(
      intl.formatMessage({ id: "PARTY.ADDRESS_MANDATORY" })
    );
  }

  var countryVal = Yup.string().length(
    2,
    intl.formatMessage({ id: "PARTY.FIELD_LENGTH_2_CHARS" })
  );
  if (mandatory) {
    countryVal = countryVal.required(
      intl.formatMessage({ id: "PARTY.COUNTRY_MANDATORY" })
    );
  }

  var countyVal = Yup.string().length(
    2,
    intl.formatMessage({ id: "PARTY.FIELD_LENGTH_2_CHARS" })
  );
  if (mandatory) {
    countyVal = countyVal.required(
      intl.formatMessage({ id: "PARTY.COUNTY_MANDATORY" })
    );
  }

  var zipCodeVal = Yup.string();
  if (mandatory) {
    zipCodeVal = zipCodeVal.required(
      intl.formatMessage({ id: "PARTY.ZIPCODE_MANDATORY" })
    );
  }

  var municipalityVal = Yup.string();
  if (mandatory) {
    municipalityVal = municipalityVal.required(
      intl.formatMessage({ id: "PARTY.MUNICIPALITY_MANDATORY" })
    );
  }

  return {
    [address]: addressVal,
    [country]: countryVal,
    [county]: countyVal,
    [zipCode]: zipCodeVal,
    [municipality]: municipalityVal,
  };
}
