export function YYYYMMDD_HHMM(date, separator = "-") {
  var year = "" + date.getFullYear();
  var month = "" + (date.getMonth() + 1);
  var day = "" + date.getDate();
  var hour = "" + date.getHours();
  var minute = "" + date.getMinutes();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  if (hour.length < 2) {
    hour = "0" + hour;
  }
  if (minute.length < 2) {
    minute = "0" + minute;
  }

  return year + separator + month + separator + day + " " + hour + ":" + minute;
}
