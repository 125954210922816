import React from "react";
import { Menu as AdminMenu } from "../../Admin/components/Menu";
import { Menu as RetailMenu } from "../../Retail/components/Menu";
import { Menu as DTRBIMenu } from "../../DTRBI/components/Menu";

export function ServiceHeaderMenu() {
  return (
    <>
      <AdminMenu />
      <RetailMenu />
      <DTRBIMenu />
      {/*
			<GlobalSettingsMenu />
			<ProductMenu />
			<CustomerMenu />
			<SalesMenu />
			<AccountingMenu />
			*/}
    </>
  );
}
