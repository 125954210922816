import React, { useState, useEffect } from "react";

export function HourMinuteTimePicker({ 
  defaultValue = "00:00", 
  label,
  onChange,
  maxHour = 23,
  minHour = 0,
  required = false,
  minuteSteps = 5,
}) {
  const [hour, setHour] = useState(parseInt(defaultValue.split(":")[0]));
  const [minute, setMinute] = useState(parseInt(defaultValue.split(":")[1]));

  useEffect(() => {
    const paddedHour = (hour + "").padStart(2, "0");
    const paddedMinute = (minute + "").padStart(2, "0");

    onChange(paddedHour + ":" + paddedMinute);
  }, [hour, minute]);

  const onHourUp = () => {
    setHour(old => old >= maxHour ? minHour : (old + 1));
  }

  const onHourDown = () => {
    setHour(old => old >= (minHour + 1) ? (old - 1) : minHour);
  }

  const onMinuteUp = () => {
    setMinute(old => old >= (60 - minuteSteps) ? 0 : (old + minuteSteps));
  }

  const onMinuteDown = () => {
    setMinute(old => old >= minuteSteps ? (old - minuteSteps) : (60 - minuteSteps));
  }

  return (
    <>
      <label>{label}</label>
      {required && (
        <>
          {" "}
          <strong className="text-danger">*</strong>
        </>
      )}
      <br/>
      <div className="row">
        <div className="col-5 d-flex flex-column">
          <button
            type="button"
            className="btn btn-transparent rounded "
            onClick={onHourUp}
          >
            <i className="fas fa fa-chevron-up"></i>
          </button>

          <input 
            type="text" 
            value={(hour + "").padStart(2, "0")} 
            disabled
            className="text-center"
          />

          <button
            type="button"
            className="btn btn-transparent rounded "
            onClick={onHourDown}
          >
            <i className="fas fa fa-chevron-down"></i>
          </button>
        </div>

        <div className="col-2 text-center align-self-center">
          <div >
            <span>{" : "}</span>  
          </div>
        </div>

        <div className="col-5 d-flex flex-column">
          <button
            type="button"
            className="btn btn-transparent rounded "
            onClick={onMinuteUp}
          >
            <i className="fas fa fa-chevron-up"></i>
          </button>

          <input 
            type="text" 
            value={(minute + "").padStart(2, "0")} 
            disabled
            className="text-center"
          />

          <button
            type="button"
            className="btn btn-transparent rounded "
            onClick={onMinuteDown}
          >
            <i className="fas fa fa-chevron-down"></i>
          </button>
        </div>
      </div>
    </>
  );
}
