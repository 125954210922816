import { API, graphqlOperation } from "aws-amplify";
/*
import {
  searchTenants,
  listTenants,
  tenantsByDefaultPartnerCode,
  tenantsByShortCode,
  tenantsByCompanyVat,
  getTenant,
} from "../../../../../graphql/queries";
*/
import {
  updateTenant,
} from "../../../../../graphql/mutations";
import * as customQueries from "../../graphql/private/queries";

export function searchObjects(queryParams, additionalFilters, nextToken) {
  var params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  
  if (queryParams.sortField && queryParams.sortOrder) {
    params.sort = {
      field: queryParams.sortField,
      direction: queryParams.sortOrder,
    };
  }

  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
  }

  params.filter = {
    ...params.filter,
    rtUtilityInactive: { ne: true },
  };

  return API.graphql(graphqlOperation(customQueries.searchTenants, params));
}

export function findObjects(queryParams, additionalFilters, nextToken) {
  const params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(customQueries.listTenants, params));
}

export function findObjectsByDefaultPartnerCode(
  defaultPartnerCode,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    limit: queryParams.pageSize,
    defaultPartnerCode: defaultPartnerCode,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(customQueries.tenantsByDefaultPartnerCode, params));
}

export function findObjectsShortCode(
  shortCode,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    limit: queryParams.pageSize,
    shortCode: shortCode,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(customQueries.tenantsByShortCode, params));
}

export function findObjectsByCompanyVat(
  companyVat,
  queryParams,
  additionalFilters,
  nextToken,
  customQuery = null
) {
  const params = {
    limit: queryParams.pageSize,
    companyVat: companyVat,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = { ...params.filter, ...additionalFilters };
  }

  return API.graphql(graphqlOperation(customQuery ? customQueries[customQuery] : customQueries.tenantsByCompanyVat, params));
}

export function getObjectById(id, customQuery = null) {
  return API.graphql(graphqlOperation(customQuery ? customQueries[customQuery] : customQueries.getTenant, { id: id }));
}

export function updateObject(obj) {
  return API.graphql(graphqlOperation(updateTenant, { input: obj }));
}

export function updateObjects(objs) {
  var updates = [];
  objs.forEach((obj) => {
    updates.push(
      new Promise((resolve, reject) => {
        return resolve(updateObject(obj));
      })
    );
  });
  return Promise.all(updates);
}

export function decrementCashRegisterCount(tenantId) {
  return getObjectById(tenantId).then((result) => {
    const tenant = result.data.getTenant;
    const newTenant = {
      id: tenant.id,
      cashRegisterCount: tenant.cashRegisterCount
        ? tenant.cashRegisterCount - 1
        : 0,
    };

    return updateObject(newTenant);
  });
}

export function decrementMoitoIotCount(tenantId) {
  return getObjectById(tenantId).then((result) => {
    const tenant = result.data.getTenant;
    const newTenant = {
      id: tenant.id,
      moitoIotCount: tenant.moitoIotCount
        ? tenant.moitoIotCount - 1
        : 0,
    };

    return updateObject(newTenant);
  });
}
