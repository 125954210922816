import React from "react";

const getValueFromStringPath = (obj, path) => {
  return path.split(".").reduce(function (o, k) {
    return o && o[k];
  }, obj);
};

export function ShowMultipleEntities({ entities, id, name }) {
  return (
    <div className="timeline timeline-5 mt-3">
      {entities.map((entity) => (
        <div className="timeline-item align-items-start" key={`${entity.id}`}>
          <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
          <div className="timeline-badge">
            <i className="fa fa-genderless icon-xxl" />
          </div>
          <div className="timeline-content text-dark-50 mr-5">
            <span className="ml-3">{getValueFromStringPath(entity, name)}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
