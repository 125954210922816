import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";
import Countries from "../../common/localization/countries";
import { CreatableLookupField } from "../lookup/CreatableLookupField";
import { orderBy } from "lodash";
import { GetValueFromStringPath } from "../functions/GetValueFromStringPath";

export function CountryLookupField({ country, disabled = false, required = false }) {
  const intl = useIntl();
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext();

  const [defaultCountry, setDefaultCountry] = useState(null);

  useEffect(() => {
    const value = GetValueFromStringPath(values, country);
    if (value) {
      setDefaultCountry({ value: value, label: value });
      setFieldTouched(country);
    } else {
      setDefaultCountry({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[country]]);

  return (
    <CreatableLookupField
      defaultValue={defaultCountry}
      label={intl.formatMessage({ id: "PARTY.COUNTRY" })}
      data={orderBy(Countries, ["label"], ["asc"])}
      labelField="label"
      valueField="value"
      onChange={(val) => setFieldValue(country, val.value)}
      disabled={disabled}
      required={required}
    />
  );
}
