import React from "react";
import Can from "../../AWS/Can";
import { UserNotificationsDropdown } from "../../User/components/UserNotificationsDropdown";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../../../../_metronic/layout/components/extras/QuiclUserToggler";
import { NavLink, useLocation, Link } from "react-router-dom";
import { getMenuItemActive } from "../../../utils";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";
import { ModuleVisualizationDropdown } from "../../User/components/ModuleVisualizationDropdown";

export function Topbar() {
  const location = useLocation();

  return (
    <div className="topbar w-100">
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex align-items-stretch">
          <div
            className="header-menu-wrapper header-menu-wrapper-left"
            id="kt_header_menu_wrapper"
          >
            <div className="header-logo">
              <Link to="/dashboard">
                <img
                  alt="logo"
                  src={toAbsoluteUrl(
                    "/media/coristech/logos/dtrservice_white.jpg"
                  )}
                  style={{ width: 180, height: 60 }}
                />
              </Link>
            </div>
          </div>

          <div className="border-left pr-2 pl-2"> </div>

          <div className="" id="kt_header_menu_wrapper">
            <div
              id="kt_header_menu"
              className={`header-menu header-menu-mobile`}
            >
              <ul className={`menu-nav p-0 d-flex align-items-stretch`}>
                <Can I="create" a="einvoice_invoice">
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/einvoice/composer"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/einvoice/composer">
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.EINVOICE.COMPOSER" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="upload" a="einvoice_invoice">
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/einvoice/upload"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/einvoice/upload">
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.EINVOICE.UPLOAD" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="einvoice_active_invoices">
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/einvoice/active_invoices"
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to="/einvoice/active_invoices"
                    >
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.EINVOICE.ACTIVE_INVOICES" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="einvoice_passive_invoices">
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/einvoice/passive_invoices"
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to="/einvoice/passive_invoices"
                    >
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.EINVOICE.PASSIVE_INVOICES" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="customer">
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/customers/customers"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/customers/customers">
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.CUSTOMER.CUSTOMERS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="manage" a="einvoice_items">
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/einvoice/items"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/einvoice/items">
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.PRODUCT.ITEMS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="sectional">
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/accounting/sectionals"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/accounting/sectionals">
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.ACCOUNTING.SECTIONALS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="einvoice_settings">
                  <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                      location,
                      "/einvoice/settings"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/einvoice/settings">
                      <span className="menu-text">
                        <FormattedMessage id="SERVICE.ASIDE.EINVOICE.SETTINGS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>
              </ul>
            </div>
          </div>

          <div className="border-right pr-2 pl-2"> </div>
        </div>

        <div className="d-flex align-items-stretch">
          <ModuleVisualizationDropdown show={false} />

          <UserNotificationsDropdown />
          <LanguageSelectorDropdown />
          <QuickUserToggler />
        </div>
      </div>
    </div>
  );
}
