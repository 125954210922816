import {
  AUTH_USER,
  AUTH_MFA,
  AUTH_NEW_PASSWORD_REQUIRED,
  UNAUTH_USER,
  REGISTER_USER,
  REGISTER_USER_CONFIRM,
  REGISTER_MFA,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CONFIRM,
  AUTH_ERROR,
  AUTH_ERROR_CLEAR,
  COMPLETE_REGISTRATION,
  UPDATE_USER_PARTY,
  UPDATE_TENANT_PARTY,
  UPDATE_TENANT,
  UPDATE_USER,
  REFRESH_CURRENT_USER,
  INCREMENT_ITEM_SORTING,
  INCREMENT_ITEMGROUP_SORTING,
  CHANGE_LOGIN_TYPE,
  CANCEL_FORGOT_PASSWORD,
  CANCEL_REGISTRATION,
  FORCE_REFRESH_TOKEN,
  PENDING_SOCIAL_SIGN_IN,
  SET_USER_TYPE
} from "./types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

/* export default function(state = {}, action) { */
const initialAuthState = {
  user: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "auth" /* , whitelist: ["user", "authToken"] */ },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case AUTH_USER: {
        var result = {
          ...state,
          error: "",
          authenticated: true,
          mfa: false,
          changePassword: false,
          cognitoUser: action.payload.cognitoUser,
          loginType: action.payload.loginType,
          pendingSocialSignIn: false,
          lastReduxAction: "AUTH_USER",
        };

        if (action.payload.user) {
          result.user = {
            ...action.payload.user,
            tenantUser: {
              ...action.payload.user.tenantUser,
              items: action.payload.user.tenantUser.items.map((item) => {
                return {
                  ...item,
                  tenant: {
                    ...item.tenant,
                    extensions: JSON.parse(item.tenant.extensions),
                  },
                };
              }),
            },
            userRetailStore: {
              ...action.payload.user.userRetailStore,
              items: action.payload.user.userRetailStore.items.map((item) => {
                return {
                  ...item,
                  retailStore: {
                    ...item.retailStore,
                    extensions: JSON.parse(item.retailStore.extensions),
                  },
                };
              }),
            },
          };
        } else {
          result.loginType = 0;
        }

        const returnProps = result;
        return returnProps;
      }
      case SET_USER_TYPE: {
        const returnProps = {
          ...state,
          userType: action.payload.userType,
          lastReduxAction: "SET_USER_TYPE",
        };

        return returnProps;
      }
      case PENDING_SOCIAL_SIGN_IN: {
        const returnProps = {
          ...state,
          pendingSocialSignIn: true,
          socialProvider: action.payload.provider,
          lastReduxAction: "PENDING_SOCIAL_SIGN_IN",
        };

        return returnProps;
      }
      case FORCE_REFRESH_TOKEN: {
        const returnProps = {
          ...state,
          cognitoUser: action.payload,
          lastReduxAction: "FORCE_REFRESH_TOKEN",
        };

        return returnProps;
      }
      case AUTH_MFA: {
        console.log("AUTH_MFA");

        const returnProps = {
          ...state,
          error: "",
          authenticated: false,
          mfa: true,
          changePassword: false,
          cognitoUser: action.payload,
          lastReduxAction: "AUTH_MFA",
        };

        return returnProps;
      }
      case AUTH_NEW_PASSWORD_REQUIRED: {
        console.log("AUTH_NEW_PASSWORD_REQUIRED");

        const returnProps = {
          ...state,
          error: "",
          authenticated: false,
          mfa: false,
          changePassword: true,
          cognitoUser: action.payload,
          lastReduxAction: "AUTH_NEW_PASSWORD_REQUIRED",
        };

        return returnProps;
      }
      case UNAUTH_USER: {
        console.log("UNAUTH_USER");

        const returnProps = {
          ...state,
          error: "",
          authenticated: false,
          user: null,
          cognitoUser: null,
          pendingSocialSignIn: false,
          socialProvider: "",
          lastReduxAction: "UNAUTH_USER",
          source: action.payload.source,
        };

        return returnProps;
      }
      case REGISTER_USER: {
        console.log("REGISTER_USER");

        const returnProps = {
          ...state,
          error: "",
          authenticated: false,
          register: true,
          lastReduxAction: "REGISTER_USER",
        };

        return returnProps;
      }
      case REGISTER_USER_CONFIRM: {
        console.log("REGISTER_USER_CONFIRM");

        const returnProps = {
          ...state,
          error: "",
          authenticated: false,
          register: true,
          mfa: false,
          resendCode: false,
          userType: action.payload.userType,
          registrationMethod: action.payload.registrationMethod,
          lastReduxAction: "REGISTER_USER_CONFIRM",
        };

        return returnProps;
      }
      case REGISTER_MFA: {
        const returnProps = {
          ...state,
          error: "",
          mfa: true,
          resendCode: false,
          cognitoUser: action.payload,
          lastReduxAction: "REGISTER_MFA",
        };

        return returnProps;
      }
      case REGISTER_USER_ERROR: {
        const returnProps = {
          ...state,
          error: action.payload,
          mfa: true,
          resendCode: true,
          cognitoUser: action.cognitoUser,
          lastReduxAction: "REGISTER_USER_ERROR",
        };

        return returnProps;
      }
      case AUTH_ERROR: {
        console.log("AUTH_ERROR");

        const returnProps = {
          ...state,
          error: action.payload,
          authenticated: false,
          lastReduxAction: "AUTH_ERROR",
        };

        return returnProps;
      }
      case FORGOT_PASSWORD: {
        console.log("FORGOT_PASSWORD");

        const returnProps = {
          ...state,
          error: "",
          forgotPasswordCode: true,
          authenticated: false,
          lastReduxAction: "FORGOT_PASSWORD",
        };

        return returnProps;
      }
      case FORGOT_PASSWORD_CONFIRM: {
        console.log("FORGOT_PASSWORD_CONFIRM");

        const returnProps = {
          ...state,
          error: "",
          forgotPasswordCode: false,
          authenticated: false,
          lastReduxAction: "FORGOT_PASSWORD_CONFIRM",
        };

        return returnProps;
      }
      case AUTH_ERROR_CLEAR: {
        console.log("AUTH_ERROR_CLEAR");
        
        const returnProps = { 
          ...state, 
          error: "",
          lastReduxAction: "AUTH_ERROR_CLEAR",
          /*, authenticated: false*/ 
        };

        return returnProps;
      }
      case COMPLETE_REGISTRATION: {
        const returnProps = {
          ...state,
          user: action.payload.user,
          lastReduxAction: "COMPLETE_REGISTRATION",
        };
        return returnProps;
      }
      case UPDATE_USER_PARTY: {
        const returnProps = {
          ...state,
          lastReduxAction: "UPDATE_USER_PARTY",
          user: {
            ...state.user,
            ...action.payload.user,
          },
        };
        return returnProps;
      }
      case UPDATE_TENANT_PARTY: {
        const resultState = {
          ...state,
          lastReduxAction: "UPDATE_TENANT_PARTY",
          user: {
            ...state.user,
            tenantUser: {
              ...state.user.tenantUser,
              items: state.user.tenantUser.items.map((item) => {
                if (item.tenantId === action.payload.tenant.id) {
                  item.tenant = { ...item.tenant, ...action.payload.tenant };
                }
                return item;
              }),
            },
          },
        };
        return resultState;
      }
      case UPDATE_TENANT: {
        const stateResult = {
          ...state,
          lastReduxAction: "UPDATE_TENANT",
          user: {
            ...state.user,
            tenantUser: {
              ...state.user.tenantUser,
              items: state.user.tenantUser.items.map((item) => {
                if (item.tenantId === action.payload.tenantId) {
                  item.tenant = {
                    ...action.payload.tenant,
                    extensions: JSON.parse(action.payload.tenant.extensions),
                  };
                }
                return item;
              }),
            },
          },
        };

        return stateResult;
      }
      case UPDATE_USER: {
        if (action.payload.user.id === state.user.id) {
          const stateResult = {
            ...state,
            lastReduxAction: "UPDATE_USER",
            user: {
              ...state.user,
              ...action.payload.user,
              tenantUser: {
                ...state.user.tenantUser,
              },
            },
          };

          return stateResult;
        }
        return { ...state };
      }
      case REFRESH_CURRENT_USER: {
        var rfc_result = {
          ...state,
          lastReduxAction: "REFRESH_CURRENT_USER",
        };

        if (action.payload.user) {
          rfc_result.user = {
            ...action.payload.user,
            tenantUser: {
              ...action.payload.user.tenantUser,
              items: action.payload.user.tenantUser.items.map((item) => {
                return {
                  ...item,
                  tenant: {
                    ...item.tenant,
                    extensions: JSON.parse(item.tenant.extensions),
                  },
                };
              }),
            },
            userRetailStore: {
              ...action.payload.user.userRetailStore,
              items: action.payload.user.userRetailStore.items.map((item) => {
                return {
                  ...item,
                  retailStore: {
                    ...item.retailStore,
                    extensions: JSON.parse(item.retailStore.extensions),
                  },
                };
              }),
            },
          };
        }

        const returnProps = rfc_result;
        return returnProps;
      }
      case INCREMENT_ITEM_SORTING: {
        return {
          ...state,
          user: {
            ...state.user,
            tenantUser: {
              ...state.user.tenantUser,
              items: state.user.tenantUser.items.map((item) => {
                if (item.tenantId === action.payload.tenantId) {
                  return {
                    ...item,
                    tenant: {
                      ...item.tenant,
                      nextItemSorting: action.payload.nextItemSorting,
                    },
                  };
                }
                return { ...item };
              }),
            },
          },
        };
      }
      case INCREMENT_ITEMGROUP_SORTING: {
        return {
          ...state,
          user: {
            ...state.user,
            tenantUser: {
              ...state.user.tenantUser,
              items: state.user.tenantUser.items.map((item) => {
                if (item.tenantId === action.payload.tenantId) {
                  return {
                    ...item,
                    tenant: {
                      ...item.tenant,
                      nextItemGroupSorting: action.payload.nextItemGroupSorting,
                    },
                  };
                }
                return { ...item };
              }),
            },
          },
        };
      }
      case CHANGE_LOGIN_TYPE: {
        return {
          ...state,
          lastReduxAction: "CHANGE_LOGIN_TYPE",
          loginType: action.payload.loginType,
        };
      }
      case CANCEL_FORGOT_PASSWORD: {
        return {
          ...state,
          lastReduxAction: "CANCEL_FORGOT_PASSWORD",
          forgotPasswordCode: false,
        };
      }
      case CANCEL_REGISTRATION: {
        return {
          ...state,
          lastReduxAction: "CANCEL_REGISTRATION",
          resendCode: false,
          mfa: false,
        };
      }

      default:
        return state;
    }
  }
);
