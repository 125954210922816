import * as request from "./crud";
import { orderBy } from "lodash";
import { GenerateSlice, SliceCallTypes } from "../../../../utils/components/generic/_redux";

const sliceActions = GenerateSlice("tenants").actions;

const clearData = () => (dispatch) => {
  dispatch(sliceActions.clearData());
};

const setEntity = (data) => (dispatch) => {
  dispatch(sliceActions.setEntity(data));
};

const searchObjects =
  (queryParams, additionalFilters, nextToken, operation = "overwrite", saveRedux = true) =>
  (dispatch) => {
    if (saveRedux) {
      dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));
    }

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.searchObjects(
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal,
          );
          var data = result.data.searchTenants;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );

        if (saveRedux) {
          dispatch(
            sliceActions.objectsFetched({
              totalCount: data.total,
              operation: operation,
              items: sortedItems,
              nextToken: nextTokenInternal,
              prevToken: nextToken,
            })
          );
        }

        return resolve({
          data: {
            searchTenants: {
              items: sortedItems,
              nextToken: nextTokenInternal,
              total: data.total,
            },
          },
        });
      } catch (error) {
        error.clientMessage = "Can't find tenants";

        if (saveRedux) {
          dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        }
        //return reject(error);
      }
    });
  };

const fetchObjects =
  (queryParams, additionalFilters, nextToken, operation = "overwrite") =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjects(
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal
          );
          var data = result.data.listTenants;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: sortedItems.length,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve(sortedItems);
      } catch (error) {
        error.clientMessage = "Can't find tenants";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };

const fetchObjectsByDefaultPartnerCode =
  (
    defaultPartnerCode,
    queryParams,
    additionalFilters,
    nextToken,
    operation = "overwrite"
  ) =>
  (dispatch) => {
    dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));

    var nextTokenInternal = nextToken;
    var items = [];

    return new Promise(async (resolve, reject) => {
      try {
        do {
          var result = await request.findObjectsByDefaultPartnerCode(
            defaultPartnerCode,
            {
              ...queryParams,
              pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
            },
            additionalFilters,
            nextTokenInternal
          );
          var data = result.data.tenantsByDefaultPartnerCode;

          items = items.concat(data.items);
          nextTokenInternal = data.nextToken;
          if (items.length >= queryParams.pageSize) {
            break;
          }
        } while (nextTokenInternal !== null);

        const sortedItems = orderBy(
          items,
          [queryParams.sortField],
          [queryParams.sortOrder]
        );
        dispatch(
          sliceActions.objectsFetched({
            totalCount: sortedItems.length,
            operation: operation,
            items: sortedItems,
            nextToken: nextTokenInternal,
            prevToken: nextToken,
          })
        );

        return resolve(sortedItems);
      } catch (error) {
        error.clientMessage = "Can't find tenants";
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
        //return reject(error);
      }
    });
  };

/*
export const searchObjects = (queryParams, additionalFilters, nextToken, operation = "overwrite") => dispatch => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.list }));
  return request
    .searchObjects(queryParams, additionalFilters, nextToken)
    .then(response => {
      const items = response.data.searchTenants.items;
      const responseNextToken = response.data.searchTenants.nextToken;
      const totalCount = items.length;

      const sortedItems = orderBy(items, [queryParams.sortField],[queryParams.sortOrder]);

      dispatch(sliceActions.objectsFetched({ totalCount, operation, items: sortedItems, nextToken: responseNextToken, prevToken: nextToken }));
    })
    .catch(error => {
      error.clientMessage = "Can't find tenants";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.list }));
    });
};
*/

const getByShortCode = (code) => async (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));

  const queryParams = {
    pageSize: 1000,
    filter: {
      shortCode: { eq: code },
      isPartner: { eq: true },
    },
  };

  var nextToken = null;

  try {
    do {
      var result = await request.searchObjects(
        {
          ...queryParams,
          pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
        },
        null,
        nextToken
      );
      var data = result.data.searchTenants;

      if (data && data.items && data.items.length > 0) {
        dispatch(sliceActions.objectFetched({ entity: data.items[0] }));
        return data.items[0];
      }

      nextToken = data.nextToken;
    } while (nextToken !== null);

    dispatch(sliceActions.objectFetched({ entity: undefined }));
    return undefined;
  } catch (error) {
    error.clientMessage = "Can't get tenant by code";
    dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    return error;
  }
};

const getByVatNumber =
  (vatNum, checkEnabled, saveRedux = true) =>
  async (dispatch) => {
    if (saveRedux) {
      dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
    }

    var queryParams = {
      pageSize: 1000,
    };

    if (checkEnabled) {
      if (queryParams.filter) {
        queryParams.filter = {
          ...queryParams.filter,
          enabled: { eq: true },
        };
      } else {
        queryParams.filter = {
          enabled: { eq: true },
        };
      }
    }

    var nextToken = null;

    try {
      do {
        var result = await request.findObjectsByCompanyVat(
          vatNum,
          {
            ...queryParams,
            pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
          },
          null,
          nextToken,
          "lightTenantsByCompanyVat"
        );
        var data = result.data.tenantsByCompanyVat;

        if (data.items.length > 0) {
          if (saveRedux) {
            dispatch(sliceActions.objectFetched({ entity: data.items[0] }));
          }
          return data.items[0];
        }

        nextToken = data.nextToken;
      } while (nextToken !== null);

      if (saveRedux) {
        dispatch(sliceActions.objectFetched({ entity: undefined }));
      }
      return undefined;
    } catch (error) {
      error.clientMessage = "Can't get tenant by vat number";
      if (saveRedux) {
        dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
      }
      return error;
    }
  };

const updateObject = (object) => (dispatch) => {
  var newObject = { ...object };
  if (newObject && newObject.extensions) {
    newObject.extensions = JSON.stringify(newObject.extensions);
  }
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .updateObject(newObject)
    .then((response) => {
      dispatch(
        sliceActions.objectsUpdated({ objects: [response.data.updateTenant] })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't update tenant";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
      return error;
    });
};

const updateObjects = (objects) => (dispatch) => {
  dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
  return request
    .updateObjects(objects)
    .then((response) => {
      const objects = response.map((obj) => obj.data.updateTenant);
      dispatch(sliceActions.objectsUpdated({ objects: objects }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update tenants";
      dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
    });
};

const fetchObject =
  (id, saveRedux = true, customQuery = null) =>
  (dispatch) => {
    if (!id) {
      if (saveRedux) {
        return dispatch(sliceActions.objectFetched({ entity: undefined }));
      }
    }

    if (saveRedux) {
      dispatch(sliceActions.startCall({ callType: SliceCallTypes.action }));
    }
    return request
      .getObjectById(id, customQuery)
      .then((response) => {
        const obj = response.data.getTenant;
        if (saveRedux) {
          dispatch(sliceActions.objectFetched({ entity: obj }));
        }
        return response;
      })
      .catch((error) => {
        error.clientMessage = "Can't find tenant";
        if (saveRedux) {
          dispatch(sliceActions.catchError({ error, callType: SliceCallTypes.action }));
        }
        return error;
      });
  };

const decrementCashRegisterCount = (tenantId) => (dispatch) => {
  return request.decrementCashRegisterCount(tenantId);
};

const decrementMoitoIotCount = (tenantId) => (dispatch) => {
  return request.decrementMoitoIotCount(tenantId);
};

export const actions = {
  clearData,
  setEntity,
  searchObjects,
  fetchObjects,
  fetchObjectsByDefaultPartnerCode,
  getByShortCode,
  getByVatNumber,
  updateObject,
  updateObjects,
  fetchObject,
  decrementCashRegisterCount,
  decrementMoitoIotCount,
}