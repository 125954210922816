export default {
  plu: [],
  departments: [
    {
      no: 1,
      name: "reparto 1",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 2,
      name: "reparto 2",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 3,
      name: "reparto 3",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 4,
      name: "reparto 4",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 5,
      name: "reparto 5",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 6,
      name: "reparto 6",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 7,
      name: "reparto 7",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 8,
      name: "reparto 8",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 9,
      name: "reparto 9",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 10,
      name: "reparto 10",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 11,
      name: "reparto 11",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 12,
      name: "reparto 12",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 13,
      name: "reparto 13",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 14,
      name: "reparto 14",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 15,
      name: "reparto 15",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 16,
      name: "reparto 16",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 17,
      name: "reparto 17",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 18,
      name: "reparto 18",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 19,
      name: "reparto 19",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 20,
      name: "reparto 20",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 21,
      name: "reparto 21",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 22,
      name: "reparto 22",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 23,
      name: "reparto 23",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 24,
      name: "reparto 24",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 25,
      name: "reparto 25",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 26,
      name: "reparto 26",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 27,
      name: "reparto 27",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 28,
      name: "reparto 28",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 29,
      name: "reparto 29",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 30,
      name: "reparto 30",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 31,
      name: "reparto 31",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 32,
      name: "reparto 32",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 33,
      name: "reparto 33",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 34,
      name: "reparto 34",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 35,
      name: "reparto 35",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 36,
      name: "reparto 36",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 37,
      name: "reparto 37",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 38,
      name: "reparto 38",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 39,
      name: "reparto 39",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 40,
      name: "reparto 40",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 41,
      name: "reparto 41",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 42,
      name: "reparto 42",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 43,
      name: "reparto 43",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 44,
      name: "reparto 44",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 45,
      name: "reparto 45",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 46,
      name: "reparto 46",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 47,
      name: "reparto 47",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 48,
      name: "reparto 48",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 49,
      name: "reparto 49",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 50,
      name: "reparto 50",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 51,
      name: "reparto 51",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 52,
      name: "reparto 52",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 53,
      name: "reparto 53",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 54,
      name: "reparto 54",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 55,
      name: "reparto 55",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 56,
      name: "reparto 56",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 57,
      name: "reparto 57",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 58,
      name: "reparto 58",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 59,
      name: "reparto 59",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 60,
      name: "reparto 60",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 61,
      name: "reparto 61",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 62,
      name: "reparto 62",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 63,
      name: "reparto 63",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 64,
      name: "reparto 64",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 65,
      name: "reparto 65",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 66,
      name: "reparto 66",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 67,
      name: "reparto 67",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 68,
      name: "reparto 68",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 69,
      name: "reparto 69",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 70,
      name: "reparto 70",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 71,
      name: "reparto 71",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 72,
      name: "reparto 72",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 73,
      name: "reparto 73",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 74,
      name: "reparto 74",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 75,
      name: "reparto 75",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 76,
      name: "reparto 76",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 77,
      name: "reparto 77",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 78,
      name: "reparto 78",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 79,
      name: "reparto 79",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 80,
      name: "reparto 80",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 81,
      name: "reparto 81",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 82,
      name: "reparto 82",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 83,
      name: "reparto 83",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 84,
      name: "reparto 84",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 85,
      name: "reparto 85",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 86,
      name: "reparto 86",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 87,
      name: "reparto 87",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 88,
      name: "reparto 88",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 89,
      name: "reparto 89",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 90,
      name: "reparto 90",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 91,
      name: "reparto 91",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 92,
      name: "reparto 92",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 93,
      name: "reparto 93",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 94,
      name: "reparto 94",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 95,
      name: "reparto 95",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 96,
      name: "reparto 96",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 97,
      name: "reparto 97",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 98,
      name: "reparto 98",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
    {
      no: 99,
      name: "reparto 99",
      price: 0,
      limit: 0,
      discType: "-",
      percentFlag: true,
      discount: 0,
      pluidx: 0,
      group: 0,
      freePrice: true,
      inputQty: true,
      autoClose: false,
      acCodeIdx: 1,
      TaxIdx: 1,
    },
  ],
  groups: [],
  systemFlag: {
    "Stampa Sbt": false,
    "St Valori=0?": false,
    "Imp. Pagam.": false,
    "Stampa Logo": false,
    "Prest. Logo": false,
    "Autospegnim.": false,
    "Q.obblig": false,
    "Autodis. Op.": false,
    Beep: false,
    "St. N.Pezzi": false,
    "DECIMALI AUTOMATICI": false,
    "Sensore Quasi Fine": false,
    "NO AUTO CUT?": false,
    "Stampa Barcode Cli.": false,
    "STAMPA INT.": false,
    "Clienti IVA importo": false,
    "Stampa IVA lista": false,

    "APRI CASSETTO A Z": false,
    "DEFAULT STAN 0": false,
    "Decimali con": false,
    "PROLUNGA ATTIVITA": false,
    "Pagamenti lotteria": false,
    "STAMPA QTY UNITARIA": false,
    "Stampa Footer Logo": false,
    "Disable EftPos": true,
  },
  discountinfo: {
    discount: {
      name: "ART",
      discType: "+",
      percentFlag: false,
      value: 0,
      subflag: false,
    },
    subdiscount: {
      name: "SBT",
      discType: "-",
      percentFlag: false,
      value: 0,
      subflag: false,
    },
    discount1: {
      name: "-+1",
      discType: "+",
      percentFlag: false,
      value: 0,
      subflag: false,
    },
    discount2: {
      name: "-+2",
      discType: "-",
      percentFlag: false,
      value: 0,
      subflag: false,
    },
    discount3: {
      name: "-+3",
      discType: "+",
      percentFlag: false,
      value: 0,
      subflag: false,
    },
    discount4: {
      name: "-+4",
      discType: "+",
      percentFlag: false,
      value: 0,
      subflag: false,
    },
  },
  HeadrInfo: {
    Lunghezza: 32,
    lines: [
      { line: 1, msg: "", doubleHigh: false },
      { line: 2, msg: "", doubleHigh: false },
      { line: 3, msg: "", doubleHigh: false },
      { line: 4, msg: "", doubleHigh: false },
      { line: 5, msg: "", doubleHigh: false },
      { line: 6, msg: "", doubleHigh: false },
      { line: 7, msg: "", doubleHigh: false },
      { line: 8, msg: "", doubleHigh: false },
      { line: 9, msg: "", doubleHigh: false },
    ],
  },
  TAX: [
    {
      taxid: 1,
      code: "A",
      rate: 4,
    },
    {
      taxid: 2,
      code: "B",
      rate: 10,
    },
    {
      taxid: 3,
      code: "C",
      rate: 22,
    },
    {
      taxid:4,
      code:"D",
      rate:5
    },
    {
      taxid:5,
      code:"E",
      rate:0
    },
    {
      taxid:6,
      code:"F",
      rate:0
    },
    {
      taxid:7,
      code:"VI"
    },
    {
      taxid:8,
      code:"EE"
    },
    {
      taxid:9,
      code:"NS"
    },
    {
      taxid:10,
      code:"NI"
    },
    {
      taxid:11,
      code:"ES"
    },
    {
      taxid:12,
      code:"RM"
    },
    {
      taxid:13,
      code:"AL"
    }
  ],
  tail: {
    lines: [
      {
        line: 1,
        msg: "",
        prtFlag: 0,
      },
      {
        line: 2,
        msg: "",
        prtFlag: 0,
      },
    ],
    tailSpaceLine: 1,
  },
  PrnDensity: 5,
  advertMsg: {
    msg: "",
    display: true,
  },
  clerk: [],
  macro: [],
  currency: [],
  Ateco: [],
  omaggio: [],
  PayKey: [
    {
      key: "ASSEGNI Key",
      description: "PAGAME. DEFAULT",
    },
    {
      key: "TICKET Key",
      description: "TUTTI I PAGAME.",
    },
    {
      key: "CREDITO Key",
      description: "NON PAGATO CREDITO",
    },
  ],
  varie: {
    SetPsw: 0,
    printReportFlag: {
      Finanziario: false,
      "Operatore Per.": false,
      "Repati Giorno": false,
      "IVA Giorno": false,
      "Vendite Orarie Per": false,
    },
    barcodeType: 0,
    fpflag: false,
    display: false,
    fptype: "Rs232",
    BaudRate: 9600,
  },
  programkey: {
    programkey0: [],
    programkey1: [],
  },
  logo: {},
  urlData: {
    z_report_url:
      "https://apid-ivaservizi.agenziaentrate.gov.it/v1/dispositivi/",
    lottery_file_url:
      "https://apid-ivaservizi.agenziaentrate.gov.it/v1/dispositivi/lotteria/corrispettivi/",
    ssl_crt_filepath: "",
    xml_crt_filepath: "",
    lotteryStartDate: "16-10-2023",
  },
  serverServices: "https://api.dtr-service.it/",
  footerlogo: {},
};
