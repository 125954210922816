import { SET_CURRENT_STORE, SET_CURRENT_STORE_ASYNC } from "./types";

function setCurrentStore(storeId) {
  return function (dispatch) {
    dispatch({ type: SET_CURRENT_STORE, payload: storeId });
  };
}

function setCurrentStoreAsync(storeId) {
  return function (dispatch) {
    dispatch({ type: SET_CURRENT_STORE_ASYNC, payload: storeId });
  };
}

export const actions = {
	setCurrentStore,
	setCurrentStoreAsync,
}