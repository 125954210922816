import * as request from "./crud";
import { slice, callTypes } from "./slice";
import { GenerateActions, GenerateSlowFetchObjects, GenerateFetchObjectsBy } from "../../../../utils/components/generic/_redux";

const sliceActions = slice.actions;

export const actions = {
  ...GenerateActions(sliceActions, request, "ECRDailySales"),
  fetchObjects: (qp, af, nextToken, op) => GenerateSlowFetchObjects(
    sliceActions,
    request.findObjects,
    "listECRDailySaless",
    qp,
    af,
    nextToken,
    op
  ),
  fetchObjectsByElectronicCashRegisterId: (ecrId, qp, af, nextToken, op, cc) => GenerateFetchObjectsBy(
    sliceActions,
    request.findObjectsByElectronicCashRegisterId,
    "ecrDailySalesByElectronicCashRegisterId",
    ecrId,
    qp,
    af,
    nextToken,
    op,
    cc
  ),
  fetchAllObjectsByMultipleElectronicCashRegisterId: (ecrIds, queryParams) => (dispatch) => {
    dispatch(sliceActions.startCall({ callType: callTypes.action }));

    return new Promise(async (resolve, reject) => {
      var entities = [];

      try {
        for (const ecrId of ecrIds) {
          var nextToken = null;

          do {
            var result = await request.findObjectsByElectronicCashRegisterId(
              ecrId,
              {
                ...queryParams,
                pageSize: queryParams.pageSize > 100 ? 100 : queryParams.pageSize,
              },
              null, 
              nextToken
            );
            var data = result.data.ecrDailySalesByElectronicCashRegisterId;

            entities = entities.concat(data.items);
            nextToken = data.nextToken;
          } while (nextToken !== null);
        }
      } catch (error) {
        //eslint-disable-line no-empty
      }

      dispatch(sliceActions.clearData());
      dispatch(sliceActions.objectsFetched({
        totalCount: entities.length,
        operation: "overwrite",
        items: entities,
        nextToken: null,
        prevToken: null,
      }));
      return resolve(entities);
    });
  },
};
