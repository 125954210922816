import { FileToBase64 } from "./FileToBase64";
import axios from "axios";
import * as restApi from "../../modules/Admin/rest/api";

export async function UploadProtectedFile(file, tenantId, s3Key, sendToastNotification, intl, callback) {
  return restApi.generatePreSignedURL(tenantId, s3Key, "putObject")
    .then(result => {
      //https://github.com/aws/aws-sdk-js/issues/2482
      FileToBase64(file)
      .then(r => {
        const buffer = Buffer.from(r.replace(/^data:image\/\w+;base64,/, ""), 'base64');
          
        axios.put(result.data, file.type.includes("image") ? buffer : file, {
          headers: { 
            'Content-Type': file.type,
            "Content-Encoding": "base64",
          }
        })
        .then((axiosResponse) => {
          callback();
        })
        .catch(() => {
          sendToastNotification("error", intl.formatMessage({ id: "SERVICE.ERROR_UPLOADING_FILE" }));
        });
      })
    })
    .catch((e) => {
      sendToastNotification("error", intl.formatMessage({ id: "SERVICE.ERROR_UPLOADING_FILE" }));
    });
}
