import * as Yup from "yup";
import * as restApi from "../../modules/Admin/rest/api";
import { YupCacheValidation } from "./YupCacheValidation";

const checkOnDb = async (value) => {
  return new Promise((resolve, reject) => {
    if (value && value.length === 8) {
      restApi.checkShortCode(value)
      .then(result => {
        if (result?.data?.exists && result?.data?.isPartner) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        resolve(false);
      });
    } else {
      resolve(false);
    }
  });
}

export function YupPartnerCodeValidation(dispatch, intl, required = true) {
  const checkOnDbTest = YupCacheValidation((value) => checkOnDb(value));

  var result = Yup.string()
    .max(
      8,
      intl.formatMessage({ id: "ADMIN.LICENSE.PARTNER_CODE_LENGTH_ERROR" })
    )
    .test(
      "checkCodeExistance",
      intl.formatMessage({ id: "ADMIN.LICENSE.PARTNER_CODE_EXISTANCE_ERROR" }),
      checkOnDbTest,
    );

  if (required) {
    return result.required(
      intl.formatMessage({ id: "SERVICE.PARTNER_CODE_IS_MANDATORY" })
    );
  }
  return result;
}
