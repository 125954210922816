import React from "react";
import Can from "../../AWS/Can";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { getMenuItemActive } from "../../../utils";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Aside({ isPartner }) {
  const location = useLocation();

  return (
    <>
      {isPartner && (
        <Can I="view" a="admin">
          {/* Admin */}
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              location,
              "/admin"
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className={"menu-link menu-toggle "} to="/admin">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")} />
              </span>
              <span className={"menu-text "}>
                <FormattedMessage id="SERVICE.ASIDE.ADMIN" />
              </span>
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">
                      <FormattedMessage id="SERVICE.ASIDE.ADMIN" />
                    </span>
                  </span>
                </li>

                <Can I="view" a="tenant">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/tenants"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className={"menu-link "} to="/admin/tenants">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.TENANT" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </Can>

                <Can I="view" a="license">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/licenses"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className={"menu-link "} to="/admin/licenses">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.LICENSES" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </Can>

                <Can I="view" a="user">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/users"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className={"menu-link "} to="/admin/users">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.USERS" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </Can>

                <Can I="view" a="onboarding">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/onboarding"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className={"menu-link "} to="/admin/onboarding">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.ONBOARDING" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </Can>

                <Can I="view" a="license_report">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/license_report"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className={"menu-link "}
                      to="/admin/license_report"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.LICENSE_REPORT" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </Can>

                <Can I="view" a="rtutility_report">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/rtutility_report"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className={"menu-link "}
                      to="/admin/rtutility_report"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.RTUTILITY_REPORT" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="view" a="moitoiot_report">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/moitoiot_report"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className={"menu-link "}
                      to="/admin/moitoiot_report"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.MOITOIOT_REPORT" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="manage" a="registration">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/ecr_registration"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className={"menu-link "}
                      to="/admin/ecr_registration"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.ECR_REGISTRATION" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </Can>

                <Can I="manage" a="moitoiot_registration">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/moitoiot_registration"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className={"menu-link "}
                      to="/admin/moitoiot_registration"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.MOITOIOT_REGISTRATION" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <Can I="manage" a="license_setting">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/license_setting"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className={"menu-link "}
                      to="/admin/license_setting"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.LICENSE_SETTING" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </Can>

                <Can I="manage" a="accountants">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/accountants"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className={"menu-link "}
                      to="/admin/accountants"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.ACCOUNTANTS" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </Can>

                <Can I="view" a="block_dealers">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/block_dealers"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className={"menu-link "}
                      to="/admin/block_dealers"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.BLOCK_DEALERS" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </Can>

                <Can I="view" a="user_access">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/user_access"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className={"menu-link "}
                      to="/admin/user_access"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.USER_ACCESS" />
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </Can>

                <Can I="manage" a="docs">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/admin/docs"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className={"menu-link "} to="/admin/docs">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.ADMIN.DOCS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>

                <li
                  className={`menu-item ${getMenuItemActive(
                    location,
                    "/admin/help"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className={"menu-link "} to="/admin/help">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className={"menu-text "}>
                      <FormattedMessage id="SERVICE.ASIDE.ADMIN.ONLINE_HELP" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          {/*end::1 Level*/}
        </Can>
      )}
    </>
  );
}
