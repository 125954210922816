import React, { useState } from "react";
import { useIntl } from "react-intl";

export function ManualRecordAdding({
  label,
  defaulValue,
  add,
  splitCharachers = [" ", "\n", "\t"],
  validate,
}) {
  const intl = useIntl();

  const [textareaValue, setTextareaValue] = useState(defaulValue);
  const [error, setError] = useState("");

  const onChangeTextarea = (value) => {
    const lastChar = value.slice(-1);
    if (splitCharachers.includes(lastChar)) {
      const newValue = value.slice(0, -1);
      validate(newValue)
        .then(() => {
          setError("");
          add(newValue);
          setTextareaValue("");
        })
        .catch((err) => {
          const error =
            err.errors.length > 0
              ? err.errors[0]
              : intl.formatMessage({ id: "SERVICE.ERROR" });
          setError(error);
        });
    } else {
      setTextareaValue(value);
    }
  };

  return (
    <>
      {label && <label>{label}</label>}
      <textarea
        value={textareaValue}
        onChange={(e) => onChangeTextarea(e.target.value)}
        className={"w-100 form-control " + (error ? "is-invalid" : "")}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
}
