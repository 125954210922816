import React, { useMemo } from "react";
import {
  Card as MetronicCard,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useIntl, FormattedMessage } from "react-intl";

export function Card({ 
  UIContext, 
  location = null, 
  titleId, 
  Filter, 
  Grouping,
  Table,
  otherButtons = []
}) {
  const intl = useIntl();

  const UIProps = useMemo(() => {
    return {
      ids: UIContext.ids,
      newButtonClick: UIContext.newButtonClick,
    };
  }, [UIContext]);

  return (
    <>
      {(!location || (!location.pathname.includes("/new") 
        && !location.pathname.match("([^,]+)/copy") 
        && !location.pathname.match("([^,]+)/massiveNew")
        && !location.pathname.match("([^,]+)/edit"))) && (
          <MetronicCard>
            <CardHeader
              title={intl.formatMessage({ id: titleId })}
            >
              <CardHeaderToolbar>
                {otherButtons.map((b, idx) => (
                  <div key={idx}>
                    {React.isValidElement(b) ? (
                      <>{b}</>
                    ) : (
                      <button
                        type="button"
                        className={"btn btn-" + (b.color || "primary") + " my-2 ml-2"}
                        onClick={b.onClick}
                      >
                        {b.icon && (
                          <>
                            <i className={b.icon}></i>
                            {" "}
                          </>
                        )}
                        <FormattedMessage id={b.labelId} />
                      </button>
                    )}

                    {" "}
                  </div>
                ))}

                <> </>

                {UIProps.newButtonClick && (
                  <button
                    type="button"
                    className="btn btn-primary my-2 ml-2"
                    onClick={UIProps.newButtonClick}
                  >
                    <i className="fas fa-plus"></i>
                    {" "}
                    <FormattedMessage id="SERVICE.NEW" />
                  </button>
                )}
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              {Filter && (
                <>{Filter}</>
              )}
              {Grouping && (
                <>{Grouping}</>
              )}
              {Table && (
                <>{Table}</>
              )}
            </CardBody>
          </MetronicCard>
      )}
    </>
  );
}
