import { API, graphqlOperation } from "aws-amplify";
import {
  listNotifications,
  notificationsByUser,
} from "../../../../../graphql/queries";
import {
  updateNotification,
  createNotification,
} from "../../../../../graphql/mutations";

export function findObjects(queryParams, additionalFilters, nextToken) {
  const params = {
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
  }

  return API.graphql(graphqlOperation(listNotifications, params));
}

export function findObjectsByUser(
  userId,
  queryParams,
  additionalFilters,
  nextToken
) {
  const params = {
    userId: userId,
    limit: queryParams.pageSize,
    nextToken: nextToken,
  };
  if (queryParams.filter && Object.keys(queryParams.filter).length > 0) {
    params.filter = queryParams.filter;
  }

  params.filter = {
    ...params.filter,
  };
  if (additionalFilters) {
    params.filter = {
      ...params.filter,
      ...additionalFilters,
    };
  }

  return API.graphql(graphqlOperation(notificationsByUser, params));
}

export function createObject(obj) {
  return API.graphql(graphqlOperation(createNotification, { input: obj }));
}

export function updateObject(obj) {
  return API.graphql(graphqlOperation(updateNotification, { input: obj }));
}

export function updateObjects(objs) {
  var updates = [];
  objs.forEach((obj) => {
    updates.push(
      new Promise((resolve, reject) => {
        return resolve(updateObject(obj));
      })
    );
  });
  return Promise.all(updates);
}
