import React, { useState, useEffect, useCallback, useRef } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { FormattedMessage, useIntl } from "react-intl";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import * as restApi from "../../modules/Admin/rest/api";

export function PdfDocument({ s3Key, tenantId }) {
  const intl = useIntl();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfPath, setPdfPath] = useState(1);

  useEffect(() => {
    setNumPages(null);
    setPageNumber(1);

    restApi.generatePreSignedURL(tenantId, s3Key, "getObject")
    .then((result) => {
      setPdfPath(result.data);
    });
  }, [s3Key]);

  const goToPrevPage = () => {
    setPageNumber((old) => old - 1);
  };
  const goToNextPage = () => {
    setPageNumber((old) => old + 1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onItemClick = (item) => {
    if (item && item.pageNumber) {
      setPageNumber(item.pageNumber);
    }
  };

  const renderBackNext = () => {
    return (
      <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
          <div className="text-center">
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={goToPrevPage}
              className="btn btn-light btn-hover-primary text-dark mx-1"
            >
              {"<"}
            </button>

            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={goToNextPage}
              className="btn btn-light btn-hover-primary text-dark mx-1"
            >
              {">"}
            </button>
          </div>
        </div>
        <div className="col-4">
          <div className="text-right">
            <a
              href={pdfPath}
              className="btn btn-transparent"
              target="_blank"
              download
            >
              <span className="fas fa fa-download"></span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  const onResize = useCallback(() => {
    if (ref.current) setWidth(ref.current.clientWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div className="w-100 p-0 m-0" ref={ref} >
      {pdfPath && (
        <div>
          {renderBackNext()}

          <Document
            file={pdfPath}
            onLoadSuccess={onDocumentLoadSuccess}
            className="text-center border border-dark border-1 my-2"
            onItemClick={onItemClick}
            error={intl.formatMessage({ id: "SERVICE.ERROR_LOADING_PDF" })}
            noData={intl.formatMessage({ id: "SERVICE.ERROR_LOADING_PDF" })}
            loading={intl.formatMessage({ id: "SERVICE.PDF_IS_LOADING" })}
          >
            <Page pageNumber={pageNumber} scale={1} width={parseInt(width * 0.98)} />
          </Document>

          <p className="text-center">
            <span>
              <FormattedMessage id="SERVICE.PAGE" /> {pageNumber}{" "}
              <FormattedMessage id="SERVICE.OF" /> {numPages}
            </span>
          </p>

          {renderBackNext()}
        </div>
      )}
    </div>
  );
}
