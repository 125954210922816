import React, { useState, useEffect } from "react";
import { getFromExtensions } from "../functions/manageExtensions";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import { Storage } from "aws-amplify";

export function ImagesCarousel({ row }) {
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);

  const style = {
    absolute: {
      position: "absolute",
    },
    img_size: {
      width: "100%",
      height: "100%",
    },
    img_cell: {
      width: 100,
      height: 100,
      cursor: "pointer",
    },
  };

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      const custom_images = row.images;
      const selected_images = getFromExtensions(
        row,
        "atorder",
        "selected_images",
        []
      );

      setImages(selected_images);

      if (custom_images && custom_images.length > 0) {
        custom_images.forEach((img_key) => {
          Storage.get(img_key).then((img) => {
            setImages((old) => [...old, img]);
          });
        });
      }
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {images.length > 0 && (
        <div className="text-center">
          <img
            src={images[0]}
            style={style.img_cell}
            onClick={() => setOpen(true)}
            className="rounded"
            alt=""
          />
          <br />
          <label className="text-muted">
            <small>{"1 / " + images.length}</small>
          </label>
        </div>
      )}
      <AutoRotatingCarousel
        open={open}
        onClose={() => setOpen(false)}
        onStart={() => setOpen(false)}
        style={style.absolute}
      >
        {images.map((image, idx) => (
          <Slide
            key={idx}
            media={<img src={image} style={style.img_size} alt="" />}
            title={row.name}
            subtitle={row.description}
          />
        ))}
      </AutoRotatingCarousel>
    </div>
  );
}
